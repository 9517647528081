import _ from 'lodash';
import cleanSet from 'clean-set';

function addNewMessage(state, action, identifierKey, id) {
  const newState = _.cloneDeep(state);
  const newMessage = action.payload.Message;
  let pendingMessages;
  let messages;
  if (newMessage.IsByPerson) {
    pendingMessages = _.get(state, ['Pending', identifierKey, id, action.payload.Message.From], []);
    pendingMessages = pendingMessages.filter(message => message.Id !== action.payload.Message.Id);
    _.setWith(newState, ['Pending', identifierKey, id, action.payload.Message.From], pendingMessages, Object);
    messages = _.get(state, [identifierKey, id, action.payload.Message.From, 'Messages'], []);
    let { unreadSmsCount } = (messages && messages.length > 0 && messages[0]) ?? {};
    if (!unreadSmsCount) unreadSmsCount = 0;
    unreadSmsCount += 1;
    const messageWithUnreadSmsCount = {
      ...action.payload.Message,
      unreadSmsCount,
    };
    const receivedMessageGuid = action.payload.Message.Id;
    const index = messages.findIndex(message => message.Id === receivedMessageGuid);
    if (index !== -1) messages[index] = messageWithUnreadSmsCount;
    else messages.unshift(messageWithUnreadSmsCount);
    _.setWith(
      newState,
      [identifierKey, id, messageWithUnreadSmsCount.From, 'Messages'],
      _.uniqBy(messages, 'Id'),
      Object
    );
  } else {
    pendingMessages = _.get(state, ['Pending', identifierKey, id, action.payload.Message.To], []);
    pendingMessages = pendingMessages.filter(message => message.Id !== action.payload.Message.Id);
    _.setWith(newState, ['Pending', identifierKey, id, action.payload.Message.To], pendingMessages, Object);
    messages = _.get(state, [identifierKey, id, action.payload.Message.To, 'Messages'], []);
    let { unreadSmsCount } = (messages && messages.length > 0 && messages[0]) ?? {};
    if (!unreadSmsCount) unreadSmsCount = 0;
    const messageWithUnreadSmsCount = {
      ...action.payload.Message,
      unreadSmsCount,
    };
    messages.unshift(messageWithUnreadSmsCount);
    _.setWith(
      newState,
      [identifierKey, id, messageWithUnreadSmsCount.To, 'Messages'],
      _.uniqBy(messages, 'Id'),
      Object
    );
  }
  return newState;
}

function setConversationMessages(state, action, identifierKey, id) {
  const newState = _.cloneDeep(state);
  const messages = _.get(state, [identifierKey, id, action.payload.PhoneNumber, 'Messages'], []);
  const messagesIds = messages.map(message => message.Id);

  let pendingMessages = _.get(state, ['Pending', identifierKey, id, action.payload.PhoneNumber], []);
  pendingMessages = pendingMessages.filter(message => !messagesIds.includes(message.Id));
  pendingMessages = pendingMessages.map(message => ({ ...message, Status: 'Pending' }));

  _.setWith(newState, ['Pending', identifierKey, id, action.payload.PhoneNumber], pendingMessages, Object);

  _.setWith(
    newState,
    [identifierKey, id, action.payload.PhoneNumber, 'Messages'],
    [
      ...(messages || []),
      ...(action.payload.Messages || []).filter(
        payloadMessage => !messages.some(existingMessage => existingMessage.Id === payloadMessage.Id)
      ),
    ],
    Object
  );

  _.setWith(newState, [identifierKey, id, action.payload.PhoneNumber, 'MessagesTotal'], action.payload.total, 0);
  return newState;
}

function setLatestMessages(latestMessages, messageObject) {
  const updatedMessageObject = { ...messageObject };

  latestMessages.forEach(message => {
    const tempObj = {
      ...message.LatestMessage,
      unreadSmsCount: message.UnreadSmsCount,
    };

    if (!updatedMessageObject[message.PhoneNumber]) {
      updatedMessageObject[message.PhoneNumber] = {};
    }

    if (!updatedMessageObject[message.PhoneNumber].Messages) {
      updatedMessageObject[message.PhoneNumber].Messages = {};
    }

    updatedMessageObject[message.PhoneNumber].Messages = [tempObj];
  });

  return updatedMessageObject;
}

function MessageConversationsReducer(state = {}, action) {
  let newState;
  let messages;
  let pendingMessages;
  let messagesIds;
  let newMessage;
  const { isAllConversationsEnabled } = action.payload || {};
  switch (action.type) {
    case 'SET_CONVERSATION_MESSAGES':
      newState = _.cloneDeep(state);
      if (!newState.ByConversationId) {
        newState.ByConversationId = {};
      }
      if (!newState.ByPersonId) {
        newState.ByPersonId = {};
      }
      if (action.payload.PhoneNumber) {
        if (action.payload.conversationId) {
          newState = setConversationMessages(state, action, 'ByConversationId', action.payload.conversationId);
        } else if (action.payload.personId && isAllConversationsEnabled) {
          newState = setConversationMessages(state, action, 'ByPersonId', action.payload.personId);
        }
      }
      return newState;
    case 'RESET_CONVERSATION_MESSAGES': {
      newState = _.cloneDeep(state);
      if (action.payload.conversationId) {
        _.set(
          newState,
          ['ByConversationId', action.payload.conversationId, action.payload.PhoneNumber, 'Messages'],
          {}
        );
      }
      if (action.payload.personId && isAllConversationsEnabled) {
        _.set(newState, ['ByPersonId', action.payload.personId, action.payload.PhoneNumber, 'Messages'], {});
      }
      return newState;
    }
    case 'SET_LATEST_MESSAGES': {
      const { conversationId, personId } = action.payload;
      let messageObject = {};
      if (action.payload.conversationId || action.payload.personId) {
        const { messages: latestMessages } = action.payload;

        if (action.payload.userId && latestMessages) {
          messageObject = setLatestMessages(latestMessages, messageObject);
        }

        const identifierKey = action.payload.conversationId ? 'ByConversationId' : 'ByPersonId';
        const identifierId = action.payload.conversationId || action.payload.personId;

        newState = cleanSet(state, [identifierKey, identifierId], messageObject);
      }
      return newState;
    }
    case 'SET_CONVERSATION_PENDING_MESSAGES':
      newState = _.cloneDeep(state);
      pendingMessages = _.get(
        newState,
        ['Pending', 'ByConversationId', action.payload.conversationId, action.payload.message.To],
        []
      );
      pendingMessages.unshift({ ...action.payload.message, Status: 'Pending' });
      _.setWith(
        newState,
        ['Pending', 'ByConversationId', action.payload.conversationId, action.payload.message.To],
        pendingMessages,
        Object
      );
      return newState;

    case 'ADD_NEW_MESSAGE':
      if (action.payload.Message.RequestStatus === 'Pending' && !action.payload?.isChatBotDemo) {
        return state;
      }
      newState = _.cloneDeep(state);
      if (!newState.ByConversationId) {
        newState.ByConversationId = {};
      }
      if (action.payload.conversationId) {
        newState = addNewMessage(state, action, 'ByConversationId', action.payload.conversationId);
      } else if (action.payload.personId) {
        newState = addNewMessage(state, action, 'ByPersonId', action.payload.personId);
      }
      return newState;
    default:
      return state;
  }
}

function getMessageConversations(state, conversationId) {
  const messages = _.cloneDeep(_.get(state.MessageConversationsReducer, ['ByConversationId', conversationId], {}));
  let pendingMessages = _.cloneDeep(
    _.get(state.MessageConversationsReducer, ['Pending', 'ByConversationId', conversationId], {})
  );
  pendingMessages = _.differenceBy(pendingMessages, messages, 'Id');
  return _.mergeWith(messages, pendingMessages, (objValue, srcValue) => {
    if (_.isArray(objValue) && _.isArray(srcValue)) {
      return [...srcValue, ...objValue];
    }
    return undefined;
  });
}

function getMessageConversationOfAllPhoneNumbers(state, conversationId) {
  return _.get(state.MessageConversationsReducer, ['ByConversationId', conversationId], {});
}

function getAllMessageConversation(state, personId) {
  return _.get(state.MessageConversationsReducer, ['ByPersonId', personId], {});
}

function getPendingMessageConversation(state, conversationId) {
  const pending = state.MessageConversationsReducer?.Pending;
  if (pending && pending.ByConversationId) {
    return pending.ByConversationId[conversationId] || {};
  }
  return {};
}

function getAllPendingMessageConversation(state, personId) {
  const pending = state.MessageConversationsReducer?.Pending;
  if (pending && pending.ByPersonId) {
    return pending.ByPersonId[personId] || {};
  }
  return {};
}

export {
  MessageConversationsReducer,
  getMessageConversations,
  getMessageConversationOfAllPhoneNumbers,
  getPendingMessageConversation,
  getAllMessageConversation,
  getAllPendingMessageConversation,
};
