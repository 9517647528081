import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  SET_REPORT_SUMMARY,
  SET_REPORT_STATS,
  SET_CLIENT_LIST_FOR_DROPDOWNS,
  APPEND_CLIENT_LIST_FOR_DROPDOWNS,
  SET_CLIENTS_MAX_COUNT,
  SET_RECRUITERS_MAX_COUNT,
  SET_RECRUITER_LIST_FOR_DROPDOWNS,
  APPEND_RECRUITER_LIST_FOR_DROPDOWNS,
  SET_REPORT_LIST_PAGE_SIZE,
  SET_JOB_LIST_FOR_DROPDOWNS,
  APPEND_JOB_LIST_FOR_DROPDOWNS,
  SET_JOBS_MAX_COUNT,
  SET_REPORT_COLUMNS,
  SET_SAVED_REPORT_QUERIES,
  PATCH_UPDATE_SAVED_REPORT_QUERY,
  SET_SAVED_QUERIES_PAGE_SIZE,
  SET_REPORT_PORTAL_ORGANIZATIONS,
  SET_AVAILABLE_ORGANISATION_REPORT,
  SET_ALL_REPORT_LIST_TO_NULL,
  RESET_REPORT_SUMMARY_DATAIL,
  SET_ORG_IN_REPORT_SUMMARY,
  SAVE_CURRENT_NOTIFICATION,
} from '../Actions/ActionCreators/ReportPortalActionCreator';

const initialState = {
  reportSummary: {},
  reportStats: {},
  clientList: {},
  recruiterList: {},
  jobList: [],
  reportColumns: {},
  savedReportQueries: {},
  reportPortalOrgs: {},
};

function ReportPortalReducer(state = initialState, action) {
  let newState;
  let reportStatsCategory;
  let reportStatsData;
  let reportSummaryData;
  let clientMaxCount;
  let clientList;
  let newClientList;
  let recruiterMaxCount;
  let recruiterList;
  let newRecruiterList;
  let jobList;
  let reportColumns;
  let newJobList;
  let jobMaxCount;
  let newSavedQueries;

  switch (action.type) {
    case SET_REPORT_SUMMARY:
      reportSummaryData = _.get(action, ['payload', 'reportSummary'], null);
      newState = _.cloneDeep(state);
      _.setWith(newState, ['reportSummary'], reportSummaryData, Object);
      return newState;

    case SET_REPORT_STATS:
      reportStatsData = action?.payload?.reportStats;
      reportStatsCategory = action?.payload?.reportStatsCategory?.toLowerCase();
      reportStatsCategory = reportStatsCategory?.toLowerCase();
      newState = _.cloneDeep(state);
      _.setWith(newState, ['reportStats', reportStatsCategory], reportStatsData, Object);
      return newState;

    case SET_REPORT_COLUMNS:
      reportColumns = action?.payload?.reportColumns;
      reportStatsCategory = action?.payload?.reportStatsCategory?.toLowerCase();
      reportStatsCategory = reportStatsCategory?.toLowerCase();
      newState = _.cloneDeep(state);
      _.setWith(newState, ['reportColumns', reportStatsCategory], reportColumns, Object);
      return newState;

    case SET_CLIENTS_MAX_COUNT:
      clientMaxCount = action?.payload?.maxCount;
      reportStatsCategory = action?.payload?.reportStatsCategory?.toLowerCase();
      newState = _.cloneDeep(state);
      _.setWith(newState, ['clientMaxCount', reportStatsCategory], clientMaxCount, Object);
      return newState;

    case SET_CLIENT_LIST_FOR_DROPDOWNS:
      clientList = action?.payload?.clients ?? [];
      reportStatsCategory = action?.payload?.reportStatsCategory?.toLowerCase();
      newState = _.cloneDeep(state);
      _.setWith(newState, ['clientList', reportStatsCategory], clientList, Object);
      return newState;

    case APPEND_CLIENT_LIST_FOR_DROPDOWNS:
      newClientList = action?.payload?.clients ?? [];
      reportStatsCategory = action?.payload?.reportStatsCategory?.toLowerCase();
      newState = _.cloneDeep(state);
      clientList = _.get(state, ['clientList', reportStatsCategory], []);
      clientList = _.concat(clientList, newClientList);
      clientList = _.uniqBy(clientList, client => client?.Id);
      _.setWith(newState, ['clientList', reportStatsCategory], clientList, Object);
      return newState;

    case SET_JOBS_MAX_COUNT:
      jobMaxCount = _.get(action, ['payload'], null);
      newState = _.cloneDeep(state);
      _.setWith(newState, ['jobMaxCount'], jobMaxCount, Object);
      return newState;

    case SET_JOB_LIST_FOR_DROPDOWNS:
      jobList = _.get(action, ['payload', 'jobs'], []);
      newState = _.cloneDeep(state);
      _.setWith(newState, ['jobList'], jobList, Object);
      return newState;

    case APPEND_JOB_LIST_FOR_DROPDOWNS:
      newJobList = _.get(action, ['payload', 'jobs'], []);
      newState = _.cloneDeep(state);
      jobList = _.get(state, 'jobList', []);
      jobList = _.unionBy(jobList, newJobList, job => job?.JobId);
      _.setWith(newState, ['jobList'], jobList, Object);
      return newState;

    case SET_RECRUITERS_MAX_COUNT:
      recruiterMaxCount = action?.payload?.maxCount;
      reportStatsCategory = action?.payload?.reportStatsCategory?.toLowerCase();
      newState = _.cloneDeep(state);
      _.setWith(newState, ['recruiterMaxCount', reportStatsCategory], recruiterMaxCount, Object);
      return newState;

    case SET_RECRUITER_LIST_FOR_DROPDOWNS:
      recruiterList = action?.payload?.recruiters ?? [];
      reportStatsCategory = action?.payload?.reportStatsCategory?.toLowerCase();
      newState = _.cloneDeep(state);
      _.setWith(newState, ['recruiterList', reportStatsCategory], recruiterList, Object);
      return newState;

    case APPEND_RECRUITER_LIST_FOR_DROPDOWNS:
      newRecruiterList = action?.payload?.recruiters ?? [];
      reportStatsCategory = action?.payload?.reportStatsCategory?.toLowerCase();
      newState = _.cloneDeep(state);
      recruiterList = _.get(state, ['recruiterList', reportStatsCategory], []);
      recruiterList = _.unionBy(recruiterList, newRecruiterList, recruiter => recruiter?.Id);
      _.setWith(newState, ['recruiterList', reportStatsCategory], recruiterList, Object);
      return newState;

    case SET_REPORT_LIST_PAGE_SIZE:
      newState = _.cloneDeep(state);
      newState.ReportListPageSize = action.payload;
      return newState;

    case SET_SAVED_QUERIES_PAGE_SIZE:
      newState = _.cloneDeep(state);
      newState.SavedQueriesPageSize = action.payload;
      return newState;

    case SET_SAVED_REPORT_QUERIES:
      newSavedQueries = action.payload.savedQueries;
      newState = _.cloneDeep(state);
      _.setWith(newState, ['savedReportQueries'], newSavedQueries, Object);
      return newState;

    case PATCH_UPDATE_SAVED_REPORT_QUERY: {
      const { reportId, updatedReportQuery } = action.payload;
      newState = _.cloneDeep(state);
      const existingSavedReportQueries = newState?.savedReportQueries?.SavedReports;
      const existingReportQuery = existingSavedReportQueries?.find(x => x.SavedReportId === reportId);
      const mergedReportQuery = { ...existingReportQuery, ...updatedReportQuery };
      const index = existingSavedReportQueries?.findIndex(x => x.SavedReportId === reportId);
      if (index !== -1) _.setWith(newState, ['savedReportQueries', 'SavedReports', index], mergedReportQuery);
      return newState;
    }

    case SET_REPORT_PORTAL_ORGANIZATIONS:
      newState = _.cloneDeep(state);
      if (action.payload.searchTerm !== state?.reportPortalOrgs?.SearchTerm) {
        newState.reportPortalOrgs.Organizations = action.payload?.orgData?.Organizations;
      } else {
        newState.reportPortalOrgs.Organizations = [
          ...(newState?.reportPortalOrgs?.Organizations ?? []),
          ...action.payload?.orgData?.Organizations,
        ];
        newState.reportPortalOrgs.Organizations = _.uniqBy(newState?.reportPortalOrgs?.Organizations, 'Id');
      }
      newState.reportPortalOrgs.Total = action.payload?.orgData?.Total;
      newState.reportPortalOrgs.SearchTerm = action.payload?.searchTerm;
      return newState;

    case SET_AVAILABLE_ORGANISATION_REPORT: {
      const { availableReports } = action.payload;
      newState = _.cloneDeep(state);
      return { ...newState, AvailableReports: availableReports };
    }
    case SET_ALL_REPORT_LIST_TO_NULL: {
      newState = _.cloneDeep(state);
      return { ...newState, reportStats: {} };
    }
    case RESET_REPORT_SUMMARY_DATAIL: {
      newState = _.cloneDeep(state);

      return { ...newState, reportSummary: {} };
    }

    case SET_ORG_IN_REPORT_SUMMARY: {
      newState = _.cloneDeep(state);
      newState.reportSummary.currentOrg = action.payload;
      return newState;
    }
    case SAVE_CURRENT_NOTIFICATION: {
      newState = _.cloneDeep(state);
      newState.Notification = action.payload;
      return newState;
    }

    default:
      return state;
  }
}

ReportPortalReducer.propTypes = {
  state: PropTypes.shape({}),
  action: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function getReportSummary(state) {
  return _.get(state, ['ReportPortalReducer', 'reportSummary'], {}) ?? {};
}

function getReportStats(state, reportStatsCategory) {
  return _.get(state, ['ReportPortalReducer', 'reportStats', reportStatsCategory], {}) ?? {};
}

function getReportColumns(state, reportStatsCategory) {
  if (reportStatsCategory) {
    return state?.ReportPortalReducer?.reportColumns?.[reportStatsCategory] ?? [];
  }
  return state?.ReportPortalReducer?.reportColumns ?? {};
}

function getClientList(state, reportStatsCategory) {
  if (reportStatsCategory) {
    return _.get(state, ['ReportPortalReducer', 'clientList', reportStatsCategory], []) ?? [];
  }
  return _.get(state, ['ReportPortalReducer', 'clientList'], {}) ?? {};
}

function getRecruiterList(state, reportStatsCategory) {
  if (reportStatsCategory) {
    return _.get(state, ['ReportPortalReducer', 'recruiterList', reportStatsCategory], []) ?? [];
  }
  return _.get(state, ['ReportPortalReducer', 'recruiterList'], {}) ?? {};
}

function getRecruiterMaxCount(state, reportStatsCategory) {
  return _.get(state, ['ReportPortalReducer', 'recruiterMaxCount', reportStatsCategory], 0);
}

function getReportListPageSize(state) {
  return _.get(state, ['ReportPortalReducer', 'ReportListPageSize'], 10);
}

function getSavedQueriesPageSize(state) {
  return state?.ReportPortalReducer?.SavedQueriesPageSize ?? 10;
}

function getJobList(state) {
  return _.get(state, ['ReportPortalReducer', 'jobList'], []) ?? [];
}

function getJobsMaxCount(state) {
  return _.get(state, ['ReportPortalReducer', 'jobMaxCount'], 0);
}

function getSavedReportQueries(state) {
  return state?.ReportPortalReducer?.savedReportQueries ?? {};
}

function getOrganizations(state) {
  return state?.ReportPortalReducer?.reportPortalOrgs || {};
}
function getOrganizationAvailableReports(state) {
  return state?.ReportPortalReducer?.AvailableReports || [];
}

function getCurrentSelectedOrg(state) {
  return state?.ReportPortalReducer?.reportSummary?.currentOrg;
}
function getCurrentNotification(state) {
  return state?.ReportPortalReducer?.Notification?.currentNotification;
}

export {
  ReportPortalReducer,
  getReportStats,
  getReportSummary,
  getClientList,
  getRecruiterList,
  getReportListPageSize,
  getJobList,
  getRecruiterMaxCount,
  getReportColumns,
  getJobsMaxCount,
  getSavedReportQueries,
  getSavedQueriesPageSize,
  getOrganizations,
  getOrganizationAvailableReports,
  getCurrentSelectedOrg,
  getCurrentNotification,
};
