import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import classname from 'classnames';
import { connect } from 'react-redux';
import {
  getApiStatus,
  getFavouriteCandidateApiStatus,
  getRemoveFavouriteCandidateApiAtatus,
} from '../../Reducers/ApiStatusReducer';
import { getConfig, getWhiteLabelInformation, getMaskingConfig } from '../../Reducers/ConfigReducer';
import {
  getCandidateJobGlobalTags,
  getCandidateDetails,
  getRejectedCandidateIds,
  getCandidateNotesByCandidateId,
  getMspIcons,
} from '../../Reducers/CandidateReducer';
import * as CandidateActions from '../../Actions/CandidateActions';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getCurrentJobDetails, getCurrentUserViewedStatusFlag } from '../../Reducers/JobReducer';
import {
  getActiveSourceName,
  getCandidateCollapsedFilters,
  getActiveTab,
} from '../../Reducers/JobCandidatesTabReducer';
import CandidateCardWrapper from './CandidateCardWrapper';
import CandidateCardFooter from './CandidateCardFooter';
import CandidateCardHeader from './CandidateCardHeader';
import CandidateCardInfo from './CandidateCardInfo';
import CandidateCheckbox from './CandidateCheckbox';
import styles from './CandidateCardWrapper.module.scss';
import CandidateRejectCard from '../CandidateRejectCard/CandidateRejectCard';
import CandidateRejectCardContainer from '../../Containers/CandidateRejectCard/CandidateRejectCardContainer';
import eventTypes, { modules } from '../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import { logSmartKarrotEvent } from '../../Analytics/EventUtils';
import { getCandidateDrawerRedirectedTab } from '../../Utils/CandidateDrawerUtils';
import { REJECTED, SERVICE_REP, PULSE_USER } from '../../Utils/CandidateListUtils';
import { isPulseUser } from '../../Utils/ConfigUtils';
import { getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import { getAllDuplicateCandidates, getAllTabAggregationFilters } from '../../Reducers/ManualSearchCandidateReducer';
import DuplicateCandidateCardContainer from './DuplicateCandidateCardContainer/DuplicateCandidateCardContainer';
import {
  getIsCandidateGroupHead,
  getDuplicateCandidateIdsFromGroupId,
  getIsDeDuplicationAllowed,
} from '../../Utils/DeDuplicationUtils';
import { getSelectedSubsegmentId } from '../../Reducers/SubsegmentReducer';
import { setSubsegmentAddModalStatus } from '../../Actions/SubSegmentActions';
import { getDefaultCandidateCardView } from '../../Utils/CandidateCardUtils';
import { getAtsConfig } from '../../Reducers/AtsReducer';
import { getAdminFeatures } from '../../Reducers/UserReducer';
import * as sourcingStatsAction from '../../Actions/SourcingStatsActions';
import message from '../Placeholders/PlaceholdersMessages';

const mapStateToProps = (state, props) => {
  const activeSourceName = getActiveSourceName(state);
  return {
    userConfig: getConfig(state),
    candidateAllNotesFetchStatus: getApiStatus(state, 'fetchBulkCandidateAllNotesApiStatus'),
    whiteLabelInfo: getWhiteLabelInformation(state),
    candidateBookmarkApiStatus: getApiStatus(state, 'candidateBookmarkApiStatus'),
    candidateJobGlobalTags: getCandidateJobGlobalTags(state, props.candidateId),
    candidateStatusUpdate: getApiStatus(state, 'sourceCandidateStatusUpdate'),
    connectStatus: getApiStatus(state, 'ConnectStatus'),
    featureToggleList: getFeatureToggleList(state),
    currentJobDetails: getCurrentJobDetails(state),
    candidate: getCandidateDetails(state, props.candidateId),
    rejectedCandidateIds: getRejectedCandidateIds(state),
    getCandidate360Notes: candidateId => getCandidateNotesByCandidateId(state, candidateId),
    activeSourceName,
    maskingConfig: getMaskingConfig(state),
    currentUserDetails: getCurrentUserDetails(state),
    candidateViewedApiStatus: getApiStatus(state, 'candidateViewedApiStatus'),
    currentUserViewedStatusFlag: getCurrentUserViewedStatusFlag(state, props.jobId),
    allTabDuplicateCandidates: getAllDuplicateCandidates(state, props.jobId, activeSourceName),
    getCandidateDetails: candidateId => getCandidateDetails(state, candidateId),
    candidateCollapsedFilters: getCandidateCollapsedFilters(state, props.jobId),
    manualSearchFiltersStatus: getApiStatus(state, 'manualSearchFiltersStatus'),
    atsConfig: getAtsConfig(state),
    activeTab: getActiveTab(state),
    adminFeatures: getAdminFeatures(state),
    favouriteCandidateApiStatus: getFavouriteCandidateApiStatus(state),
    removeFavouriteCandidateApiAtatus: getRemoveFavouriteCandidateApiAtatus(state),
    mspIcons: getMspIcons(state, props.candidateId),
    allTabAggregationFilter: getAllTabAggregationFilters(state, { jobId: props.jobId }),
    selectedSubSegmentId: getSelectedSubsegmentId(state),
  };
};

const mapDispatchToProps = {
  updateCandidateBookmarkStatus: CandidateActions.updateCandidateBookmarkStatus,
  setCandidateRejectStatus: CandidateActions.setCandidateRejectStatus,
  fetchCandidate360Notes: CandidateActions.getAllCandidateNotes,
  undoPortalApply: CandidateActions.undoApplyFromPortal,
  recommendFavouriteCandidate: CandidateActions.recommendFavouriteCandidate,
  removeFavouriteCandidate: CandidateActions.removeFavouriteCandidate,
  setAddSubsegmentModalStatus: setSubsegmentAddModalStatus,
  fetchJobSourcingStats: sourcingStatsAction.fetchJobSourcingStats,
};

const CandidateCardContainer = props => {
  const {
    activeTab,
    featureToggleList,
    onCandidateStatusChange,
    size,
    jobId,
    candidateContext,
    setCandidateRejectStatus,
    showCandidateDetail,
    indexKey,
    rejectedCandidateIds,
    userConfig,
    candidateType,
    allTabDuplicateCandidates,
    activeSourceName,
    candidateCollapsedFilters = [],
    manualSearchFiltersStatus,
    openInNewTab,
    setAddSubsegmentModalStatus,
    undoPortalApply,
    recommendFavouriteCandidate,
    adminFeatures,
    favouriteCandidateApiStatus,
    removeFavouriteCandidate,
    removeFavouriteCandidateApiAtatus,
    fetchCandidates,
    defaultFilter,
    msp,
    mspIcons,
    setMspIcons,
    fetchJobSourcingStats,
    selectedSubSegmentId,
    candidateViewedApiStatus,
  } = props;

  const { candidate, candidateId, currentUserDetails, ...candidateCardProps } = props;

  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);

  const isAdvancedSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;

  const getIsShortlistIconEnabled = candidateConnectionStatus => {
    return !(
      isPaidJobServiceEnabled &&
      candidateConnectionStatus === 'Engaged' &&
      (activeTab === 'engaged' || activeTab === 'rejected')
    );
  };
  const onCandidateReject = (candidateDetail, _size) => {
    setCandidateRejectStatus(candidateDetail.Id, _size);
  };

  const isCandidateInfoPanelAllowed = candidateIdData => {
    if (activeTab === REJECTED && userConfig.Role !== SERVICE_REP && isPulseUser()) {
      const candidateIndex = rejectedCandidateIds?.find(key => key.CandidateId === candidateIdData);
      return candidateIndex?.UserType === PULSE_USER;
    }
    return true;
  };

  const onCandidateCardClick = tabName => {
    if (isCandidateInfoPanelAllowed(candidateId)) {
      if (showCandidateDetail) {
        showCandidateDetail(candidate, 'profile', indexKey, tabName);
      }
      if (tabName === 'notes') {
        logSmartKarrotEvent(
          getEventNameByCandidateStatus(eventTypes.candidate.candidateProfile.goToCandidateNotes, candidate),
          { Module: modules.candidate.candidateActions }
        );
      } else
        logSmartKarrotEvent(getEventNameByCandidateStatus(eventTypes.candidate.candidateView.view, candidate), {
          Module: modules.candidate.candidateActions,
        });
    }
  };

  const { RevealPortalsUnderGroup: revealPortalsUnderGroup } = featureToggleList;
  const revealActiveChannelSourceName = revealPortalsUnderGroup.IsEnabled;
  const redirectTabName = getCandidateDrawerRedirectedTab(candidate, revealActiveChannelSourceName, activeSourceName);

  const candidateCardClickDivProps = {
    tabIndex: 0,
    onClick: () => onCandidateCardClick(redirectTabName),
    role: 'button',
    onKeyPress: () => onCandidateCardClick(redirectTabName),
  };

  const getIsIgnoreSimilar = () => {
    if (candidateType === 'quick-search' || activeSourceName === 'scoutingAgent') return false;
    return !isPaidJobServiceEnabled;
  };

  const ignoreSimilar = getIsIgnoreSimilar();

  const [isCandidateViewIconVisible, setCandidateViewIconVisibility] = React.useState(false);
  const [areDuplicateCandidatesVisible, setDuplicateCandidatesVisibility] = React.useState(false);
  const [showExpandedCandidateCard, setshowExpandedCandidateCard] = React.useState(true);
  const [showDuplicateExpandedCandidateCard, setShowDuplicateExpandedCandidateCard] = React.useState(true);
  const [isUndoModalVisible, setUndoModalVisibility] = React.useState(false);

  React.useEffect(() => {
    setshowExpandedCandidateCard(
      getDefaultCandidateCardView(
        candidateCollapsedFilters,
        candidate,
        currentUserDetails,
        featureToggleList,
        showDuplicateExpandedCandidateCard,
        activeSourceName
      )
    );
  }, [candidate.LastViewedBy, manualSearchFiltersStatus, candidate.Status, candidate.ConnectionStatus]);

  const isCandidateGroupHead = getIsCandidateGroupHead(
    allTabDuplicateCandidates,
    candidate.CandidateGroup,
    candidate.Id,
    activeSourceName
  );

  if (getIsDeDuplicationAllowed(activeSourceName) && !isCandidateGroupHead) return null;

  const duplicateCandidateIds =
    candidate.CandidateGroup && getIsDeDuplicationAllowed(activeSourceName)
      ? getDuplicateCandidateIdsFromGroupId(allTabDuplicateCandidates, candidate.CandidateGroup, activeSourceName)
      : [];

  const duplicateCandidateCard = duplicateCandidateIds.length
    ? duplicateCandidateIds.map((duplicateCandidateId, index) => (
        <DuplicateCandidateCardContainer
          {...candidateCardProps}
          candidateId={duplicateCandidateId}
          isCandidateInfoPanelAllowed={isCandidateInfoPanelAllowed}
          redirectTabName={redirectTabName}
          isLastDuplicateCandidate={index === duplicateCandidateIds.length - 1}
          currentUserDetails={currentUserDetails}
          setshowExpandedCandidateCard={setshowExpandedCandidateCard}
          setShowDuplicateExpandedCandidateCard={setShowDuplicateExpandedCandidateCard}
          showExpandedCandidateCard={showDuplicateExpandedCandidateCard}
          areDuplicateCandidatesVisible={areDuplicateCandidatesVisible}
          duplicateCandidateIds={duplicateCandidateIds}
          setCandidateViewIconVisibility={setCandidateViewIconVisibility}
          isCandidateViewIconVisible={isCandidateViewIconVisible}
          adminFeatures={adminFeatures}
        />
      ))
    : null;

  const candidateCardWrapperStyle =
    areDuplicateCandidatesVisible && duplicateCandidateIds.length
      ? {
          borderRadius: '0px',
        }
      : {};

  const handleExpandClick = () => {
    setshowExpandedCandidateCard(true);
    if (!showDuplicateExpandedCandidateCard && duplicateCandidateIds.length) {
      setShowDuplicateExpandedCandidateCard(true);
      setDuplicateCandidatesVisibility(true);
    }
  };
  const ConditionalCandidateRejectCard = isAdvancedSearchV2Enabled ? (
    <CandidateRejectCardContainer
      key={candidateId}
      candidate={candidate}
      onCandidateReject={onCandidateReject}
      onCandidateStatusChange={onCandidateStatusChange}
      ignoreSimilar={ignoreSimilar}
      size={size}
      isPaidJobServiceEnabled={isPaidJobServiceEnabled}
      isCandidateListRejectCard
      jobId={jobId}
      candidateContext={candidateContext}
      openInNewTab={openInNewTab}
    />
  ) : (
    <CandidateRejectCard
      key={candidateId}
      candidate={candidate}
      onCandidateReject={onCandidateReject}
      onCandidateStatusChange={onCandidateStatusChange}
      ignoreSimilar={ignoreSimilar}
      size={size}
      isPaidJobServiceEnabled={isPaidJobServiceEnabled}
      isCandidateListRejectCard
      jobId={jobId}
      candidateContext={candidateContext}
      openInNewTab={openInNewTab}
    />
  );
  return (
    <div className={styles.candidateCardContainer}>
      <div
        className={classname(
          styles.candidateCardWrapper,
          {
            [styles.blurCandidateCardWrapper]: !showExpandedCandidateCard && isCandidateViewIconVisible,
          },
          {
            [styles.initialBlurCandidateCardWrapper]:
              !showExpandedCandidateCard && !isCandidateViewIconVisible && !areDuplicateCandidatesVisible,
          }
        )}
        {...candidateCardClickDivProps}
        onMouseMove={() => {
          setCandidateViewIconVisibility(true);
        }}
        onMouseLeave={() => {
          if (showExpandedCandidateCard) setCandidateViewIconVisibility(false);
        }}
        style={candidateCardWrapperStyle}
        id="candidate-card-container"
        data-testid="candidate-card-wrapper"
      >
        {!candidate.rejectFlag ? (
          <CandidateCardWrapper
            {...props}
            isShortlistIconEnabled={getIsShortlistIconEnabled(candidate.ConnectionStatus)}
            isPaidJobServiceEnabled={isPaidJobServiceEnabled}
            onCandidateReject={onCandidateReject}
            onCandidateCardClick={onCandidateCardClick}
            isCandidateViewIconVisible={isCandidateViewIconVisible}
            isCandidateGroupHead={isCandidateGroupHead}
            duplicateCandidateIds={duplicateCandidateIds}
            areDuplicateCandidatesVisible={areDuplicateCandidatesVisible}
            setDuplicateCandidatesVisibility={setDuplicateCandidatesVisibility}
            redirectTabName={redirectTabName}
            showExpandedCandidateCard={showExpandedCandidateCard}
            setUndoModalVisibility={setUndoModalVisibility}
            isUndoModalVisible={isUndoModalVisible}
          >
            {!msp && <CandidateCardHeader showExpandedCandidateCard={showExpandedCandidateCard} />}
            <div className={styles.candidateCheckboxAndInfoWrapper}>
              <CandidateCheckbox />
              <CandidateCardInfo
                showExpandedCandidateCard={showExpandedCandidateCard}
                undoPortalApply={undoPortalApply}
                setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
                recommendFavouriteCandidate={recommendFavouriteCandidate}
                activeTab={activeTab}
                adminFeatures={adminFeatures}
                favouriteCandidateApiStatus={favouriteCandidateApiStatus}
                removeFavouriteCandidate={removeFavouriteCandidate}
                removeFavouriteCandidateApiAtatus={removeFavouriteCandidateApiAtatus}
                defaultFilter={defaultFilter}
                fetchCandidates={fetchCandidates}
                msp={msp}
                mspIcons={mspIcons}
                setMspIcons={setMspIcons}
                fetchJobSourcingStats={fetchJobSourcingStats}
                selectedSubSegmentId={selectedSubSegmentId}
                candidateViewedApiStatus={candidateViewedApiStatus}
              />
            </div>
            {showExpandedCandidateCard ? <CandidateCardFooter /> : null}
          </CandidateCardWrapper>
        ) : (
          ConditionalCandidateRejectCard
        )}
      </div>
      <div className={styles.duplicateCandidateCard}>{duplicateCandidateCard}</div>
      {!showExpandedCandidateCard && isCandidateViewIconVisible ? (
        <div
          className={classname(styles.candidateCardWrapper, styles.collapsedCandidateCardWrapper)}
          onClick={handleExpandClick}
          onMouseMove={() => {
            setCandidateViewIconVisibility(true);
          }}
          onMouseLeave={() => {
            setCandidateViewIconVisibility(false);
          }}
        >
          <FormattedMessage {...message.clickToExpandLabel} />
        </div>
      ) : null}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCardContainer);
export { CandidateCardContainer as CandidateCardContainerWithoutStore };
