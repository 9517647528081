/* eslint-disable react/no-danger */
import { Button, Icon, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import DOMPurify from 'dompurify';
import React from 'react';
import { CandidateScoreIcon } from '../../Icons/AryaIcons';
import BellCurveGraph from './BellCurveGraph';
import CompanyTable from './CompanyTable';
import { generatePdf } from '../../Utils/PdfGeneratorUtils';
import message from '../../Containers/CandidateDrawer/messages';

const sectionOrder = ['Summary', 'Skill', 'Company', 'Experience', 'Industry', 'Role', 'Education'];

const ScoreDetailSection = ({
  type,
  data,
  styles,
  candidateDetails,
  id,
  overview,
  isCandidateIntelDownloadEnabled,
}) => {
  switch (type.toLowerCase()) {
    case 'summary':
      return (
        <Summary
          overview={overview}
          type={type}
          data={data}
          styles={styles}
          candidateDetails={candidateDetails}
          id={id}
          isCandidateIntelDownloadEnabled={isCandidateIntelDownloadEnabled}
        />
      );
    case 'skill':
      return <Skills candidateDetails={candidateDetails} type={type} data={data} styles={styles} id={id} />;
    default:
      return <Default type={type} data={data} styles={styles} id={id} />;
  }
};

const initiatePdfGeneration = async (candidateDetails, elementIds, exclusionClassNames, setDownloadButtonLoading) => {
  const { candidateName, jobTitle } = candidateDetails;
  await generatePdf(candidateName, jobTitle, elementIds, setDownloadButtonLoading, exclusionClassNames);
};

const handleCandidateExplanationDownloadClick = async (candidateDetails, setDownloadButtonLoading) => {
  const elementIds = sectionOrder.map(item => `${item}-id-ref-wrapper`);
  setDownloadButtonLoading(true);
  await initiatePdfGeneration(
    candidateDetails,
    elementIds,
    ['ant-btn', 'anticon-info-circle'],
    setDownloadButtonLoading
  );
  setDownloadButtonLoading(false);
};

const Summary = ({ data, styles, candidateDetails, id, overview, isCandidateIntelDownloadEnabled }) => {
  const { candidateName, jobTitle } = candidateDetails;
  const bulletPoints = data.Facts.map(fact => fact.Fact) || [];
  const highlightedKeyWords = data.Facts.filter(({ HighlightKeywords }) => HighlightKeywords).map(
    item => item.HighlightKeywords
  );
  const [downloadButtonLoading, setDownloadButtonLoading] = React.useState(false);
  const name = candidateName.split(' ')[0];
  return (
    <div className={styles.summaryWrapper} id={`${id}-wrapper`}>
      <div id={id}></div>
      <div className={styles.summaryContainer}>
        <div className={`${styles.bigHeading} ${styles.summaryHeader}`}>
          <span>
            <FormattedMessage {...message.SummaryofNameForTheJobTitleRoleLabel} values={{ name, jobTitle }} />
          </span>
        </div>
        <div className={styles.skillFacts}>
          <BulletPoints styles={styles} bulletPoints={bulletPoints} highlightedKeyWords={highlightedKeyWords} />
        </div>
      </div>
      <div className={styles.radarChartContainer}>
        {isCandidateIntelDownloadEnabled ? (
          <Button
            shape="round"
            onClick={() => handleCandidateExplanationDownloadClick(candidateDetails, setDownloadButtonLoading)}
            loading={downloadButtonLoading}
          >
            <FormattedMessage {...message.downloadButton} />
          </Button>
        ) : null}
      </div>
    </div>
  );
};

function getskillFacts(facts, type) {
  return facts.filter(item => item.Type.toLowerCase() === type).map(factsObj => factsObj.Fact);
}

const Skills = ({ data, styles, candidateDetails, id }) => {
  const skillTypes = Object.keys(data);
  const skillSectionData = [];
  skillTypes.forEach(skillType => {
    const tempObj = {};
    if (skillType.toLowerCase() === 'matched') {
      tempObj.title = <FormattedMessage {...message.perfectMatchSkillsLabel} />;
      tempObj.facts = getskillFacts(data.Facts, 'matched');
      tempObj.tooltipData = <FormattedMessage {...message.jobSkillsLabel} />;
    } else if (skillType.toLowerCase() === 'missing') {
      tempObj.title = <FormattedMessage {...message.missingSkillsLabel} />;
      tempObj.facts = getskillFacts(data.Facts, 'missing');
      tempObj.tooltipData = <FormattedMessage {...message.jobSkillsCandidateDoesNotPossessLabel} />;
    } else if (skillType.toLowerCase() === 'trainable') {
      tempObj.title = <FormattedMessage {...message.trainableOrSimilarSkillsLabel} />;
      tempObj.facts = getskillFacts(data.Facts, 'trainable');
      tempObj.tooltipData = <FormattedMessage {...message.jobSkillsThatTheCandidateCanEasilyAcquireLabel} />;
    } else if (skillType.toLowerCase() === 'transferable') {
      tempObj.title = <FormattedMessage {...message.transferableSkillsLabel} />;
      tempObj.facts = getskillFacts(data.Facts, 'transferable');
      tempObj.tooltipData = <FormattedMessage {...message.valuableSkillsLabel} />;
    } else return;
    tempObj.skills = data[skillType].Skills || [];
    if (
      (data[skillType].Skills && data[skillType].Skills.length > 0) ||
      (data[skillType].Facts && data[skillType].Facts.length > 0)
    ) {
      skillSectionData.push(tempObj);
    }
  });
  const { candidateName, score } = candidateDetails;
  return (
    <div className={styles.sectionWrapper} id={`${id}-wrapper`}>
      <div id={id}></div>
      <div className={styles.skillContainer}>
        <div className={styles.bigHeading}>
          <FormattedMessage {...message.whyIsCandidateNameRatedLabel} values={{ candidateName }} />{' '}
          <CandidateScoreIcon /> <span>{score}</span> ?{' '}
        </div>
        <div className={styles.sectionHeading}>
          <FormattedMessage {...message.skillsLabel} />{' '}
          <Tooltip placement="right" title={<FormattedMessage {...message.gainInsightLabel} />}>
            <Icon type="info-circle" />
          </Tooltip>
        </div>
        <div className={styles.sectionCard}>
          {skillSectionData.map((content, i) => (
            <SkillSection {...content} lastElement={i === skillSectionData.length - 1} styles={styles} />
          ))}
        </div>
      </div>
    </div>
  );
};

const SkillSection = ({ title, tooltipData, skills, facts, styles, lastElement }) => (
  <div className={styles.skillSectionContent}>
    <div className={styles.sectionContentHeading}>
      {title}
      <Tooltip placement="right" title={tooltipData}>
        <Icon type="info-circle" />
      </Tooltip>
    </div>
    <div className={styles.skillArray}>
      {skills &&
        skills.map((skill, i) => {
          return (
            <span>
              {skill}
              {skills.length - 1 === i ? '' : ','}{' '}
            </span>
          );
        })}
    </div>
    <div className={styles.skillFacts}>{facts && <BulletPoints styles={styles} bulletPoints={facts} />}</div>
    {!lastElement && <div className={styles.sectionLine}></div>}
  </div>
);

const tooltipMapper = {
  Company: <FormattedMessage {...message.CompanyTooltipLabel} />,
  Experience: <FormattedMessage {...message.ExperienceTooltipLabel} />,
  Industry: <FormattedMessage {...message.IndustryTooltipLabel} />,
  Education: <FormattedMessage {...message.EducationTooltipLabel} />,
  Role: <FormattedMessage {...message.RoleTooltipLabel} />,
};

const Default = ({ type, styles, data, id }) => {
  let bulletPoints = [];
  bulletPoints = data.Facts.map(fact => fact.Fact) || [];
  return (
    <div className={`${styles.defaultContainer} ${styles.sectionWrapper}`} id={`${id}-wrapper`}>
      <div className={styles.sectionHeading}>
        <FormattedMessage {...message[type]} />
        {tooltipMapper[type] && (
          <Tooltip
            overlayClassName={styles.candidateIntelV2Tooltip}
            openClassName="openClassName"
            overlayStyle={{ width: 'fit-content' }}
            placement="right"
            title={tooltipMapper[type]}
          >
            <Icon type="info-circle" />
          </Tooltip>
        )}
      </div>
      <div className={styles.sectionCard}>
        <div id={id}></div>
        {type === 'Company' && data?.CompanyFeaturesScales && <CompanyTable tableData={data?.CompanyFeaturesScales} />}
        {type === 'Experience' && (
          <BellCurveGraph
            meanExperience={data.Metadata.AverageExperience}
            candidateExperience={data.Metadata.CandidateTotalExperience}
            requiredExperience={data.Metadata.RequiredExperience}
          />
        )}
        <BulletPoints styles={styles} bulletPoints={bulletPoints} />
      </div>
    </div>
  );
};

export const BulletPoints = ({ bulletPoints, styles, highlightedKeyWords }) => {
  function highlightKeywordsInFacts(facts, highlightWords) {
    return facts.map((str, i) => {
      let tempString = str;
      const sortedKeywords = highlightWords
        .flat()
        .sort((a, b) => b.length - a.length);
      sortedKeywords.forEach(word => {
        const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = word.match(/[^\w\s]/)
          ? new RegExp(escapedWord, 'gi')
          : new RegExp(`\\b${escapedWord}\\b`, 'gi');
        tempString = tempString.replace(regex, (match) => `<b>${match}</b>`);
      });
      return tempString;
    });
  }
  const highlightedFacts =
    highlightedKeyWords && highlightedKeyWords.length > 0
      ? highlightKeywordsInFacts(bulletPoints, highlightedKeyWords)
      : bulletPoints;
  return (
    <div>
      {highlightedFacts &&
        highlightedFacts.map((fact, index) => {
          const key = `${fact[0]}-${index}`;
          return (
            <p key={key} className={styles.bulletPointContainer}>
              <p className={styles.bulletLine}></p>
              <p style={{ width: 'fit-content' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fact) }}></p>
            </p>
          );
        })}
    </div>
  );
};
export default ScoreDetailSection;
export { handleCandidateExplanationDownloadClick };
