import React from 'react';
import { Modal, Form, Popover, Alert, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import ExclamationCircleIcon from '../../Icons/ExclamationCircleIcon';
import RichTextEditor from '../Editor/RichTextEditor';
import config from '../../Config/Config';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import './MessageComposer.scss';
import ConnectTemplateSelect from '../JobSettings/ConnectTemplateSelect';
import CreditInfoAlert from '../CreditInfo/CreditInfoAlert';
import { UserAlertWithWrapper } from '../UserAlerts/UserAlerts';
import { messageCount, emojiFinder, getInfoAlertMessage } from '../Utils/SmsUtils';
import MessageWindowTemplateConsentNote from './MessageWindowTemplateConsentNote';
import message from './MessageComposerMessage';
import SpamAlertText from '../Common/SpamTextAlert/SpamTextAlert';

class MessageComposer extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.addTags = this.addTags.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { visible, fetchMessageTemplates } = this.props;

    const prevVisibility = _.get(prevProps, 'visible', false);
    if (prevVisibility === false && visible === true) {
      fetchMessageTemplates({ From: 0, Size: 10 });
    }
  }

  addTags(tag) {
    this.myRef.current.addTags(tag, false);
  }

  render() {
    const {
      visible,
      onCancel,
      eventCallbacks,
      form,
      currentValues,
      mergeTags = [],
      // consentRequire = false,
      messageTemplateIds,
      messageTemplatesById,
      messageTemplatesCount,
      fetchMessageTemplates,
      selectedMessageTemplateId,
      selectedActiveCandidateCount,
      currentUser,
      credits,
      onCloseCreditInfoAlert,
      isPaidJobServiceEnabled,
      candidateDownloadFilterConfig,
      candidateCommunicationFilterConfig,
      communicationDisabledMessage,
      availableCredits,
      featureToggleList,
      userConfig,
      isJunkWordFound,
      junkWords,
      consentText,
    } = this.props;

    const availableTextCredits = availableCredits?.SMS?.AvailableCredits;
    const isPanelContentDisable = !availableTextCredits || availableCredits === 'N/A';

    // const isConsentMessage = true;
    const isConsentMessage = false;
    const { validateFields } = form;
    const { isConsent, messageInputValue } = currentValues;
    const { charactersPerMessage } = config;
    const regex = /\n$/gi;
    let isDisable = true;
    const selectedMessageTemplateName = _.get(messageTemplatesById, [selectedMessageTemplateId, 'Name'], null);

    const handleSubmit = event => {
      event.preventDefault();
      validateFields(err => {
        if (!err) {
          const text = this.myRef.current.quillRef.getText().trim().replace(regex, '');
          eventCallbacks.onSend(text);
        }
      });
    };

    const popoverContent = (
      <div className="tags-popover-content">
        {mergeTags.map(mergeTag => (
          <div className="merge-tag" onClick={() => this.addTags(mergeTag)} role="presentation" key={mergeTag.Key}>
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const addMergeTags = (
      <Popover content={popoverContent} placement="right" trigger="click">
        <div className="message-composer-merge-tags">
          <FormattedMessage {...message.mergeTagsLabel} />
        </div>
      </Popover>
    );

    if (this.myRef.current) {
      isDisable = this.myRef.current.quillRef.getText().trim().replace(regex, '').length === 0;
    }
    const {
      MessageConsentTemplateCreation: { IsEnabled: messageConsentTemplateCreationEnabled },
    } = featureToggleList;
    const isEditorBodyDisabled = isConsentMessage || !messageConsentTemplateCreationEnabled;

    return (
      <div>
        <Modal
          title={<FormattedMessage {...message.messageComposerTitleLabel} />}
          visible={visible}
          onOk={handleSubmit}
          onCancel={onCancel}
          okText={<FormattedMessage {...message.sendLabel} />}
          className="bulkMessageModalBody"
          okButtonProps={{
            disabled: isConsentMessage ? false : isDisable,
          }}
        >
          {communicationDisabledMessage ? (
            <UserAlertWithWrapper
              header={communicationDisabledMessage.header}
              content={communicationDisabledMessage.body}
            />
          ) : (
            <Form onSubmit={handleSubmit} className="connect-message-form">
              <div id="muti-select-message-window " style={{ cursor: isPanelContentDisable ? 'not-allowed' : '' }}>
                <CreditInfoAlert
                  credits={credits}
                  onCloseCreditInfoAlert={onCloseCreditInfoAlert}
                  selectedActiveCandidateCount={selectedActiveCandidateCount}
                  currentUser={currentUser}
                  isPaidJobServiceEnabled={isPaidJobServiceEnabled}
                  availableCredits={availableCredits}
                  userConfig={userConfig}
                  showCreditInfoAlert
                />
                <Alert message={<FormattedMessage {...message.consentMessageLabel} />} type="info" showIcon />
                {getInfoAlertMessage(availableTextCredits)}
                <div
                  className={`multi-select-message-window-flex-items ${
                    isPanelContentDisable ? 'messageTextCreditsNotAvailable' : ''
                  }`}
                >
                  <ConnectTemplateSelect
                    cssClassname="candidate-multi-select-message-template-select"
                    templatesById={messageTemplatesById}
                    templateIds={messageTemplateIds}
                    templatesCount={messageTemplatesCount}
                    fetchTemplates={fetchMessageTemplates}
                    selectedTemplateId={selectedMessageTemplateId}
                    selectedTemplateName={selectedMessageTemplateName}
                    placeholderValue={<FormattedMessage {...message.connectTemplatePlaceHolderLabel} />}
                    onChange={eventCallbacks.onTemplateSelect}
                    isDisabled={isConsentMessage}
                    isClearFieldAllowed
                  />
                  {isConsentMessage || isEditorBodyDisabled ? null : addMergeTags}
                  {/* <img
            id="connect-aryacredit-icon"
            src={`${process.env.PUBLIC_URL}/static/images/aryacredit.svg`}
            alt="Arya credit"
          />
          <span>
            Credits Remaining: <b>534</b>
          </span> */}
                </div>

                <div
                  className={`muti-select-message-window-flex-items ${
                    isPanelContentDisable ? 'messageTextCreditsNotAvailable' : ''
                  }`}
                >
                  <div className="message-textarea">
                    <RichTextEditor
                      className="mail-editor"
                      editorContent={{
                        htmlContent: isConsentMessage ? config.consentTemplateBody : currentValues.messageInputValue,
                      }}
                      onChange={eventCallbacks.onUpdateMessage}
                      mergeTags={mergeTags}
                      ref={this.myRef}
                      showToolbar={false}
                      disabled={isEditorBodyDisabled}
                      readonly={isEditorBodyDisabled}
                      editorContext="Message"
                    />
                    {isJunkWordFound ? (
                      <SpamAlertText
                        style={{ paddingLeft: '15px' }}
                        alertStyle={{ width: '425px' }}
                        junkWords={junkWords}
                        placement="right"
                      />
                    ) : null}
                    <div
                      style={{
                        marginLeft: '15px',
                        paddingTop: '5px',
                        justifyContent: 'center',
                        display: 'inline-flex',
                      }}
                    >
                      {messageCount(messageInputValue, true)} &nbsp;
                      <div style={{ marginTop: '2px' }}>
                        <Tooltip
                          zIndex={2223}
                          title={<FormattedMessage {...message.totalCharactersShownWarning} />}
                          placement="rightBottom"
                        >
                          <ExclamationCircleIcon />
                        </Tooltip>
                      </div>
                    </div>
                    <hr
                      style={{
                        borderColor: 'rgb(252,252,252)',
                        width: '100%',
                        marginLeft: '0px',
                        marginBottom: '-1px',
                      }}
                    />
                    {emojiFinder(messageInputValue).length ? (
                      <div
                        style={{
                          display: 'inline-flex',
                          backgroundColor: 'rgb(255,251,230)',
                          paddingLeft: '15px',
                          alignItems: 'center',
                        }}
                      >
                        <p style={{ marginBottom: '-1px' }}>
                          &nbsp; Unicode (Special characters/emojis) {emojiFinder(messageInputValue)} detected &nbsp;
                        </p>
                        <div style={{ marginTop: '4px' }}>
                          <Tooltip
                            zIndex={2223}
                            title="Utilizing Unicode (special characters/emojis) for text reduces the character count per text from 160 to 70 and may result in carrier flagging text as spam. Their usage also results in an increased number of texts sent and credits used."
                            placement="rightBottom"
                          >
                            <ExclamationCircleIcon />
                          </Tooltip>
                        </div>
                      </div>
                    ) : null}
                    {/* <div className="message-character-count">
                      {charactersPerMessage - ((currentValues.messageInputValue || '').length % charactersPerMessage)}/
                      {Math.floor((currentValues.messageInputValue || '').length / charactersPerMessage)}
                      &nbsp; &nbsp;
                    </div> */}
                  </div>
                </div>
                {isConsent ? (
                  <div>
                    <MessageWindowTemplateConsentNote />
                    <div className="message-window-template-consent-text">{consentText}</div>
                  </div>
                ) : null}
              </div>
              <BulkCandidatesDownloadFilterInfo
                candidateDownloadFilterConfig={candidateDownloadFilterConfig}
                candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
              />
            </Form>
          )}
        </Modal>
      </div>
    );
  }
}

export default Form.create()(MessageComposer);
