import React from 'react';
import styles from './NotesTab.module.scss';
import NotesTabHeader from './NotesTabHeader';

const MinimizedNote = props => {
  const { candidates, candidateId, setCandidateNoteTab, smallBox } = props;
  const candidate = candidates.find(item => item.Id === candidateId);
  const toggleMaximize = () => {
    setCandidateNoteTab(candidateId, { visible: true, minimized: false });
  };
  const handleClose = () => {
    setCandidateNoteTab(candidateId, { visible: false, minimized: false, note: {} });
  };

  return (
    <NotesTabHeader
      candidate={candidate}
      toggleMaximize={toggleMaximize}
      handleClose={handleClose}
      smallBox={smallBox}
    />
  );
};

const LongMinimizedBoxes = props => {
  const { minimizedNotesPopup, candidates, setCandidateNoteTab } = props;
  return minimizedNotesPopup.map(note => (
    <div key={note.Id} className={styles.longBoxes}>
      <MinimizedNote candidates={candidates} candidateId={note.Id} setCandidateNoteTab={setCandidateNoteTab} />
    </div>
  ));
};

const SmallMinimizedBoxes = props => {
  const { candidates, minimizedNotesPopup, setCandidateNoteTab } = props;
  return minimizedNotesPopup.map(note => (
    <div key={note.Id} className={styles.smallBoxes}>
      <MinimizedNote candidates={candidates} candidateId={note.Id} setCandidateNoteTab={setCandidateNoteTab} smallBox />
    </div>
  ));
};

const NotesTabMinimized = props => {
  const { candidatesNotesList, candidates, setCandidateNoteTab } = props;
  const [minimizedNotesPopup, setMinimizedNotesPopup] = React.useState([]);

  React.useEffect(() => {
    setMinimizedNotesPopup(getMinimizedNotesPopupLength(candidatesNotesList));
  }, [candidatesNotesList]);

  const getMinimizedNotesPopupLength = candidateNotesObj => {
    const candidateNotesArray = Object.entries(candidateNotesObj).map(([Id, data]) => {
      return { ...data, Id };
    });
    return candidateNotesArray.filter(note => note.minimized);
  };

  if (minimizedNotesPopup.length < 2)
    return (
      <LongMinimizedBoxes
        candidates={candidates}
        minimizedNotesPopup={minimizedNotesPopup}
        setCandidateNoteTab={setCandidateNoteTab}
      />
    );
  return (
    <SmallMinimizedBoxes
      candidates={candidates}
      setCandidateNoteTab={setCandidateNoteTab}
      minimizedNotesPopup={minimizedNotesPopup}
    />
  );
};

export default NotesTabMinimized;
