import _ from 'lodash';
import { IFeature, IUiFeatureToggleList, featureToggleMapper } from '../Utils/FeatureMapper';

export const defaultFeatureToggleList: IUiFeatureToggleList = {
  ApplicationSettings: {
    Name: 'ApplicationSettings',
    IsEnabled: true,
    IsAllowed: true,
  },
  AudioRead: { Name: 'AudioRead', IsEnabled: true, IsAllowed: true },
  AudioWrite: { Name: 'AudioWrite', IsEnabled: false, IsAllowed: true },
  AryaRejection: { Name: 'AryaRejection', IsEnabled: true, IsAllowed: true },
  AoDAdvertisement: { Name: 'AoDAdvertisement', IsEnabled: true, IsAllowed: true },
  AdvancedJobCreation: { Name: 'AdvancedJobCreation', IsEnabled: false, IsAllowed: false },
  BotRead: { Name: 'BotRead', IsEnabled: true, IsAllowed: true },
  MinutesDelayInWorkflow: { Name: 'MinutesDelayInWorkflow', IsEnabled: false, IsAllowed: false },
  Workflow: { Name: 'Workflow', IsEnabled: false, IsAllowed: false },
  BotWrite: { Name: 'BotWrite', IsEnabled: false, IsAllowed: true },
  IsCareerportalChatViewEnabled: {
    Name: 'IsCareerportalChatViewEnabled',
    IsEnabled: false,
    IsAllowed: false,
  },
  BulkRecommend: {
    Name: 'BulkRecommend',
    IsEnabled: false,
    IsAllowed: false,
  },
  CandidateCRM: {
    Name: 'CandidateCRM',
    IsEnabled: false,
    IsAllowed: false,
  },
  AddEmailAndVerify: {
    Name: 'AddEmailAndVerify',
    IsEnabled: false,
    IsAllowed: false,
  },
  Calendar: { Name: 'Calendar', IsEnabled: false, IsAllowed: true },
  CandidateFilter: {
    Name: 'CandidateFilter',
    IsEnabled: true,
    IsAllowed: true,
  },
  CandidatePipeline: {
    Name: 'CandidatePipeline',
    IsEnabled: true,
    IsAllowed: true,
  },
  CandidateSearch: {
    Name: 'CandidateSearch',
    IsEnabled: true,
    IsAllowed: true,
  },
  CandidateSort: { Name: 'CandidateSort', IsEnabled: true, IsAllowed: true },
  Connect: { Name: 'Connect', IsEnabled: false, IsAllowed: true },
  ConnectV2: {
    Name: 'ConnectV2',
    IsEnabled: false,
    IsAllowed: false,
  },
  ConnectWidget: { Name: 'ConnectWidget', IsEnabled: true, IsAllowed: true },
  ContactFetch: { Name: 'ContactFetch', IsEnabled: true, IsAllowed: true },
  BulkContactFetch: { Name: 'BulkContactFetch', IsEnabled: false, IsAllowed: false },
  Diversity: {
    Name: 'Diversity',
    IsEnabled: true,
    IsAllowed: true,
    AllowedCountryCodes: [],
    IsPublic: false,
  },
  EmailRead: { Name: 'EmailRead', IsEnabled: true, IsAllowed: true },
  EmailWrite: { Name: 'EmailWrite', IsEnabled: false, IsAllowed: true },
  EmailReply: { Name: 'EmailReply', IsEnabled: false, IsAllowed: false },
  EngagedBucket: { Name: 'EngagedBucket', IsEnabled: true, IsAllowed: true },
  FlagConversation: {
    Name: 'FlagConversation',
    IsEnabled: false,
    IsAllowed: false,
  },
  AryaJobPostingUrl: {
    Name: 'AryaJobPostingUrl',
    IsEnabled: false,
    IsAllowed: false,
  },
  JobConfiguration: {
    Name: 'JobConfiguration',
    IsEnabled: true,
    IsAllowed: true,
  },
  JobDistribution: {
    Name: 'JobDistribution',
    IsEnabled: false,
    IsAllowed: false,
  },
  JobSummary: {
    Name: 'JobSummary',
    IsEnabled: false,
    IsAllowed: false,
  },
  JobIntel: { Name: 'JobIntel', IsEnabled: true, IsAllowed: true },
  JobSettings: { Name: 'JobSettings', IsEnabled: true, IsAllowed: true },
  JobStatus: { Name: 'JobStatus', IsEnabled: true, IsAllowed: true },
  JobTag: { Name: 'JobTag', IsEnabled: true, IsAllowed: true },
  MessageConsentTemplateCreation: { Name: 'MessageConsentTemplateCreation', IsEnabled: true, IsAllowed: true },
  MessageRead: { Name: 'MessageRead', IsEnabled: true, IsAllowed: true },
  MessageWrite: { Name: 'MessageWrite', IsEnabled: false, IsAllowed: true },
  Notes: { Name: 'Notes', IsEnabled: true, IsAllowed: true },
  NotesIndicator: { Name: 'NotesIndicator', IsEnabled: true, IsAllowed: true },
  OrderProgress: { Name: 'OrderProgress', IsEnabled: false, IsAllowed: false },
  PaidJobService: {
    Name: 'PaidJobService',
    IsEnabled: false,
    IsAllowed: false,
  },
  PendingOrders: { Name: 'PendingOrders', IsEnabled: false, IsAllowed: false },
  Personality: { Name: 'Personality', IsEnabled: true, IsAllowed: true },
  PublishingCandidates: {
    Name: 'PublishingCandidates',
    IsEnabled: false,
    IsAllowed: false,
  },
  AdvanceSearchBulkRecommend: {
    Name: 'AdvanceSearchBulkRecommend',
    IsEnabled: true,
    IsAllowed: true,
  },
  AdvanceSearchConnectOptions: {
    Name: 'AdvanceSearchConnectOptions',
    IsEnabled: true,
    IsAllowed: true,
  },
  AdvanceSearchBulkConnect: { Name: 'AdvanceSearchBulkConnect', IsEnabled: false, IsAllowed: false },
  RevealPortalsUnderGroup: {
    Name: 'RevealPortalsUnderGroup',
    IsEnabled: false,
    IsAllowed: false,
  },
  ReportingPortal: {
    Name: 'ReportingPortal',
    IsEnabled: false,
    IsAllowed: false,
  },
  ShareJob: { Name: 'ShareJob', IsEnabled: true, IsAllowed: false },
  ShortlistReject: {
    Name: 'ShortlistReject',
    IsEnabled: true,
    IsAllowed: true,
  },
  SourceFilter: { Name: 'SourceFilter', IsEnabled: true, IsAllowed: true },
  SourcingProgress: {
    Name: 'SourcingProgress',
    IsEnabled: true,
    IsAllowed: true,
  },
  SourcingNotificationEmails: {
    Name: 'SourcingNotificationEmails',
    IsEnabled: false,
    IsAllowed: false,
  },
  WhiteGloveServiceProgress: {
    Name: 'WhiteGloveServiceProgress',
    IsEnabled: false,
    IsAllowed: false,
  },
  AlternateLocation: {
    Name: 'AlternateLocation',
    IsEnabled: false,
    IsAllowed: false,
  },
  JobList: {
    Name: 'JobList',
    IsEnabled: false,
    IsAllowed: false,
  },
  Notification: {
    Name: 'Notification',
    IsEnabled: false,
    IsAllowed: false,
  },
  Profile: {
    Name: 'Profile',
    IsEnabled: false,
    IsAllowed: false,
  },
  CustomizeJobUrl: {
    Name: 'CustomizeJobUrl',
    IsEnabled: false,
    IsAllowed: false,
  },
  CustomizeJobNotInterestedUrl: {
    Name: 'CustomizeJobNotInterestedUrl',
    IsEnabled: false,
    IsAllowed: false,
  },
  ReactEmailEditor: {
    Name: 'ReactEmailEditor',
    IsEnabled: false,
    IsAllowed: false,
  },
  PrimaryFromEmail: {
    Name: 'PrimaryFromEmail',
    IsEnabled: true,
    IsAllowed: true,
  },
  SalesDashboardReports: { Name: 'SalesDashboardReports', IsEnabled: false, IsAllowed: true },
  CareerProjectionReport: { Name: 'CareerProjectionReport', IsEnabled: false, IsAllowed: true },
  CandidateResumeGeneration: {
    Name: 'CandidateResumeGeneration',
    IsEnabled: false,
    IsAllowed: false,
    AllowedCountryCodes: [],
    IsPublic: false,
  },
  MarketIntelligence: { Name: 'MarketIntelligence', IsEnabled: false, IsAllowed: false },
  SourceLimitConfiguration: { Name: 'SourceLimitConfiguration', IsEnabled: false, IsAllowed: false },
  ShareJobOnSocialMedia: {
    Name: 'ShareJobOnSocialMedia',
    IsEnabled: false,
    IsAllowed: false,
  },
  MultiLocationSourcing: {
    Name: 'MultiLocationSourcing',
    IsEnabled: false,
    IsAllowed: false,
  },
  StateWideSearch: {
    Name: 'StateWideSearch',
    IsEnabled: false,
    IsAllowed: false,
  },
  PulsePromotionalOffer: {
    Name: 'PulsePromotionalOffer',
    IsEnabled: false,
    IsAllowed: false,
  },
  CandidateManualSearch: {
    Name: 'CandidateManualSearch',
    IsEnabled: false,
    IsAllowed: false,
  },
  JobDescriptionTemplates: {
    Name: 'JobDescriptionTemplates',
    IsEnabled: false,
    IsAllowed: false,
  },
  FirstJobFree: {
    Name: 'FirstJobFree',
    IsEnabled: false,
    IsAllowed: false,
  },
  ViewApplicationOnCandidateCard: {
    Name: 'ViewApplicationOnCandidateCard',
    IsEnabled: false,
    IsAllowed: false,
  },
  UndoCandidateApplication: {
    Name: 'UndoCandidateApplication',
    IsEnabled: false,
    IsAllowed: false,
  },
  SegmentCandidateSearchLimit: {
    Name: 'SegmentCandidateSearchLimit',
    IsEnabled: false,
    IsAllowed: false,
  },
  CandidateBulkSelection: {
    Name: 'CandidateBulkSelection',
    IsEnabled: false,
    IsAllowed: false,
  },
  CandidateBulkSelectionInput: {
    Name: 'CandidateBulkSelectionInput',
    IsEnabled: false,
    IsAllowed: false,
  },
  AllowNonInternalPortalsForSegment: {
    Name: 'AllowNonInternalPortalsForSegment',
    IsEnabled: false,
    IsAllowed: false,
  },
  CandidateMatchingJobs: {
    Name: 'CandidateMatchingJobs',
    IsEnabled: false,
    IsAllowed: false,
  },

  GenerativeAIEmailGeneration: {
    Name: 'GenerativeAIEmailGeneration',
    IsEnabled: false,
    IsAllowed: false,
  },
  CandidateIntelV2: {
    Name: 'CandidateIntelV2',
    IsEnabled: false,
    IsAllowed: false,
  },
  KnowledgeBase: {
    Name: 'KnowledgeBase',
    IsEnabled: false,
    IsAllowed: false,
  },
  ConnectedCandidatesV2: {
    Name: 'ConnectedCandidatesV2',
    IsEnabled: false,
    IsAllowed: false,
  },
  BulkSms: {
    Name: 'BulkSms',
    IsEnabled: false,
    IsAllowed: false,
  },
  SocialAdvanceSettings: {
    Name: 'SocialAdvanceSettings',
    IsEnabled: false,
    IsAllowed: false,
  },
  CandidateIntelDownload: {
    Name: 'CandidateIntelDownload',
    IsEnabled: false,
    IsAllowed: false,
  },
  AdvanceSearchV2: {
    Name: 'AdvanceSearchV2',
    IsEnabled: false,
    IsAllowed: false,
  },
  AryaRanking: {
    Name: 'AryaRanking',
    IsEnabled: false,
    IsAllowed: false,
  },
  ScoutingAgent: {
    Name: 'ScoutingAgent',
    IsEnabled: false,
    IsAllowed: false,
  },
  PrefetchAdvanceSearchResults: {
    Name: 'PrefetchAdvanceSearchResults',
    IsEnabled: false,
    IsAllowed: false,
  },
  PreferredKeywordAutoSelect: {
    Name: 'PreferredKeywordAutoSelect',
    IsEnabled: false,
    IsAllowed: false,
  },
  AryaAutoSourcing: {
    Name: 'AryaAutoSourcing',
    IsEnabled: false,
    IsAllowed: false,
  },
  ShowAllClientCandidateStatus: {
    Name: 'ShowAllClientCandidateStatus',
    IsEnabled: false,
    IsAllowed: false,
  },
  SMSFiltersForJob: {
    Name: 'SMSFiltersForJob',
    IsEnabled: false,
    IsAllowed: false,
  },
  UnsubscribeCandidate: {
    Name: 'UnsubscribeCandidate',
    IsEnabled: false,
    IsAllowed: false,
  },
  ContactValidityStatusUpdate: {
    Name: 'ContactValidityStatusUpdate',
    IsEnabled: false,
    IsAllowed: false,
  },
  JobIdealCandidateResumeUpload: {
    Name: 'JobIdealCandidateResumeUpload',
    IsEnabled: false,
    IsAllowed: false,
  },
  CandidateLevelWorkflow: {
    Name: 'CandidateLevelWorkflow',
    IsEnabled: false,
    IsAllowed: false,
  },
  AssistedSourcing: {
    Name: 'AssistedSourcing',
    IsEnabled: false,
    IsAllowed: false,
  },
  AssistedCandidatePublish: {
    Name: 'CandidatePublish',
    IsEnabled: false,
    IsAllowed: false,
  },
  RecruiterAI: {
    Name: 'RecruiterAI',
    IsEnabled: false,
    IsAllowed: false,
  },
  AdvancedSearchAggregationFilters: {
    Name: 'AdvancedSearchAggregationFilters',
    IsEnabled: false,
    IsAllowed: false,
  },
  AodSubmitCandidate: {
    Name: 'AodSubmitCandidate',
    IsEnabled: false,
    IsAllowed: false,
  },
  AssistedCandidateIndicator: {
    Name: 'AssistedCandidateIndicator',
    IsEnabled: false,
    IsAllowed: false,
  },
  MoveToAppliedInJobs: {
    Name: 'MoveToAppliedInJobs',
    IsEnabled: false,
    IsAllowed: false,
  },
  ShowAdditionalInfoInJobInfo: {
    Name: 'ShowAdditionalInfoInJobInfo',
    IsEnabled: false,
    IsAllowed: false,
  },
  SubSegmentCreation: {
    Name: 'SubSegmentCreation',
    IsEnabled: false,
    IsAllowed: false,
  },
  SegmentCreation: {
    Name: 'SegmentCreation',
    IsEnabled: false,
    IsAllowed: false,
  },
  JobLevelSourcingSupportAssistant: {
    Name: 'JobLevelSourcingSupportAssistant',
    IsEnabled: false,
    IsAllowed: false,
  },
  SubSegmentEmailAlerts: {
    Name: 'SubSegmentEmailAlerts',
    IsEnabled: false,
    IsAllowed: false,
  },
  MessageDeliveryStatus: {
    Name: 'MessageDeliveryStatus',
    IsEnabled: false,
    IsAllowed: false,
  },
  AdvanceSearchAliasSkills: {
    Name: 'AdvanceSearchAliasSkills',
    IsEnabled: true,
    IsAllowed: true,
  },
  ChatBotDemo: {
    Name: 'ChatBotDemo',
    IsEnabled: false,
    IsAllowed: false,
  },
  AryaAutoSourcingForRepetitiveJobs: {
    Name: 'AryaAutoSourcingForRepetitiveJobs',
    IsEnabled: false,
    IsAllowed: false,
  },
  RemoveEngageToggle: {
    Name: 'RemoveEngageToggle',
    IsEnabled: false,
    IsAllowed: false,
  },
  SmartAgentCandidate: {
    Name: 'SmartAgentCandidate',
    IsEnabled: false,
    IsAllowed: false,
  },
  AllConversations: {
    Name: 'AllConversations',
    IsEnabled: false,
    IsAllowed: false,
  },
  JobUsageBudget:{
    Name: 'JobUsageBudget',
    IsEnabled: false,
    IsAllowed: false,
  }
};

function FeatureToggleReducer(state = defaultFeatureToggleList, action): IUiFeatureToggleList {
  let newState;
  let features;
  switch (action.type) {
    case 'SET_FEATURES':
      if (action.payload) {
        features = [...action.payload];
        features = features.map((feature: IFeature) => {
          return { [feature.Name]: feature };
        });
        return featureToggleMapper(Object.assign({}, state, ...features));
      }
      return state;
    case 'SET_FEATURE_ADDITIONAL_INFO':
      newState = _.cloneDeep(state);
      if (action.payload) {
        const { featureNames, additionalInfoDictionary } = action.payload;
        featureNames.forEach(featureName => {
          newState[featureName] = { ...newState[featureName], ...additionalInfoDictionary[featureName] };
        });
      }
      return newState;
    default:
      return state;
  }
}

function getFeatureToggleList(state): IUiFeatureToggleList {
  return _.get(state, ['FeatureToggleReducer'], {});
}

export { FeatureToggleReducer, getFeatureToggleList };
