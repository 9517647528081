import _ from 'lodash';
import cleanSet from 'clean-set';
import { SET_JOBS_BASIC_INFO_LIST } from '../Actions/ActionCreators/JobActionCreator';

const emptyObject = {};

const initialState = {
  ById: {},
  JobGuidToIdMapping: {},
};

export const JobsBasicInfoReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_JOBS_BASIC_INFO_LIST: {
      const newById = { ...state.ById };
      const newJobGuidToIdMapping = { ...state.JobGuidToIdMapping };
      action.payload.forEach(job => {
        newById[job.JobId] = job;
        newJobGuidToIdMapping[job.JobGuid] = job.JobId;
      });
      newState = cleanSet(state, 'ById', newById);
      newState = cleanSet(newState, 'JobGuidToIdMapping', newJobGuidToIdMapping);
      return newState;
    }
    default:
      return state;
  }
};

export function getJobsBasicInfoById(state) {
  return _.get(state, ['JobsBasicInfoReducer', 'ById'], emptyObject);
}

export function getBasicInfoJobGuidToIdMapping(state) {
  return state.JobsBasicInfoReducer.JobGuidToIdMapping;
}
