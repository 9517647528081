export const SET_CANDIDATE_NOTE_POPUP_INFO = 'SET_CANDIDATE_NOTE_POPUP_INFO';

export function setCandidateNotePopupAction(candidateId, { visible, minimized, note }) {
  return {
    type: SET_CANDIDATE_NOTE_POPUP_INFO,
    payload: {
      candidateId,
      visible,
      minimized,
      note,
    },
  };
}
