import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Empty, Skeleton, Icon, message } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getMailConversations, getAllMailConversations } from '../../Reducers/MailConversationsReducer';
import { getCandidatesConnectInfo } from '../../Reducers/ConnectReducer';
import { getCandidateWorkflowHistory, getIsWorkflowInitiated } from '../../Reducers/WorkflowWindowReducer';
import { getEmailTemplatesById, getEmailTemplateIds } from '../../Reducers/EmailTemplates';
import ConnectMailWindow from '../../Components/Connect/ConnectMailWindow/ConnectMailWindow';
import * as ConnectActions from '../../Actions/ConnectActions';
import { setCandidateWorkflowHistory } from '../../Actions/WorkflowWindowAction';
import * as CandidateActions from '../../Actions/CandidateActions';
import FetchContactInfo from '../../Components/Connect/FetchContactInfo/FetchContactInfo';
import getMergeTags from '../../Actions/MergeTagsActions';
import * as ChatGptActions from '../../Actions/ChatGptActions';
import * as WorkflowDripTemplatesActions from '../../Actions/WorkflowActions';
import * as MergeTagsReducer from '../../Reducers/MergeTagsReducer';
import { getDripTemplatesById } from '../../Reducers/DripTemplates';
import * as DripTemplatesActions from '../../Actions/DripTemplates';
import * as WorkflowReportActions from '../../Actions/WorkflowReportActions';
import * as WorkflowWindowActions from '../../Actions/WorkflowWindowAction';
import * as WorkflowReprotReducer from '../../Reducers/WorkflowReportReducer';
import {
  getConnectSettings,
  getEmailSmtpConfiguration,
  getOrgDefaultEmail,
} from '../../Reducers/ConnectSettingsReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { parseHtmlStringFromEditor } from '../../Utils/DomParserUtils';
import { chatGptDefaultEmailConfiguration } from '../../Utils/ChatGptDefaultRequestUtils';
import { getFilteredUserEmails, getSignatures, getUsersByGuId } from '../../Reducers/UserReducer';
import { getCurrentUser, getImpersonatedUserDetails, getCurrentUserDetails } from '../../Reducers/UserSessionReducer';
import {
  getEmptyContactsTabScreenStyle,
  getNonRefundedEmails,
  getProviderLinkedEmails,
  getIsLinkedEmailsTestSuccesful,
  getSuccessfulTestedEmailList,
} from '../../Utils/ContactUtils';
import { getCurrentSelectedFromEmail } from '../../Utils/UserInfo';
import { getEmailTemplateDetails } from '../../Utils/EmailTemplateUtil';
import { getEmailTemplate as _getEmailTemplate } from '../../Actions/EmailTemplates';
import { getFilteredMergeTags } from '../../Utils/MergeTagsUtils';
import { EmailIcon } from '../../Icons/AryaIcons';
import {
  getCommunicationDisabledContent,
  getIsCandidateCommunicationDisabled,
  getSelectedEmail,
} from '../../Utils/ConnectUtils';
import { UserAlertWithWrapper } from '../../Components/UserAlerts/UserAlerts';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getWorkflowTemplatesFetchApiStatus,
  getWorkflowDripTemplates,
  getCurrentWorkflowDripTemplateIds,
} from '../../Reducers/WorkflowReducer';
import { getChatGptSavedConfiguration, getResolvedMergeTagValues } from '../../Reducers/ChatGptReducer';
import Candidate360EmailAndMessageEmptyScreen from '../../Components/Connect/Candidate360ContactInfo/Candidate360EmailAndMessageEmptyScreen';
import { getCommunicationTabsErrorMessage } from '../../Utils/CandidateApiErrorResponseMapper';
import styles from './MailWindow.module.scss';
import { isPulseUser } from '../../Utils/ConfigUtils';
import candidateDrawerMessage from '../CandidateDrawer/messages';
import { getJobsBasicInfoById } from '../../Reducers/JobsBasicInfoReducer';
import { setNotification } from '../../Actions/ActionCreators/ConnectActions';

const mapStateToProps = (state, ownProps) => {
  const emailSmtpConfiguration = getEmailSmtpConfiguration(state);
  return {
    workflowTemplatesFetchApiStatus: getWorkflowTemplatesFetchApiStatus(state, 'getWorkflowsFetchApiStatus'),
    MailConversations: getMailConversations(state, ownProps.conversationId),
    allMailConversations: getAllMailConversations(state, ownProps.personId),
    usersByGuId: getUsersByGuId(state),
    CandidatesConnectInfo: getCandidatesConnectInfo(state),
    emailTemplatesById: getEmailTemplatesById(state),
    emailTemplateIds: getEmailTemplateIds(state),
    dripTemplatesById: getDripTemplatesById(state),
    mergeTags: MergeTagsReducer.getMergeTags(state),
    featureToggleList: getFeatureToggleList(state),
    connectSettings: getConnectSettings(state),
    emailSmtpConfiguration,
    userEmails: getFilteredUserEmails(state),
    orgDefaultEmail: getOrgDefaultEmail(state),
    signatures: getSignatures(state),
    currentUser: getCurrentUser(state),
    currentUserDetails: getCurrentUserDetails(state),
    impersonatedUser: getImpersonatedUserDetails(state),
    conversationMailsApiStatus: getApiStatus(state, 'SetConversationMailsApiStatus'),
    chatGptSavedConfiguration: getChatGptSavedConfiguration(state),
    resolvedMergeTagValues: getResolvedMergeTagValues(state),
    workflowDripTemplatesById: getWorkflowDripTemplates(state),
    currentWorkflowDripTemplateIds: getCurrentWorkflowDripTemplateIds(state),
    savedChatGptConfigurationApiStatus: getApiStatus(state, 'fetchChatGptConfigApiStatus'),
    startWorkflowWindowApiStatus: getApiStatus(state, 'startWorkflowWindowApiStatus'),
    stopWorkflowApiStatus: getApiStatus(state, 'stopWorkflowApiStatus'),
    candidateWorkflowHistoryApiStatus: getApiStatus(state, 'candidateWorkflowHistoryApiStatus'),
    candidateWorkflowHistory: getCandidateWorkflowHistory(state, ownProps.candidate.Id),
    workflowReportFilterData: WorkflowReprotReducer.getWorkflowReportFilterData(state),
    jobsBasicInfoById: getJobsBasicInfoById(state),
    isWorkflowInitiatedForCandidate: getIsWorkflowInitiated(state, ownProps.candidate.Id, ownProps.jobId),
  };
};

const mapDispatchToProps = {
  fetchMails: ConnectActions.fetchMails,
  sendMail: CandidateActions.sendMail,
  addNewDripToConversation: CandidateActions.addNewDripToConversation,
  fetchEmailTemplates: ConnectActions.fetchEmailTemplates,
  getDripTemplate: DripTemplatesActions.getDripTemplate,
  fetchMergeTags: getMergeTags,
  fetchUserEmails: ConnectActions.fetchUserEmails,
  getEmailSignatures: ConnectActions.getEmailSignatures,
  getEmailTemplate: _getEmailTemplate,
  setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
  addContact: ConnectActions.addContact,
  generateContent: ChatGptActions.generateContent,
  cancelGenerateContent: ChatGptActions.cancelGenerateContent,
  fetchGenerativeAiConfiguration: ChatGptActions.fetchGenerativeAiConfiguration,
  saveGenerativeAiConfiguration: ChatGptActions.saveGenerativeAiConfiguration,
  getGeneratedEmailsContentList: ChatGptActions.getGeneratedEmailsContentList,
  fetchResolvedMergeTags: ChatGptActions.fetchResolvedMergeTags,
  searchWorkflowTemplates: WorkflowDripTemplatesActions.searchWorkflowTemplates,
  fetchWorkflowTemplateById: WorkflowDripTemplatesActions.fetchWorkflowTemplateById,
  setCandidateWorkflowHistoryAction: setCandidateWorkflowHistory,
  setWorkflowReportData: WorkflowReportActions.setWorkflowReportData,
  startCandidateWorkflow: WorkflowWindowActions.startCandidateWorkflow,
  stopCandidateWorkflow: WorkflowWindowActions.stopCandidateWorkflow,
  setNotification,
  resetMails: ConnectActions.resetMails,
};

class MailWindowContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMailInputContent: null,
      selectedEmail: undefined,
      selectedFromEmail: undefined,
      isSubjectDisabled: false,
      mailPreHeader: null,
      preHeaderVisibility: false,
      textEditorContentVisibility: true,
      generativeEmailsList: [],
      generativeEmailListSize: 0,
      counter: 0,
      enableGenerationThroughChatGpt: true,
      isRegenerateButtonLoading: false,
      regenerativeVarients: false,
      isSubmitButtonEnabled: true,
      restartButtonFlag: false,
      bannerVisibility: false,
      displayRestartButton: false,
      displayRegenerateButton: false,
      userPromptAfterRestart: '',
      isChatGptContentGenerated: false,
      currentReplyingToEmail: [],
      workflowId: null,
    };
    this.onTemplateSelect = this.onTemplateSelect.bind(this);
    this.onSendMail = this.onSendMail.bind(this);
    this.onUpdateMailContent = this.onUpdateMailContent.bind(this);
    this.onChangeMail = this.onChangeMail.bind(this);
    this.onChangeFromEmail = this.onChangeFromEmail.bind(this);
    this.onChangeMultipleMail = this.onChangeMultipleMail.bind(this);
    this.onUpdateEmailSubject = this.onUpdateEmailSubject.bind(this);
    this.mailBody = '';
    this.mailSubject = '';
    this.currentMailInputContentRef = React.createRef();
    this.currentMailSubjectContentRef = React.createRef();
  }

  static getDerivedStateFromProps(props, prevState) {
    const updatedState = {};
    const { MailConversations, CandidatesConnectInfo, candidate, currentSelectedEmail, allMailConversations } = props;
    let { selectedEmail } = prevState;
    const { candidateId, contact: prevContact } = prevState;
    let currentEmails;

    const candidateConnectId = candidate.PersonId;
    const candidateConnectedInfo = _.get(CandidatesConnectInfo, ['ConnectStatuses', candidateConnectId], null);
    const contact = _.get(candidateConnectedInfo, 'Contact', {});

    const {
      ConsentStatus: consentStatus,
      ConsentConfiguration: consentConfiguration,
      Subscription: subscription,
    } = contact;
    const emails = _.get(contact, 'Emails', []);
    const lastConversationWith = _.get(candidateConnectedInfo, ['EmailStatus', 'LastConversationWith'], null);

    if (selectedEmail === undefined || candidate.Id !== candidateId) {
      if (lastConversationWith) {
        selectedEmail = emails.find(e => e.EmailAddress === lastConversationWith);
      } else if (emails.length) {
        const nonRefundedEmails = getNonRefundedEmails(emails);
        if (selectedEmail) {
          currentEmails = nonRefundedEmails.filter(email => {
            return email.EmailAddress === selectedEmail.EmailAddress;
          });
        }
        [selectedEmail] = getSelectedEmail(currentEmails, nonRefundedEmails);
      } else selectedEmail = undefined;
    }
    if (selectedEmail) {
      const email = emails.find(e => e.EmailAddress === selectedEmail.EmailAddress);
      selectedEmail = {
        ...selectedEmail,
        ...email,
      };
    }
    const isCommunicationAllowed =
      !(emails || []).some(email => !email.IsCommunicationAllowed) &&
      subscription?.Status?.toLowerCase() !== 'unsubscribed';
    const showFetchContact =
      !candidateConnectedInfo ||
      (!(getNonRefundedEmails(emails) || []).length &&
        (!consentStatus ||
          consentStatus === 'Approved' ||
          (consentConfiguration && consentConfiguration.IsContactViewAllowed)));

    updatedState.candidateId = candidate.Id;
    updatedState.allContextualEmails = allMailConversations;
    if (selectedEmail) {
      updatedState.allEmails = MailConversations;
    } else {
      updatedState.allEmails = [];
      updatedState.isEmailEmpty = true;
    }

    const { Emails: prevEmails } = prevContact || {};
    if (prevEmails?.length !== emails.length && currentSelectedEmail)
      selectedEmail = (emails || []).find(e => e.EmailAddress === currentSelectedEmail) || null;

    updatedState.showFetchContact = showFetchContact;
    updatedState.selectedEmail = selectedEmail;
    updatedState.isCommunicationAllowed = isCommunicationAllowed;
    updatedState.contact = contact;
    return updatedState;
  }

  componentDidMount() {
    const {
      fetchMails,
      conversationId,
      personId,
      fetchUserEmails,
      getEmailSignatures,
      FromEmailAddress,
      currentUserDetails,
      jobId,
      fetchGenerativeAiConfiguration,
      candidate,
      fetchResolvedMergeTags,
      composeEmailType,
      setCandidateWorkflowHistoryAction,
      currentJobDetails,
      workflowWindowFlag,
      workflowSelectedEmails,
      setWorkflowSelectedEmails,
      searchWorkflowTemplates,
      jobGuid,
      resetMails,
      isAllEmails,
    } = this.props;

    fetchGenerativeAiConfiguration(currentUserDetails, jobId);
    fetchResolvedMergeTags(jobId, candidate?.Id);
    fetchUserEmails({ testEmailStatus: true });
    getEmailSignatures();
    const { selectedEmail } = this.state;
    if (conversationId || personId) {
      resetMails(conversationId, personId);
      fetchMails(conversationId, personId, null, null, 0, 10, true);
    }
    if (composeEmailType === 'chatGPT' && selectedEmail) {
      this.setChatGptButtonOnChange();
      this.getResponseFromChatGpt(chatGptDefaultEmailConfiguration);
      this.updateCurrentInputValue();
    }
    if (workflowSelectedEmails?.length === 0) {
      setWorkflowSelectedEmails([selectedEmail?.EmailAddress]);
    }
    if (workflowWindowFlag) {
      setCandidateWorkflowHistoryAction(currentJobDetails.JobGuid ?? jobGuid, candidate.Id);
      searchWorkflowTemplates({
        page: 0,
        pageSize: 50,
        searchTerm: '',
        isDraft: false,
        includeRecommendedTemplates: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fetchMails,
      conversationId,
      personId,
      candidate,
      dripTemplatesById,
      emailTemplatesById,
      isContactTabRedirection,
      setCandidateWorkflowHistoryAction,
      currentJobDetails,
      workflowWindowFlag,
      setWorkflowComposeModalVisibility,
      jobGuid,
      isWorkflowInitiatedForCandidate,
      resetMails,
    } = this.props;
    const { selectedEmail, templateId } = this.state;
    if (prevProps.candidate.Id !== candidate.Id && (conversationId || personId) && selectedEmail) {
      resetMails(conversationId, personId);
      fetchMails(conversationId, personId, selectedEmail.EmailAddress, null, 0, 10);
    }
    if (
      !prevState.selectedEmail &&
      prevState.selectedEmail !== selectedEmail &&
      conversationId &&
      candidate.Id &&
      isContactTabRedirection
    ) {
      resetMails(conversationId, personId);
      fetchMails(conversationId, personId, selectedEmail.EmailAddress, null, 0, 10);
    }
    if (templateId) {
      const prevEmailTemplate = _.get(
        prevProps.dripTemplatesById,
        templateId,
        _.get(prevProps.emailTemplatesById, templateId, {})
      );
      const currentEmailTemplate = _.get(dripTemplatesById, templateId, _.get(emailTemplatesById, templateId, {}));
      if (!_.isEqual(prevEmailTemplate, currentEmailTemplate)) {
        const emailTemplateDetails = getEmailTemplateDetails(templateId, emailTemplatesById, dripTemplatesById);
        this.updatedEmailTemplateDetails(emailTemplateDetails);
      }
    }
    if (
      (prevProps.candidate.Id !== candidate.Id && workflowWindowFlag) ||
      (prevProps.isWorkflowInitiatedForCandidate !== isWorkflowInitiatedForCandidate && isWorkflowInitiatedForCandidate)
    ) {
      setWorkflowComposeModalVisibility(false);
      setCandidateWorkflowHistoryAction(currentJobDetails.JobGuid ?? jobGuid, candidate.Id);
    }
  }

  updatedEmailTemplateDetails = emailTemplateDetails => {
    const { Body, Name, PreHeader, IsReadonly, Subject, EditorUsed } = emailTemplateDetails;
    this.setState({
      currentMailInputContent: Body,
      mailPreHeader: PreHeader,
      preHeaderVisibility: PreHeader ?? false,
      emailTemplateName: Name,
      isReadonly: IsReadonly,
      mailSubject: Subject,
      editorUsed: EditorUsed,
    });
  };

  updateCurrentInputValue = () => {
    this.setState({
      textEditorContentVisibility: false,
      displayRegenerateButton: false,
    });
  };

  setChatGptButtonOnChange = () => {
    const { enableGenerationThroughChatGpt } = this.state;
    this.setState({
      enableGenerationThroughChatGpt: !enableGenerationThroughChatGpt,
      bannerVisibility: false,
    });
  };

  setRestartButtonOnClick = () => {
    this.setState({
      displayRestartButton: false,
      bannerVisibility: true,
      restartButtonFlag: true,
    });
    this.resetUserPromptAfterRestart('Your inputs will appear here');
  };

  onCloseEmailComposer = () => {
    const { cancelGenerateContent, setComposeEmailType } = this.props;
    const { enableGenerationThroughChatGpt } = this.state;
    if (!enableGenerationThroughChatGpt)
      this.setState({
        enableGenerationThroughChatGpt: true,
        regenerativeVarients: false,
        bannerVisibility: false,
        textEditorContentVisibility: true,
        currentMailInputContent: '',
        generativeEmailsList: [],
        mailSubject: '',
        isSubmitButtonEnabled: true,
      });

    if (setComposeEmailType) {
      setComposeEmailType(false);
    }
    cancelGenerateContent();
  };

  handleStopGenerationOnClick = () => {
    const { cancelGenerateContent } = this.props;
    this.mailBody = '';
    this.mailSubject = '';
    this.setState({
      displayRegenerateButton: true,
      isSubmitButtonEnabled: true,
    });
    cancelGenerateContent();
  };

  handleEmailResponse = async emailResponse => {
    const { getGeneratedEmailsContentList, jobId, candidate, setNotification } = this.props;
    try {
      const reader = emailResponse.body.getReader();
      const decoder = new TextDecoder();
      this.setState({
        textEditorContentVisibility: true,
        currentMailInputContent: '',
      });
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { done, value } = await reader.read();
        if (done) {
          // eslint-disable-next-line no-await-in-loop
          const multipleEmailResponse = await getGeneratedEmailsContentList({
            jobId,
            candidate,
          });

          this.setState({
            currentMailInputContent: this.mailBody.replace(/\n/g, '<br>'),
            isChatGptContentGenerated: false,
            isSubmitButtonEnabled: true,
            displayRegenerateButton: true,
            generativeEmailsList: multipleEmailResponse,
            counter: multipleEmailResponse?.length,
            generativeEmailListSize: multipleEmailResponse?.length,
            mailSubject: multipleEmailResponse?.[multipleEmailResponse.length - 1]?.EmailTemplate?.Subject ?? '',
            displayRestartButton:
              multipleEmailResponse?.[multipleEmailResponse.length - 1]?.GenerativeAIContext?.UserPrompts?.length !== 0,
          });
          this.mailBody = '';
          this.mailSubject = '';
          this.resetUserPromptAfterRestart('');
          break;
        }
        const chunk = decoder.decode(value);
        try {
          const chunkArray = chunk.trim().split('\n');
          // eslint-disable-next-line no-loop-func
          chunkArray.forEach(data => {
            const parsedContent = JSON.parse(data.replace(/^data:\s*/, '')); // Parse the JSON data
            if (parsedContent.Type === 'Body') {
              const content = parsedContent.Content;
              this.mailBody += content;
              this.currentMailInputContentRef.current.quillRef.setText(this.mailBody.replace(/<br\s*\/?>/g, '\n'));
              const quillRef = this.currentMailInputContentRef?.current?.reactQuillRef?.getEditor();
              if (quillRef) {
                const { scrollingContainer } = quillRef;
                const { scrollHeight, clientHeight } = scrollingContainer;
                if (scrollHeight > clientHeight) {
                  quillRef.scrollingContainer.scrollTo(0, scrollHeight - clientHeight);
                }
              }
            } else {
              const content = parsedContent.Content;
              this.mailSubject += content;
              this.currentMailSubjectContentRef.current.quillRef.setText(this.mailSubject.replace(/<br\s*\/?>/g, '\n'));
            }
          });
        } catch (error) {
          console.error({ chunk });
          console.error('Incomplete JSON content:', error);
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        setNotification('ERROR', {
          messageId: 'requestAbortedByTheUser',
        });
      }
    }
  };

  resetUserPromptAfterRestart = text => this.setState({ userPromptAfterRestart: text });

  getResponseFromChatGpt = async (value, regenerateButtonClick) => {
    const { jobId, candidate, generateContent } = this.props;
    this.setState(
      {
        mailSubject: '',
      },
      () => {
        this.setState({
          isSubmitButtonEnabled: false,
          displayRestartButton: false,
          isChatGptContentGenerated: true,
        });
      }
    );
    if (regenerateButtonClick) {
      this.setState({
        isRegenerateButtonLoading: true,
        bannerVisibility: false,
      });
    }
    const emailResponse = await generateContent({
      jobId,
      candidate,
      context: value,
    });
    this.handleEmailResponse(emailResponse);
    this.setState({
      textEditorContentVisibility: true,
      isRegenerateButtonLoading: false,
      regenerativeVarients: !!emailResponse,
      mailPreHeader: '',
      emailTemplateName: undefined,
      restartButtonFlag: false,
    });
  };

  onTemplateSelect(templateId) {
    const {
      emailTemplatesById,
      dripTemplatesById,
      getDripTemplate,
      getEmailTemplate,
      workflowDripTemplatesById,
      fetchWorkflowTemplateById,
      setWorkflowReportData,
    } = this.props;
    const { isSubjectDisabled, textEditorContentVisibility, enableGenerationThroughChatGpt } = this.state;
    if (templateId !== undefined) {
      const emailTemplate = getEmailTemplateDetails(
        templateId,
        { ...emailTemplatesById, ...workflowDripTemplatesById },
        dripTemplatesById
      );

      if (emailTemplate.IsDrip) {
        getDripTemplate(templateId);
      } else if (emailTemplate.IsPublished) {
        const workflowReportPayload = {
          id: templateId,
          aggregationIds: [templateId],
        };
        setWorkflowReportData(workflowReportPayload);
        fetchWorkflowTemplateById(templateId);
        this.setState({
          workflowId: templateId,
        });
      } else {
        getEmailTemplate(templateId);
      }
      this.setState({
        currentMailInputContent: emailTemplate.Body,
        mailPreHeader: emailTemplate?.PreHeader,
        preHeaderVisibility: emailTemplate?.PreHeader ?? false,
        templateId,
        emailTemplateName: emailTemplate.Name,
        isReadonly: emailTemplate.IsReadonly,
        editorUsed: emailTemplate.EditorUsed,
      });
      if (
        (!isSubjectDisabled && enableGenerationThroughChatGpt) ||
        (!enableGenerationThroughChatGpt && textEditorContentVisibility)
      ) {
        this.setState({
          mailSubject: emailTemplate.Subject,
        });
      }
    } else {
      this.setState({
        currentMailInputContent: '',
        mailPreHeader: '',
        templateId: '',
        preHeaderVisibility: false,
        emailTemplateName: undefined,
        isReadonly: false,
        editorUsed: null,
      });
      if (!isSubjectDisabled) {
        this.setState({
          mailSubject: '',
        });
      }
    }
    this.setState({ isAllDripsVisible: false });
  }

  onSendMail(values, subject, email, replyToMessageId) {
    const { sendMail, addNewDripToConversation, conversationId, candidate, jobId, dripTemplatesById, personId } =
      this.props;
    const { currentMailInputContent, templateId, editorUsed, mailPreHeader } = this.state;
    const currentSelectedFromEmail = this.getCurrentSelectedFromEmail();
    const candidates = [{ Id: candidate.Id }];
    const dripTemplate = _.cloneDeep(dripTemplatesById[templateId]);
    if (dripTemplate) {
      const firstDrop = dripTemplate.Drops[0];
      const dripDrops = _.cloneDeep(values.Drops);
      firstDrop.Subject = subject;
      firstDrop.Body = currentMailInputContent;
      firstDrop.PreHeader = mailPreHeader;
      dripDrops.splice(0, 0, firstDrop);
      for (let i = 0; i < dripDrops.length; i += 1) {
        dripDrops[i].Body = parseHtmlStringFromEditor(dripDrops[i].Body);
      }
      dripTemplate.Drops = dripDrops;
      const drip = {
        ...dripTemplate,
        From: currentSelectedFromEmail,
        To: email,
      };
      addNewDripToConversation(jobId, candidates, drip, conversationId, candidate);
    } else {
      const mail = {
        From: currentSelectedFromEmail,
        To: [email],
        Subject: subject,
        PreHeader: mailPreHeader,
        Body:
          editorUsed?.toLowerCase() === 'reactemaileditor'
            ? currentMailInputContent
            : parseHtmlStringFromEditor(currentMailInputContent),
        TemplateId: templateId,
        ReplyToMessageId: replyToMessageId,
      };
      sendMail(jobId, mail, conversationId, personId, candidate);
    }
    this.setState({
      currentMailInputContent: '',
      mailSubject: '',
      mailPreHeader: '',
      emailTemplateName: undefined,
      isAllDripsVisible: false,
      templateId: '',
      isSubjectDisabled: false,
      isReadonly: false,
      editorUsed: null,
    });
  }

  onUpdateMailContent({ htmlContent }) {
    const { isSubmitButtonEnabled } = this.state;
    if (isSubmitButtonEnabled) {
      this.setState({
        currentMailInputContent: htmlContent,
      });
    }
  }

  onChangeMultipleMail(mailAddresses) {
    const { setWorkflowSelectedEmails } = this.props;
    setWorkflowSelectedEmails(mailAddresses);
  }

  onChangeMail(mailAddress) {
    const { conversationId, fetchMails, generativeEmailListSize, featureToggleList, personId } = this.props;
    const {
      contact: { Emails: emails },
      enableGenerationThroughChatGpt,
    } = this.state;
    const selectedEmail = (emails || []).find(e => e.EmailAddress === mailAddress) || null;
    const isLoadingAllowed = false;

    if (
      enableGenerationThroughChatGpt ||
      (featureToggleList.GenerativeAIEmailGeneration.IsEnabled && generativeEmailListSize <= 0)
    ) {
      this.setState({
        selectedEmail,
        currentMailInputContent: '',
        mailPreHeader: '',
        mailSubject: '',
        emailTemplateName: undefined,
        isAllDripsVisible: false,
        templateId: '',
        isSubjectDisabled: false,
        isReadonly: false,
        editorUsed: null,
      });
    }
  }

  onChangeFromEmail(mailAddress) {
    this.setState({
      selectedFromEmail: mailAddress,
    });
  }

  onUpdateEmailSubject({ htmlContent }) {
    this.setState({
      mailSubject: htmlContent,
    });
  }

  onUpdateEmailPreHeader = ({ htmlContent }) => {
    this.setState({
      mailPreHeader: htmlContent,
    });
  };

  showAllDrips = () => {
    this.setState({ isAllDripsVisible: true });
  };

  getCurrentSelectedFromEmail = () => {
    const { userEmails, orgDefaultEmail, currentUser, impersonatedUser, featureToggleList, connectSettings } =
      this.props;
    const { selectedFromEmail } = this.state;
    const combinedEmails = [orgDefaultEmail, ...userEmails];
    const currentUserEmail = impersonatedUser ? impersonatedUser.Email : currentUser?.email;
    const { AdminEmailProviders: adminEmailProviders, UserEmailProviders: userEmailProviders } = connectSettings;
    const linkedEmails = getProviderLinkedEmails(userEmailProviders);
    const isLinkedEmailsTestSuccesful = getIsLinkedEmailsTestSuccesful({ userEmailProviders });
    const successfulTestedEmailList = getSuccessfulTestedEmailList({ userEmailProviders });
    const isAdminSMTPConfigurationExists = (adminEmailProviders || []).length !== 0;
    const isEmailProviderSMTPConfigurationExists =
      (linkedEmails || []).length !== 0 && !_.isEmpty(isLinkedEmailsTestSuccesful);
    return getCurrentSelectedFromEmail({
      userEmails: combinedEmails,
      currentUserEmail,
      featureToggleList,
      selectedFromEmail,
      isAdminSMTPConfigurationExists,
      isEmailProviderSMTPConfigurationExists,
      orgDefaultEmail: orgDefaultEmail?.EmailId?.toLowerCase(),
      successfulTestedEmailList,
    });
  };

  setSubjectDisability = isSubjectDisabled => {
    this.setState({
      isSubjectDisabled,
    });
  };

  addPreHeaderToEmail = () => {
    const { isChatGptContentGenerated } = this.state;
    if (!isChatGptContentGenerated) {
      this.setState({
        preHeaderVisibility: true,
      });
    }
  };

  generativeVarientsLeftClick = () => {
    const { generativeEmailsList, counter } = this.state;
    const tempClick = counter - 1;
    if (tempClick > 0) {
      const emailBody = generativeEmailsList[tempClick - 1]?.EmailTemplate?.Body;
      const convertedBody = emailBody ? emailBody.replace(/\n/g, '<br>') : '';
      this.setState({
        counter: tempClick,
        currentMailInputContent: convertedBody,
        mailSubject: generativeEmailsList[tempClick - 1]?.EmailTemplate?.Subject,
        emailTemplateName: 'Select template',
      });
    } else {
      const emailBody = generativeEmailsList[0]?.EmailTemplate?.Body;
      const convertedBody = emailBody ? emailBody.replace(/\n/g, '<br>') : '';
      this.setState({
        counter: 1,
        currentMailInputContent: convertedBody,
        mailSubject: generativeEmailsList[0]?.EmailTemplate?.Subject,
        emailTemplateName: 'Select template',
      });
    }
  };

  generativeVarientsRightClick = () => {
    const { generativeEmailsList, counter } = this.state;
    const tempClick = counter + 1;
    if (counter < generativeEmailsList?.length) {
      const emailBody = generativeEmailsList[tempClick - 1]?.EmailTemplate?.Body;
      const convertedBody = emailBody ? emailBody.replace(/\n/g, '<br>') : '';
      this.setState({
        counter: tempClick,
        currentMailInputContent: convertedBody,
        mailSubject: generativeEmailsList[tempClick - 1]?.EmailTemplate?.Subject,
        emailTemplateName: 'Select template',
      });
    } else {
      const emailBody = generativeEmailsList[generativeEmailsList.length - 1]?.EmailTemplate?.Body;
      const convertedBody = emailBody ? emailBody.replace(/\n/g, '<br>') : '';
      this.setState({
        counter: generativeEmailsList.length,
        currentMailInputContent: convertedBody,
        mailSubject: generativeEmailsList[generativeEmailsList.length - 1]?.EmailTemplate?.Subject,
        emailTemplateName: 'Select template',
      });
    }
  };

  removePreHeaderFromEmail = () => {
    this.setState({
      mailPreHeader: undefined,
      preHeaderVisibility: false,
    });
  };

  getSubscriptionStatus = () => {
    const { contact, selectedEmail } = this.state;
    if (!contact) return undefined;
    return selectedEmail ? selectedEmail.EmailSubscriptionStatus : undefined;
  };

  getConsentStatusInfoBanner = () => {
    const { candidate = {}, candidateStatus, usersByGuId } = this.props;
    const candidateName = candidate.Name;
    const { contact, selectedEmail, isCommunicationAllowed } = this.state;
    const { ConsentStatus: consentStatus, Subscription: subscription } = contact;
    const subscriptionStatus = this.getSubscriptionStatus();
    const nameParts = (candidateName || '').split(' ');
    const firstName = nameParts && nameParts.length > 0 ? nameParts[0] : '';
    const errorMessageStyle = { marginTop: '0px' };
    if (!isCommunicationAllowed) {
      const _subscription = subscription
        ? { ...subscription, CreatedByName: usersByGuId[subscription.CreatedBy]?.FullName }
        : null;
      return getCommunicationTabsErrorMessage({
        candidateStatus,
        candidateName,
        style: errorMessageStyle,
        subscription: _subscription,
      });
    }
    if ((!selectedEmail || !selectedEmail.IsAddedByUser) && consentStatus === 'Denied') {
      return (
        <div className="consent-status-info-message consent-denied">
          <Icon type="close-circle" theme="filled" />
          <span>
            <FormattedMessage {...candidateDrawerMessage.emailConsentDenied} values={{ firstName }} />
          </span>
        </div>
      );
    }
    if ((!selectedEmail || !selectedEmail.IsAddedByUser) && consentStatus === 'Failed') {
      return (
        <div className="consent-status-info-message consent-denied">
          <Icon type="close-circle" theme="filled" />
          <span>
            <FormattedMessage {...candidateDrawerMessage.consentFailed} values={{ firstName }} />
          </span>
        </div>
      );
    }
    if ((!selectedEmail || !selectedEmail.IsAddedByUser) && consentStatus === 'Pending') {
      return (
        <div className="consent-status-info-message consent-pending">
          <Icon type="clock-circle" theme="filled" />
          <span>
            <FormattedMessage {...candidateDrawerMessage.consentPending} values={{ firstName }} />
          </span>
        </div>
      );
    }
    if (subscriptionStatus === 'Unsubscribed') {
      return (
        <div className="consent-status-info-message consent-denied">
          <Icon type="close-circle" theme="filled" />
          <span>
            <FormattedMessage {...candidateDrawerMessage.unsubscribed} values={{ firstName }} />
          </span>
        </div>
      );
    }
    if (selectedEmail?.IsUnsubscribeByUser) {
      return (
        <div className="consent-status-info-message consent-denied">
          <Icon type="close-circle" theme="filled" />
          <span>
            <FormattedMessage {...candidateDrawerMessage.recruiterHasUnsubscribedLabel} /> {selectedEmail.EmailAddress}
          </span>
        </div>
      );
    }
    if ((!selectedEmail || !selectedEmail.IsAddedByUser) && consentStatus && subscriptionStatus !== 'Subscribed') {
      return (
        <div className="consent-status-info-message consent-pending">
          <Icon type="clock-circle" theme="filled" />
          <span>
            <FormattedMessage {...candidateDrawerMessage.consentPending} values={{ firstName }} />
          </span>
        </div>
      );
    }
    return null;
  };

  setDefaultEmailTemplateValues = () => {
    const { emailTemplatesById } = this.props;
    const isPulse = isPulseUser();
    const defaultEmailTemplate = Object.values(emailTemplatesById).find(template => template.IsDefault);
    if (defaultEmailTemplate?.Id && !isPulse) {
      this.onTemplateSelect(defaultEmailTemplate?.Id);
    }
  };

  setCurrentReplyingToEmail = value => {
    this.setState({ currentReplyingToEmail: value });
  };

  render() {
    const {
      candidate,
      emailTemplatesById,
      emailTemplateIds,
      mergeTags,
      availableProviders,
      dripTemplatesById,
      connectSettings,
      candidateStatus,
      featureToggleList,
      timeLineClassName,
      currentUser,
      signatures,
      userEmails,
      orgDefaultEmail,
      currentJobDetails,
      setInvalidTemplateNotification,
      CandidatesConnectInfo,
      emailSmtpConfiguration,
      candidateContext = 'job',
      version,
      isCandidateViewHeaderVisible,
      conversationMailsApiStatus,
      addContact,
      generateContent,
      onSubTabClick,
      isComposeEmailModalVisible,
      setEmailComposeModalVisibility,
      currentSelectedEmail,
      setCurrentSelectedEmail,
      FromEmailAddress,
      conversationId,
      fetchMails,
      isConnectContentPane,
      jobId,
      resolvedMergeTagValues,
      composeEmailType,
      contactsTabRef,
      workflowDripTemplatesById,
      currentWorkflowDripTemplateIds,
      searchWorkflowTemplates,
      workflowWindowFlag,
      isComposeWorkflowModalVisible,
      setWorkflowComposeModalVisibility,
      candidateWorkflowHistory,
      workflowReportFilterData,
      startCandidateWorkflow,
      stopCandidateWorkflow,
      startWorkflowWindowApiStatus,
      stopWorkflowApiStatus,
      candidateWorkflowHistoryApiStatus,
      setCandidateWorkflowHistoryAction,
      workflowSelectedEmails,
      workflowTemplatesFetchApiStatus,
      jobGuid,
      personId,
      isAllEmails,
      emailApiStatus,
      jobsBasicInfoById,
      openCandidateView,
    } = this.props;
    const {
      currentMailInputContent,
      selectedEmail,
      mailSubject,
      templateId,
      showFetchContact,
      allEmails,
      allContextualEmails,
      contact,
      isCommunicationAllowed,
      isAllDripsVisible,
      emailTemplateName,
      isSubjectDisabled,
      isReadonly,
      editorUsed,
      isEmailEmpty,
      mailPreHeader,
      preHeaderVisibility,
      textEditorContentVisibility,
      generativeEmailListSize,
      counter,
      enableGenerationThroughChatGpt,
      isRegenerateButtonLoading,
      regenerativeVarients,
      isSubmitButtonEnabled,
      generativeEmailsList,
      bannerVisibility,
      restartButtonFlag,
      isChatGptContentGenerated,
      displayRestartButton,
      displayRegenerateButton,
      currentReplyingToEmail,
      workflowId,
    } = this.state;

    let emails;
    if (personId) emails = allContextualEmails;
    else if (conversationId) emails = allEmails;

    if (jobsBasicInfoById && Object.values(jobsBasicInfoById).length > 0) {
      const jobBasicInfo = Object.values(jobsBasicInfoById);
      jobBasicInfo.forEach(jobInfo => {
        emails = emails?.map(email => {
          const tempObj = { ...email };
          if (email.RefId === jobInfo.JobGuid) {
            tempObj.JobId = jobInfo.JobId;
            tempObj.JobTitle = jobInfo.JobTitle;
          }
          return tempObj;
        });
      });
    }

    const showSkeleton = conversationMailsApiStatus === 'INPROGRESS' && !isEmailEmpty && !isComposeEmailModalVisible;
    const workflowSkeleton =
      candidateWorkflowHistoryApiStatus === 'INPROGRESS' || workflowTemplatesFetchApiStatus === 'InProgress';
    if (showSkeleton || (workflowWindowFlag && workflowSkeleton)) return <Skeleton active paragraph={{ rows: 6 }} />;
    const isCandidate360EmptyScreenVisible = !emails?.length && !isComposeEmailModalVisible;
    const showCommunicationDisabledAlert = getIsCandidateCommunicationDisabled(
      candidate.PersonId,
      CandidatesConnectInfo
    );

    if (
      workflowWindowFlag &&
      candidateWorkflowHistory?.length === 0 &&
      !isComposeWorkflowModalVisible &&
      !showFetchContact
    ) {
      const consentInforBanner = this.getConsentStatusInfoBanner();
      return (
        <>
          <h1 className={styles.consentText}>{consentInforBanner}</h1>
          <Candidate360EmailAndMessageEmptyScreen
            setComposeModalVisibility={setWorkflowComposeModalVisibility}
            isDisabled={!!consentInforBanner}
            activeTab="workflow"
            isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
            infoMessage="Set up to begin engagement"
          />
        </>
      );
    }

    if (showCommunicationDisabledAlert) {
      const alertContent = getCommunicationDisabledContent();
      return <UserAlertWithWrapper header={alertContent.header} content={alertContent.body} />;
    }

    const isContactFetchEnabled = _.get(featureToggleList, ['ContactFetch', 'IsEnabled'], false);
    const combinedEmails = [orgDefaultEmail, ...userEmails];
    const showFetchContactInfo = () => {
      const candidateName = candidate.Name;
      return (
        <FetchContactInfo
          mediaType="email"
          candidateName={candidateName}
          availableProviders={availableProviders}
          candidate={candidate}
          contact={contact}
          currentJobDetails={currentJobDetails}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        />
      );
    };
    if (showFetchContact) {
      return isContactFetchEnabled ? (
        showFetchContactInfo()
      ) : (
        <div className="chat-empty">
          <Empty
            description={
              <div className="chat-empty-text">
                <FormattedMessage {...candidateDrawerMessage.noEmailHistoryAvailableLabel} />
              </div>
            }
            image={<EmailIcon style={{ fontSize: '40px', color: '#CCC' }} />}
          />
        </div>
      );
    }

    if (!workflowWindowFlag && isCandidate360EmptyScreenVisible && isCommunicationAllowed) {
      const consentInforBanner = this.getConsentStatusInfoBanner();

      const emptyScreenStyle = getEmptyContactsTabScreenStyle({ consentInforBanner, isCandidateViewHeaderVisible });
      const { iconStyle, infoMessageStyle, contactInfoStyle } = emptyScreenStyle || {};

      return (
        <>
          <div className={styles.consentInfoBanner360}>{consentInforBanner}</div>
          <Skeleton
            active
            paragraph={{ rows: 6 }}
            loading={conversationMailsApiStatus === 'INPROGRESS' || emailApiStatus === 'INPROGRESS'}
          >
            <Candidate360EmailAndMessageEmptyScreen
              setComposeModalVisibility={setEmailComposeModalVisibility}
              activeTab="email"
              isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
              isDisabled={!!consentInforBanner}
              iconStyle={iconStyle}
              infoMessageStyle={infoMessageStyle}
              contactInfoStyle={contactInfoStyle}
              composeEmailType={composeEmailType}
              isAllEmails={isAllEmails}
            />
          </Skeleton>
        </>
      );
    }

    const currentValues = {
      emailInputValue: currentMailInputContent,
      selectedEmail,
      selectedMultipleEmail: workflowSelectedEmails,
      mailSubject,
      mailPreHeader,
      isCommunicationAllowed,
      emailTemplateName,
      isReadonly,
      editorUsed,
    };
    const eventCallbacks = {
      onSend: this.onSendMail,
      onUpdateMailContent: this.onUpdateMailContent,
      onChangeMail: this.onChangeMail,
      onUpdateEmailSubject: this.onUpdateEmailSubject,
      onUpdateEmailPreHeader: this.onUpdateEmailPreHeader,
      onTemplateSelect: this.onTemplateSelect,
      onChangeFromEmail: this.onChangeFromEmail,
      setSubjectDisability: this.setSubjectDisability,
      onChangeMultipleMail: this.onChangeMultipleMail,
    };

    const filteredMergeTags = getFilteredMergeTags(
      mergeTags,
      {
        CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
        JobSummary: featureToggleList.JobSummary,
        WhiteGloveServiceProgress: featureToggleList.WhiteGloveServiceProgress,
        PaidJobService: featureToggleList.PaidJobService,
        CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
      },
      version,
      currentJobDetails
    );
    const consentInforBanner = this.getConsentStatusInfoBanner();
    return (
      <>
        {isCommunicationAllowed ? <div className={styles.consentInfoBanner360}>{consentInforBanner}</div> : null}
        <ConnectMailWindow
          workflowWindowFlag={workflowWindowFlag}
          candidateName={candidate.Name}
          candidateStatus={candidateStatus}
          emailSmtpConfiguration={emailSmtpConfiguration}
          emailTemplatesById={emailTemplatesById}
          emailTemplateIds={emailTemplateIds}
          candidate={candidate}
          contact={contact}
          allEmails={emails}
          eventCallbacks={eventCallbacks}
          currentValues={currentValues}
          emailTemplates={Object.values(emailTemplatesById)}
          mergeTags={filteredMergeTags}
          dripTemplate={dripTemplatesById[templateId]}
          featureToggleList={featureToggleList}
          emailConfig={_.get(connectSettings, 'EmailConfig', {})}
          timeLineClassName={timeLineClassName}
          isAllDripsVisible={isAllDripsVisible}
          showAllDrips={this.showAllDrips}
          currentUser={currentUser}
          signatures={signatures}
          userEmails={combinedEmails}
          connectSettings={connectSettings}
          selectedFromEmail={this.getCurrentSelectedFromEmail()}
          isSubjectDisabled={isSubjectDisabled}
          conversationId={candidate.ConversationId}
          setInvalidTemplateNotification={setInvalidTemplateNotification}
          candidateContext={candidateContext}
          dripTemplatesById={dripTemplatesById}
          version={version}
          isComposeEmailModalVisible={isComposeEmailModalVisible}
          setEmailComposeModalVisibility={setEmailComposeModalVisibility}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
          addContact={addContact}
          generateContent={generateContent}
          jobId={jobId}
          onSubTabClick={onSubTabClick}
          currentSelectedEmail={currentSelectedEmail}
          setCurrentSelectedEmail={setCurrentSelectedEmail}
          FromEmailAddress={FromEmailAddress}
          mailConversationId={conversationId}
          fetchMails={fetchMails}
          addPreHeaderToEmail={this.addPreHeaderToEmail}
          preHeaderVisibility={preHeaderVisibility}
          removePreHeaderFromEmail={this.removePreHeaderFromEmail}
          getConsentStatusInfoBanner={this.getConsentStatusInfoBanner}
          isConnectContentPane={isConnectContentPane}
          getResponseFromChatGpt={this.getResponseFromChatGpt}
          isRegenerateButtonLoading={isRegenerateButtonLoading}
          textEditorContentVisibility={textEditorContentVisibility}
          updateCurrentInputValue={this.updateCurrentInputValue}
          generativeVarientsLeftClick={this.generativeVarientsLeftClick}
          generativeVarientsRightClick={this.generativeVarientsRightClick}
          generativeEmailListSize={generativeEmailListSize}
          setChatGptButtonOnChange={this.setChatGptButtonOnChange}
          onCloseEmailComposer={this.onCloseEmailComposer}
          enableGenerationThroughChatGpt={enableGenerationThroughChatGpt}
          counter={counter}
          resolvedMergeTagValues={resolvedMergeTagValues}
          regenerativeVarients={regenerativeVarients}
          isSubmitButtonEnabled={isSubmitButtonEnabled}
          generativeEmailsList={generativeEmailsList}
          restartButtonFlag={restartButtonFlag}
          isChatGptContentGenerated={isChatGptContentGenerated}
          displayRestartButton={displayRestartButton}
          setRestartButtonOnClick={this.setRestartButtonOnClick}
          bannerVisibility={bannerVisibility}
          displayRegenerateButton={displayRegenerateButton}
          handleStopGenerationOnClick={this.handleStopGenerationOnClick}
          userPromptAfterRestart={this.state.userPromptAfterRestart}
          resetUserPromptAfterRestart={this.resetUserPromptAfterRestart}
          chatGptBodyRef={this.currentMailInputContentRef}
          chatGptSubjectRef={this.currentMailSubjectContentRef}
          contactsTabRef={contactsTabRef}
          currentReplyingToEmail={currentReplyingToEmail}
          setCurrentReplyingToEmail={this.setCurrentReplyingToEmail}
          setDefaultEmailTemplateValues={this.setDefaultEmailTemplateValues}
          searchWorkflowTemplates={searchWorkflowTemplates}
          workflowDripTemplatesById={workflowDripTemplatesById}
          currentWorkflowDripTemplateIds={currentWorkflowDripTemplateIds}
          workflowId={workflowId}
          isComposeWorkflowModalVisible={isComposeWorkflowModalVisible}
          setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
          workflowHistory={candidateWorkflowHistory}
          workflowReportFilterData={workflowReportFilterData}
          jobGuid={currentJobDetails.JobGuid ?? jobGuid}
          jobDetails={currentJobDetails}
          startCandidateWorkflow={startCandidateWorkflow}
          stopCandidateWorkflow={stopCandidateWorkflow}
          startWorkflowWindowApiStatus={startWorkflowWindowApiStatus}
          stopWorkflowApiStatus={stopWorkflowApiStatus}
          setCandidateWorkflowHistoryAction={setCandidateWorkflowHistoryAction}
          personId={personId}
          openCandidateView={openCandidateView}
        />
      </>
    );
  }
}
export { MailWindowContainer as MailWindowWithoutContainer };
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MailWindowContainer);
