import React from 'react';
import { Tabs } from 'antd';
import ReportSummaryTabContainer from '../../Containers/ReportPortalContainer/ReportSummaryTabContainer/ReportSummaryTabContainer';
import ReportStatsTabContainer from '../../Containers/ReportPortalContainer/ReportStatsTabContainer/ReportStatsTabContainer';
import styles from './ReportPortalComponent.module.scss';

const { TabPane } = Tabs;

export default function ReportPortalComponent(props) {
  const { featureToggleList, currentNotification } = props;
  const isReportPortalEnabled = featureToggleList.ReportingPortal.IsEnabled;

  return (
    <div style={{ display: 'flex' }}>
      <div className={styles.tabs} style={{ position: 'relative', width: '100%' }}>
        <Tabs
          defaultActiveKey="summary"
          tabBarExtraContent={
            isReportPortalEnabled && <div className={styles.reportNotificationContent}>{currentNotification}</div>
          }
        >
          {isReportPortalEnabled ? (
            <TabPane tab="Overview" key="summary">
              <div>
                <ReportSummaryTabContainer />
              </div>
            </TabPane>
          ) : null}
          {isReportPortalEnabled ? (
            <TabPane tab="Reports" key="reports">
              <div>
                <ReportStatsTabContainer />
              </div>
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    </div>
  );
}
