import React, { useCallback } from 'react';
import _ from 'lodash';
import { Affix, Form, Tooltip } from 'antd';
import { connect } from 'react-redux';
import Draggable from 'react-draggable';
import store from '../../store';
import isEuropeanCountry from '../../Utils/GDPRcountry';
import CandidateBulkActionsContainer from '../CandidateBulkActionsContainer/CandidateBulkActionsContainer';
import ManualSearchCandidateList from '../ManualSearchCandidateList/ManualSearchCandidateList';
import * as JobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as candidateActionCreators from '../../Actions/ActionCreators/CandidateActions';
import * as jobActions from '../../Actions/JobActions';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import {
  getBulkShortlistedNotification,
  getCandidateCount,
  getCandidates,
  getCandidatesAggregations,
  getSourcingNotification,
  getMergedAllTabDuplicateCandidates,
  getSmartAgentCandidateNotification,
} from '../../Reducers/CandidateReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getCurrentPage,
  getFilterQueries,
  getPageSize,
  getSelectedBulkActivity,
  getSelectedCandidates as _getSelectedCandidates,
  getSelectedCandidateIds,
  getCandidateCollapsedFilters,
  getSelectedCustomBulkActivity,
} from '../../Reducers/JobCandidatesTabReducer';
import { getCandidateSource, getCandidateSourceName, getSourceName } from '../../Utils/SourceUtils';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import {
  geLastPerformedManualSearchTime,
  getManualSearchPayload,
  getIsAllTabFiltersApplied,
} from '../../Reducers/ManualSearchReducer';
import ReactivateArya from '../CandidateFilters/ReactivateArya/ReactivateArya';
import JobReactivationTree from '../CandidateFilters/JobReactivationTree/JobReactivationTree';
import styles from './ManualSearchCandidatesTab.module.scss';
import AggregationFilter from '../QuickSearch/QuickSearchAggregationFilter/QuickSearchAggregationFilter';
import {
  getFilterQueriesPayload,
  clearFilterQueries,
  clearAggregation,
} from '../../Components/QuickSearchForm/QuickSearchFormUtils';
import SelectedCandidatesText from '../CandidateFilters/SelectedCandidatesText';
import {
  getAllNonGroupHeadDuplicateCandidateIds,
  getAllTabTotalFilteredCandidatesCount,
  getAllTabFetchedCandidatesCount,
  getManualSearchCandidates,
  getAllDuplicateCandidates,
  getAllTabCandidates,
  getManualSearchAllTabDisplayedCandidateCount,
} from '../../Reducers/ManualSearchCandidateReducer';
import {
  getIsDeDuplicationAllowed,
  findGroupHeadsNotPresentInFiltertedAllTabCandidates,
} from '../../Utils/DeDuplicationUtils';
import WarningIcon from '../../Icons/WarningIcon';
import AllTabCandidateAggregationFilterDrawer from '../CandidateFilters/AllTabCandidateAggregationFilter/AllTabCandidateAggregationFilterDrawer';
import { getFilteredAllTabCandidates } from '../../Utils/CandidateListUtils';
import CandidateAggregationIcon from '../../Icons/CandidateAggregationIcon';
import SmartAgentCandidatesFetchButton from '../CandidateFilters/SmartAgentCandidate/SmartAgentCandidatesFetchButton';
import * as CandidateActions from '../../Actions/CandidateActions';

const mapStateToProps = (state, props) => {
  const sourceName = getSourceName(props.activeSource);
  return {
    candidatesById: getCandidates(state),
    candidateListStatus: getApiStatus(state, 'candidateListStatus'),
    featureToggleList: getFeatureToggleList(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    totalCandidate: getCandidateCount(state),
    currentSelectedAllCandidates: _getSelectedCandidates(state, 'NonRecommended'),
    selectedCandidateIds: getSelectedCandidateIds(state, 'NonRecommended'),
    bulkActivitySelection: getSelectedBulkActivity(state, 'NonRecommended'),
    manualSearchPayload: getManualSearchPayload(state, { jobId: props.jobId }),
    config: getConfig(state),
    candidateAggregations: getCandidatesAggregations(state),
    manualSearchLastPerformedTime: geLastPerformedManualSearchTime(state, { jobId: props.jobId }),
    filterQueries: getFilterQueries(state, sourceName),
    sourcingNotification: getSourcingNotification(state),
    bulkShortlistedNotification: getBulkShortlistedNotification(state),
    whiteLabelInfo: getWhiteLabelInformation(state),
    nonGroupHeadDuplicateCandidateIds: getAllNonGroupHeadDuplicateCandidateIds(state, props.jobId, sourceName),
    mergedAllTabDuplicateCandidates: getMergedAllTabDuplicateCandidates(state, props.jobId),
    candidateCollapsedFilters: getCandidateCollapsedFilters(state, props.jobId),
    bulkCustomActivitySelection: getSelectedCustomBulkActivity(state, 'NonRecommended'),
    allTabFiltertedCandidatesCount: getAllTabTotalFilteredCandidatesCount(state, { jobId: props.jobId }),
    isAllTabCandidatesFilterApplied: getIsAllTabFiltersApplied(state, { jobId: props.jobId }),
    allTabFetchedCandidatesCount: getAllTabFetchedCandidatesCount(state, { jobId: props.jobId }),
    manualSearchCandidates: getManualSearchCandidates(state, props.jobId),
    allTabCandidatesGroups: getAllDuplicateCandidates(state, props.jobId, 'All'),
    allTabCandidates: getAllTabCandidates(state, props.jobId),
    allTabCandidatesCount: getManualSearchAllTabDisplayedCandidateCount(state, { jobId: props.jobId }),
    smartAgentCandidateNotification: getSmartAgentCandidateNotification(state),
  };
};

const mapDispatchToProps = {
  setSelectedCandidates: JobCandidatesTabActions.setSelectedCandidates,
  setSelectedBulkActivity: JobCandidatesTabActions.setSelectedBulkActivity,
  setJobCandidateTabActiveSourceName: JobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setFilterQueries: JobCandidatesTabActions.setFilterQueries,
  setCandidateListType: candidateActionCreators.setCandidateListType,
  fetchJobSourcingStats: jobActions.fetchJobSourcingStats,
  seCandidateCollapsedFilters: JobCandidatesTabActions.seCandidateCollapsedFilters,
  setCandidateCollapsedFilterStatus: JobCandidatesTabActions.setCandidateCollapsedFilterStatus,
  setCustomSelectedBulkActivity: JobCandidatesTabActions.setCustomSelectedBulkActivity,
  setCustomSelectedCandidates: JobCandidatesTabActions.setCustomSelectedCandidates,
  fetchSmartAgentCandidates: CandidateActions.fetchSmartAgentCandidates,
};

function ManualSearchCandidatesTab(props) {
  const {
    candidatesById,
    activeSource,
    jobId,
    featureToggleList,
    candidateListStatus,
    setSelectedCandidates,
    setSelectedBulkActivity,
    currentSelectedAllCandidates,
    bulkActivitySelection,
    currentPage,
    pageSize,
    config,
    currentJobDetails,
    totalCandidate,
    manualSearchPayload,
    atsJobCandidateListProps,
    bulkOptionsOffestTop,
    candidateAggregations,
    form,
    fetchManualSearchCandidates,
    manualSearchLastPerformedTime,
    setFilterQueries,
    filterQueries,
    setCandidateListType,
    whiteLabelInfo,
    candidateContext = 'job',
    allTabCandidateFetchApiStatus,
    version,
    onPageChange,
    isSegmentCandidateSearchLimitFeatureEnabled,
    selectedCandidateIds,
    isNonInternalPortalForSegmentEnabled,
    openSipCallWindowsApp,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    nonGroupHeadDuplicateCandidateIds,
    mergedAllTabDuplicateCandidates,
    smartRecruiterVersion,
    onStatusChange,
    activeTab,
    candidateCollapsedFilters,
    seCandidateCollapsedFilters,
    setCandidateCollapsedFilterStatus,
    isExactMatchSearch,
    setCustomSelectedBulkActivity,
    bulkCustomActivitySelection,
    candidatesRecommendationStatus,
    maskingConfig = {},
    fetchManualSearchCandidateList,
    isAllTabCandidatesFilterApplied,
    allTabFiltertedCandidatesCount,
    manualSearchCandidates,
    allTabCandidatesGroups,
    allTabCandidates,
    allTabCandidatesCount,
    smartAgentCandidateNotification,
    fetchSmartAgentCandidates,
  } = props;
  const isAllTabAggregationFilterEnabled = featureToggleList.AdvancedSearchAggregationFilters.IsEnabled;
  const isCandidateBulkSelectionInputEnabled =
    candidateContext === 'segment' && featureToggleList.CandidateBulkSelectionInput.IsEnabled;

  const {
    IsActive: isMaskingActive,
    IsNameMasked: isNameMasked,
    IsUniversityMasked: isUniversityMasked,
  } = maskingConfig;
  const showMaskWarningMessage = isMaskingActive && (isNameMasked || isUniversityMasked);
  const segmentMaximumSelectionLimit = config?.SegmentCandidateMaxLimitPerQuery;
  const sourceName = getSourceName(activeSource);
  const segmentBulkLimit = sourceName?.toLowerCase() === 'internal' ? segmentMaximumSelectionLimit : 1000;
  const segmentCandidateSearchMaxLimit = isNonInternalPortalForSegmentEnabled
    ? segmentBulkLimit
    : segmentMaximumSelectionLimit;
  const maxBulkCandidatesSelectionCount = candidateContext === 'segment' ? segmentCandidateSearchMaxLimit : 500;
  const [isDragging, setIsDragging] = React.useState(false);
  const [isFilterApplied, setisFilterApplied] = React.useState(false);

  const isAdvancedSearchV2Enabled = featureToggleList?.AdvanceSearchV2?.IsEnabled;
  React.useEffect(() => {
    setCandidateListType('ManualSearchCandidateList');
    return () => {
      setCandidateListType(null);
    };
  }, []);

  React.useEffect(() => {
    if (
      jobId?.toString() === smartAgentCandidateNotification?.jobId?.toString() &&
      sourceName === 'smartAgentCandidates'
    ) {
      fetchSmartAgentCandidates({ from: 0, size: pageSize, jobId });
    }
  }, [smartAgentCandidateNotification?.time]);

  const _fetchManualSearchCandidates = (_filterQueries = {}) => {
    const currentPortalQueries = manualSearchPayload.PortalQueries?.[sourceName];
    const currentFilterQueries = _filterQueries;
    const updatedPortalQueries = { ...currentPortalQueries, ...currentFilterQueries };
    const newManualSearchPayload = {
      ...manualSearchPayload,
      Sources: [activeSource],
      PortalQueries: { [sourceName]: updatedPortalQueries },
    };
    const fetchRemoteDataOnly = true;
    fetchManualSearchCandidates({
      manualSearchPayload: newManualSearchPayload,
      fetchRemoteDataOnly,
    });
  };

  const collapsedFilterField = 'collapsedFilter';
  const selectedKeys = form.getFieldValue(collapsedFilterField) || [];
  const applyAggregationFilter = async () => {
    const _filterQueries = getFilterQueriesPayload(form.getFieldsValue(), activeSource);
    _fetchManualSearchCandidates(_filterQueries);
    setFilterQueries({ filterQueries: _filterQueries, sourceName });
    if (isAdvancedSearchV2Enabled) {
      setCandidateCollapsedFilterStatus('INPROGRESS');
      await seCandidateCollapsedFilters({ jobId, filters: selectedKeys });
      setCandidateCollapsedFilterStatus('COMPLETED');
      if (selectedKeys.length) setisFilterApplied(true);
      else setisFilterApplied(false);
    }
  };

  const resetAggregationFilter = useCallback(() => {
    clearFilterQueries(form, sourceName);
    setFilterQueries({ filterQueries: {}, sourceName });
  }, [sourceName]);

  const clearAggregationFilter = useCallback(async () => {
    resetAggregationFilter();
    _fetchManualSearchCandidates();
    if (isAdvancedSearchV2Enabled) {
      form.setFieldsValue({ collapsedFilter: [] });
      setCandidateCollapsedFilterStatus('INPROGRESS');
      await seCandidateCollapsedFilters({ jobId, filters: [] });
      setCandidateCollapsedFilterStatus('COMPLETED');
      setisFilterApplied(false);
    }
  });

  const removeAggregation = (_sourceName, aggregationKey) => {
    const updatedFilterQueries = _.omit(filterQueries, [aggregationKey]);
    clearAggregation(form, sourceName, aggregationKey);
    _fetchManualSearchCandidates(updatedFilterQueries);
    setFilterQueries({ filterQueries: updatedFilterQueries, sourceName });
  };

  React.useEffect(() => {
    if (!_.isEmpty(filterQueries)) resetAggregationFilter();
  }, [manualSearchLastPerformedTime]);

  const {
    AdvanceSearchBulkRecommend: { IsEnabled: isBulkRecommendEnabled },
    AdvanceSearchBulkConnect: { IsEnabled: isBulkConnectEnabled },
    CandidateBulkSelection: { IsEnabled: isBulkSelectionAllowed },
    BulkSms: { IsEnabled: isBulkMessageEnabled },
  } = featureToggleList;
  const isCandidateListLoading = candidateListStatus === 'INPROGRESS';

  const resetMultiSelect = useCallback(() => {
    setSelectedCandidates({ candidateType: 'NonRecommended', selectedCandidates: [] });
    setSelectedBulkActivity({ candidateType: 'NonRecommended', bulkActivitySelection: {} });
    setCustomSelectedBulkActivity({
      candidateType: 'NonRecommended',
      bulkCustomActivitySelection: {},
    });
  }, []);

  const getCurrentSourceConfig = candidate => {
    const candidateSource = getCandidateSource(candidate);
    const candidateSourceName = getCandidateSourceName(candidateSource, true);
    return config?.SourceConfigsByName?.[candidateSourceName?.toLowerCase()];
  };

  const getCurrentPageCandidates = () => {
    if (Object.keys(candidatesById).length === 0 || !activeSource) {
      return [];
    }
    const currentPageCandidateIds = candidatesById ? Object.keys(candidatesById) : [];
    const currentSource = activeSource?.Portal ?? activeSource?.Group;

    return currentPageCandidateIds.map((Id, index) => {
      const candidateIndex = (currentPage - 1) * pageSize + index;
      const candidateInfo = getIsDeDuplicationAllowed(sourceName) ? mergedAllTabDuplicateCandidates?.[Id] : {};
      const candidate = candidatesById?.[Id]?.Id ? candidatesById?.[Id] : candidateInfo;
      const sourceConfiguration = !_.isEmpty(candidate) ? getCurrentSourceConfig(candidate) : null;
      return {
        CandidateId: Id,
        RecommendedInfo: {
          SourceConfigId: sourceConfiguration?.Id ?? null,
          SourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
        },
        index: candidateIndex,
        source: currentSource,
      };
    });
  };

  const getFiltertedAllTabCandidates = () => {
    if (Object.keys(manualSearchCandidates).length === 0 || !activeSource) {
      return [];
    }
    const filteredAllTabCandidates =
      getFilteredAllTabCandidates({ store: store.getState(), jobId, candidateContext }) || [];

    const currentSource = activeSource?.Portal ?? activeSource?.Group;

    const groupHeadsNotPresentInFilteredAllCandidatesInfo = findGroupHeadsNotPresentInFiltertedAllTabCandidates(
      filteredAllTabCandidates,
      nonGroupHeadDuplicateCandidateIds,
      allTabCandidatesGroups,
      allTabCandidates
    );

    return [...filteredAllTabCandidates, ...groupHeadsNotPresentInFilteredAllCandidatesInfo].map(candidateInfo => {
      const candidateIndex = (currentPage - 1) * pageSize + candidateInfo.Index;
      const candidate = manualSearchCandidates?.[candidateInfo.Id];
      const sourceConfiguration = !_.isEmpty(candidate) ? getCurrentSourceConfig(candidate) : null;
      return {
        CandidateId: candidateInfo.Id,
        RecommendedInfo: {
          SourceConfigId: sourceConfiguration?.Id ?? null,
          SourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
        },
        index: candidateIndex,
        source: currentSource,
      };
    });
  };

  const setCurrentSelectedAllCandidates = (candidates, isChecked) => {
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const newBulkActivitySelection = _.cloneDeep(bulkActivitySelection);
    const currentSourceBulkActivitySelection = newBulkActivitySelection[currentSource] ?? {};
    const { bulkActivitySearchCriteria } = currentSourceBulkActivitySelection;
    const currentPageCandidates = getCurrentPageCandidates();
    if (!bulkActivitySearchCriteria) {
      const isDeDuplicationAllowed = getIsDeDuplicationAllowed(sourceName);
      const filteredCandidates = isDeDuplicationAllowed
        ? _.uniqBy(candidates, 'CandidateId').filter(
            obj => !nonGroupHeadDuplicateCandidateIds.includes(obj.CandidateId)
          )
        : candidates;
      const selectedCandidates = filteredCandidates;
      setSelectedCandidates({
        candidateType: 'NonRecommended',
        selectedCandidates,
      });
      return;
    }
    let newcurrentSelectedAllCandidates = _.cloneDeep(currentSelectedAllCandidates) ?? [];
    let excludedCandidates = currentSourceBulkActivitySelection?.excludedCandidates ?? [];
    const { Size: currentSearchCriteriaSize = 0 } = bulkActivitySearchCriteria;
    const inRangeCandidates = currentPageCandidates.filter(x => x.index < currentSearchCriteriaSize);
    const inRangeCandidateIds = inRangeCandidates.map(x => x.CandidateId);
    const outOfRangeCandidates = currentPageCandidates.filter(x => x.index >= currentSearchCriteriaSize);
    if (isChecked && inRangeCandidates?.length > 0) {
      excludedCandidates = excludedCandidates?.filter(x => !inRangeCandidateIds.includes(x.CandidateId));
    } else if (inRangeCandidates?.length > 0) {
      excludedCandidates = excludedCandidates?.concat(inRangeCandidates);
    }
    if (outOfRangeCandidates?.length > 0) {
      newcurrentSelectedAllCandidates = currentSelectedAllCandidates.concat(outOfRangeCandidates);
    }
    if (!_.isEmpty(bulkActivitySearchCriteria) && excludedCandidates?.length < bulkActivitySearchCriteria.Size) {
      currentSourceBulkActivitySelection.excludedCandidates = excludedCandidates;
      newBulkActivitySelection[currentSource] = currentSourceBulkActivitySelection;
    } else if (newBulkActivitySelection[currentSource]) {
      delete newBulkActivitySelection[currentSource];
    }
    setSelectedCandidates({ candidateType: 'NonRecommended', selectedCandidates: newcurrentSelectedAllCandidates });
    setSelectedBulkActivity({ candidateType: 'NonRecommended', bulkActivitySelection: newBulkActivitySelection });
  };

  const currentPageCandidates = getCurrentPageCandidates();

  const getSelectedCandidatesCount = () => {
    let bulkSelectionCandidatesCount = 0;
    const bulkActivities = Object.values(bulkActivitySelection);
    bulkActivities.forEach(element => {
      bulkSelectionCandidatesCount =
        bulkSelectionCandidatesCount +
        (element.bulkActivitySearchCriteria?.Size ?? 0) -
        (element.excludedCandidates?.length ?? 0);
    });
    return (currentSelectedAllCandidates ?? []).length + bulkSelectionCandidatesCount;
  };

  const getSelectedCandidatesCustomCount = () => {
    let bulkCustomSelectionCandidatesCount = 0;

    const bulkCustomActivities = Object.values(bulkCustomActivitySelection);
    bulkCustomActivities.forEach(element => {
      bulkCustomSelectionCandidatesCount =
        bulkCustomSelectionCandidatesCount +
        (element.bulkActivitySearchCriteria?.Size ?? 0) -
        (element.excludedCandidates?.length ?? 0);
    });
    return bulkCustomSelectionCandidatesCount;
  };

  const getSelectedActiveCandidatesCount = () => {
    const selectedActiveCandidates =
      currentSelectedAllCandidates?.filter(x => x.source?.toLowerCase() === 'active') ?? [];
    const selectedActiveCandidatesCount = selectedActiveCandidates.length;
    const activeBulkSelection = bulkActivitySelection?.Active;
    if (_.isEmpty(activeBulkSelection)) {
      return selectedActiveCandidatesCount;
    }
    const { bulkActivitySearchCriteria, excludedCandidates } = activeBulkSelection;
    const bulkSelectedActiveCandidates = bulkActivitySearchCriteria?.Size - excludedCandidates?.length;
    return selectedActiveCandidatesCount + bulkSelectedActiveCandidates;
  };

  const updateCustomBulkActivity = (isChecked, isSelectCustom, customSelectNumber) => {
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const bulkActivitySourceConfiguration = getCurrentSourceConfig({ Source: activeSource });
    if (candidateContext === 'segment' && isSelectCustom) {
      if (isChecked) {
        setCustomSelectedBulkActivity({
          candidateType: 'NonRecommended',
          bulkCustomActivitySelection: {},
        });
      } else {
        const newBulkCustomActivitySelection = _.cloneDeep(bulkCustomActivitySelection);
        newBulkCustomActivitySelection[currentSource] = {
          bulkActivitySearchCriteria: {
            ...manualSearchPayload,
            Sources: [activeSource],
            From: 0,
            Size: customSelectNumber,
          },
          bulkActivitySourceConfiguration,
        };
        setCustomSelectedBulkActivity({
          candidateType: 'NonRecommended',
          bulkCustomActivitySelection: newBulkCustomActivitySelection,
        });
      }
    }
  };

  const onSelectBulkCandidates = (event, isSelectCustom, customSelectNumber) => {
    const isChecked = event.target.checked;
    const activeSourceName = getSourceName(activeSource);
    if (
      activeSourceName === 'All' &&
      candidateContext === 'job' &&
      isAllTabAggregationFilterEnabled &&
      isAllTabCandidatesFilterApplied
    ) {
      if (!isChecked) setSelectedCandidates({ candidateType: 'NonRecommended', selectedCandidates: [] });
      else {
        let currentSelectedAllTabCandidates = getFiltertedAllTabCandidates() || [];
        currentSelectedAllTabCandidates = _.uniqWith(currentSelectedAllTabCandidates, _.isEqual);
        setCurrentSelectedAllCandidates(currentSelectedAllTabCandidates, isChecked);
      }
    } else {
      const currentSource = activeSource?.Portal ?? activeSource?.Group;
      const bulkActivitySourceConfiguration = getCurrentSourceConfig({ Source: activeSource });
      const size = Math.min(maxBulkCandidatesSelectionCount, totalCandidate);
      const newBulkActivitySelection = _.cloneDeep(bulkActivitySelection);
      if (isChecked) {
        const newCurrentSelectedAllCandidates = currentSelectedAllCandidates.filter(
          x => x.source !== currentSource || x.index > size
        );
        newBulkActivitySelection[currentSource] = {
          bulkActivitySearchCriteria: { ...manualSearchPayload, Sources: [activeSource], From: 0, Size: size },
          bulkActivitySourceConfiguration,
        };
        setSelectedCandidates({ candidateType: 'NonRecommended', selectedCandidates: newCurrentSelectedAllCandidates });
      } else {
        delete newBulkActivitySelection[currentSource];
      }
      setSelectedBulkActivity({ candidateType: 'NonRecommended', bulkActivitySelection: newBulkActivitySelection });
      updateCustomBulkActivity(isChecked, isSelectCustom, customSelectNumber);
    }
  };

  const onCustomSelectBulkCandidates = (event, customNumber) => {
    const isChecked = event.target.checked;
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const customBulkActivitySourceConfiguration = getCurrentSourceConfig({ Source: activeSource });
    const size = Math.min(maxBulkCandidatesSelectionCount, customNumber);
    const newBulkCustomActivitySelection = _.cloneDeep(bulkCustomActivitySelection);
    if (isChecked) {
      newBulkCustomActivitySelection[currentSource] = {
        bulkActivitySearchCriteria: { ...manualSearchPayload, Sources: [activeSource], From: 0, Size: size },
        customBulkActivitySourceConfiguration,
      };
    } else {
      delete newBulkCustomActivitySelection[currentSource];
    }
    setCustomSelectedBulkActivity({
      candidateType: 'NonRecommended',
      bulkCustomActivitySelection: newBulkCustomActivitySelection,
    });
  };

  const getIsBulkSelectionChecked = () => {
    const activeSourceName = getSourceName(activeSource);
    if (
      activeSourceName === 'All' &&
      candidateContext === 'job' &&
      isAllTabAggregationFilterEnabled &&
      isAllTabCandidatesFilterApplied
    ) {
      const selectedCandidateCount = getSelectedCandidatesCount();
      const filteredAllTabCandidates = getFilteredAllTabCandidates({ store: store.getState(), jobId }) || [];
      const uniqueGroupHeadCandidates = _.uniqBy(filteredAllTabCandidates, 'Id').filter(
        obj => !nonGroupHeadDuplicateCandidateIds.includes(obj.Id)
      );

      const groupHeadsNotPresentInFilteredAllCandidatesInfo = findGroupHeadsNotPresentInFiltertedAllTabCandidates(
        filteredAllTabCandidates,
        nonGroupHeadDuplicateCandidateIds,
        allTabCandidatesGroups,
        allTabCandidates
      );

      return (
        selectedCandidateCount ===
        _.uniqBy([...uniqueGroupHeadCandidates, ...groupHeadsNotPresentInFilteredAllCandidatesInfo], 'Id').length
      );
    }

    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentBulkSelection = bulkActivitySelection[currentSource];
    if (!currentBulkSelection) {
      return false;
    }
    const { bulkActivitySearchCriteria, excludedCandidates } = currentBulkSelection;
    return !_.isEmpty(bulkActivitySearchCriteria) && !excludedCandidates?.length;
  };

  const getIsPartialBulkSelectionChecked = () => {
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentBulkSelection = bulkActivitySelection[currentSource];
    if (!currentBulkSelection) {
      return false;
    }
    const { bulkActivitySearchCriteria } = currentBulkSelection;
    return !_.isEmpty(bulkActivitySearchCriteria);
  };

  const getIsSelectThisPageChecked = () => {
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentBulkSelection = bulkActivitySelection[currentSource];
    const { bulkActivitySearchCriteria, excludedCandidates } = currentBulkSelection ?? {};
    if (_.isEmpty(bulkActivitySearchCriteria)) {
      return false;
    }
    const currentPageCandidatesCount = Object.keys(candidatesById).length;
    if (!currentPageCandidatesCount) {
      return false;
    }
    const firstCandidateIndex = (currentPage - 1) * pageSize;
    const lastCandidateIndex = (currentPage - 1) * pageSize + currentPageCandidatesCount - 1;
    if (lastCandidateIndex >= bulkActivitySearchCriteria.Size) {
      return false;
    }
    const isExcludedCandidatesExistsInCurrentPage = excludedCandidates?.some(
      x => x.index >= firstCandidateIndex && x.index <= lastCandidateIndex
    );
    return !isExcludedCandidatesExistsInCurrentPage;
  };

  const selectedCandidatesCount = getSelectedCandidatesCount();
  const selectedCandidatesCustomCount = getSelectedCandidatesCustomCount();
  const selectedActiveCandidatesCount = getSelectedActiveCandidatesCount();
  const isBulkOptionsEnabled = isBulkRecommendEnabled || isBulkConnectEnabled;

  const getBulkOptions = () => {
    const isBulkAddAvailable = candidateContext === 'segment';
    const isBulkDeleteAvailable = candidateContext === 'segment';
    const bulkOptions = (
      <CandidateBulkActionsContainer
        jobId={jobId}
        visiblityFlag
        candidateListType="manualsearch"
        isBulkRecommendEnabled={isBulkRecommendEnabled}
        isBulkEmailEnabled={isBulkConnectEnabled}
        isBulkMessageEnabled={isBulkConnectEnabled && isBulkMessageEnabled}
        selectedCandidates={selectedCandidateIds}
        currentPageCandidates={currentPageCandidates}
        _currentSelectedAllCandidates={currentSelectedAllCandidates}
        selectedActiveCandidateCount={selectedActiveCandidatesCount}
        resetMultiSelect={resetMultiSelect}
        setCurrentSelectedAllCandidates={setCurrentSelectedAllCandidates}
        isConsentRequired={isEuropeanCountry(currentJobDetails?.CountryCode)}
        onSelectBulkCandidates={onSelectBulkCandidates}
        bulkActivitySelection={bulkActivitySelection}
        isBulkSelectionAllowed={isBulkSelectionAllowed}
        selectedCandidatesCount={
          candidateContext === 'segment'
            ? selectedCandidatesCount + selectedCandidatesCustomCount
            : selectedCandidatesCount
        }
        selectedCandidatesCountWithoutCustom={selectedCandidatesCount}
        selectedCandidatesCustomCount={selectedCandidatesCustomCount}
        isBulkSelectionChecked={getIsBulkSelectionChecked()}
        isCurrentPageChecked={getIsSelectThisPageChecked()}
        isSelectThisPageDisabled={isCandidateListLoading}
        isBulkSelectionDisabled={isCandidateListLoading}
        candidateContext={candidateContext}
        isBulkAddAvailable={isBulkAddAvailable}
        isBulkDeleteAvailable={isBulkDeleteAvailable}
        version={version}
        jobDetails={currentJobDetails}
        maxBulkCandidatesSelectionCount={maxBulkCandidatesSelectionCount}
        activeSourceName={sourceName}
        isExactMatchSearch={isExactMatchSearch}
        isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
        bulkCustomActivitySelection={bulkCustomActivitySelection}
        onCustomSelectBulkCandidates={onCustomSelectBulkCandidates}
        totalCandidate={totalCandidate}
        updateCustomBulkActivity={updateCustomBulkActivity}
        isPartialBulkSelectionChecked={getIsPartialBulkSelectionChecked()}
        candidatesRecommendationStatus={candidatesRecommendationStatus}
        isCandidateBulkSelectionInputEnabled={isCandidateBulkSelectionInputEnabled}
      />
    );
    const offsetTop = currentSelectedAllCandidates?.length ? bulkOptionsOffestTop : 0;
    return <Affix offsetTop={offsetTop}>{bulkOptions}</Affix>;
  };
  const showSelectedCandidatesText =
    !isCandidateListLoading &&
    totalCandidate &&
    (!_.isEmpty(filterQueries) ||
      (sourceName === 'All' && isAllTabCandidatesFilterApplied && allTabFiltertedCandidatesCount));
  const getAggregationFilter = () => {
    return (
      <AggregationFilter
        candidateAggregations={candidateAggregations}
        applyAggregationFilter={applyAggregationFilter}
        removeAggregation={removeAggregation}
        clearAggregationFilter={clearAggregationFilter}
        form={form}
        activeSource={activeSource}
        filterQueries={{ [sourceName]: filterQueries }}
        showSelectedAggregations={false}
        jobId={jobId}
        candidateCollapsedFilters={candidateCollapsedFilters}
        isAdvancedSearchV2Enabled={isAdvancedSearchV2Enabled}
        isFilterApplied={isFilterApplied}
        candidateContext={candidateContext}
        maxCandidateCountInAdvanceSearch={config?.MaxCandidateCountInAdvanceSearch}
        showV2FilterIcon={isAllTabAggregationFilterEnabled}
      />
    );
  };

  const candidateAggregationIcon = isAllTabAggregationFilterEnabled ? (
    <div style={{ opacity: 0.3, cursor: sourceName === 'All' ? 'not-allowed' : '' }}>
      <CandidateAggregationIcon />
    </div>
  ) : null;
  let candidateCount;
  if (sourceName === 'All' || sourceName === 'scoutingAgent') {
    candidateCount = Math.min(config?.MaxCandidateCountInAdvanceSearch, allTabFiltertedCandidatesCount);
  } else {
    candidateCount = Math.min(config?.MaxCandidateCountInAdvanceSearch, totalCandidate);
  }
  return (
    <div>
      {sourceName !== 'smartAgentCandidates' ? (
        <div className={styles.candidateFilters}>
          <div>{showSelectedCandidatesText ? <SelectedCandidatesText candidatesCount={candidateCount} /> : null}</div>
          {candidateContext === 'job' ? (
            <div className={styles.rightSection}>
              {sourceName === 'All' && isAllTabAggregationFilterEnabled && allTabCandidatesCount ? (
                <AllTabCandidateAggregationFilterDrawer
                  jobId={jobId}
                  fetchManualSearchCandidateList={fetchManualSearchCandidateList}
                  form={form}
                  activeSourceName={sourceName}
                  candidateContext={candidateContext}
                />
              ) : (
                <Tooltip
                  title={
                    sourceName !== 'All'
                      ? 'Filtration is not supported for job boards. You must switch to all tabs to filter candidates.'
                      : 'Filters'
                  }
                  placement={sourceName === 'All' ? 'top' : 'left'}
                >
                  {candidateAggregationIcon}
                </Tooltip>
              )}
              <Draggable>
                <div className={styles.dragged}>
                  <ReactivateArya
                    jobId={jobId}
                    currentJobDetails={currentJobDetails}
                    AppName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
                    smartRecruiterVersion={smartRecruiterVersion}
                    isDragging={isDragging}
                    setIsDragging={setIsDragging}
                    onStatusChange={onStatusChange}
                    activeTab={activeTab}
                  />
                </div>
              </Draggable>
              {featureToggleList.SmartAgentCandidate.IsEnabled ? (
                <SmartAgentCandidatesFetchButton
                  jobId={jobId}
                  candidateContext={candidateContext}
                  featureToggleList={featureToggleList}
                />
              ) : null}
              {getAggregationFilter()}
              <JobReactivationTree
                jobId={jobId}
                currentJobDetails={currentJobDetails}
                AppName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
                showV2ReactivationIcon={isAllTabAggregationFilterEnabled}
              />
            </div>
          ) : (
            <div className={styles.rightSection}>{getAggregationFilter()}</div>
          )}
        </div>
      ) : (
        <div className={styles.candidateFilters}>
          <div className={styles.rightSection}>
            {featureToggleList.SmartAgentCandidate.IsEnabled ? (
              <SmartAgentCandidatesFetchButton
                jobId={jobId}
                candidateContext={candidateContext}
                featureToggleList={featureToggleList}
                sourceName={sourceName}
              />
            ) : null}
          </div>
        </div>
      )}
      {isBulkOptionsEnabled ? getBulkOptions() : null}
      {showMaskWarningMessage && (activeTab === 'sourced' || activeTab === 'ranked') ? (
        <div>
          <WarningIcon /> The identities of the candidates are masked by the admin and will be revealed upon
          shortlisting, rejection, or upon initiation of any communication.
        </div>
      ) : null}
      <ManualSearchCandidateList
        {...atsJobCandidateListProps}
        activeSource={activeSource}
        jobId={jobId}
        isBulkOptionsEnabled={isBulkOptionsEnabled}
        getCurrentSourceConfig={getCurrentSourceConfig}
        filterQueries={{ [sourceName]: filterQueries }}
        clearAggregationFilter={clearAggregationFilter}
        candidateContext={candidateContext}
        allTabCandidateFetchApiStatus={allTabCandidateFetchApiStatus}
        onPageChange={onPageChange}
        isSegmentCandidateSearchLimitFeatureEnabled={isSegmentCandidateSearchLimitFeatureEnabled}
        activeSourceName={sourceName}
        openSipCallWindowsApp={openSipCallWindowsApp}
        isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        activeTab={activeTab}
      />
    </div>
  );
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Form.create()(ManualSearchCandidatesTab)));
export { ManualSearchCandidatesTab as ManualSearchCandidatesTabWithoutStore };
