import { Button, Form, Input, Modal, Tag } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ReportChatModal.module.scss';
import message from '../../../Containers/CandidateDrawer/messages';

const { CheckableTag } = Tag;
const { TextArea } = Input;
const TAG_KEYS = ['IrrelevantInformation', 'UnprofessionalTone', 'Repetitive', 'Misspelled', 'Gibberish', 'Others'];

function ReportChatModal(props) {
  const { onClose, isVisible, onSubmit, form, reportDetails, reportConversationApiStatus, intl } = props;
  const [tagsSelected, setTagsSelected] = React.useState(
    TAG_KEYS.reduce((acc, tag) => {
      acc[tag] = false;
      return acc;
    }, {})
  );

  React.useEffect(() => {
    if (reportDetails) {
      const tags = reportDetails.Tags || [];
      const currentSelectedTags = TAG_KEYS.reduce((acc, tag) => {
        acc[tag] = tags.includes(tag);
        return acc;
      }, {});
      setTagsSelected(currentSelectedTags);
    }
  }, [reportDetails]);

  const onReportSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        const tags = Object.keys(tagsSelected).filter(tag => tagsSelected[tag]);
        onSubmit({ Tags: tags, Comment: values.Comment });
      }
    });
  };

  const footerButtons = (
    <div className={styles.actionButtons}>
      <Button shape="round" key="back" onClick={onClose}>
        <FormattedMessage {...message.cancelButton} />
      </Button>
      <Button
        shape="round"
        key="submit"
        onClick={onReportSubmit}
        type="primary"
        loading={reportConversationApiStatus === 'INPROGRESS'}
      >
        <FormattedMessage {...message.submitLabel} />
      </Button>
    </div>
  );

  const onTagSelected = (tag, checked) => {
    setTagsSelected({ ...tagsSelected, [tag]: checked });
  };

  const toCamelCase = str => {
    return str.charAt(0).toLowerCase() + str.slice(1);
  };

  return (
    <Modal
      title={<FormattedMessage {...message.reportChatReasonLabel} />}
      onCancel={onClose}
      visible={isVisible}
      footer={[footerButtons]}
      zIndex={2501}
    >
      <div className={styles.tags}>
        {TAG_KEYS.map(tag => (
          <CheckableTag
            key={tag}
            checked={tagsSelected[tag]}
            onChange={checked => onTagSelected(tag, checked)}
            className={classNames(styles.checkableTag, {
              [styles.selectedTags]: tagsSelected[tag],
              [styles.unselectedTag]: !tagsSelected[tag],
            })}
          >
            <FormattedMessage {...message[`${toCamelCase(tag)}Label`]} />
          </CheckableTag>
        ))}
      </div>
      <div className={styles.commentSection}>
        <Form.Item label={<FormattedMessage {...message.reportChatCommentLabel} />} colon={false} required={false}>
          {form.getFieldDecorator('Comment', {
            initialValue: reportDetails?.Comment,
            rules: [
              { required: true, message: 'Please provide comment' },
              {
                validator: (_, value, callback) => {
                  if (value.length < 300) {
                    callback();
                  } else {
                    callback(<FormattedMessage {...message.commentCharsExceeded} />);
                  }
                },
              },
            ],
          })(
            <TextArea
              placeholder={intl.formatMessage({ ...message.reportChatCommentPlaceholderLabel })}
              className={styles.commentArea}
            />
          )}
        </Form.Item>
      </div>
    </Modal>
  );
}

ReportChatModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  reportConversationApiStatus: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
  reportDetails: PropTypes.shape({
    Comment: PropTypes.string,
    Tags: PropTypes.string,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(Form.create()(ReportChatModal));
