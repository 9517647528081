import AWS from 'aws-sdk';
import { fetchAuthSession } from 'aws-amplify/auth';
import config from '../Config/Config';
import { getAccountTokens } from '../Repository/CognitoRepository';
import { setApiStatus } from './ApiStatusActions';


function setCredentials(credentials) {
  return {
    type: 'SET_IDENTITY_CREDENTIALS',
    payload: credentials,
  };
}

function setIdentityCredentials() {
  return async dispatch => {
    try {
      const { region, identityPoolId, roleArn, poolId } = config.auth.cognito;
      AWS.config.region = region;

      const cognitoidentity = new AWS.CognitoIdentity();
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
        RoleSessionName: 'web',
        RoleArn: roleArn,
      });

      const session = await fetchAuthSession();
      const { idToken } = session.tokens;

      const logins = { [`cognito-idp.${region}.amazonaws.com/${poolId}`]: idToken.toString() };
      const identityIdParams = {
        IdentityPoolId: identityPoolId,
        Logins: logins,
      };

      const response = await cognitoidentity.getId(identityIdParams).promise();
      const identityId = response.IdentityId;

      const credentialsForIdentityParams = {
        IdentityId: identityId,
        Logins: logins,
      };
      const credentialsResponse = await cognitoidentity
        .getCredentialsForIdentity(credentialsForIdentityParams)
        .promise();
      const credentials = credentialsResponse.Credentials;
      dispatch(setCredentials(credentials));
      return credentials;
    } catch (e) {
      return {};
    }
  };
}

function setAccountTokens(sessionToken){
  return async dispatch => {
    dispatch(setApiStatus({ apiName: 'accountsApiToken', status: 'INPROGRESS' }));
    try {
      const tokens = await getAccountTokens(sessionToken);
      dispatch(setApiStatus({apiName: 'accountsApiToken', status: 'COMPLETED' }));
      return tokens.data;
    } catch (error) {
      dispatch(setApiStatus({apiName: 'accountsApiToken', status: 'FAILED' }));
      return null;
    }
  };
}

export { setIdentityCredentials, setAccountTokens };
