import { Amplify } from 'aws-amplify';
import langConfig from '../langConfig';
import config from '../Config/Config';

export function configureCognito() {
  const awsConfig = {
    Auth: {
      Cognito: {
        userPoolClientId: config.auth.cognito.clientId,
        userPoolId: config.auth.cognito.poolId,
        loginWith: {
          username: false,
          email: true,
          phone: false,
        },
      },
    },
  };

  Amplify.configure(awsConfig);
  window.appLocale = langConfig.en;
}
