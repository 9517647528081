import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import * as AccountAction from '../../Actions/CognitoActions';

const mapDispatchToProps = {
  setAccountTokens: AccountAction.setAccountTokens,
};

function setTokens(tokens) {
  if (typeof tokens !== 'object' || tokens === null) {
    console.error('Invalid tokens provided. Expected a non-null object.');
    return;
  }

  Object.entries(tokens).forEach(([key, value]) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error(`Failed to set item in localStorage for key: ${key}`, error);
    }
  });
}

export function AuthenticationRedirect(props) {
  const { setAccountTokens, location } = props;

  useEffect(() => {
    const fetchAndSetTokens = async () => {
      const queryParams = new URLSearchParams(location.search);
      const sidt = queryParams.get('sidt');

      if (sidt) {
        const accountsToken = await setAccountTokens(sidt);
        setTokens(accountsToken);
        const continueToUrl = localStorage.getItem('continueTo');
        if (continueToUrl) {
          localStorage.removeItem('continueTo');
          window.location.replace(continueToUrl);
        }
      }
    };

    fetchAndSetTokens();
  }, [location.search, setAccountTokens]);

  return <LoadingScreen active loading />;
}

export default connect(null, mapDispatchToProps)(AuthenticationRedirect);
