import axios from 'axios';
import { apiV1Client, apiV3Client, apiV4Client, smartJobV1Client, connectV1Client } from './BaseRepository';

export function fetchJobList(jobFilter = {}) {
  return apiV3Client.post('jobs/_search', {
    UserId: jobFilter.userId,
    Size: jobFilter.count,
    From: jobFilter.skip,
    StatusId: jobFilter.statusId,
    Status: jobFilter.status,
    ClientId: jobFilter.clientId,
    SearchKeyword: jobFilter.searchTerm,
    SortBy: jobFilter.sortBy,
    SortOrder: jobFilter.sortOrder,
    JobGuids: jobFilter.jobGuids,
    JobIds: jobFilter.jobIds,
    Sources: jobFilter.sources,
    ConnectStatus: jobFilter.connectStatus,
    IsAssigned: jobFilter.isAssigned,
    TagIds: jobFilter.tagIds,
    IsJobStatsRequired: jobFilter.isJobStatsRequired,
    EntityType: jobFilter.EntityType,
  });
}

export const getJobList = (filter = {}) => {
  const { count, skip, ...rest } = filter;
  const urlParams = new URLSearchParams(rest);
  if (skip !== undefined) urlParams.append('From', skip);
  if (count !== undefined) urlParams.append('Size', count);
  const queryString = urlParams.toString();
  return apiV3Client.get(`/Jobs?${queryString}`);
};

export function fetchJobCount(jobFilter = {}) {
  return apiV3Client.post('jobs/_count', {
    UserId: jobFilter.userId,
    StatusId: jobFilter.statusId,
    ClientId: jobFilter.clientId,
    SearchKeyword: jobFilter.searchTerm,
    ConnectStatus: jobFilter.connectStatus,
    JobGuids: jobFilter.jobGuids,
  });
}

export function fetchOrgActivatedJobsCount() {
  return apiV3Client.get('jobs/_activationcount');
}

export function fetchOrgOpenedJobsCount() {
  return apiV3Client.get('jobs/_openedjobcount');
}

export function fetchJobEmailStats(jobIds) {
  return apiV3Client.post('jobs/_stats/email', {
    jobIds,
  });
}

export function fetchJobSourcingStats(jobIds) {
  const queryParams = jobIds.join(',');
  const url = `Jobs/_sourcingstats?JobIds=${queryParams}`;
  return apiV3Client.get(url);
}

export function fetchJobActivationStatus(jobIds) {
  return apiV3Client.post('jobs/_stats/activation', {
    jobIds,
  });
}

export function changeJobStatus(jobId, status) {
  return apiV3Client.put(`jobs/${jobId}/status`, {
    Status: status,
  });
}

export function fetchJobDetails(jobId) {
  return apiV3Client.get(`jobs/${jobId}`);
}

export function activatePortals({ activationDetails, searchCriteria }) {
  const { Sources, IncludeRejectedCandidates, JobId, IsActivationRequired, IsAppliedCandidateScoringRequired } =
    activationDetails;
  return apiV3Client.post(`jobs/${JobId}/_sourcingstatus`, {
    Sources,
    IncludeRejectedCandidates,
    SearchCriteria: searchCriteria,
    IsActivationRequired,
    IsAppliedCandidateScoringRequired,
  });
}

export function fetchActivationDetails(jobIds) {
  return apiV3Client.post('jobs/_sourcingstatuses', {
    JobIds: jobIds,
  });
}

export function getApiClient(userConfig) {
  return userConfig.isPaidJobServiceEnabled && userConfig.role !== 'Service Rep' ? apiV4Client : apiV3Client;
}

export function createJob(jobDetails, userConfig) {
  const apiClient = getApiClient(userConfig);
  return apiClient.post('jobs', jobDetails);
}

export function putEditJob(jobId, jobDetails, userConfig) {
  const apiClient = getApiClient(userConfig);
  return apiClient.put(`jobs/${jobId}`, jobDetails);
}

export function patchEditJob(jobId, jobDetails) {
  return apiV3Client.patch(`jobs/${jobId}`, jobDetails);
}

export function setBotContextId(jobId, botContextId) {
  return apiV3Client.put(`jobs/${jobId}/botcontext`, {
    BotContextId: botContextId,
  });
}

export function duplicateJob(jobId) {
  return apiV3Client.post(`Jobs/${jobId}/_duplicate`);
}

export function undoJobCandidateAutoReject(jobId) {
  return apiV3Client.post(`jobs/${jobId}/_undoAryaReject`);
}

export function getTitleSynonyms(title) {
  return apiV3Client.get('titlesynonyms', {
    params: {
      title,
    },
  });
}

export function fetchTagsForJobForm(filter) {
  return apiV3Client.post(`tags/_search?type=job`, {
    From: filter.from,
    Size: filter.size,
    SearchTerm: filter.searchTerm,
  });
}

export function fetchJobTags(filter) {
  return apiV3Client.post(`jobs/_aggregations`, {
    StatusId: filter.statusId,
    UserId: filter.userId,
    IsAssigned: filter.isAssigned,
  });
}

export function getLocations(filter) {
  return apiV3Client.post('locations', {
    Country: filter.country || 'US',
    SearchTerm: filter.search,
    Size: filter.size || 100,
  });
}

export function getJobIdUsingJobCode(filter) {
  const { jobCode } = filter;
  return apiV3Client.get(`jobs/_getId`, {
    params: {
      jobCode,
    },
  });
}

export function getSmartjobLocations(filter) {
  return smartJobV1Client.post('locations', {
    Country: filter.country,
    SearchTerm: filter.search,
    Size: filter.size || 100,
  });
}

export function getCountries() {
  return apiV3Client.get('countries', {
    params: {
      subscribed: true,
    },
  });
}

export function getSmartjobCountries() {
  return smartJobV1Client.get('Countries');
}

export function fetchCountryDetails(countryCode) {
  return apiV3Client.get(`countries/${countryCode}`);
}

export function getZipCodes(filter) {
  return apiV3Client.post('zips', {
    Country: filter.country,
    Location: filter.location,
    Size: filter.size || 100,
  });
}

export function getSmartjobZipCodes(filter) {
  return smartJobV1Client.post('zips', {
    Country: filter.country,
    Location: filter.location,
    Size: filter.size || 100,
  });
}

function searchZipCodeWithCancellation() {
  let cancelRequest;
  const { CancelToken } = axios;
  return filter => {
    if (cancelRequest) {
      cancelRequest('Operation canceled due to new request.');
    }
    return apiV3Client
      .post(
        'locations/_search',
        {
          CountryCode: filter.countryCode,
          PostalCode: filter.postalCode,
        },
        {
          cancelToken: new CancelToken(c => {
            cancelRequest = c;
          }),
        }
      )
      .catch(error => {
        if (axios.isCancel(error)) throw new Error('Request Cancelled');
        throw error;
      });
  };
}

export function getIndustries(filter) {
  return apiV3Client.post('industries', filter);
}

export function getSmartjobIndustries(filter) {
  return smartJobV1Client.post('industries', filter);
}

export function getClients(filter) {
  const validateFilter = filter;
  if (!filter.SearchTerm) {
    validateFilter.SearchTerm = undefined;
  }
  return apiV3Client.post('clients/_search', validateFilter);
}

export function fetchJobIntel(jobId) {
  return apiV3Client.get(`jobs/${jobId}/intel`);
}

export function fetchJobIntelLocationData(jobId) {
  return apiV3Client.get(`jobs/${jobId}/intel/location`);
}

export function shareJob(jobId, shareOptions) {
  return apiV3Client.post(`jobs/${jobId}/_share`, {
    ShareWith: shareOptions.sharedWith,
    UnshareWith: shareOptions.unsharedWith,
  });
}

export function updateJobIntelligence(jobId, intelligenceData) {
  return apiV3Client.put(`jobs/${jobId}/intel/usergiven`, intelligenceData);
}

export function fetchUserGivenIntel(jobId) {
  return apiV3Client.get(`jobs/${jobId}/intel/usergiven`);
}

export function fetchJobResumes(jobId) {
  return apiV3Client.get(`jobs/${jobId}/resumes`);
}
export function createSegment(jobDetails, userConfig) {
  const apiClient = getApiClient(userConfig);
  return apiClient.post(`Jobs?entityType=Segment`, jobDetails);
}
export function putEditSegment(jobId, jobDetails, userConfig) {
  const apiClient = getApiClient(userConfig);
  return apiClient.put(`Jobs/${jobId}?entityType=Segment`, jobDetails);
}
export function fetchSegmentList() {
  return apiV3Client.get(`Jobs?entityType=Segment`);
}
export function uploadJobResume(jobId, resume) {
  return apiV3Client.post(`jobs/${jobId}/resumes`, resume);
}

export function bulkUploadJobResumes(jobId, resumes) {
  return apiV3Client.post(`jobs/${jobId}/resumes/_bulk`, resumes);
}

export function downloadJobResume(jobId, resumeId) {
  return apiV3Client.get(`jobs/${jobId}/resumes/${resumeId}`, {
    responseType: 'blob',
    params: {
      download: true,
    },
  });
}

export function deleteJobResume(jobId, resumeId) {
  return apiV3Client.delete(`jobs/${jobId}/resumes/${resumeId}`);
}

export function bulkDeleteJobResumes(jobId, resumeIds) {
  return apiV3Client.delete(`jobs/${jobId}/resumes/_bulk`, {
    data: {
      ResumeIds: resumeIds,
    },
  });
}

export function fetchJobConfig(jobId) {
  return apiV3Client.get(`jobs/${jobId}/_config`);
}

export function updateJobConfig(jobId, jobConfig) {
  return apiV3Client.put(`jobs/${jobId}/_config`, jobConfig);
}

export const searchZipCode = searchZipCodeWithCancellation();

export function distributeJob(jobId, payload) {
  return apiV3Client.post(`jobs/${jobId}/_distribute`, payload);
}

export function recallJob(jobId, portals) {
  return apiV3Client.post(`jobs/${jobId}/_recall`, {
    Portals: portals,
  });
}

export function fetchJobDistributionStatus(jobId) {
  return apiV3Client.get(`jobs/${jobId}/_distributionStatus`);
}

export function fetchJobDilatedIntel(jobId, statuses) {
  return apiV3Client.get(`jobs/${jobId}/intel/dilated`, {
    params: {
      statuses: statuses ? statuses.join(',') : undefined,
    },
  });
}

export function applyJobDilatedIntel(jobId, dilatedIntel) {
  return apiV3Client.put(`jobs/${jobId}/intel/dilated`, {
    DilatedCriteria: dilatedIntel,
  });
}

export function fetchDilatedCandidateCount(jobId, dilatedIntel) {
  return apiV3Client.post(`jobs/${jobId}/intel/dilated/_candidatescount`, {
    DilatedCriteria: dilatedIntel,
  });
}

export function fetchBulkJobTags(jobIds) {
  return apiV3Client.post('jobs/tags/_bulk', {
    JobIds: jobIds,
  });
}

export function getOccupations(filter) {
  return apiV3Client.post('occupations', filter);
}

export function saveJobDiversityChanges(diversityOptionChanges) {
  return apiV3Client.post('jobs/diversityAcknowledgement', diversityOptionChanges);
}
export function fetchJobAutomationConfiguration(filter) {
  return apiV3Client.post(`jobs/_automationconfig`, {
    JobIds: filter.JobIds,
  });
}

export function fetchCandidateRecommendationStatus(jobId, action) {
  return apiV1Client.get(`segments/${jobId}/candidates/CandidateAddRequest/_stats?status=${action}`);
}

export function fetchJobBasicInfoList(jobFilter = {}) {
  return apiV4Client.post('jobs/_basicInfo', {
    UserId: jobFilter.userId,
    JobGuids: jobFilter.jobGuids,
    JobIds: jobFilter.jobIds,
    EntityType: jobFilter.EntityType,
    ShowAllJobEntities: jobFilter.showAllJobEntities,
  });
}

export function getJobSharedUsers(jobId, shareOptions) {
  return apiV4Client.get(`jobs/${jobId}/_sharedUsers`);
}
export function enableSourcingForTheJob(jobId) {
  return apiV3Client.post(`Jobs/${jobId}/enablesourcing`);
}

export function fetchBoostStopInfo(jobId) {
  return apiV3Client.get(`Jobs/${jobId}/boost/_info`);
}

export function fetchCustomConsentTemplate(country) {
  return connectV1Client.get(`consent/consentText?country=${country}`);
}
