import React, { useCallback } from 'react';
import { Skeleton } from 'antd';
import { connect, batch } from 'react-redux';
import _ from 'lodash';
import * as JobActions from '../../../Actions/JobActions';
import ManualSearchForm from '../ManualSearchForm/ManualSearchForm';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import * as manualSearchCandidateActions from '../../../Actions/ManualSearchCandidateActions';
import * as candidateActions from '../../../Actions/CandidateActions';
import * as jobCandidatesTabActions from '../../../Actions/JobCandidatesTabActions';
import * as candidateActionCreators from '../../../Actions/ActionCreators/CandidateActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import {
  getManualSearchCriteria,
  getJobActivityWithPayload,
  getIsReactivationAllowed,
  getIsDefaultCriteriaFetchedFlag,
  geLastPerformedManualSearchTime,
  getManualSearchPreFetchedFlag,
  getManualSearchCriteriaType,
} from '../../../Reducers/ManualSearchReducer';
import {
  getDefaultFormValuesFromCriteria,
  getDefaultManualSearchFormValues,
  getManualSearchModifiedSource,
} from '../../../Utils/ManualSearchUtils';
import { getJobDetails, getIndustrySuggestions } from '../../../Reducers/JobReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import ManualSearchCandidatesFetchModal from '../ManualSearchCandidatesFetchModal/ManualSearchCandidatesFetchModal';
import { getPageSize } from '../../../Reducers/JobCandidatesTabReducer';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import ManualSearchContext from '../ManualSearchWrapper/ManualSearchContext';
import { getWhiteLabelInformation } from '../../../Reducers/ConfigReducer';
import styles from './ManualSearchContainer.module.scss';

const mapStateToProps = (state, props) => {
  return {
    fetchManualSearchDraftCriteriaApiStatus: getApiStatus(state, 'fetchManualSearchDraftCriteriaApiStatus'),
    fetchAryaGeneratedCriteriaApiStatus: getApiStatus(state, 'fetchAryaGeneratedCriteriaApiStatus'),
    aryaGeneratedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'AryaGenerated' }),
    unsavedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' }),
    currentJobDetails: getJobDetails(state, props.jobId),
    jobActivity: getJobActivityWithPayload(state, { jobId: props.jobId }),
    pageSize: getPageSize(state),
    currentUserDetails: getCurrentUserDetails(state),
    isReactivationAllowed: getIsReactivationAllowed(state, { jobId: props.jobId }),
    isDefaultCriteriaFetchedFlag: getIsDefaultCriteriaFetchedFlag(state, { jobId: props.jobId }),
    manualSearchLastPerformedTime: geLastPerformedManualSearchTime(state, { jobId: props.jobId }),
    whiteLabelInfo: getWhiteLabelInformation(state),
    isPreFetchRequied: getManualSearchPreFetchedFlag(state, { jobId: props.jobId }),
    featureToggleList: getFeatureToggleList(state),
    industrySuggestions: getIndustrySuggestions(state),
    manualSearchCriteriaType: getManualSearchCriteriaType(state),
  };
};

const mapDispatchToProps = {
  fetchManualSearchDraftCriteria: manualSearchActions.fetchManualSearchDraftCriteria,
  fetchAryaGeneratedIntel: manualSearchActions.fetchAryaGeneratedIntel,
  resetManualSearchApiStatuses: manualSearchActions.resetManualSearchApiStatuses,
  resetManualSearchJobActivity: manualSearchActions.resetManualSearchJobActivity,
  fetchManualSearchCandidateList: candidateActions.fetchManualSearchCandidateList,
  resetJobCandidatesTabBulkSelection: jobCandidatesTabActions.resetJobCandidatesTabBulkSelection,
  setJobCandidateTabActiveSourceName: jobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setJobCandidateTabPagination: jobCandidatesTabActions.setJobCandidateTabPagination,
  setManualSearchActiveSource: jobCandidatesTabActions.setManualSearchActiveSource,
  clearManualSearchCandidates: manualSearchCandidateActions.clearManualSearchCandidates,
  setSelectedCriteriaName: manualSearchActions.setSelectedCriteriaName,
  setManualSearchLastPerformedTime: manualSearchActions.setManualSearchLastPerformedTime,
  setCandidateListType: candidateActionCreators.setCandidateListType,
  reorderManualSearchSources: manualSearchActions.reorderManualSearchSources,
  setIsReactivationAllowedFlag: manualSearchActions.setIsReactivationAllowedFlag,
  setIsDefaultCriteriaFetchedFlag: manualSearchActions.setIsDefaultCriteriaFetchedFlag,
  setManualSearchFormValues: manualSearchActions.setManualSearchFormValues,
  setPreFetchRequired: manualSearchActions.setIsPrefetchRequiredFlag,
  fetchIndustrySuggestions: JobActions.fetchIndustries,
  updateManualSearchCriteriaType: manualSearchActions.setManualSearchCriteriaType,
};

const scrollToRef = manualSearchRef =>
  window.scrollTo({
    top: manualSearchRef.current.offsetTop,
    left: 0,
    behavior: 'smooth',
  });

const noOpFunc = () => {};

function ManualSearchContainer(props) {
  const {
    jobId,
    pageSize,
    jobActivity,
    currentJobDetails,
    unsavedCriteria,
    aryaGeneratedCriteria,
    fetchManualSearchDraftCriteria,
    fetchAryaGeneratedIntel,
    fetchManualSearchDraftCriteriaApiStatus,
    fetchAryaGeneratedCriteriaApiStatus,
    resetJobCandidatesTabBulkSelection,
    resetManualSearchApiStatuses,
    resetManualSearchJobActivity,
    setJobCandidateTabActiveSourceName,
    setManualSearchActiveSource,
    fetchManualSearchCandidateList,
    clearManualSearchCandidates,
    setSelectedCriteriaName,
    setManualSearchLastPerformedTime,
    setJobCandidateTabPagination,
    setCandidateListType,
    currentUserDetails,
    reorderManualSearchSources,
    isReactivationAllowed,
    setIsReactivationAllowedFlag,
    setIsDefaultCriteriaFetchedFlag,
    setManualSearchFormValues,
    isDefaultCriteriaFetchedFlag,
    onSearchCandidatesCallback,
    pageName,
    manualSearchLastPerformedTime,
    whiteLabelInfo,
    version,
    candidateContext,
    history,
    isNonInternalPortalForSegmentEnabled,
    setSearchPerformed = noOpFunc,
    isPreFetchRequied = true,
    setPreFetchRequired,
    featureToggleList,
    fetchIndustrySuggestions,
    isAtsCandidateFetchModalVisible,
    manualSearchCriteriaType,
    updateManualSearchCriteriaType,
    isPrefetchEnabled,
    isPreferredKeywordAutoSelectEnabled,
    atsActivePageName,
  } = props;

  const [initialLoadFlag, setInitialLoadFlag] = React.useState(false);

  const manualSearchRef = React.useRef();
  const manualSearchFormRef = React.useRef();

  const {
    form,
    defaultFormValues,
    setDefaultFormValues,
    isManualSearchFormMinimized,
    minimizeManualSearchForm,
    manualSearchCandidatesFetchModalVisibility,
    setManualSearchCandidatesFetchModalVisibility,
  } = React.useContext(ManualSearchContext);

  const scrollToManualSearchWrapper = () => scrollToRef(manualSearchRef);
  const scrollToManualSearchForm = () => scrollToRef(manualSearchFormRef);
  const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
  const {
    AdvanceSearchV2: { IsEnabled: isAdvanceSearchV2Enabled },
  } = featureToggleList;

  const shouldSetDefaultFormValues =
    !defaultFormValues &&
    fetchManualSearchDraftCriteriaApiStatus &&
    fetchManualSearchDraftCriteriaApiStatus !== 'INPROGRESS' &&
    (!_.isEmpty(unsavedCriteria) ||
      (fetchAryaGeneratedCriteriaApiStatus && fetchAryaGeneratedCriteriaApiStatus !== 'INPROGRESS'));

  React.useEffect(() => {
    if (version === 'ats') {
      fetchIndustrySuggestions({
        SearchTerm: '',
        Size: 200,
      });
    }
    if (!isDefaultCriteriaFetchedFlag) {
      fetchManualSearchDraftCriteria({ jobId });
      fetchAryaGeneratedIntel({ jobId, candidateContext });
      setIsDefaultCriteriaFetchedFlag({ jobId, isDefaultCriteriaFetchedFlag: true });
    }
    return () => {
      resetManualSearchApiStatuses();
      resetManualSearchJobActivity({ jobId });
      setIsDefaultCriteriaFetchedFlag({ jobId, isDefaultCriteriaFetchedFlag: false });
      minimizeManualSearchForm(true);
    };
  }, []);

  React.useEffect(() => {
    if (initialLoadFlag && (window.location?.hash?.includes('refine-criteria') || candidateContext === 'segment')) {
      minimizeManualSearchForm(false);
      scrollToManualSearchWrapper();
    }
  }, [initialLoadFlag]);

  React.useEffect(() => {
    if (shouldSetDefaultFormValues) {
      (async () => {
        setDefaultFormValues(
          await getDefaultManualSearchFormValues({
            unsavedCriteria,
            aryaGeneratedCriteria,
            currentJobDetails,
            currentUserDetails,
            candidateContext,
            isPreferredKeywordAutoSelectEnabled,
          })
        );
        setManualSearchFormValues({
          manualSearchFormValues: form?.getFieldsValue(),
          jobId,
        });
        const isUnsavedCriteriaExisting = !_.isEmpty(unsavedCriteria);
        if (isUnsavedCriteriaExisting && manualSearchCriteriaType === 'SaveCriteria') {
          minimizeManualSearchForm(true);
        } else {
          setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag: true });
        }
        setInitialLoadFlag(true);
      })();
    }
  }, [shouldSetDefaultFormValues]);

  React.useEffect(() => {
    return () => {
      updateManualSearchCriteriaType('SaveCriteria');
    };
  }, []);

  React.useEffect(() => {
    if (jobActivity.jobActivityId) {
      const { jobActivityPayload } = jobActivity;
      minimizeManualSearchForm(false);
      scrollToManualSearchForm();
      setSelectedCriteriaName({ jobId, criteriaName: jobActivityPayload?.CriteriaName });
      setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag: false });
      (async () => {
        setDefaultFormValues(
          await getDefaultFormValuesFromCriteria({ criteria: jobActivityPayload, currentJobDetails })
        );
        form.resetFields();
      })();
    }
  }, [jobActivity.lastJobActivityRetrievedTime]);

  React.useEffect(() => {
    if (manualSearchLastPerformedTime && manualSearchCriteriaType === 'SaveCriteria') {
      minimizeManualSearchForm(true);
    }
  }, [manualSearchLastPerformedTime]);

  const handleShowMoreLessClick = useCallback(_isManualSearchFormMinimized => {
    if (_isManualSearchFormMinimized) scrollToManualSearchWrapper();
    minimizeManualSearchForm(_isManualSearchFormMinimized);
  }, []);

  const setManualSearchCandidatesFetchModalVisibilityWrapperFunc = value => {
    setManualSearchCandidatesFetchModalVisibility(value);
  };

  const onSearchCandidates = useCallback(
    searchContext => {
      batch(() => {
        if (searchContext !== 'default') scrollToManualSearchWrapper();
        if (manualSearchCriteriaType === 'SaveCriteria') minimizeManualSearchForm(true);
        resetJobCandidatesTabBulkSelection();
        setManualSearchCandidatesFetchModalVisibilityWrapperFunc(true);
        clearManualSearchCandidates({ jobId });
        setManualSearchLastPerformedTime({ jobId });
        setJobCandidateTabPagination({ currPage: 1 });
        setCandidateListType('ManualSearchCandidateList');
        setIsReactivationAllowedFlag({ jobId, isReactivationAllowedFlag: true });
        if (onSearchCandidatesCallback && searchContext !== 'default') onSearchCandidatesCallback();
      });
    },
    [jobId, pageName, currentUserDetails?.Email]
  );

  const onViewResults = useCallback(
    (navigateToAllTab = true) => {
      batch(async () => {
        reorderManualSearchSources({ jobId });
        setManualSearchCandidatesFetchModalVisibilityWrapperFunc(false);
        setSearchPerformed(true);
        if (navigateToAllTab) {
          const updatedSources = getManualSearchModifiedSource(candidateContext, isNonInternalPortalForSegmentEnabled);
          const updatedSourceName =
            candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled ? 'Internal' : 'All';
          await setJobCandidateTabActiveSourceName({ activeSourceName: updatedSourceName });
          fetchManualSearchCandidateList({
            manualCriteria: { From: 0, Size: pageSize, Sources: [updatedSources] },
            jobId,
            jobGuid: currentJobDetails.JobGuid,
            isBulkTagFetch: true,
          });
          setManualSearchActiveSource({ manualSearchActiveSource: updatedSources });
        }
      });
    },
    [jobId, pageSize]
  );
  if (!defaultFormValues) return <Skeleton active paragraph={{ rows: 1 }} />;

  return (
    <div>
      <div className={styles.manualSearchWrapper} ref={manualSearchRef}>
        <div ref={manualSearchFormRef}>
          <ManualSearchForm
            jobId={jobId}
            unsavedCriteriaId={unsavedCriteria.Id}
            defaultFormValues={defaultFormValues}
            isManualSearchFormMinimized={isManualSearchFormMinimized}
            onSearchCandidates={onSearchCandidates}
            handleShowMoreLessClick={handleShowMoreLessClick}
            setDefaultFormValues={setDefaultFormValues}
            isReactivationAllowed={isReactivationAllowed}
            setIsReactivationAllowedFlag={setIsReactivationAllowedFlag}
            manualSearchLastPerformedTime={manualSearchLastPerformedTime}
            candidateContext={candidateContext}
            isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
            history={history}
            setPreFetchRequired={setPreFetchRequired}
            isPreFetchRequied={isPreFetchRequied}
            manualSearchCandidatesFetchModalVisibility={manualSearchCandidatesFetchModalVisibility}
            isAdvanceSearchV2Enabled={isAdvanceSearchV2Enabled}
            pageName={pageName}
            version={version}
          />
        </div>
      </div>
      {manualSearchCandidatesFetchModalVisibility ? (
        <ManualSearchCandidatesFetchModal
          visible={manualSearchCandidatesFetchModalVisibility}
          jobId={jobId}
          onViewResults={onViewResults}
          setSearchPerformed={setSearchPerformed}
          AppName={AppName}
          setManualSearchCandidatesFetchModalVisibility={setManualSearchCandidatesFetchModalVisibilityWrapperFunc}
          setJobCandidateTabActiveSourceName={setJobCandidateTabActiveSourceName}
          fetchManualSearchCandidateList={fetchManualSearchCandidateList}
          jobGuid={currentJobDetails.JobGuid}
          pageSize={pageSize}
          reorderManualSearchSources={reorderManualSearchSources}
          candidateContext={candidateContext}
          isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
          setManualSearchActiveSource={setManualSearchActiveSource}
          isPreFetchRequied={isPreFetchRequied}
          pageName={pageName}
          version={version}
          isAtsCandidateFetchModalVisible={isAtsCandidateFetchModalVisible}
        />
      ) : null}
    </div>
  );
}

const MemoizedManualSearchContainer = React.memo(ManualSearchContainer);

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedManualSearchContainer);
export { ManualSearchContainer as ManualSearchContainerWithoutStore };
