import moment from 'moment';
import { getLocalDateFormat } from './RelativeTimeCalculator';

function getMessageDate(date) {
  const momentDate = moment.utc(date).local();
  const localDateFormat = getLocalDateFormat();
  return momentDate.calendar(null, {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    sameElse: localDateFormat,
  });
}

export function getChatHistoryWithDatesOnTop(messages) {
  if (!messages?.length) return [];
  const date = messages[messages.length - 1].CreatedTime;
  const firstDate = getMessageDate(date);
  const chatHistoryArraywithDates = [{ type: 'date', Body: firstDate, Id: messages.length - 1 }];
  chatHistoryArraywithDates.push(messages[messages.length - 1]);
  let latestCreatedTime = messages[messages.length - 1].CreatedTime;
  for (let i = messages.length - 2; i >= 0; i -= 1) {
    if (messages[i].CreatedTime.split('T')[0] !== latestCreatedTime.split('T')[0]) {
      latestCreatedTime = messages[i].CreatedTime;
      const newDate = getMessageDate(latestCreatedTime);
      chatHistoryArraywithDates.push({ type: 'date', Body: newDate, Id: i });
    }
    chatHistoryArraywithDates.push(messages[i]);
  }
  return chatHistoryArraywithDates;
}
