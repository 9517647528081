import cleanSet from 'clean-set';
import { SET_CANDIDATE_NOTE_POPUP_INFO } from '../Actions/ActionCreators/NotesActionCreator';

const initialState = {
  candidatesNotesList: {},
};

function NotesReducer(state = initialState, action) {
  let newState;
  if (action.type === SET_CANDIDATE_NOTE_POPUP_INFO) {
    const { candidateId, visible, minimized, note } = action.payload;
    const existingState = state.candidatesNotesList?.[candidateId] || {};
    const updatedState = {
      visible: typeof visible === 'boolean' ? visible : existingState.visible,
      minimized: typeof minimized === 'boolean' ? minimized : existingState.minimized,
      note: typeof note === 'object' ? note : existingState.note,
    };

    newState = cleanSet(state, ['candidatesNotesList', action.payload.candidateId], updatedState);
    return newState;
  }
  return state;
}

function getCandidatesNotesList(state) {
  return state.NotesReducer.candidatesNotesList;
}

function getCandidateNotePopUpStatus(state, candidateId) {
  return state.NotesReducer.candidatesNotesList[candidateId] || false;
}

export { NotesReducer, getCandidatesNotesList, getCandidateNotePopUpStatus };
