import _ from 'lodash';
import moment from 'moment';
import { message as messageNotification } from 'antd';
import uuid from 'uuid/v4';
import PubSub from 'pubsub-js';
import { connectEmailToProviderAndTest } from './ConnectSettingsActions';
import * as jobRepository from '../Repository/JobRepository';
import { SETUP_SOCKET_CONNECTIONS, BROADCAST_CHANNEL_POST_MESSAGE } from '../PubSub/EventTypes';
import * as connectRepository from '../Repository/ConnectRepository';
import * as candidateRepository from '../Repository/CandidateRepository';
import * as notificationRepository from '../Repository/NotificationRepository';
import { fetchConnectUsers, fetchUsers } from './UserActions';
import { fetchAvailableCredits } from './AryaPayActions';
import * as ConnectUtils from '../Utils/ConnectUtils';
import { isPulseUser } from '../Utils/ConfigUtils';
import { fetchConnectStatus } from './ConnectStatusActions';
import {
  setFetchContactStatus,
  setCandidatePersonId,
  setCandidatePersonalityStatus,
  setCandidatePersonality,
  setCandidateConnectIds,
} from './ActionCreators/CandidateActions';
import { setJobsBasicInfoList } from './ActionCreators/JobActionCreator';
import { getMergedCandidateDetails, fetchCallNotes } from './CandidateConnectActions';
import {
  setNotification,
  setConversationDetails,
  setFetchConversationMailsApiStatus,
  setFetchContextualMailsConversationApiStatus,
  setFetchAllContextualMailsConversationApiStatus,
  setFetchConversationMessagesApiStatus,
  setFetchOrgLevelConversations,
  setOrgLevelConversations,
  setOrgLevelPersonDetails,
  setLatestMessages,
  appendOrgLevelConversations,
  appendOrgLevelPersonDetails,
  setOrgLevelConversationsCount,
  SET_SUBSCRIPTION_STATS,
  setReportConversationApiStatus,
  setReportConversationDetails,
  setGetReportConversationDetailsApiStatus,
} from './ActionCreators/ConnectActions';
import { setApiStatus } from './ApiStatusActions';
import { getCurrentUser } from '../Reducers/UserSessionReducer';
import { RESET_CONVERSATION_MESSAGES } from './MessageConversationActions';
import { fetchJobUsageAvailableBudget } from './JobUsageBudgetActions';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';

function fetchConnectStatuses(jobGuid, personIds) {
  return dispatch => {
    connectRepository.fetchConnectStatuses(jobGuid, personIds).then(connectStatusResponse => {
      dispatch({
        type: 'SET_CANDIDATE_CONNECT_INFO',
        payload: {
          connectInfo: {
            ...connectStatusResponse.data.ConnectStatuses,
          },
        },
      });
    });
  };
}

function addContact(jobGuid, personId, contactObject, candidateDetails = {}) {
  const eventData = {
    Id: candidateDetails.candidateId,
    JobId: candidateDetails.jobId,
    PersonId: personId,
    ConversationId: candidateDetails.conversationId,
    IsContactUpdated: true,
  };
  const broadcastNotification = { eventType: 'ContactFetched', eventData };
  return dispatch => {
    connectRepository.addContact(personId, contactObject).then(() => {
      dispatch(fetchConnectStatuses(jobGuid, [personId]));
      PubSub.publish(BROADCAST_CHANNEL_POST_MESSAGE, { broadcastNotification });
    });
  };
}

function fetchContact(personId, conversationId, candidateId, jobId) {
  const ConnectStatus = {
    ConnectStatuses: {},
  };
  ConnectStatus.ConnectStatuses[personId] = {
    MessageRequestStatus: null,
    EmailRequestStatus: null,
  };
  const eventData = {
    Id: candidateId,
    JobId: jobId,
    PersonId: personId,
    ConversationId: conversationId,
    IsContactUpdated: true,
  };
  const broadcastNotification = { eventType: 'ContactFetched', eventData };
  return (dispatch, getState) => {
    const store = getState();
    const featureToggleList = getFeatureToggleList(store);
    const prevConnectStatuses = getState().ConnectReducer.ConnectStatuses || {};
    dispatch(setFetchContactStatus(candidateId, 'InProgress', jobId));
    connectRepository
      .fetchContact(personId, conversationId, jobId)
      .then(response => {
        const connectResponse = response.data;
        ConnectStatus.ConnectStatuses[personId] = {
          ...prevConnectStatuses[personId],
          Contact: {
            ...connectResponse,
            Emails: connectResponse.Emails || [],
            Phones: connectResponse.Phones || [],
          },
        };

        const isJobUsageBudgetFeatureEnabled = featureToggleList.JobUsageBudget.IsEnabled;
        if (isJobUsageBudgetFeatureEnabled) dispatch(fetchJobUsageAvailableBudget(jobId));

        dispatch({
          type: 'SET_CANDIDATE_CONNECT_INFO',
          payload: {
            connectInfo: {
              ...ConnectStatus.ConnectStatuses,
            },
          },
        });
        if (candidateId) {
          dispatch(setFetchContactStatus(candidateId, 'Completed', jobId));
        }
        PubSub.publish(BROADCAST_CHANNEL_POST_MESSAGE, { broadcastNotification });
      })
      .catch(error => {
        const errorResponse = error.response;
        let contact = null;
        if (errorResponse.status === 451 || errorResponse.status === 422) {
          if (errorResponse.status === 422) {
            contact = {
              RequestStatus: 'NoResult',
              ConsentStatus: null,
              AvailableProviders: 0,
            };
          } else if (errorResponse.status === 451) {
            contact = _.get(errorResponse, ['data', 'Error', 'Info', 'Error', 'Details'], null);
          }
          ConnectStatus.ConnectStatuses[personId] = {
            Contact: contact,
          };
          dispatch({
            type: 'SET_CANDIDATE_CONNECT_INFO',
            payload: {
              connectInfo: {
                ...ConnectStatus.ConnectStatuses,
              },
            },
          });
          PubSub.publish(BROADCAST_CHANNEL_POST_MESSAGE, { broadcastNotification });
        }
        if (errorResponse.status === 402) {
          dispatch({
            type: 'SET_ALERT_ERROR',
            payload: {
              Type: 'PAYMENT_REQUIRED_ALERT',
              Action: 'CONTACT_PULL',
            },
          });
        }
        if (errorResponse.status === 500) {
          messageNotification.error('Something went wrong while fetching contact. Please try again.');
        }
        if (candidateId) {
          dispatch(setFetchContactStatus(candidateId, 'Failed', jobId));
        }
      });
  };
}

function getContact(personId, candidateId, jobId) {
  const ConnectStatus = {
    ConnectStatuses: {},
  };
  ConnectStatus.ConnectStatuses[personId] = {
    MessageRequestStatus: null,
    EmailRequestStatus: null,
  };
  return dispatch => {
    dispatch(setFetchContactStatus(candidateId, 'InProgress', jobId));
    connectRepository
      .getContact(personId)
      .then(response => {
        const connectResponse = response.data;
        ConnectStatus.ConnectStatuses[personId] = {
          Contact: {
            ...connectResponse,
            Emails: connectResponse.Emails || [],
            Phones: connectResponse.Phones || [],
          },
        };

        dispatch({
          type: 'SET_CANDIDATE_CONNECT_INFO',
          payload: {
            connectInfo: {
              ...ConnectStatus.ConnectStatuses,
            },
          },
        });
        if (candidateId) {
          dispatch(setFetchContactStatus(candidateId, 'Completed', jobId));
        }
      })
      .catch(error => {
        const errorResponse = error.response;
        if (errorResponse.status === 451 || errorResponse.status === 422) {
          ConnectStatus.ConnectStatuses[personId] = {
            Contact: {
              RequestStatus: errorResponse.status === 422 ? 'NoResult' : 'Success',
              ConsentStatus: errorResponse.status === 451 ? 'Pending' : null,
              AvailableProviders: 0,
            },
          };
          dispatch({
            type: 'SET_CANDIDATE_CONNECT_INFO',
            payload: {
              connectInfo: {
                ...ConnectStatus.ConnectStatuses,
              },
            },
          });
        }
        if (errorResponse.status === 402) {
          dispatch({
            type: 'SET_ALERT_ERROR',
            payload: {
              Type: 'PAYMENT_REQUIRED_ALERT',
              Action: 'CONTACT_PULL',
            },
          });
        }
        if (errorResponse.status === 500) {
          messageNotification.error('Something went wrong while fetching contact. Please try again.');
        }
        if (candidateId) {
          dispatch(setFetchContactStatus(candidateId, 'Failed', jobId));
        }
      });
  };
}

function getRefundForInvalidContacts(jobGuid, personId) {
  return dispatch => {
    const refundCredits = {};
    refundCredits[personId] = 0;
    dispatch({
      type: 'SET_CURRENT_REFUND_CREDITS',
      payload: refundCredits,
    });
    connectRepository.getRefundForInvalidContacts(personId).then(creditRefundResponse => {
      refundCredits[personId] = creditRefundResponse.data.Credits;
      dispatch(fetchConnectStatuses(jobGuid, [personId]));
      dispatch({
        type: 'SET_CURRENT_REFUND_CREDITS',
        payload: refundCredits,
      });
    });
  };
}

const reportConversation = (conversationId, payload) => {
  return async dispatch => {
    dispatch(setReportConversationApiStatus('INPROGRESS'));
    try {
      connectRepository.reportConversation(conversationId, payload);
      dispatch(setReportConversationDetails({ ...payload, ConversationId: conversationId }));
      dispatch(setReportConversationApiStatus('COMPLETED'));
      dispatch(setNotification('SUCCESS', { messageId: 'conversationReported' }));
    } catch {
      dispatch(setReportConversationApiStatus('FAILED'));
      dispatch(setNotification('ERROR', { messageId: 'unableToReportConversation' }));
    }
  };
};

const getReportConversationDetails = conversationId => {
  return async dispatch => {
    dispatch(setGetReportConversationDetailsApiStatus('INPROGRESS'));
    try {
      const reportDetails = await connectRepository.getReportConversationDetails(conversationId);
      dispatch(setReportConversationDetails(reportDetails.data));
      dispatch(setGetReportConversationDetailsApiStatus('COMPLETED'));
    } catch {
      dispatch(setGetReportConversationDetailsApiStatus('FAILED'));
    }
  };
};

function pushCandidateToConnect(
  jobId,
  candidateInfo,
  RefId,
  fetchCandidateContact = false,
  isPassiveGroup,
  isQuickSearchCandidate
) {
  return async (dispatch, getState) => {
    if (fetchCandidateContact) {
      dispatch(setFetchContactStatus(candidateInfo.Id, 'InProgress', jobId));
    }
    const currentState = getState();
    const featureToggleList = _.get(currentState, ['FeatureToggleReducer'], {});
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    let candidateOrgRefId = candidateInfo.OrgReferenceId;
    if (!candidateInfo.OrgReferenceId) {
      const candidateDetails = await getMergedCandidateDetails(candidateInfo, isQuickSearchCandidate, dispatch);
      candidateOrgRefId = candidateDetails.OrgReferenceId;
    }
    connectRepository
      .pushPerson({ ...candidateInfo, CorrelationId: candidateOrgRefId }, RefId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        const errorResponse = error.response;
        if (errorResponse.status === 409) {
          return errorResponse.data.Error.Details;
        }
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timestamp: new Date(),
          },
        });
        return Promise.reject(error);
      })
      .then(connectPersonResponse => {
        const eventData = { Id: candidateInfo.Id, JobId: jobId, ...connectPersonResponse };
        const candidateDetails = {
          candidateId: candidateInfo.Id,
          jobId,
        };
        if (!isPaidJobServiceEnabled) {
          candidateDetails.conversationId = connectPersonResponse.ConversationId;
        }
        const broadcastNotification = { eventType: 'ContactFetched', eventData };
        PubSub.publish(BROADCAST_CHANNEL_POST_MESSAGE, { broadcastNotification });
        connectRepository
          .fetchConnectStatus(connectPersonResponse.ConversationId, connectPersonResponse.PersonId)
          .then(connectStatusResponse => {
            if (!isPassiveGroup || _.get(connectStatusResponse, ['data', 'Contact', 'RequestStatus']) === 'Success') {
              dispatch({
                type: 'SET_CANDIDATE_CONNECT_INFO',
                payload: {
                  connectInfo: {
                    [connectPersonResponse.PersonId]: connectStatusResponse.data,
                  },
                },
              });
              if (isPassiveGroup) dispatch(setFetchContactStatus(candidateInfo.Id, 'Completed', jobId));
            }
          });
        return connectPersonResponse;
      })
      .then(connectPersonResponse => {
        candidateRepository.addPersonIdToCandidate(
          jobId,
          candidateInfo.Id,
          connectPersonResponse.PersonId,
          connectPersonResponse.ConversationId
        );
        return connectPersonResponse;
      })
      .then(connectPersonResponse => {
        const candidateId = candidateInfo.Id;
        const candidateConnectIds = {};
        const candidateConnectData = { ...connectPersonResponse };
        if (isPaidJobServiceEnabled && candidateConnectData.ConversationId) {
          delete candidateConnectData.ConversationId;
        }
        candidateConnectIds[candidateId] = candidateConnectData;
        dispatch(setCandidateConnectIds(candidateConnectIds));
        return connectPersonResponse;
      })
      .then(connectPersonResponse => {
        const { PersonId: personId, ConversationId: conversationId } = connectPersonResponse;
        if (fetchCandidateContact) {
          dispatch(fetchContact(personId, conversationId, candidateInfo.Id, jobId));
        }
        dispatch(setCandidatePersonId(candidateInfo.Id, personId, jobId));
      });
  };
}

function pushCandidateToConnectAndFetchContact(jobId, candidateInfo, RefId, isQuickSearchCandidate) {
  return dispatch => dispatch(pushCandidateToConnect(jobId, candidateInfo, RefId, true, null, isQuickSearchCandidate));
}

function fetchMessages(conversationId, personId, messageType = 'SMS', toNumber = null, from = 0, size = 100) {
  const phoneNumber = toNumber || null;
  return (dispatch, getState) => {
    const currentState = getState();
    const featureToggleList = _.get(currentState, ['FeatureToggleReducer'], {});
    const {
      AllConversations: { IsEnabled: isAllConversationsEnabled },
    } = featureToggleList;
    connectRepository
      .fetchMessages({
        conversationId,
        personId,
        messageType,
        toNumber: phoneNumber,
        from,
        size,
      })
      .then(messageConversationsResponse => {
        if (messageType === 'SMS') {
          dispatch(setFetchConversationMessagesApiStatus('INPROGRESS'));
          dispatch({
            type: 'SET_CONVERSATION_MESSAGES',
            payload: {
              personId,
              conversationId,
              Messages: messageConversationsResponse.data.Messages,
              PhoneNumber: toNumber,
              total: messageConversationsResponse.data.Total,
              isAllConversationsEnabled,
            },
          });
          dispatch(setFetchConversationMessagesApiStatus('COMPLETED'));
        } else if (messageType === 'Chat') {
          dispatch({
            type: 'ADD_CHAT_MESSAGES',
            payload: {
              conversationId,
              Messages: messageConversationsResponse.data.Messages,
            },
          });
        }
      });
  };
}

function resetMessages(conversationId, personId, phoneNumber) {
  return (dispatch, getState) => {
    const currentState = getState();
    const featureToggleList = _.get(currentState, ['FeatureToggleReducer'], {});
    const {
      AllConversations: { IsEnabled: isAllConversationsEnabled },
    } = featureToggleList;
    dispatch({
      type: 'RESET_CONVERSATION_MESSAGES',
      payload: {
        conversationId,
        personId,
        isAllConversationsEnabled,
      },
    });
  };
}

function fetchLatestMessages(
  conversationId,
  personId,
  userId,
  requestStatus,
  messageType = 'SMS',
  from = 0,
  size = 100,
  setCount
) {
  return async dispatch => {
    try {
      dispatch(setFetchConversationMessagesApiStatus('INPROGRESS'));
      const response = await connectRepository.fetchLatestMessages({
        conversationId,
        personId,
        messageType,
        requestStatus,
        userId,
        toNumber: null,
        from,
        size,
      });
      if (response.data) {
        dispatch(setLatestMessages(conversationId, personId, response.data.PhoneStats, userId));
        if (setCount) setCount(response.data.PhoneStats.length);
        if (personId) {
          const refIds = [...new Set(response.data.PhoneStats.map(phone => phone.LatestMessage.RefId))];
          if (refIds.length > 0) {
            jobRepository
              .fetchJobBasicInfoList({
                jobGuids: refIds,
                showAllJobEntities: true,
              })
              .then(res => {
                const jobs = _.get(res, ['data'], []);
                dispatch(setJobsBasicInfoList(jobs));
              })
              .catch(() => {
                dispatch({
                  type: 'SET_ERROR',
                  payload: {
                    code: 'JOB_LIST_FETCH_FAILED',
                    timeStamp: new Date(),
                  },
                });
              });
          }
        }
      }
      dispatch(setFetchConversationMessagesApiStatus('COMPLETED'));
    } catch {
      dispatch(setFetchConversationMessagesApiStatus('FAILED'));
    }
  };
}

function addNewMessageToConversation(conversationId, message) {
  return dispatch => {
    PubSub.publish(SETUP_SOCKET_CONNECTIONS, { dispatch });
    const id = uuid();
    const identifiedMessage = {
      Id: id,
      ...message,
    };
    dispatch({
      type: 'SET_SEND_CHAT_MESSAGE_API_STATUS',
      payload: {
        conversationId,
        message: {
          ...message,
        },
        status: 'IN_PROGRESS',
      },
    });
    dispatch({
      type: 'SET_CONVERSATION_PENDING_MESSAGES',
      payload: {
        conversationId,
        message: {
          ...identifiedMessage,
          To: identifiedMessage.To[0],
        },
      },
    });
    connectRepository
      .sendMessage(conversationId, {
        ...identifiedMessage,
      })
      .then(() => {
        dispatch({
          type: 'SET_SEND_CHAT_MESSAGE_API_STATUS',
          payload: {
            conversationId,
            message: {
              ...message,
            },
            status: 'COMPLETED',
          },
        });
      })
      .catch(err => {
        dispatch({
          type: 'SET_SEND_CHAT_MESSAGE_API_STATUS',
          payload: {
            conversationId,
            message: {
              ...message,
            },
            status: 'FAILED',
          },
        });
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: err,
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchDripStats(conversationId) {
  return async dispatch => {
    try {
      dispatch({
        type: 'SET_FETCH_DRIP_STATS_API_STATUS',
        payload: 'INPROGRESS',
      });
      const response = await connectRepository.fetchDripStats(conversationId);
      const dripStats = response.data;
      dispatch({
        type: 'SET_DRIP_EMAIL_STATS',
        payload: {
          [conversationId]: dripStats,
        },
      });
      dispatch({
        type: 'SET_FETCH_DRIP_STATS_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'SET_FETCH_DRIP_STATS_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
}

function fetchMails(
  conversationId,
  personId,
  emailAddress,
  isLoadingAllowed = true,
  from = 0,
  size = 30,
  dispatchApiStatus = false,
  setCount
) {
  return (dispatch, getState) => {
    PubSub.publish(SETUP_SOCKET_CONNECTIONS, { dispatch });
    if (isLoadingAllowed) dispatch(setFetchConversationMailsApiStatus('INPROGRESS'));
    if (conversationId) dispatch(fetchDripStats(conversationId));
    const fetchMailPayload = {
      personId,
      conversationId,
      from,
      size,
    };
    if (dispatchApiStatus) {
      if (conversationId) {
        dispatch(setFetchContextualMailsConversationApiStatus('INPROGRESS'));
      } else if (personId) {
        dispatch(setFetchAllContextualMailsConversationApiStatus('INPROGRESS'));
      }
    }
    const currentState = getState();
    const featureToggleList = _.get(currentState, ['FeatureToggleReducer'], {});
    const {
      AllConversations: { IsEnabled: isAllConversationsEnabled },
    } = featureToggleList;
    connectRepository.fetchMails(fetchMailPayload).then(mailConversationsResponse => {
      const emails = _.get(mailConversationsResponse, ['data', 'Emails'], []).filter(email => email);
      const emailsCount = _.get(mailConversationsResponse, ['data', 'Total'], 0);
      if (setCount) setCount(emailsCount);
      if (personId) {
        const refIds = _.uniq(emails.map(email => email.RefId));
        const fetchBasicInfo = { jobGuids: refIds, showAllJobEntities: true };
        jobRepository
          .fetchJobBasicInfoList(fetchBasicInfo)
          .then(response => {
            const jobs = _.get(response, ['data'], []);
            dispatch(setJobsBasicInfoList(jobs));
          })
          .catch(() => {
            dispatch({
              type: 'SET_ERROR',
              payload: {
                code: 'JOB_LIST_FETCH_FAILED',
                timeStamp: new Date(),
              },
            });
          });
      }

      dispatch({
        type: 'SET_CONVERSATION_MAILS',
        payload: {
          conversationId,
          personId,
          Mails: emails,
          MailsCount: emailsCount,
          isAllConversationsEnabled,
        },
      });
      if (conversationId) {
        dispatch(setFetchContextualMailsConversationApiStatus('SUCCESS'));
      } else if (personId) {
        dispatch(setFetchAllContextualMailsConversationApiStatus('SUCCESS'));
      }
      if (isLoadingAllowed) dispatch(setFetchConversationMailsApiStatus('COMPLETED'));
    });
  };
}

function resetMails(conversationId, personId) {
  return (dispatch, getState) => {
    const currentState = getState();
    const featureToggleList = _.get(currentState, ['FeatureToggleReducer'], {});
    const {
      AllConversations: { IsEnabled: isAllConversationsEnabled },
    } = featureToggleList;
    dispatch({
      type: 'RESET_CONVERSATION_MAILS',
      payload: {
        conversationId,
        personId,
        isAllConversationsEnabled,
      },
    });
  };
}

function fetchMailsByEmailConversationId({ emailConversationId, personId, isAllConversationsEnabled }) {
  return async dispatch => {
    try {
      const response = await connectRepository.fetchMailsByEmailConversationId({
        emailConversationId,
      });
      const emailConversation = response.data;
      dispatch({
        type: 'ADD_NEW_MAIL',
        payload: {
          conversationId: emailConversation.ConversationId,
          Mail: emailConversation,
          personId,
          isAllConversationsEnabled,
        },
      });
      return emailConversation;
    } catch (e) {
      return null;
    }
  };
}

function addNewMailToConversation(conversationId, mail) {
  return dispatch => {
    PubSub.publish(SETUP_SOCKET_CONNECTIONS, { dispatch });
    connectRepository.sendMail(conversationId, mail).then(() => {
      const Mail = {
        ...mail,
        // TODO: Add sent time, delivered time
      };
      dispatch({
        type: 'ADD_NEW_MAIL',
        payload: {
          conversationId,
          Mail,
        },
      });
    });
  };
}

function fetchCalls(conversationId, toNumber) {
  return dispatch => {
    connectRepository.fetchCalls(conversationId, toNumber).then(callConversationsResponse => {
      dispatch({
        type: 'SET_CONVERSATION_CALLS',
        payload: {
          conversationId,
          toNumber,
          Calls: callConversationsResponse.data.Conversations,
        },
      });
      const userIds = _.uniq(callConversationsResponse.data.Conversations.map(conversation => conversation.CreatedBy));
      if (userIds && userIds.length > 0) {
        dispatch(
          fetchConnectUsers({
            userIds,
          })
        );
      }
    });
  };
}
function addNewCallToConversation(conversationId, call) {
  return async (dispatch, getState) => {
    try {
      const updateCallHistoryResponse = await connectRepository.updateCallHistory(conversationId, call);
      const state = getState();
      const currentUser = getCurrentUser(state);
      const userGuid = currentUser.sub;
      const Call = {
        ...call,
        Id: updateCallHistoryResponse.data.Id,
        CreatedBy: userGuid,
      };
      if (userGuid) {
        dispatch(
          fetchConnectUsers({
            userIds: [userGuid],
          })
        );
      }

      dispatch({
        type: 'ADD_NEW_CALL',
        payload: {
          conversationId,
          Call,
        },
      });
      return updateCallHistoryResponse.data.Id;
    } catch (e) {
      return null;
    }
  };
}

function updateCallStatus(conversationId, audioConversationId, conversationStatus) {
  return dispatch => {
    if (audioConversationId) {
      connectRepository.updateCallStatus(audioConversationId, conversationStatus).then(() => {
        dispatch({
          type: 'UPDATE_CALL_STATUS',
          payload: {
            conversationId,
            audioConversationId,
            conversationStatus,
          },
        });
      });
    }
  };
}

function unsetOngoingCallId(conversationId) {
  return dispatch => {
    dispatch({
      type: 'UNSET_ONGOING_CALL_ID',
      payload: {
        conversationId,
      },
    });
  };
}

function fetchEmailTemplates(from, size, includeDrip = false, languageCode = 'en-US', ExcludeTags = ['workflow']) {
  const isPulse = isPulseUser();
  const includeSystemTemplate = !isPulse;
  return dispatch => {
    connectRepository
      .fetchEmailTemplates({ from, size, includeSystemTemplate, ExcludeTags, includeDrip, languageCode })
      .then(response => {
        dispatch({
          type: 'SET_EMAIL_TEMPLATES',
          payload: response.data.Templates,
        });
      });
  };
}

function fetchMessageTemplates(from, size, filter) {
  return dispatch => {
    connectRepository
      .fetchMessageTemplates(from, size, {
        ...filter,
      })
      .then(response => {
        const { IsConsent } = filter;
        if (IsConsent === true) {
          dispatch({
            type: 'SET_CONSENT_MESSAGE_TEMPLATES',
            payload: response.data.Templates,
          });
        } else if (IsConsent === false) {
          dispatch({
            type: 'SET_NONCONSENT_MESSAGE_TEMPLATES',
            payload: response.data.Templates,
          });
        } else {
          dispatch({
            type: 'SET_MESSAGE_TEMPLATES',
            payload: response.data.Templates,
          });
        }
      });
  };
}

function fetchBulkCallNotes(filter) {
  return dispatch => {
    const notesFilter = {
      ConversationIds: _.get(filter, 'conversationIds', []),
      From: _.get(filter, 'from', 0),
      Size: _.get(filter, 'size', 0),
    };
    if (notesFilter.ConversationIds.length === 0) {
      return Promise.resolve();
    }
    dispatch({
      type: 'SET_FETCH_BULK_CALL_NOTES_API_STATUS',
      payload: {
        status: 'INPROGRESS',
      },
    });
    dispatch({
      type: 'SET_CANDIDATES_CALL_NOTES_FETCH_API_STATUS',
      payload: {
        conversationIds: _.get(filter, 'conversationIds', []),
        candidateIdsByConversationId: _.get(filter, 'candidateIdsByConversationId', {}),
        status: 'INPROGRESS',
      },
    });
    return connectRepository
      .fetchBulkCallNotes(notesFilter)
      .then(response => {
        dispatch({
          type: 'SET_BULK_CALL_NOTES_USING_CONVERSATION_ID',
          payload: {
            conversationIds: _.get(filter, 'conversationIds', []),
            notesByConversationId: _.get(response, 'data', {}),
            candidateIdsByConversationId: _.get(filter, 'candidateIdsByConversationId', {}),
          },
        });
        dispatch({
          type: 'SET_CANDIDATES_CALL_NOTES_FETCH_API_STATUS',
          payload: {
            conversationIds: _.get(filter, 'conversationIds', []),
            candidateIdsByConversationId: _.get(filter, 'candidateIdsByConversationId', {}),
            status: 'COMPLETED',
          },
        });
        dispatch({
          type: 'SET_FETCH_BULK_CALL_NOTES_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'SET_CANDIDATES_CALL_NOTES_FETCH_API_STATUS',
          payload: {
            conversationIds: _.get(filter, 'conversationIds', []),
            candidateIdsByConversationId: _.get(filter, 'candidateIdsByConversationId', {}),
            status: 'FAILED',
          },
        });
        dispatch({
          type: 'SET_FETCH_BULK_CALL_NOTES_API_STATUS',
          payload: {
            status: 'FAILED',
          },
        });
        throw error;
      });
  };
}

function postCallNotes(audioConversationId, note, candidateId, conversationId, from, size, jobId) {
  const eventData = { CandidateId: candidateId, JobId: jobId, ConversationId: conversationId };
  const broadcastNotification = { eventType: 'CallNotesUpdated', eventData };
  return dispatch =>
    connectRepository.postCallNotes(audioConversationId, note).then(() => {
      PubSub.publish(BROADCAST_CHANNEL_POST_MESSAGE, { broadcastNotification });
      fetchCallNotes(candidateId, conversationId, from, size)(dispatch);
    });
}

function fetchConnectedCounts(jobGuids) {
  return dispatch => {
    connectRepository.fetchConnectedCount(jobGuids).then(response => {
      dispatch({
        type: 'SET_CONNECTED_COUNTS',
        payload: response.data,
      });
    });
  };
}

function getPersonality(candidateId, personId) {
  return dispatch => {
    dispatch({
      type: 'SET_CANDIDATE_PERSONALITY_STATUS_API_STATUS',
      payload: {
        status: 'INPROGRESS',
      },
    });
    connectRepository
      .getPersonality(personId)
      .then(response => {
        dispatch(setCandidatePersonality(candidateId, response.data));
        dispatch({
          type: 'SET_CANDIDATE_PERSONALITY_STATUS_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      })
      .catch(error => {
        if (error.response.status === 404) {
          if (!error.response.data.Error.Details) {
            dispatch(setCandidatePersonalityStatus(candidateId, 'NotFetched'));
          } else {
            dispatch(
              setCandidatePersonalityStatus(candidateId, error.response.data.Error.Details.PersonalityRequestStatus)
            );
          }
          dispatch({
            type: 'SET_CANDIDATE_PERSONALITY_STATUS_API_STATUS',
            payload: {
              status: 'COMPLETED',
            },
          });
        }
      });
  };
}

function fetchPersonality(candidateId, personId) {
  return dispatch => {
    dispatch({
      type: 'SET_CANDIDATE_PERSONALITY_API_STATUS',
      payload: {
        status: 'INPROGRESS',
      },
    });
    connectRepository
      .fetchPersonality(personId)
      .then(response => {
        dispatch(setCandidatePersonality(candidateId, response.data));
        dispatch({
          type: 'SET_CANDIDATE_PERSONALITY_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
        dispatch(fetchAvailableCredits(null, 'Arya'));
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 422) {
          if (!error.response.data.Error.Details) {
            dispatch(setCandidatePersonalityStatus(candidateId, 'NoResult'));
          }
          dispatch({
            type: 'SET_CANDIDATE_PERSONALITY_API_STATUS',
            payload: {
              status: 'COMPLETED',
            },
          });
        }
      });
  };
}

function getConversationReference(jobId, conversationReferenceId) {
  return dispatch => {
    return connectRepository.fetchConversationReference(conversationReferenceId).then(response => {
      dispatch({
        type: 'SET_JOB_DETAILS',
        payload: {
          IsBotEnabled: response.data.IsBotEnabled,
          JobId: jobId,
        },
      });
    });
  };
}

function editConversationReferenceDetails(conversationReferenceId, botStatus, jobId) {
  return dispatch => {
    connectRepository.putConversationReference(conversationReferenceId, botStatus).then(() => {
      dispatch(getConversationReference(jobId, conversationReferenceId));
    });
  };
}

function getPersonDetails(personId, conversationId) {
  return dispatch => {
    connectRepository.getPersonDetails(personId).then(response => {
      dispatch({
        type: 'ADD_CONVERSATION_PERSON_MAPPING',
        payload: {
          conversationId,
          personId,
        },
      });
      dispatch({
        type: 'ADD_PERSON_DETAILS',
        payload: response.data,
      });
    });
  };
}

function searchConnectNotifications(filter) {
  return dispatch => {
    connectRepository.searchConnectNotifications(filter).then(response => {
      dispatch({
        type: 'ADD_CONNECT_NOTIFICATIONS',
        payload: response.data.Notifications,
      });

      dispatch({
        type: 'SET_CONNECT_NOTIFICATIONS_COUNT',
        payload: response.data.Total,
      });

      response.data.Notifications.forEach(notification =>
        dispatch(getPersonDetails(notification.PersonId, notification.ConversationId))
      );
    });
  };
}

function getConnectNotificationCount(filter) {
  return dispatch => {
    connectRepository.getConnectNotificationCount(filter).then(response => {
      dispatch({
        type: 'SET_CONNECT_NOTIFICATIONS_COUNT',
        payload: response.data.Total,
      });
    });
  };
}

function updateConnectNotificationStatus(notificationId, status) {
  return () => {
    notificationRepository.putConnectNotificationStatus(notificationId, status);
  };
}

function updateConnectViewedTime() {
  return () => {
    notificationRepository.putConnectViewedTime();
  };
}

function fetchJobsUnreadConversationCount(jobGuids) {
  return dispatch => {
    connectRepository
      .fetchConnectedJobs({
        jobGuids,
      })
      .then(response => {
        const jobUnreadConversationCount = _.keyBy(response.data.References, 'Id');
        dispatch({
          type: 'SET_JOB_UNREAD_CONVERSATION_COUNT',
          payload: {
            jobUnreadConversationCount,
          },
        });
      });
  };
}

function markConversationAsRead(conversationId, status, jobGuid) {
  return dispatch => {
    connectRepository.updateConversationReadStatus(conversationId, status).then(() => {
      let type = '';
      if (status === 'Chat') {
        type = 'UnreadChatCount';
      } else {
        type = 'UnreadSmsCount';
      }
      dispatch({
        type: 'UPDATE_CONVERSATION_DETAILS',
        payload: {
          Id: conversationId,
          type,
        },
      });
    });
    if (jobGuid) {
      dispatch(fetchJobsUnreadConversationCount([jobGuid]));
    }
  };
}

function searchConversations(filter) {
  return dispatch => {
    connectRepository.searchConversations(filter).then(response => {
      dispatch({
        type: 'ADD_CONVERSATION_DETAILS',
        payload: response.data.Conversations,
      });
    });
  };
}

export const searchOrgLevelConversations = (filter, appendData = false) => {
  return async dispatch => {
    try {
      if (!appendData) {
        dispatch(setApiStatus(setFetchOrgLevelConversations('InProgress')));
      }
      const response = await connectRepository.searchConversations(filter);
      const { Conversations: conversations, Total: totalCount } = response.data;
      if (appendData) {
        dispatch(appendOrgLevelConversations(conversations));
      } else {
        dispatch(setOrgLevelConversations(conversations));
        dispatch(setOrgLevelConversationsCount(totalCount));
      }
      if (conversations.length) {
        const personIds = conversations.filter(item => item.PersonId).map(item => item.PersonId);
        if (personIds.length) {
          const personsDetailsResponse = await connectRepository.fetchBulkPersonDetails({
            From: 0,
            Size: 0,
            Ids: personIds,
          });
          if (appendData) {
            dispatch(appendOrgLevelPersonDetails(personsDetailsResponse.data?.Persons));
          } else {
            dispatch(setOrgLevelPersonDetails(personsDetailsResponse.data?.Persons));
          }
        }
      }
      dispatch(setApiStatus(setFetchOrgLevelConversations('Completed')));
    } catch {
      dispatch(setApiStatus(setFetchOrgLevelConversations('Failed')));
    }
  };
};

function fetchBulkUnReadConversationsCount(filter) {
  return dispatch => {
    const clonedFilter = { ...filter };
    clonedFilter.ConversationIds = _.uniq(clonedFilter.ConversationIds?.filter(conversationId => !!conversationId));
    if (clonedFilter.ConversationIds?.length > 0) {
      connectRepository.fetchBulkUnReadConversationsCount(clonedFilter).then(response => {
        dispatch(setConversationDetails(response.data));
      });
    }
  };
}

function getEmailSignatures() {
  return dispatch => {
    connectRepository.getEmailSignatures().then(response => {
      dispatch({
        type: 'SET_EMAIL_SIGNATURES',
        payload: response.data.EmailSignatures,
      });
    });
  };
}

function postEmailSignature(EmailId, Signature) {
  return dispatch => {
    connectRepository.postEmailSignature(EmailId, Signature).then(() => {
      dispatch(getEmailSignatures());
    });
    Promise.resolve();
  };
}

function postEmailSignatures(emailSignatures) {
  return dispatch => {
    connectRepository.postEmailSignatures(emailSignatures).then(() => {
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'savedSuccessfully',
        })
      );
      dispatch(getEmailSignatures());
    });
  };
}

function getUserIDsForMismatchMarkedContacts(contacts) {
  return (contacts || []).reduce((userIds, contact) => {
    const userId = contact.ContactMismatchInfo?.LastModifiedBy;
    if (userId) {
      userIds.push(userId);
    }
    return userIds;
  }, []);
}

function deduplicateUserIds(userIds) {
  return [...new Set(userIds)];
}

function fetchUsersForMismatchMarkedContacts(connectStatus) {
  return dispatch => {
    if (!connectStatus || !connectStatus.Contact) {
      return;
    }

    const { Contact: contact } = connectStatus;
    const { Emails: emails, Phones: phones } = contact || {};

    const emailMismatchUserIds = getUserIDsForMismatchMarkedContacts(emails);
    const phoneMismatchUserIds = getUserIDsForMismatchMarkedContacts(phones);

    const uniqueUserIds = deduplicateUserIds([...emailMismatchUserIds, ...phoneMismatchUserIds]);

    if (uniqueUserIds.length) {
      dispatch(fetchUsers({ userGuids: uniqueUserIds }));
    }
  };
}

function addCandidateContactInfo(candidate, pushCandInfo, isPassiveGroup, jobId) {
  const personId = candidate.PersonId;
  return dispatch => {
    connectRepository.fetchConnectStatuses(pushCandInfo.refId, [personId]).then(connectStatusResponse => {
      const connectStatus =
        connectStatusResponse.data &&
        connectStatusResponse.data.ConnectStatuses &&
        connectStatusResponse.data.ConnectStatuses[candidate.PersonId];
      const candidateDetails = {
        candidateId: candidate.Id,
        jobId,
        conversationId: candidate.ConversationId,
      };
      if (
        connectStatus &&
        (ConnectUtils.isValidContact(connectStatus.Contact) || !ConnectUtils.isValidContact(candidate))
      ) {
        if (!isPassiveGroup || _.get(connectStatus, ['Contact', 'RequestStatus']) === 'Success') {
          dispatch({
            type: 'SET_CANDIDATE_CONNECT_INFO',
            payload: {
              connectInfo: {
                ...connectStatusResponse.data.ConnectStatuses,
              },
            },
          });
          dispatch(fetchUsersForMismatchMarkedContacts(connectStatus));
          if (isPassiveGroup) dispatch(setFetchContactStatus(candidate.Id, 'Completed', jobId));
        }
      } else {
        const contact = ConnectUtils.candidateToContact(candidate);
        dispatch(addContact(pushCandInfo.refId, candidate.PersonId, contact, candidateDetails));
      }
    });
  };
}

function fetchUserEmails({ testEmailStatus, isOrgDefault } = {}) {
  return async dispatch => {
    try {
      const userEmailsResponse = await connectRepository.fetchUserEmails({ testEmailStatus, isOrgDefault });
      if (isOrgDefault) {
        dispatch({
          type: 'SET_ORG_DEFAULT_FROM_EMAIL',
          payload: userEmailsResponse.data,
        });
      } else {
        const sortedUserEmails = userEmailsResponse.data?.sort((a, b) =>
          moment.utc(a.CreatedDateTime).diff(moment.utc(b.CreatedDateTime))
        );
        dispatch({
          type: 'SET_USER_EMAILS',
          payload: sortedUserEmails,
        });
      }
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'failedToFetchUserEmails',
        })
      );
    }
  };
}

function setNotificationOnVerificationStatus(response, dispatch) {
  if (response.VerificationStatus.toLowerCase() === 'failed') {
    dispatch(
      setNotification('ERROR', {
        messageId: 'failedToSendVerificationLinkToEmailId',
        mergeTags: { EmailId: response.EmailId },
      })
    );
  } else {
    dispatch(
      setNotification('SUCCESS', {
        messageId: 'verificationLinkSentToEmailId',
        mergeTags: { EmailId: response.EmailId },
      })
    );
  }
}

function addUserEmail(emailId, isReverify, senderName, providerId) {
  return async dispatch => {
    try {
      const response = await connectRepository.addUserEmail(emailId, senderName);
      if (isReverify) {
        setNotificationOnVerificationStatus(response.data, dispatch);
      } else {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'EmailIdAddedSuccessfullyLabel',
            mergeTags: { emailId },
          })
        );
      }
      if (providerId != null) {
        const testEmailDetails = {
          emailId,
          providerId,
          senderName,
        };
        await dispatch(connectEmailToProviderAndTest(testEmailDetails));
      }
      dispatch(fetchUserEmails());
    } catch (error) {
      if (error.response.status === 409) {
        dispatch(
          setNotification('ERROR', {
            messageId: 'EmailIdAlreadyExists',
            mergeTags: { emailId },
          })
        );
      } else if (isReverify) {
        dispatch(
          setNotification('ERROR', {
            messageId: 'FailedToSendVerificationLinkToEmailId',
            mergeTags: { emailId },
          })
        );
      } else {
        dispatch(
          setNotification('ERROR', {
            messageId: 'failedToAddEmailId',
            mergeTags: { emailId },
          })
        );
      }
    }
  };
}

function deleteUserEmail(id) {
  return dispatch => {
    return connectRepository
      .deleteUserEmail(id)
      .then(() => {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'deletedSuccessfully',
          })
        );
        dispatch(fetchUserEmails());
        dispatch(getEmailSignatures());
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR', {
            messageId: 'failedToDelete',
          })
        );
      });
  };
}

function fetchEmailStats(conversationIds, jobId) {
  return dispatch => {
    return connectRepository
      .fetchEmailStats(conversationIds)
      .then(response => {
        const emailStats = response.data;
        dispatch({
          type: 'SET_EMAIL_STATS',
          payload: { jobId, emailStats },
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_EMAIL_STATS',
          payload: { jobId, emailStats: [] },
        });
      });
  };
}

function fetchConversationStats(conversationIds, jobId, preferredStats) {
  return dispatch => {
    return connectRepository
      .fetchConversationStats(conversationIds, preferredStats)
      .then(response => {
        const conversationStats = response.data;
        dispatch({
          type: 'SET_CONVERSATION_STATS',
          payload: { jobId, conversationStats },
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_CONVERSATION_STATS',
          payload: { jobId, conversationStats: [] },
        });
      });
  };
}
function fetchSubscriptionStats(personIds, jobId, jobGuid) {
  return async dispatch => {
    try {
      if (personIds?.length > 0) {
        const response = await connectRepository.fetchSubscriptionStats(personIds, jobGuid);
        const subscriptionStats = response.data;
        dispatch({
          type: SET_SUBSCRIPTION_STATS,
          payload: { jobId, subscriptionStats },
        });
      } else {
        dispatch({
          type: SET_SUBSCRIPTION_STATS,
          payload: { jobId },
        });
      }
    } catch {
      dispatch({
        type: SET_SUBSCRIPTION_STATS,
        payload: { jobId, subscriptionStats: {} },
      });
    }
  };
}

function updateScheduledEmailsStatus(conversationId, emailAddress, status) {
  return async dispatch => {
    try {
      dispatch({
        type: 'SET_CONVERSATIONS_SCHEDULED_DRIP_API_STATUS',
        payload: 'INPROGRESS',
      });
      await connectRepository.updateScheduledEmailsStatus([conversationId], status);
      await dispatch(fetchMails(conversationId, emailAddress));
      dispatch({
        type: 'SET_CONVERSATIONS_SCHEDULED_DRIP_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'failedToUpdatescheduledEmailStatus',
          mergeTags: { status: status.toLowerCase() },
          mergeTagId: 'true',
        })
      );
      dispatch({
        type: 'SET_CONVERSATIONS_SCHEDULED_DRIP_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
}

function updateEmailMismatchStatus(personId, emailAddress, validityStatus, mismatchReason) {
  return async (dispatch, getState) => {
    try {
      await connectRepository.updateEmailMismatchStatus(personId, emailAddress, validityStatus, mismatchReason);
      const state = getState();
      const currentUser = getCurrentUser(state);
      const userGuid = currentUser.sub;
      const lastUpdatedTime = Date.now();
      dispatch({
        type: 'SET_EMAIL_MISMATCH_STATUS',
        payload: { personId, emailAddress, isMismatch: true, mismatchReason, userGuid, lastUpdatedTime },
      });
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'failedToUpdateEmailStatusToValidityStatus',
          mergeTags: { validityStatus: validityStatus.toLowerCase() },
        })
      );
    }
  };
}

function updatePhoneMismatchStatus(personId, phoneNumber, validityStatus, mismatchReason) {
  return async (dispatch, getState) => {
    try {
      await connectRepository.updatePhoneMismatchStatus(personId, phoneNumber, validityStatus, mismatchReason);
      const state = getState();
      const currentUser = getCurrentUser(state);
      const userGuid = currentUser.sub;
      const lastUpdatedTime = Date.now();
      dispatch({
        type: 'SET_PHONE_MISMATCH_STATUS',
        payload: { personId, phoneNumber, isMismatch: true, mismatchReason, userGuid, lastUpdatedTime },
      });
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'failedToUpdatePhoneStatusToValidityStatus',
          mergeTags: { validityStatus: validityStatus.toLowerCase() },
          mergeTagId: 'true',
        })
      );
    }
  };
}

function setInvalidTemplateNotification(candidateContext = 'job') {
  return dispatch => {
    if (candidateContext === 'job')
      dispatch(
        setNotification('ERROR', {
          messageId: 'incorrectMergeTagFormat',
        })
      );
    else
      dispatch(
        setNotification(
          'ERROR',

          {
            messageId: 'selectedTemplateHasIncorrectTags',
          }
        )
      );
  };
}

function updateUserEmail(id, emailAddress) {
  return async () => {
    try {
      await connectRepository.patchUpdateUserEmail(id, emailAddress);
      Promise.resolve();
    } catch {
      Promise.reject();
    }
  };
}

function fetchJobDripStats(jobGuid) {
  return async () => {
    try {
      const response = await connectRepository.fetchJobDripStats(jobGuid);
      return response.data;
    } catch (error) {
      return {};
    }
  };
}

function setMessageConversationApiStatus(status) {
  return dispatch => {
    dispatch(setFetchConversationMessagesApiStatus(status));
  };
}

const setHelpTabTimesamp = data => {
  return {
    type: 'SET_HELP_TAB_TIMESTAMP',
    payload: data,
  };
};

function unsubscribeCandidate(personId, unsubscribeReason, conversationId, subscriptionStatus, isJobLevel) {
  return async dispatch => {
    try {
      await connectRepository.unsubscribeCandidate(
        personId,
        unsubscribeReason,
        isJobLevel ? conversationId : null,
        subscriptionStatus
      );
      dispatch(fetchConnectStatus(conversationId, personId));
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'failedToUpdateCandidateSubscribeStatus',
        })
      );
    }
  };
}

function fetchOrgPhoneConsentInfo(phoneNumbers, personId) {
  return async dispatch => {
    try {
      const response = await connectRepository.GetOrgPhoneConsentInfo(phoneNumbers, personId);
      dispatch({
        type: 'SET_ORG_PHONE_CONSENT_INFO',
        payload: { orgPhoneConsentInfo: response.data, PersonId: personId },
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timestamp: new Date(),
        },
      });
      return Promise.reject(error);
    }
  };
}

export {
  pushCandidateToConnect,
  pushCandidateToConnectAndFetchContact,
  addNewMessageToConversation,
  addNewMailToConversation,
  getContact,
  fetchContact,
  fetchMessages,
  fetchMails,
  resetMails,
  fetchMailsByEmailConversationId,
  fetchEmailTemplates,
  fetchMessageTemplates,
  fetchCalls,
  addNewCallToConversation,
  updateCallStatus,
  fetchConnectedCounts,
  addContact,
  postCallNotes,
  getPersonality,
  fetchPersonality,
  editConversationReferenceDetails,
  getConversationReference,
  getPersonDetails,
  searchConnectNotifications,
  getConnectNotificationCount,
  updateConnectNotificationStatus,
  updateConnectViewedTime,
  markConversationAsRead,
  searchConversations,
  getEmailSignatures,
  postEmailSignature,
  fetchJobsUnreadConversationCount,
  unsetOngoingCallId,
  fetchConnectStatus,
  addCandidateContactInfo,
  fetchBulkCallNotes,
  fetchUserEmails,
  postEmailSignatures,
  addUserEmail,
  fetchLatestMessages,
  deleteUserEmail,
  getRefundForInvalidContacts,
  fetchEmailStats,
  fetchConversationStats,
  fetchSubscriptionStats,
  updateScheduledEmailsStatus,
  fetchBulkUnReadConversationsCount,
  fetchDripStats,
  updateEmailMismatchStatus,
  updatePhoneMismatchStatus,
  setInvalidTemplateNotification,
  updateUserEmail,
  fetchJobDripStats,
  setMessageConversationApiStatus,
  setHelpTabTimesamp,
  unsubscribeCandidate,
  fetchOrgPhoneConsentInfo,
  resetMessages,
  reportConversation,
  getReportConversationDetails,
};
