import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, Badge } from 'antd';
import { getRelativeTimeSpanFromNow } from '../../../Utils/RelativeTimeCalculator';
import { getConfig } from '../../../Reducers/ConfigReducer';
import styles from './CreditsExhaustedNotification.module.scss';

function getNotificationInfo() {
  const userConfig = useSelector(state => getConfig(state));
  return (
    <div className="message">
      {userConfig?.Role !== 'Recruiter' ? (
        <span>
          Please contact <a href="mailto:support@leoforce.com">support@leoforce.com</a> to secure more credits.
        </span>
      ) : (
        <span>Please contact your administrator to add credits to your account.</span>
      )}
    </div>
  );
}

const jobLevelCreditExhausationMessage = () => <span>Please contact your admin to add credits to your account.</span>;

export default function CreditsExhaustedNotification(props) {
  const { notification, onClick } = props;
  const { UpdatedTime, Payload, Type } = notification;
  return (
    <div
      className="notification"
      role="presentation"
      onClick={() => {
        onClick(notification);
      }}
    >
      <div className={styles.creditsExhaustedImageWrapper}>
        <div className={styles.closeIcon}>
          <Icon type="close-circle" theme="filled" />
        </div>
      </div>
      <div className="content">
        <div className="notification-title">
          <span className="bold">
            {Type === 'JobUsageBudgetExhausted' ? 'Low Job Credits!' : `Low ${Payload?.CreditType} Credits!`}
          </span>
        </div>
        {Type === 'JobUsageBudgetExhausted' ? jobLevelCreditExhausationMessage() : getNotificationInfo()}
        <div className="actions" />
        <div className="since">{getRelativeTimeSpanFromNow(UpdatedTime)}</div>
      </div>
    </div>
  );
}
