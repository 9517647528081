import { setApiStatus } from '../ApiStatusActions';

export const SET_CANDIDATES = 'SET_CANDIDATES';
export const UPDATE_UNLOCKED_CANDIDATE = 'UPDATE_UNLOCKED_CANDIDATE';
export const SET_CANDIDATE_ALL_DETAILS = 'SET_CANDIDATE_ALL_DETAILS';
export const SET_BULK_FETCH_CONTACT_STATUS = 'SET_BULK_FETCH_CONTACT_STATUS';
export const SET_FETCH_CONTACT_STATUS = 'SET_FETCH_CONTACT_STATUS';
export const SET_CANDIDATE_REJECT_FLAG = 'SET_CANDIDATE_REJECT_FLAG';
export const RESET_CANDIDATE_JOBS = 'RESET_CANDIDATE_JOBS';
export const SET_CANDIDATE_NOTES = 'SET_CANDIDATE_NOTES';
export const SET_CANDIDATE_NOTES_COUNT = 'SET_CANDIDATE_NOTES_COUNT';
export const SET_BULK_CANDIDATE_NOTES = 'SET_BULK_CANDIDATE_NOTES';
export const SET_CALL_NOTES = 'SET_CALL_NOTES';
export const SET_CALL_NOTES_COUNT = 'SET_CALL_NOTES_COUNT';
export const SET_BULK_CALL_NOTES_USING_CONVERSATION_ID = 'SET_BULK_CALL_NOTES_USING_CONVERSATION_ID';

export const SET_BULK_CALL_NOTES_USING_CANDIDATE_ID = 'SET_BULK_CALL_NOTES_USING_CANDIDATE_ID';
export const SET_CANDIDATE_ALL_DETAILS_ERROR = 'SET_CANDIDATE_ALL_DETAILS_ERROR';
export const SET_CANDIDATE_CONNECT_IDS = 'SET_CANDIDATE_CONNECT_IDS';
export const SET_CANDIDATE_PERSONALITY_STATUS = 'SET_CANDIDATE_PERSONALITY_STATUS';
export const SET_CANDIDATE_PERSONALITY = 'SET_CANDIDATE_PERSONALITY';
export const SET_CANDIDATE_PERSON_ID = 'SET_CANDIDATE_PERSON_ID';
export const SET_CANDIDATE_CONVERSATION_ID = 'SET_CANDIDATE_CONVERSATION_ID';
export const SET_CANDIDATE_VIEW_STATUS = 'SET_CANDIDATE_VIEW_STATUS';
export const SET_CANDIDATE_STATUS = 'SET_CANDIDATE_STATUS';
export const SET_CANDIDATE_COUNT = 'SET_CANDIDATE_COUNT';
export const SET_SAMPLE_CANDIDATE_COUNT = 'SET_SAMPLE_CANDIDATE_COUNT';
export const UPDATE_CANDIDATE_RECOMMENDED_INFO = 'UPDATE_CANDIDATE_RECOMMENDED_INFO';
export const SET_CANDIDATE_DETAILS_API_STATUS = 'SET_CANDIDATE_DETAILS_API_STATUS';
export const SET_CANDIDATE_AGGREGATIONS_API_STATUS = 'SET_CANDIDATE_AGGREGATIONS_API_STATUS';

const SET_CONNECT_STATUS_API_STATUS = 'SET_CONNECT_STATUS_API_STATUS';
export const SET_FETCH_BULK_CONTACTS_API_STATUS = 'SET_FETCH_BULK_CONTACTS_API_STATUS';
export const SET_CANDIDATE_ALL_DETAILS_API_STATUS = 'SET_CANDIDATE_ALL_DETAILS_API_STATUS';
export const SET_CANDIDATE_LIST_ERROR = 'SET_CANDIDATE_LIST_ERROR';
export const SET_DILATED_CANDIDATE_COUNT = 'SET_DILATED_CANDIDATE_COUNT';
export const SET_CANDIDATES_AGGREGATIONS = 'SET_CANDIDATES_AGGREGATIONS';

export const SET_SMART_AGENT_CANDIDATE_NOTIFICATION = 'SET_SMART_AGENT_CANDIDATE_NOTIFICATION';
export const SET_CANDIDATE_INTEL = 'SET_CANDIDATE_INTEL';
export const SET_CANDIDATE_INTEL_API_STATUS = 'SET_CANDIDATE_INTEL_API_STATUS';
export const SET_RECOMMENDED_STATUS_CHANGED_NOTIFICATION = 'SET_RECOMMENDED_STATUS_CHANGED_NOTIFICATION';
export const SET_FETCH_TRY_NOW_CANDIDATE_API_STATUS = 'SET_FETCH_TRY_NOW_CANDIDATE_API_STATUS';

export const SET_FETCH_TRY_NOW_CANDIDATE_DETAILS_API_STATUS = 'SET_FETCH_TRY_NOW_CANDIDATE_DETAILS_API_STATUS';

export const SET_FETCH_TRY_NOW_CANDIDATE_DETAILS = 'SET_FETCH_TRY_NOW_CANDIDATE_DETAILS';

export const SET_CANDIDATE_CONNECTION_STATUS_USING_CANDIDATE_ID = 'SET_CANDIDATE_CONNECTION_STATUS_USING_CANDIDATE_ID';

export const SET_CANDIDATE_SPECIFICATIONS_API_STATUS = 'SET_CANDIDATE_SPECIFICATIONS_API_STATUS';

export const FETCH_CANDIDATE_SPECIFICATIONS = 'FETCH_CANDIDATE_SPECIFICATIONS';

export const ADD_VAULT_CANDIDATE_IDS = 'ADD_VAULT_CANDIDATE_IDS';

export const SET_CANDIDATE_LIST_TYPE = 'SET_CANDIDATE_LIST_TYPE';

export const SET_BULK_SHORTLISTED_STATUS = 'SET_BULK_SHORTLISTED_STATUS';

export const SET_CANDIDATES_PUBLISHED_NOTIFICATION = 'SET_CANDIDATES_PUBLISHED_NOTIFICATION';

export const CLEAR_CANDIDATES_EXTRA_INFO = 'CLEAR_CANDIDATES_EXTRA_INFO';
export const SET_CANDIDATE_FAVOURITE_STATUS = 'SET_CANDIDATE_FAVOURITE_STATUS';
export const SET_CANDIDATE_JOB_GLOBAL_TAGS = 'SET_CANDIDATE_JOB_GLOBAL_TAGS';
export const SET_CANDIDATE_TAGS = 'SET_CANDIDATE_TAGS';
export const CREATE_CANDIDATE_TAG = 'CREATE_CANDIDATE_TAG';
export const CREATE_CANDIDATE_JOB_GLOBAL_TAG = 'CREATE_CANDIDATE_JOB_GLOBAL_TAG';
export const DELETE_CANDIDATE_TAG = 'DELETE_CANDIDATE_TAG';
export const DELETE_CANDIDATE_JOB_GLOBAL_TAG = 'DELETE_CANDIDATE_JOB_GLOBAL_TAG';
export const SET_CANDIDATE_ACTIVITY_LOGS = 'SET_CANDIDATE_ACTIVITY_LOGS';
export const CLEAR_CANDIDATE_ACTIVITY_LOGS = 'CLEAR_CANDIDATE_ACTIVITY_LOGS';
export const SET_CANDIDATE_MATCHING_JOBS = 'SET_CANDIDATE_MATCHING_JOBS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_CANDIDATE_RECOMMENDATION_SOURCE = 'SET_CANDIDATE_RECOMMENDATION_SOURCE';
export const SET_CANDIDATE_DOWNLOADED_DETAILS = 'SET_CANDIDATE_DOWNLOADED_DETAILS';
export const SET_CANDIDATE_ID = 'SET_CANDIDATE_ID';
export const SET_IS_QUICKSEARCH_CANDIDATE = 'SET_IS_QUICKSEARCH_CANDIDATE';
export const SET_BULK_CANDIDATE_VIEW_STATUS = 'SET_BULK_CANDIDATE_VIEW_STATUS';
export const SET_CANDIDATE_VIEW_STATUS_V2 = 'SET_CANDIDATE_VIEW_STATUS_V2';
export const SET_CANDIDATE_JOB_MATCHING_LIST_SEARCH_TERM = 'SET_CANDIDATE_JOB_MATCHING_LIST_SEARCH_TERM';
export const SET_CANDIDATE_JOB_MATCHING_FILTERS = 'SET_CANDIDATE_JOB_MATCHING_FILTERS';
export const SET_FILTER_CONTEXT_ID = 'SET_FILTER_CONTEXT_ID';
export const SET_SCORING_STATUS_FOR_APPLIED_BUCKET = 'SET_SCORING_STATUS_FOR_APPLIED_BUCKET';
export const SET_FAVOURITE_CANDIDATE_API_STATUS = 'SET_FAVOURITE_CANDIDATE_API_STATUS';
export const SET_CANDIDATE_PUBLISH_API_STATUS = 'SET_CANDIDATE_PUBLISH_API_STATUS';
export const REMOVE_CANDIDATE_STATUS = 'REMOVE_CANDIDATE_STATUS';
export const SET_REMOVE_FAVOURITE_CANDIDATE_API_STATUS = 'SET_REMOVE_FAVOURITE_CANDIDATE_API_STATUS';
export const SET_SMART_AGENTS_CANDIDATE_COUNT = 'SET_SMART_AGENTS_CANDIDATE_COUNT';
export const SET_SOURCING_NOTIFICATION = 'SET_SOURCING_NOTIFICATION';

export const setCandidates = candidates => ({
  type: SET_CANDIDATES,
  payload: {
    candidates,
  },
});

export const setCandidateRecommendationSource = source => ({
  type: SET_CANDIDATE_RECOMMENDATION_SOURCE,
  payload: source,
});

export const setFilterContextId = payload => ({
  type: SET_FILTER_CONTEXT_ID,
  payload,
});

export const setCandidateFavouriteStatus = payload => {
  return {
    type: SET_CANDIDATE_FAVOURITE_STATUS,
    payload,
  };
};

export const updateUnlockCandidate = candidate => ({
  type: UPDATE_UNLOCKED_CANDIDATE,
  payload: {
    candidate,
  },
});

export const setCandidateAllDetails = candidateDetails => ({
  type: SET_CANDIDATE_ALL_DETAILS,
  payload: {
    details: candidateDetails,
  },
});

export const setCandidateDownloadedDetails = (candidateId, IsDownloaded, IsUnlocked, jobId) => ({
  type: SET_CANDIDATE_DOWNLOADED_DETAILS,
  payload: {
    candidateId,
    IsDownloaded,
    IsUnlocked,
    jobId,
  },
});

export const setBulkFetchContactStatus = (candidateIds, fetchContactStatus) => ({
  type: SET_BULK_FETCH_CONTACT_STATUS,
  payload: {
    candidateIds,
    fetchContactStatus,
  },
});

export const setFetchContactStatus = (candidateId, fetchContactStatus, jobId) => ({
  type: SET_FETCH_CONTACT_STATUS,
  payload: {
    candidateId,
    fetchContactStatus,
    jobId,
  },
});

export const setCandidateRejectFlag = (candidateId, size) => ({
  type: SET_CANDIDATE_REJECT_FLAG,
  payload: {
    candidateId,
    size,
  },
});

export const setCandidateNotes = (candidateId, notes) => ({
  type: SET_CANDIDATE_NOTES,
  payload: {
    candidateId,
    notes,
  },
});

export const setCandidateJobGlobalTags = payload => ({
  type: SET_CANDIDATE_JOB_GLOBAL_TAGS,
  payload,
});

export const setFetchCandidateJobGlobalTagsApiStatus = status => {
  const apiName = 'fetchCandidateJobGlobalTagsApiStatus';
  return setApiStatus({ apiName, status });
};

export const setFetchSendMessageApiStatus = status => {
  const apiName = 'setFetchSendMessageApiStatus';
  return setApiStatus({ apiName, status });
};

export const setCandidateTags = payload => {
  return {
    type: SET_CANDIDATE_TAGS,
    payload,
  };
};

export const setFetchCandidateGlobalTagsApiStatus = status => {
  const apiName = 'fetchCandidateGlobalTagsApiStatus';
  return setApiStatus({ apiName, status });
};

export const createCandidateTag = payload => {
  return {
    type: CREATE_CANDIDATE_TAG,
    payload,
  };
};

export const removeCandidateTag = payload => {
  return {
    type: DELETE_CANDIDATE_TAG,
    payload,
  };
};

export const setCandidateActivityLogs = payload => {
  return {
    type: SET_CANDIDATE_ACTIVITY_LOGS,
    payload,
  };
};

export const clearCandidateActivityLogs = payload => {
  return {
    type: CLEAR_CANDIDATE_ACTIVITY_LOGS,
    payload,
  };
};

export const setInitialFetchActivityLogsApiStatus = status => {
  const apiName = 'initialFetchActivityLogsApiStatus';
  return setApiStatus({ apiName, status });
};

export const createCandidateJobGlobalTag = payload => {
  return {
    type: CREATE_CANDIDATE_JOB_GLOBAL_TAG,
    payload,
  };
};

export const removeCandidateJobGlobalTag = payload => {
  return {
    type: DELETE_CANDIDATE_JOB_GLOBAL_TAG,
    payload,
  };
};

export const setCandidateNotesCount = (candidateId, candidateNotesCount) => ({
  type: SET_CANDIDATE_NOTES_COUNT,
  payload: {
    candidateId,
    candidateNotesCount,
  },
});

export const setBulkCandidateNotes = (candidateIds, notesByCandidateId) => ({
  type: SET_BULK_CANDIDATE_NOTES,
  payload: {
    candidateIds,
    notesByCandidateId,
  },
});

export const setCallNotes = (candidateId, notes) => ({
  type: SET_CALL_NOTES,
  payload: {
    notes,
    candidateId,
  },
});

export const setCallNotesCount = (candidateId, callNotesCount) => ({
  type: SET_CALL_NOTES_COUNT,
  payload: {
    candidateId,
    callNotesCount,
  },
});

export const setBulkCallNotesUsingCandidateIds = (candidateIds, notesByCandidateId = {}) => ({
  type: SET_BULK_CALL_NOTES_USING_CANDIDATE_ID,
  payload: {
    candidateIds,
    notesByCandidateId,
  },
});

export const setCandidatePersonId = (candidateId, personId, jobId) => ({
  type: SET_CANDIDATE_PERSON_ID,
  payload: {
    personId,
    candidateId,
    jobId,
  },
});

export const setCandidateConversationId = (candidateId, conversationId) => ({
  type: SET_CANDIDATE_CONVERSATION_ID,
  payload: {
    candidateId,
    conversationId,
  },
});

export const setCandidateConnectIds = candidateConnectIds => ({
  type: SET_CANDIDATE_CONNECT_IDS,
  payload: {
    candidateConnectIds,
  },
});

export const setCandidateAllDetailsError = candidateDetails => ({
  type: SET_CANDIDATE_ALL_DETAILS_ERROR,
  payload: candidateDetails,
});

export const setCandidatePersonalityStatus = (candidateId, personalityStatus) => ({
  type: SET_CANDIDATE_PERSONALITY_STATUS,
  payload: {
    candidateId,
    personalityStatus,
  },
});
export const setCandidatePersonality = (candidateId, personality) => ({
  type: SET_CANDIDATE_PERSONALITY,
  payload: {
    candidateId,
    personality,
  },
});

export const setCandidateViewStatus = viewStatus => ({
  type: SET_CANDIDATE_VIEW_STATUS,
  payload: viewStatus,
});

export const addVaultCandidateIds = (vaultName, vaultCandidates, jobId) => ({
  type: ADD_VAULT_CANDIDATE_IDS,
  payload: {
    vaultCandidates,
    vaultName,
    jobId,
  },
});

export const setCandidateStatus = (
  candidateId,
  status,
  isQuickSearchCandidate,
  version,
  isPublishedCandidate,
  jobId = 1
) => ({
  type: SET_CANDIDATE_STATUS,
  payload: {
    candidateId,
    status: isPublishedCandidate ? null : status,
    isQuickSearchCandidate,
    version,
    publishedStatus: isPublishedCandidate ? status : null,
    jobId,
  },
});

export const setCandidateCount = count => ({
  type: SET_CANDIDATE_COUNT,
  payload: {
    count,
  },
});

export const setSampleCandidateCount = count => ({
  type: SET_SAMPLE_CANDIDATE_COUNT,
  payload: {
    count,
  },
});

export const setDilatedCandidateCount = count => ({
  type: SET_DILATED_CANDIDATE_COUNT,
  payload: {
    count,
  },
});

export const updateCandidateRecommendedInfo = (candidateId, recommendedInfo, jobId) => ({
  type: UPDATE_CANDIDATE_RECOMMENDED_INFO,
  payload: {
    candidateId,
    recommendedInfo,
    jobId,
  },
});

export const setCandidateListApiStatus = status => ({
  type: SET_CANDIDATE_DETAILS_API_STATUS,
  payload: status,
});

export const setCandidateAggregationsApiStatus = status => {
  const apiName = 'CandidateAggregationsApiStatus';
  return setApiStatus({ apiName, status });
};

export const setConnectStatusApiStatus = status => ({
  type: SET_CONNECT_STATUS_API_STATUS,
  payload: { status },
});

export const setFetchBulkContactsApiStatus = (jobId, status) => ({
  type: SET_FETCH_BULK_CONTACTS_API_STATUS,
  payload: {
    jobId,
    status,
  },
});

export const setCandidateAllDetailsApiStatus = status => ({
  type: SET_CANDIDATE_ALL_DETAILS_API_STATUS,
  payload: status,
});

export const setCandidateBookmarkApiStatus = status => {
  const apiName = 'candidateBookmarkApiStatus';
  return setApiStatus({ apiName, status });
};

export const setCandidateIntelApiStatus = candidateIntelApiStatus => ({
  type: SET_CANDIDATE_INTEL_API_STATUS,
  payload: { candidateIntelApiStatus },
});

export const setCandidateListError = error => ({
  type: SET_CANDIDATE_LIST_ERROR,
  payload: {
    error,
  },
});

export const setCandidateAggregations = candidateAggregations => ({
  type: SET_CANDIDATES_AGGREGATIONS,
  payload: candidateAggregations,
});

export const setSourcingNotification = jobId => ({
  type: SET_SOURCING_NOTIFICATION,
  payload: {
    jobId: jobId.toString(),
  },
});

export const setBulkShortlistedNotification = jobId => ({
  type: SET_BULK_SHORTLISTED_STATUS,
  payload: {
    jobId: jobId.toString(),
  },
});

export const setCandidatesPublishedNotification = jobId => ({
  type: SET_CANDIDATES_PUBLISHED_NOTIFICATION,
  payload: {
    jobId: jobId.toString(),
  },
});

export const setCandidateIntel = (candidateId, intel) => ({
  type: SET_CANDIDATE_INTEL,
  payload: {
    candidateId,
    intel,
  },
});

export const resetCandidateJobs = () => ({
  type: RESET_CANDIDATE_JOBS,
});

export const setRecommendedStatusChangedNotification = jobId => ({
  type: SET_RECOMMENDED_STATUS_CHANGED_NOTIFICATION,
  payload: {
    jobId,
  },
});

export const setFetchTryNowCanidateApiStatus = status => ({
  type: SET_FETCH_TRY_NOW_CANDIDATE_API_STATUS,
  payload: status,
});

export const setFetchTryNowCanidateDetailsApiStatus = status => ({
  type: SET_FETCH_TRY_NOW_CANDIDATE_DETAILS_API_STATUS,
  payload: status,
});

export const setFetchTryNowCanidateDetails = candidateDetails => ({
  type: SET_FETCH_TRY_NOW_CANDIDATE_DETAILS,
  payload: candidateDetails,
});

export const setCandidateConnectionStatusByCandidateId = (candidateId, status) => ({
  type: SET_CANDIDATE_CONNECTION_STATUS_USING_CANDIDATE_ID,
  payload: {
    candidateId,
    status,
  },
});

export const setCandidateSpecificationsApiStatus = status => ({
  type: SET_CANDIDATE_SPECIFICATIONS_API_STATUS,
  payload: status,
});

export const fetchCandidateSpecificationsData = candidateSpecifications => ({
  type: FETCH_CANDIDATE_SPECIFICATIONS,
  payload: candidateSpecifications,
});

function setBulkShortlistApiStatus(apiName, status) {
  return setApiStatus({ apiName, status });
}

function setCandidateListType(candidateListType) {
  return {
    type: SET_CANDIDATE_LIST_TYPE,
    payload: candidateListType,
  };
}

const clearCandidatesExtraInfo = ({ jobId }) => ({
  type: CLEAR_CANDIDATES_EXTRA_INFO,
  payload: { jobId },
});

export const setFetchCandidateMatchingJobsApiStatus = status => {
  const apiName = 'candidateMatchingJobsApiStatus';
  return setApiStatus({ apiName, status });
};

export const setCandidateMatchingJobs = matchingJobs => {
  return {
    type: SET_CANDIDATE_MATCHING_JOBS,
    payload: matchingJobs,
  };
};
export const setCandidateMatchingJobsSearchTerm = searchTerm => {
  return {
    type: SET_CANDIDATE_JOB_MATCHING_LIST_SEARCH_TERM,
    payload: searchTerm,
  };
};
export const setCandidateJobMatchingJobFilters = (key, value) => {
  return {
    type: SET_CANDIDATE_JOB_MATCHING_FILTERS,
    payload: { key, value },
  };
};

export const setNotification = notification => {
  return {
    type: SET_NOTIFICATION,
    payload: notification,
  };
};

export const setCandidateId = candidateId => {
  return {
    type: SET_CANDIDATE_ID,
    payload: { candidateId },
  };
};

export const setIsQuickSearchCandidate = ({ candidateId, isQuickSearchCandidate }) => {
  return {
    type: SET_IS_QUICKSEARCH_CANDIDATE,
    payload: { candidateId, isQuickSearchCandidate },
  };
};

export const setBulkCandidateViewStatus = (candidateIds, viewStatusByCandidateId) => {
  return {
    type: SET_BULK_CANDIDATE_VIEW_STATUS,
    payload: {
      candidateIds,
      viewStatusByCandidateId,
    },
  };
};
export const setCandidateViewStatusV2 = (candidateId, viewStatusByCandidateId) => {
  return {
    type: SET_CANDIDATE_VIEW_STATUS_V2,
    payload: {
      candidateId,
      viewStatusByCandidateId,
    },
  };
};

export const setScoringStatus = (jobId, status, source = undefined) => {
  return {
    type: SET_SCORING_STATUS_FOR_APPLIED_BUCKET,
    payload: { jobId, status, source },
  };
};

export const setCandidateViewedApiStatus = status => {
  const apiName = 'candidateViewedApiStatus';
  return setApiStatus({ apiName, status });
};

export const setFavouriteCandidateApiStatus = status => ({
  type: SET_FAVOURITE_CANDIDATE_API_STATUS,
  payload: status,
});

export const setCandidatePublishApiStatus = status => ({
  type: SET_CANDIDATE_PUBLISH_API_STATUS,
  payload: status,
});

export const setRemoveFavouriteCandidate = ({ aryaCandidateIds }) => ({
  type: REMOVE_CANDIDATE_STATUS,
  payload: { aryaCandidateIds },
});
export const setRemoveFavouriteCandidateApiStatus = status => ({
  type: SET_REMOVE_FAVOURITE_CANDIDATE_API_STATUS,
  payload: status,
});
export const setSmartAgentsCandidateCount = data => ({
  type: SET_SMART_AGENTS_CANDIDATE_COUNT,
  payload: data,
});

export const setSmartAgentCandidateNotification = jobId => ({
  type: SET_SMART_AGENT_CANDIDATE_NOTIFICATION,
  payload: {
    jobId: jobId.toString(),
  },
});

export { clearCandidatesExtraInfo, setBulkShortlistApiStatus, setCandidateListType };
