import React, { Component } from 'react';
import { LocaleProvider } from 'antd';
import { addLocaleData, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Authenticator } from '@aws-amplify/ui-react';
import { getCurrentUser } from 'aws-amplify/auth';
import _ from 'lodash';
import queryString from 'query-string';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
// import Signup from './Components/Signup/Signup.tsx';
import AppToastNotificationContainer from './Containers/AppNotification/AppToastNotification';
import SsoLoginContainer from './Containers/SsoLogin/SsoLoginContainer';
import UserVerification from './Containers/UserVerification/UserVerification';
import MagicSignIn from './Containers/MagicSignIn/MagicSignIn';
import App from './App';
import * as ConfigActions from './Actions/ConfigActions';
import config from './Config/Config';
import { configureCognito } from './Cognito/Configure';
import AuthenticationRedirect from './Components/Auth/AuthenticationRedirect';
import LogoutRedirect from './Components/Auth/LogoutRedirect';

configureCognito();

const mapDispatchToProps = {
  fetchWhiteLabelInfo: ConfigActions.fetchWhiteLabelInfo,
};

class SsoRoutes extends Component {
  state = {};

  componentDidMount() {
    const { fetchWhiteLabelInfo, history } = this.props;
    const { location } = history;
    const { search } = location;
    const { isSessionClear } = queryString.parse(search);
    if (isSessionClear === 'true') {
      localStorage.clear();
      history.push('/');
      return;
    }
    let client = localStorage.getItem('Client');
    if (!client) {
      client = _.find(config.whiteLabelClients, currentClient =>
        window.location.href.toLowerCase().includes(currentClient.toLowerCase())
      );
    }
    getCurrentUser().then(() => {
      if (client) {
        localStorage.setItem('Client', client);
        fetchWhiteLabelInfo(client);
      }
    });
  }

  render() {
    const { appLocale } = window;
    addLocaleData(appLocale.data);

    return (
      <Authenticator.Provider>
        <LocaleProvider locale={appLocale.antd}>
          <IntlProvider locale={appLocale.locale} messages={appLocale.messages}>
            <Router>
              <>
                <AppToastNotificationContainer />
                <Switch>
                  {_.get(config, ['sso', 'providers'], []).map(provider => {
                    return (
                      <Route
                        exact
                        key={provider.path}
                        path={provider.path}
                        render={routeProps => <SsoLoginContainer {...routeProps} identityProvider={provider} />}
                      />
                    );
                  })}
                  <Route
                    exact
                    path="/sso/:client"
                    render={routeProps => {
                      const client = routeProps.match.params.client.toLowerCase();
                      return (
                        <SsoLoginContainer
                          {...routeProps}
                          identityProvider={{
                            path: `/sso/${client}`,
                            identifier: client,
                          }}
                        />
                      );
                    }}
                  />
                  <Route path="/magiclink" component={MagicSignIn} />
                  <Route path="/verification" component={UserVerification} />
                  <Route path="/accounts/setsid" component={AuthenticationRedirect} />
                  <Route path="/logout" component={LogoutRedirect} />
                  <Route component={App} />
                </Switch>
              </>
            </Router>
          </IntlProvider>
        </LocaleProvider>
      </Authenticator.Provider>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(SsoRoutes));
