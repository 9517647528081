import { Icon } from 'antd';
import * as React from 'react';

const SmartAgentCandidateSvg = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
    <circle cx="8.5" cy="7" r="4"></circle>
    <polyline points="17 11 19 13 23 9"></polyline>
  </svg>
);
const SmartAgentCandidateIcon = props => (
  <Icon component={customProps => <SmartAgentCandidateSvg {...props} {...customProps} />} {...props} />
);
export default SmartAgentCandidateIcon;
