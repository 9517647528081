import { defineMessages } from 'react-intl';

const messages = defineMessages({
  textConsentMessage: {
    id: 'Templates.Text.consentText',
    defaultMessage:
      `Please reply YES if you consent, NO if you’re not interested, or STOP if you no longer wish to receive texts about {{Organization.Name}} job opportunities.`,
  },
});

export default messages;
