import React from 'react';
import { Badge, Button, Icon } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import styles from './JobLevelCreditsExhaustNotification.module.scss';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';

export default function JobLevelCreditsExhaustNotification(props) {
  const { notification, onClick } = props;
  const { Payload: payload, UpdatedTime: updatedTime, UnreadCount: unreadCount, Type: type } = notification;
  const { BasicJobInfo: basicJobInfo, RequestByName: requestByName } = payload;
  const { JobId, JobTitle } = basicJobInfo?.[0] || {};

  return JobId ? (
    <div className={styles.notification} role="presentation" onClick={() => onClick(notification)}>
      {type === 'JobUsageCreditRequested' ? (
        <div className="icon">
          <Icon style={{ fontSize: 40, color: '#13C26B' }} type="clock-circle" />
        </div>
      ) : (
        <div className={styles.jobLevelExhausationImageWrapper}>
          <div className={styles.closeIcon}>
            <Icon type="close-circle" theme="filled" />
          </div>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.bold}>
          {type === 'JobUsageCreditRequested'
            ? `${requestByName} has requested credit for ${JobTitle} | ${JobId}`
            : ' Low Job Credits!'}
        </div>
        {type !== 'JobUsageCreditRequested' && <span>Please contact your admin to add credits to your account.</span>}
        {type !== 'JobUsageCreditRequested' && (
          <Button size="small" className={styles.viewJobButton}>
            <FormattedMessage {...messages.viewJobLabel} />
          </Button>
        )}
        <div className={styles.time}>{moment.utc(updatedTime).local().fromNow()}</div>
      </div>
      <div className={styles.viewSymbol}>
        <Badge dot={unreadCount > 0} />
      </div>
    </div>
  ) : null;
}
