import React from 'react';
import _ from 'lodash';
import NotesTab from './NotesTab';
import styles from './NotesTab.module.scss';
import NotesTabMinimized from './NotesTabMinimized';

const NotesTabContainer = props => {
  const {
    candidates,
    createCandidateNote,
    candidatesNotesList,
    setCandidateNoteTab,
    jobId,
    activeTab,
    openInNewTab,
    vaults,
    candidateContext,
    candidateNoteCreateApiStatus,
  } = props;
  return (
    <div className={styles.notesPopupContainer}>
      {candidates
        .filter(candidate => !_.isEmpty(candidate))
        .map(candidate => (
          <div key={candidate.Id}>
            {candidatesNotesList[candidate.Id] && (
              <NotesTab
                visible={candidatesNotesList[candidate.Id].visible}
                minimized={candidatesNotesList[candidate.Id].minimized}
                note={candidatesNotesList[candidate.Id].note}
                candidateId={candidate.Id}
                setCandidateNoteTab={setCandidateNoteTab}
                candidatesNotesList={candidatesNotesList}
                createCandidateNote={createCandidateNote}
                jobId={jobId}
                activeTab={activeTab}
                openInNewTab={openInNewTab}
                vaults={vaults}
                candidate={candidate}
                candidateNoteCreateApiStatus={candidateNoteCreateApiStatus}
                candidateContext={candidateContext}
              />
            )}
          </div>
        ))}
      {Object.keys(candidatesNotesList).length > 0 && (
        <div className={styles.notesMinimizedFooter}>
          <div className={styles.notesMinimizedContainer}>
            <div className={styles.horizontalScroll}>
              <NotesTabMinimized
                setCandidateNoteTab={setCandidateNoteTab}
                candidates={candidates}
                candidatesNotesList={candidatesNotesList}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotesTabContainer;
