import React from 'react';
import { Form } from 'antd';
import { injectIntl } from 'react-intl';
import RichTextEditor from '../../Components/Editor/RichTextEditor';
import NotesContext from './NotesContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function NotesEditorField(props) {
  const { form, onEditorContentChange, editorBody, invalidDescriptionErrorMessage } = React.useContext(NotesContext);
  const { intl, editorClassName, placeholder } = props;
  return (
    <Form.Item
      validateStatus={
        invalidDescriptionErrorMessage
          ? intl.formatMessage({ ...message.errorLabel })
          : intl.formatMessage({ ...message.successLabel })
      }
      help={invalidDescriptionErrorMessage}
    >
      {form.getFieldDecorator(
        'NotesEditor',
        {}
      )(
        <RichTextEditor
          showToolbar
          placeholder={placeholder}
          editorClassName={editorClassName}
          editorContent={{ htmlContent: editorBody }}
          onChange={onEditorContentChange}
          imageSupport={false}
        />
      )}
    </Form.Item>
  );
}

export default injectIntl(Form.create()(NotesEditorField));
