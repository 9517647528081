import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import Config from '../Config/Config';
import {
  InternalIcon,
  CareerBuilderIcon,
  DiceIcon,
  IndeedIcon,
  NexxtIcon,
  SocialIcon,
  MonsterIcon,
  MonsterIndiaIcon,
  NaukriIcon,
  CVLibraryIcon,
  ClearanceJobsIcon,
  ResumeLibraryIcon,
  EFinancialIcon,
  CWJobsIcon,
  TotalJobsIcon,
  AryaActiveIcon,
  AryaPassiveIcon,
  JobsiteIcon,
  AppcastIcon,
  InternalIconForDownloadedCandidates,
  LinkedInCircularIcon,
} from '../Icons/AryaIcons';
import messages from '../Containers/ManualSearchV2/ManualSearchMessages';

import { getConfiguration } from '../Components/Collaborators/Collaborators';

const portalToFilename = {
  CAREERBUILDER: 'Careerbuilder.svg',
  CAREERBUILDEREDGE: 'Careerbuilder.svg',
  DICE: 'Dice.svg',
  INDEED: 'Indeed.svg',
  NEXXT: 'next.svg',
  EFINANCIAL: 'EFinancial.png',
  SOCIAL: 'Social.svg',
  INTERNAL: 'Internal.svg',
  MONSTER: 'Monster.svg',
  RESUMELIBRARY: 'ResumeLibrary.svg',
  CVLIBRARY: 'CVLibrary.svg',
  CWJOBS: 'cwjobs.svg',
  JOBSITE: 'jobsite.svg',
  TOTALJOBS: 'total_job.svg',
  ARYAACTIVE: 'aryaactive.svg',
  ARYAPASSIVE: 'aryapassive.svg',
};

const portalToIcon = (name = '', style) => {
  const client = localStorage.getItem('Client');
  const className = `${name.toLowerCase()}-icon`;
  switch (name) {
    case 'CAREERBUILDER':
      return <CareerBuilderIcon style={style} tag="Classic" className={className} />;
    case 'CAREERBUILDEREDGE':
      return <CareerBuilderIcon style={style} tag="Edge" className={className} />;
    case 'CWJOBS':
      return <CWJobsIcon style={style} className={className} />;
    case 'JOBSITE':
      return <JobsiteIcon style={style} className={className} />;
    case 'TOTALJOBS':
      return <TotalJobsIcon style={style} className={className} />;
    case 'DICE':
      return <DiceIcon style={style} className={className} />;
    case 'INDEED':
      return <IndeedIcon style={style} className={className} />;
    case 'NEXXT':
      return <NexxtIcon style={style} className={className} />;
    case 'EFINANCIAL':
      return <EFinancialIcon style={style} className={className} />;
    case 'SOCIAL':
      return <SocialIcon style={style} className={className} />;
    case 'INTERNAL':
      return <InternalIcon style={style} className={className} />;
    case 'MONSTER':
      return <MonsterIcon style={style} className={className} />;
    case 'MONSTERINDIA':
      return <MonsterIndiaIcon style={style} tag="India" className={className} />;
    case 'NAUKRI':
      return <NaukriIcon style={style} className={className} />;
    case 'RESUMELIBRARY':
      return <ResumeLibraryIcon style={style} className={className} />;
    case 'CVLIBRARY':
      return <CVLibraryIcon style={style} className={className} />;
    case 'CLEARANCEJOBS':
      return <ClearanceJobsIcon style={style} className={className} />;
    case 'ACTIVE':
      if (client) {
        return (
          <img
            src={`${Config.urls.getWhiteLabelResourceUrl(client)}/activeicon.png`}
            alt="whitelabel active icon"
            style={{ ...style, margin: 'auto' }}
          />
        );
      }
      return <AryaActiveIcon style={style} className={className} />;
    case 'PASSIVE':
      if (client) {
        return (
          <img
            src={`${Config.urls.getWhiteLabelResourceUrl(client)}/passiveicon.png`}
            alt="whitelabel passive icon"
            style={{ ...style, margin: 'auto' }}
          />
        );
      }
      return <AryaPassiveIcon style={style} className={className} />;
    case 'APPCAST':
      return <AppcastIcon style={style} className={className} />;
    case 'ARYAVAULT':
      return <AryaActiveIcon style={style} className={className} />;
    case 'INTERNALFORDOWNLOADEDCANDIDATES':
      return <InternalIconForDownloadedCandidates style={style} className={className} />;
    case 'LINKEDINADVANCE':
      return <LinkedInCircularIcon style={style} className={className} />;
    default:
      return <SocialIcon style={style} className={className} />;
  }
};

function displaySecondaryInternalSourceIcon(
  candidateSourceName,
  isSRPulseUser,
  ResumeOrCvUnlocked,
  isPulseUser,
  candidateDetails = {}
) {
  const lowerCasedCandidateSourceName = candidateSourceName?.toLowerCase();
  let isCandidateIdInSourceUrl;
  if (lowerCasedCandidateSourceName === 'linkedinadvance') {
    isCandidateIdInSourceUrl = candidateDetails.Sources?.some(source => {
      return source.Url?.includes(source.CandidateId);
    });
  }

  const isDownloadedJobBoardCandidate =
    candidateDetails.IsDownloaded &&
    !['social', 'internal', 'indeed', 'passive'].includes(lowerCasedCandidateSourceName) &&
    ResumeOrCvUnlocked &&
    !isCandidateIdInSourceUrl;

  return !isPulseUser ? isDownloadedJobBoardCandidate : isDownloadedJobBoardCandidate && isSRPulseUser;
}

function getSourceName(source = {}, revealActiveChannelSourceName = true) {
  if (typeof source === 'string') return source;
  let sourceName;
  if ((source.Portal || '').toLowerCase() === 'internal') sourceName = source.Name || source.Portal || source.Group;
  else if (revealActiveChannelSourceName) sourceName = source.Portal || source.Group;
  else sourceName = source.Group || source.Portal;
  return sourceName;
}

const getPortalName = (sources, revealActiveChannelSourceName) => {
  const primarySource = sources?.find(source => source.Type && source.Type.toLowerCase() === 'primary');
  if (revealActiveChannelSourceName && primarySource?.Portal.toLowerCase() === 'social')
    return primarySource.Group || primarySource.Portal;
  if (revealActiveChannelSourceName) return primarySource && (primarySource.Portal || primarySource.Group);
  return primarySource && (primarySource.Group || primarySource.Portal);
};

function getSourceImage(source, iconStyle, isMultiAtsConfigured) {
  if (source.Portal === 'Internal' && source.Name && isMultiAtsConfigured) {
    const config = getConfiguration(source.Name);
    return (
      <InternalIcon
        style={{
          ...iconStyle,
          color: config.color,
        }}
      />
    );
  }
  const sourceName = source.Portal || source.Group;
  return portalToIcon(sourceName?.toUpperCase(), iconStyle);
}

function getAtsSource(sources = []) {
  return sources.find(
    source =>
      source.Portal &&
      source.Portal.toLowerCase() === 'Internal'.toLowerCase() &&
      source.Type &&
      source.Type.toLowerCase() === 'Primary'.toLowerCase() &&
      source.Name
  );
}

function getInternalSourceWithCandidateId(sources = []) {
  return sources.find(
    source =>
      source.Portal &&
      source.Portal.toLowerCase() === 'internal' &&
      source.CandidateId &&
      source.Type &&
      source.Type.toLowerCase() === 'mapped'
  );
}

function getPushedAtsSource(sources = []) {
  return sources.find(
    source =>
      source.Portal &&
      source.Portal.toLowerCase() === 'Internal'.toLowerCase() &&
      source.Name &&
      source.CandidateId !== undefined
  );
}

function getCandidateSourceName(source, revealActiveChannelSourceName) {
  return revealActiveChannelSourceName ? source.Portal || source.Group : source.Group || source.Portal;
}

function getCandidateStatus(sources = []) {
  const candidatePushedAtsSource = sources.find(
    source => source.Portal && source.Portal.toLowerCase() === 'internal' && source.CandidateId !== undefined
  );
  return _.get(candidatePushedAtsSource, ['Status'], null);
}

function getSourceDisplayName(
  source = {},
  userConfig,
  showVaultName,
  displayNameFor = {},
  whiteLabelInfo,
  revealActiveChannelSourceName = false
) {
  const AppName = _.get(whiteLabelInfo, ['AppName'], 'Arya');
  const { Name } = displayNameFor;
  let sourceName;
  if ((_.get(source, 'Portal') || '').toLowerCase() === 'internal' && showVaultName) {
    return getSourceName(source, revealActiveChannelSourceName);
  }
  if (Name && Name.toLowerCase() === 'candidateaggfilter') sourceName = (getSourceName(source) || '').toLowerCase();
  else sourceName = (getCandidateSourceName(source, revealActiveChannelSourceName) || '').toLowerCase();

  if (sourceName.toLowerCase() === 'social' && revealActiveChannelSourceName === true) sourceName = 'passive';

  const activeTabDisplayName = userConfig?.ActiveChannelAlias || `${AppName} Active`;

  switch (sourceName) {
    case 'active':
      return activeTabDisplayName;
    case 'passive':
      return `${AppName} Passive`;
    case 'careerbuilder':
      if (Name === 'quickSearchCandidateTabs') return 'CB Classic';
      if (Name === 'quickSearchForm') return 'Career_Builder (C)';
      if (Name === 'activationDialougeIcon') return 'Career Builder(C)';
      if (Name === 'candidateListItem') return 'CB';
      return 'Career Builder (Classic)';
    case 'careerbuilderedge':
      if (Name === 'quickSearchCandidateTabs') return 'CB Edge';
      if (Name === 'quickSearchForm') return 'Career_Builder (E)';
      if (Name === 'activationDialougeIcon') return 'Career Builder(E)';
      if (Name === 'candidateListItem') return 'CB Edge';
      return 'Career Builder (Edge)';
    case 'cvlibrary':
      return 'CV-Library';
    case 'cwjobs':
      return 'CWJobs';
    case 'dice':
      return 'Dice';
    case 'efinancial':
      return 'eFinancial';
    case 'indeed':
      return 'Indeed';
    case 'internal':
      return 'Internal';
    case 'jobsite':
      return 'Jobsite';
    case 'monster':
      return 'Monster';
    case 'nexxt':
      return 'Nexxt';
    case 'resumelibrary':
      return 'Resume-Library';
    case 'social':
      return 'Social';
    case 'totaljobs':
      return 'Totaljobs';
    case 'appcast':
      return 'Appcast';
    case 'clearancejobs':
      if (Name === 'quickSearchForm') return 'Clearance_Jobs';
      return 'ClearanceJobs';
    default:
      return getSourceName(source, revealActiveChannelSourceName);
  }
}

function getMaskedSourceFilters(sources) {
  const maskedSourceFilters = [];
  const activeSources = sources
    .filter(source => source.Portal.toLowerCase() !== 'social')
    .map(source => {
      return {
        value: source.Value,
        count: source.Count,
      };
    })
    .filter(source => source.value && source.value.toLowerCase() !== 'social');

  let activeSourcesCandidatesCount = 0;
  activeSources.forEach(source => {
    activeSourcesCandidatesCount += source.count;
  });

  const passiveSources = sources
    .map(source => {
      return {
        value: source.Portal,
        count: source.Count,
      };
    })
    .filter(source => source.value && source.value.toLowerCase() === 'social');

  let passiveSourcesCandidateCount = 0;
  passiveSources.forEach(source => {
    passiveSourcesCandidateCount += source.count;
  });

  // const internalSources = sources
  //   .map(source => {
  //     return { value: source.Portal, count: source.Count };
  //   })
  //   .filter(source => source.value && source.value.toLowerCase() === 'internal');

  // let internalSourcesCandidateCount = 0;
  // internalSources.forEach(source => {
  //   internalSourcesCandidateCount += source.count;
  // });

  if (activeSources && activeSources.length > 0) {
    maskedSourceFilters.push({
      value: 'Active',
      count: activeSourcesCandidatesCount,
    });
  }
  if (passiveSources && passiveSources.length > 0) {
    maskedSourceFilters.push({
      value: 'Passive',
      count: passiveSourcesCandidateCount,
    });
  }
  // if (internalSources && internalSources.length > 0) {
  //   aryaLiteSourceFilters.push({ value: 'Internal', count: internalSourcesCandidateCount });
  // }
  return maskedSourceFilters;
}

function getSourceFiltersFromMaskedFilters(aryaVersion, candAggSources, formSourceValues) {
  let sources = [];
  if (aryaVersion === 'Lite') {
    const activeSources = candAggSources
      .filter(source => !['Social'].includes(source.Portal))
      .map(source => source.Value);
    const passiveSources = candAggSources
      .filter(source => ['Social'].includes(source.Portal))
      .map(source => source.Value);
    // const internalSources = candAggSources
    //   .filter(source => ['Internal'].includes(source.Portal))
    //   .map(source => source.Value);
    (formSourceValues || []).forEach(source => {
      if (source === 'Active') {
        sources = sources.concat(activeSources);
      }
      if (source === 'Passive') {
        sources = sources.concat(passiveSources);
      }
      // if (source === 'Internal') {
      //   sources = sources.concat(internalSources);
      // }
    });
  } else {
    sources = formSourceValues || [];
  }
  return sources;
}

function getCandidatePrimarySource(sources = []) {
  return sources.find(source => _.get(source, 'Type', '').toLowerCase() === 'primary');
}

function getCandidateSource(candidate) {
  return candidate.Source || getCandidatePrimarySource(candidate.Sources);
}

function getSourceConfigId(config, source) {
  const currentSourceIndex = _.get(config, ['Sources'], []).findIndex(
    sourceObject =>
      _.get(sourceObject, ['Source', 'Portal']) === source.Portal ||
      _.get(sourceObject, ['Group', 'Portal']) === source.Portal
  );
  return _.get(config, ['Sources', currentSourceIndex, 'Id'], null);
}

function getCandidateCountBySources(sourceList) {
  const countsBySource = {};
  sourceList.forEach(source => {
    const sourceName = getSourceName(source).toLowerCase();
    if (countsBySource[sourceName]) {
      countsBySource[sourceName] += _.get(source, 'Count', 0);
    } else {
      countsBySource[sourceName] = _.get(source, 'Count', 0);
    }
  });
  return countsBySource;
}

function getIsSourceCredentialsExists({ source, portalsConfig, jobCountryCode }) {
  const currentSourceCredentials = portalsConfig?.[source?.Portal]?.PortalCredentials;
  return (
    !currentSourceCredentials?.length ||
    currentSourceCredentials.find(
      credential => credential.CountryCode === jobCountryCode || credential.CountryCode === 'All'
    )
  );
}

function getIsSourceAuthorized({ source, sourcesConfig, portalsConfig, jobCountryCode }) {
  const currentSourceConfig = sourcesConfig?.find(
    sourceConfig =>
      (source?.Portal && sourceConfig.Source?.Portal?.toLowerCase() === source.Portal?.toLowerCase()) ||
      (source?.Group && sourceConfig.Source?.Group?.toLowerCase() === source.Group?.toLowerCase())
  );

  return currentSourceConfig?.IsAuthorized && getIsSourceCredentialsExists({ source, portalsConfig, jobCountryCode });
}

function getOriginalSource(sources = []) {
  return sources.find(
    source => source.Portal && source.CandidateId && source.Type && source.Type.toLowerCase() === 'original'
  );
}

const AryaPortals = ['social', 'internal', 'aryavault'];

const JobboardsWithoutCandidateContact = ['indeed'];

function getCandidateApplicationInfo(applicationInfo = {}) {
  const newApplicationInfo = _.cloneDeep(applicationInfo);
  if (applicationInfo?.Source) {
    newApplicationInfo.AppliedSource = applicationInfo?.Source;
    delete newApplicationInfo.Source;
  }
  if (newApplicationInfo) {
    return newApplicationInfo;
  }
  return {};
}

function mergeCandidateData({ candidatesObject, isQuickSearchCandidate }) {
  return candidatesObject.map(c => {
    return {
      ...c.Metadata,
      ...c.Recommended,
      ...(c.ApplicationInfo ? getCandidateApplicationInfo(c.ApplicationInfo) : {}),
      ...(c.PublishedInfo ? c.PublishedInfo : {}),
      ...(c.FilterSearchResult ?? {}),
      IsQuickSearchCandidate: isQuickSearchCandidate,
    };
  });
}

function getSourceListItemSource(source) {
  let sourceListItemSource = {
    Name: source.Name,
    Type: source.Type,
  };
  if (source.Portal) {
    sourceListItemSource = { ...sourceListItemSource, Portal: source.Portal };
  } else if (source.Group) {
    sourceListItemSource = { ...sourceListItemSource, Group: source.Group };
  }
  return sourceListItemSource;
}

const getSampleSearchInitialSources = ({ sources, userConfig, portalsConfig, currentJobDetails }) => {
  const jobCountryCode = currentJobDetails?.CountryCode;
  const sourcesConfig = userConfig?.Sources;
  return sources
    .filter(source => {
      return getIsSourceAuthorized({
        source,
        sourcesConfig,
        portalsConfig,
        jobCountryCode,
      });
    })
    .map(source => getSourceName(source));
};

const getSampleSearchActiveSource = ({ userConfig, sources, sourceNamesSelected }) => {
  return userConfig?.SubscriptionType?.toLowerCase() === 'lite'
    ? sources.find(sourceObject => getSourceName(sourceObject) === 'Social')
    : sources.find(sourceObject => getSourceName(sourceObject) === sourceNamesSelected[0]);
};

const getSampleSearchSources = ({ activeSource, userConfig }) => {
  return [
    {
      Name: activeSource?.Portal === 'Internal' && !userConfig.ShowVaultName ? undefined : activeSource?.Name,
      Type: activeSource?.Type,
      Portal: activeSource?.Portal,
      Group: activeSource?.Group,
    },
  ];
};
function getPortalIcon(type) {
  if (type?.toLowerCase() === 'arya passive') return <AryaPassiveIcon style={{ fontSize: '20px' }} />;
  if (type?.toLowerCase() === 'arya active') return <AryaActiveIcon style={{ fontSize: '20px' }} />;
  return null;
}

const getReorderedSources = (sources = [], isMatchedSource) => {
  const clonedSources = _.cloneDeep(sources);
  const filteredSourceIndex = clonedSources.findIndex(source => isMatchedSource(source));
  if (filteredSourceIndex !== -1) {
    const [firstSource] = clonedSources;
    clonedSources[0] = clonedSources[filteredSourceIndex];
    clonedSources[filteredSourceIndex] = firstSource;
  }
  return clonedSources;
};

const isSourcingDeactivated = (userConfiguredSources, jobConfiguredSources) =>
  !userConfiguredSources.some(source => {
    const sourceName = getSourceName(source).toLowerCase();
    return _.get(jobConfiguredSources, [sourceName, 'IsActivated']);
  });

export const getShowSourcingProgress = featureToggleList => {
  let showSourcingProgress = featureToggleList.SourcingProgress.IsEnabled;
  if (featureToggleList.AdvanceSearchV2.IsEnabled && !featureToggleList.AryaRanking.IsAllowed) {
    showSourcingProgress = false;
  }
  return showSourcingProgress;
};

export const removeKeysFromFilter = (obj, keysToRemove) => {
  const clonedObj = { ...obj };
  keysToRemove.map(props => delete clonedObj[props]);
  return clonedObj;
};

export {
  getSourceName,
  portalToIcon,
  getSourceImage,
  getPortalName,
  getAtsSource,
  getPushedAtsSource,
  getSourceDisplayName,
  portalToFilename,
  getInternalSourceWithCandidateId,
  getMaskedSourceFilters,
  getSourceFiltersFromMaskedFilters,
  getCandidateStatus,
  getCandidatePrimarySource,
  getCandidateSource,
  getCandidateSourceName,
  getSourceConfigId,
  AryaPortals,
  JobboardsWithoutCandidateContact,
  getCandidateCountBySources,
  getIsSourceCredentialsExists,
  getIsSourceAuthorized,
  getOriginalSource,
  mergeCandidateData,
  getCandidateApplicationInfo,
  getSourceListItemSource,
  getSampleSearchInitialSources,
  getSampleSearchActiveSource,
  getSampleSearchSources,
  getPortalIcon,
  getReorderedSources,
  isSourcingDeactivated,
  displaySecondaryInternalSourceIcon,
};
