import { Input, Form } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import NotesContext from './NotesContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

export const validateSubject = (rule, value, callback) => {
  if (value && value.trim().length > 1000) callback('Subject cannot exceed 1000 characters');
  else callback();
};

function NotesSubjectField(props) {
  const { form } = React.useContext(NotesContext);
  const { intl } = props;

  return (
    <Form.Item>
      {form.getFieldDecorator('NotesSubject', {
        rules: [{ validator: validateSubject }],
      })(<Input placeholder={intl.formatMessage({ ...message.subjectLineLabel })} />)}
    </Form.Item>
  );
}

export default injectIntl(Form.create()(NotesSubjectField));
export { NotesSubjectField as NotesSubjectFieldWithoutInjectIntl };
