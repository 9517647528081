export const RESET_CONVERSATION_MESSAGES = 'RESET_CONVERSATION_MESSAGES';

const setConversationPendingMessages = (conversationId, message) => ({
  type: 'SET_CONVERSATION_PENDING_MESSAGES',
  payload: {
    conversationId,
    message,
  },
});
export { setConversationPendingMessages };
