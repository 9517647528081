import React from 'react';
import { Icon } from 'antd';

export const JobUsageBudgetDismissedSVG = props => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="20" fill="#F5222D" fill-opacity="0.2" />
      <path
        d="M12.6973 21.5755C12.3691 22.0091 12.1875 22.5403 12.1875 23.0931C12.1875 23.97 12.6777 24.8001 13.4668 25.263C13.6699 25.3822 13.9012 25.4449 14.1367 25.4446H18.8203L18.7031 27.845C18.6758 28.4251 18.8809 28.9759 19.2793 29.3958C19.4748 29.6028 19.7107 29.7675 19.9724 29.8797C20.2341 29.9919 20.516 30.0492 20.8008 30.0482C21.8164 30.0482 22.7148 29.3646 22.9844 28.386L24.6621 22.3118H24.668V13.9525H15.4434C15.2637 13.9525 15.0879 13.9876 14.9258 14.0579C13.9961 14.4544 13.3965 15.3626 13.3965 16.3704C13.3965 16.6165 13.4316 16.8587 13.502 17.0931C13.1738 17.5267 12.9922 18.0579 12.9922 18.6107C12.9922 18.8568 13.0273 19.0989 13.0977 19.3333C12.7695 19.7669 12.5879 20.2982 12.5879 20.8509C12.5918 21.097 12.627 21.3411 12.6973 21.5755ZM27.8125 21.6868V14.5775C27.8125 14.2318 27.5332 13.9525 27.1875 13.9525H25.918V22.3118H27.1875C27.5332 22.3118 27.8125 22.0325 27.8125 21.6868Z"
        fill="#F5222D"
      />
    </svg>
  );
};

const JobUsageBudgetDismissed = props => <Icon component={JobUsageBudgetDismissedSVG} {...props} />;

export default JobUsageBudgetDismissed;
