import React from 'react';
import { Icon } from 'antd';
import * as GsmCharsetUtils from '@trt2/gsm-charset-utils';
import { FormattedMessage } from 'react-intl';
import { parseTextFromHtml } from '../../Utils/DomParserUtils';
import ConnectMessageStatusOptions from '../../Data/ConnectMessageStatus/ConnectMessageStatusOptions.json';
import MessageDeliveredIcon from '../../Icons/MessageDeliveredIcon';
import MessageSentIcon from '../../Icons/MessageSentIcon';
import styles from '../../Containers/MessageWindow/MessageWindow.module.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';
import store from '../../store';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';

const { onlyEmoji } = require('emoji-aware');

export function removeUnicodeQuotation(inputValue) {
  return inputValue?.replace(/[‘’`]/g, "'").replace(/[“”]/g, '"').replace(/ /g, ' '); // eslint-disable-line
}

export function messageCount(inputValue, isConsentMessage) {
  if (inputValue) {
    const nonHtmlString = parseTextFromHtml(inputValue);
    const shouldShowConsentMessageCount = nonHtmlString.length > 0 && isConsentMessage;
    const actualCharacterCount = shouldShowConsentMessageCount ? nonHtmlString?.length + 133 : nonHtmlString?.length;
    const charCount = inputValue ? actualCharacterCount : 0;
    const isUnicodeCharacterPresent = emojiFinder(inputValue).length > 0;
    const totalTextCount = isUnicodeCharacterPresent
      ? determineUnicodeCharactersMessageCount(charCount)
      : determineMessageCount(charCount);

    return <FormattedMessage {...message.characterCount} values={{charCount,totalTextCount}}/>;
  }
  const zero=0;
  return <FormattedMessage {...message.characterCountzero} values={{zero}}/>;
}

export function emojiFinder(inputValue) {
  return inputValue ? onlyEmoji(inputValue) : '';
}

export const getMessageDeliveryStatusInformation = (
  messageDeliveryStatusInformation,
  deliveryStatus,
  conversationRequestStatusReason
) => {
  const state = store.getState();
  const featureToggleList = getFeatureToggleList(state);
  const isMessageDeliveryStatusEnabled = featureToggleList.MessageDeliveryStatus.IsEnabled;
  if (!isMessageDeliveryStatusEnabled) return null;
  if (messageDeliveryStatusInformation) return ConnectMessageStatusOptions[messageDeliveryStatusInformation];
  if (conversationRequestStatusReason) return ConnectMessageStatusOptions[conversationRequestStatusReason];
  switch (deliveryStatus?.toLowerCase()) {
    case 'failed':
      return ConnectMessageStatusOptions.UnknownFailure;
    case 'pending':
      return ConnectMessageStatusOptions.NetworkSent;
    default:
      return null;
  }
};

export const getMessageDeliveryStatusIcon = (deliveryStatus, messageDeliveryStatusInformation, RequestStatus) => {
  const state = store.getState();
  const featureToggleList = getFeatureToggleList(state);
  const isMessageDeliveryStatusEnabled = featureToggleList.MessageDeliveryStatus.IsEnabled;
  if (!isMessageDeliveryStatusEnabled) {
    const updatedDeliveryStatus = RequestStatus === 'Success' && deliveryStatus === 'Pending' ? 'Sent' : deliveryStatus;
    return updatedDeliveryStatus;
  }
  switch (deliveryStatus?.toLowerCase()) {
    case 'delivered':
      return (
        <span>
          <MessageDeliveredIcon />
          {deliveryStatus}
        </span>
      );
    case 'failed':
      return (
        <span>
          <Icon type="exclamation-circle" /> {deliveryStatus}
        </span>
      );
    case 'pending':
    case 'scheduled':
      if (RequestStatus === 'Success')
        return (
          <span>
            <MessageSentIcon />
            Sent
          </span>
        );
      return (
        <span>
          <Icon type="clock-circle" style={{ color: '#ffcc00', fontSize: '12px' }} /> {deliveryStatus}
        </span>
      );
    case 'skipped':
      return 'Skipped';
    default:
      return <Icon type="clock-circle" style={{ color: '#ffcc00', fontSize: '12px' }} />;
  }
};

export const determineMessageCount = characterCount => {
  const messageRanges = [
    { maxCharacters: 160, messageCount: 1 },
    { maxCharacters: 306, messageCount: 2 },
    { maxCharacters: 459, messageCount: 3 },
    { maxCharacters: 612, messageCount: 4 },
    { maxCharacters: 765, messageCount: 5 },
    { maxCharacters: 918, messageCount: 6 },
    { maxCharacters: 1071, messageCount: 7 },
    { maxCharacters: 1224, messageCount: 8 },
  ];

  const range = messageRanges.find(range => characterCount <= range.maxCharacters);
  return range ? range.messageCount : messageRanges[messageRanges.length - 1].messageCount;
};

export const determineUnicodeCharactersMessageCount = characterCount => {
  const messageRanges = [
    { maxCharacters: 70, messageCount: 1 },
    { maxCharacters: 134, messageCount: 2 },
    { maxCharacters: 201, messageCount: 3 },
    { maxCharacters: 268, messageCount: 4 },
    { maxCharacters: 335, messageCount: 5 },
    { maxCharacters: 402, messageCount: 6 },
    { maxCharacters: 469, messageCount: 7 },
    { maxCharacters: 536, messageCount: 8 },
  ];

  const range = messageRanges.find(range => characterCount <= range.maxCharacters);
  return range ? range.messageCount : messageRanges[messageRanges.length - 1].messageCount;
};
export const clickSendTextMessageSpamWordsList = [
  'Free access',
  'Free consultation',
  'Free gift',
  'Free hosting',
  'Free info',
  'Free investment',
  'Free membership',
  'Free money',
  'Free preview',
  'Free quote',
  'Free trial',
  'Free $$$',
  'Best price',
  'Save up to',
  'Billions',
  'Cash',
  'Big buck',
  'Cheap',
  'Cash bonus',
  '$$$',
  'Pure profit',
  'Save big money',
  'Click here',
  'Buy now',
  'Guaranteed',
  'Must-have',
  'Limited quantity',
  'Act fast',
  'Quick',
  'Now or never',
  'Act immediately',
  'Save now',
  'One-time offer',
  'Time-limited',
  'Take action',
  'This won’t last',
  "This won't last",
  'Urgent',
  'What are you waiting for?',
  'While supplies last',
  'Once in a lifetime',
  'One time',
  'Act now',
  'Apply now',
  'Call now',
  'Get it now',
  'Do it today',
  'Limited time',
  'Don’t delete',
  "Don't delete",
  '4U',
  'You have been selected',
  'Get in touch now',
  'Instant cash',
  'Make money',
  'Earn',
  'Extra income',
  'Work from home',
  'Financial freedom',
  'Make thousands',
  'Additional income',
  'Be your own boss',
  'Consolidate debt',
  'Double your cash',
  'Refinance home',
  'Double your income',
  'Earn extra cash',
  'Earn money',
  'Extra cash',
  'Eliminate bad credit',
  'Expect to earn',
  'Fast cash',
  'Full refund',
  'Get out of debt',
  'Get paid',
  'Make money',
  'Million dollars',
  'Pennies a day',
  'Lottery',
  'Jackpot',
  'Cash prize',
  'Win big',
  'Win',
  'Hurry',
  'Only a few left',
  'Act quickly',
  'Limited availability',
  'Don’t miss out',
  "Don't miss out",
  'Almost gone',
  'While supplies last',
  'Time-sensitive',
  'Running out',
  'Quick response',
  'Fast-selling',
  'Popular demand',
  'Exclusive stock',
  'Act immediately',
  'Immediate delivery',
  'Almost sold out',
  'First come, first served',
  'Mega savings',
  'Unbelievable savings',
  'Best value ever',
  'Tremendous value',
  'Massive discount',
  'Unmatched value',
  'Tremendous savings',
  'Unbelievable value',
  'Insane savings',
  'Huge value',
  'Unmatched savings',
  'Tremendous discount',
  'Amazing value',
  'VIP access',
  'Exclusive VIP offer',
  'Members-only',
  'Elite access',
  'Exclusive membership',
  'Invitation-only',
  'Premium offer',
  'Limited VIP slots',
  'Exclusive privileges',
  'Trusted',
  'Reliable',
  'Authentic',
  'Proven',
  'Verified',
  'Certified',
  'Guaranteed quality',
  'Reputable',
  'Industry-leading',
  'Endorsed',
  'Accredited',
  'No catch',
  'No cost',
  'No credit check',
  'No fees',
  'No gimmick',
  'No hidden fees',
  'No interest',
  'No investment',
  'No obligation',
  'No purchase necessary',
  'No questions asked',
  'No strings attached',
  'Not junk',
  'This isn’t junk',
  "This isn't junk",
  'Not spam',
  'This isn’t spam',
  "This isn't spam",
  'See for yourself',
  'We hate spam',
  'Cancel any time',
  'No BS',
  'Top',
  'Increase sales',
  'Increase traffic',
  'Sample',
  'Avoid bankruptcy',
  'Become a member',
  'Click below',
  'Click here',
  'Information you requested',
  'Important information regarding',
  'Auto email remover',
  'Please read',
  'Bulk email',
  'Direct email',
  'Check or money order',
  'Congratulations',
  'Confidentiality',
  'Dear friend',
  'Buy direct',
  'Direct marketing',
  'Internet marketing',
  'Multi-level marketing',
  'Meet singles',
  'Mass email / SMS',
  'Obligation',
  'Passwords',
  'Requires initial investment',
  'Social security number',
  'Undisclosed',
  'Unsecured credit',
  'Unsolicited',
  'Unsecured debt',
  'Accept credit cards',
  'All new',
  'Ad',
  'As seen on',
  'Beneficiary',
  'Billing',
  'Cards accepted',
  'Credit card offers',
  'Claims',
  'Debt',
  'Compare rates',
  'In accordance with laws',
  'Loans',
  'Luxury',
  'Marketing Solution',
  'Online marketing',
  'Mortgage rates',
  'Name brand',
  'Message contains',
  'Opt-in',
  'Pre-approved',
  'Quote',
  'Removal',
  'Reserves the right',
  'Score',
  'Search engine',
  'Sent in compliance',
  'Subject to…',
  'Terms and conditions',
  'Trial',
  'Warranty',
  'Web traffic',
  'Work from home',
  'XXX',
  'Removes wrinkles',
  'Reverse aging',
  'You will not believe your eyes',
  'Bargain',
  'Guaranteed',
  'Miracle',
  'Risk-free',
  'Instant',
  'Cures',
  'Certified',
  'Fantastic',
  '$$$',
  'Cure baldness',
  '#1',
  '100% more',
  '100% free',
  '100% satisfied',
  'Incredible deal',
  'Promise',
  'Satisfaction guaranteed',
  'Exclusive deal',
  'Winning',
  'You are a winner',
  'Lifetime',
  'Lose weight',
  'Weight loss',
  'Valium',
  'Viagra',
  'Vicodin',
  'Human growth hormone',
  'Xanax',
  'Reinvent yourself',
  'Unlimited',
  'Join millions',
];

export function checkForJunkWordsInText(paragraph) {
  const escapedWords = clickSendTextMessageSpamWordsList.map(word =>
    word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/'/g, "['\\\\’]?")
  );

  const pattern = new RegExp(`(^|\\s|[^\\w])(${escapedWords.join('|')})(?=\\s|$|[^\\w])`, 'gi');
  const matches = paragraph.match(pattern);

  return {
    hasJunkWords: matches !== null,
    junkWords: matches || [],
  };
}

export const parseTextForJunkDetection = html => {
  return html.replace(/<\/?p[^>]*>/gi, ' ').replace(/(<([^>]+)>)/gi, '');
};

export function getInfoAlertMessage(availableCredits) {
  const consentInfoClassName = `${styles.consentStatusInfoMessage360View} ${styles.consentDenied}`;
  if (availableCredits === 0) {
    return (
      <div className={consentInfoClassName} style={{ marginTop: '10px' }}>
        <span style={{ color: 'red' }}>
          <FormattedMessage {...message.youDoNotHaveEnoughCreditsLabel} />{' '}
          <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
          <FormattedMessage {...message.forAssistanceLabel} />
        </span>
      </div>
    );
  }

  if (availableCredits === 'N/A') {
    return (
      <div className={consentInfoClassName} style={{ marginTop: '10px' }}>
        <span style={{ color: 'red' }}>
          <FormattedMessage {...message.yourOrganizationDoesNotHaveAccessLabel} />{' '}
          <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
          <FormattedMessage {...message.forAssistanceLabel} />
        </span>
      </div>
    );
  }

  if (availableCredits <= 10) {
    return (
      <div className={consentInfoClassName} style={{ marginTop: '10px' }}>
        <span style={{ color: 'red' }}>
          <FormattedMessage {...message.yourTextCreditsAreLowLabel} />{' '}
          <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
          <FormattedMessage {...message.forAssistanceLabel} />
        </span>
      </div>
    );
  }
  return null;
}
