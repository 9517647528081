import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import handleLogout from '../../Hooks/handleLogout';

function LogOutButton(props) {
  const { renderProp } = props;

  const handleClick = async () => {
    await handleLogout();
  };

  return <>{renderProp(handleClick, <FormattedMessage {...messages.logout} />)}</>;
}

export default LogOutButton;
