import _ from 'lodash';

const doubleQuoteRegex = /".*?"/g;
const singleQuoteRegex = /'.*?'/g;
const andOrRegex = /\b(OR|AND)\b/gi;
const notRegex = /\b(NOT)\b/gi;
const wordRegex = /[^'\s()µΨ"Δ]+/g;
const whiteSpaceRegex = /\s+/g;

export default function isBooleanString(booleanString) {
  const stack = [];
  const expr = booleanString
    .replace(doubleQuoteRegex, 'Δ')
    .replace(singleQuoteRegex, 'Δ') // replace quoted words with Δ
    .replace(andOrRegex, 'µ') // replace OR, AND with µ
    .replace(notRegex, 'Ψ') // replace NOT with Ψ
    .replace(wordRegex, 'W') // replace simple words with W
    .replace(/\s/g, '');
  for (let i = 0; i < expr.length; i += 1) {
    switch (expr[i]) {
      case '(':
        if (![undefined, 'µ', '(', 'Ψ'].includes(expr[i - 1]) || expr[i + 1] === ')') return false;
        stack.push(expr[i]);
        break;
      case 'µ':
        if (
          ['W', 'Δ', '(', 'Ψ'].includes(expr[i + 1]) &&
          expr[i - 1] !== '(' &&
          expr[i + 1] !== ')' &&
          expr[i - 1] !== undefined
        )
          break;
        else {
          return false;
        }
      case 'W':
        if (['µ', ')', '(', undefined, 'Ψ'].includes(expr[i + 1])) break;
        else {
          return false;
        }

      case 'Δ':
        if (['µ', ')', '(', undefined, 'Ψ'].includes(expr[i + 1])) break;
        else {
          return false;
        }

      case ')':
        if (![undefined, 'µ', ')', 'Ψ'].includes(expr[i + 1])) return false;
        if (stack[stack.length - 1] === '(') {
          stack.pop();
          break;
        } else {
          return false;
        }

      case 'Ψ':
        if (['(', 'W', 'Δ'].includes(expr[i + 1])) break;
        else return false;
      default: {
        return false;
      }
    }
  }

  return stack.length === 0;
}

export function sanitizeKeyword(item) {
  const sanitizedItem = _.trim(item, '"`\'() ');
  return sanitizedItem?.includes(' ') ? `"${sanitizedItem}"` : sanitizedItem;
}

function sanitizeBooleanSubArrays(booleanSubArrays) {
  return booleanSubArrays
    ?.map(booleanSubArray => booleanSubArray?.filter(keyword => keyword))
    .filter(booleanSubArray => booleanSubArray?.some(keyword => keyword));
}

export function toBooleanQueryStringUsingOR(values) {
  const sanitizedValues = values?.map(keyword => sanitizeKeyword(keyword))?.filter(keyword => keyword);
  return sanitizedValues?.length
    ? values?.map(value => (value.split(' ').length > 1 ? `"${value}"` : value))?.join(' OR ')
    : '';
}

export function generateBooleanString(values) {
  const booleanSubArrays = sanitizeBooleanSubArrays(values);
  if (!booleanSubArrays?.length) return '';
  return `(${booleanSubArrays
    .map(booleanSubArray => booleanSubArray.map(keyword => sanitizeKeyword(keyword)))
    .map(booleanSubArray => booleanSubArray.join(' OR '))
    .join(') AND (')})`;
}

export function generateArrayFromBooleanString(booleanString) {
  if (!booleanString) return [];
  const tokenizedString = booleanString.split(/(\(|\)|")|\s+/g);
  const outerArray = [];
  let innerArray = [];
  let tempString = '';
  let isDoubleQuoteOpen = false;
  let isclosedBracket = true;
  tokenizedString.forEach(token => {
    if (token == null || token === '') {
      return;
    }
    if (token === '"') {
      isDoubleQuoteOpen = !isDoubleQuoteOpen;
    } else if (token === '(') {
      isclosedBracket = !isclosedBracket;
    } else if (token === ')') {
      isclosedBracket = !isclosedBracket;
    } else if ((token.toLowerCase() === 'and' || token.toLowerCase() === 'or') && !isDoubleQuoteOpen) {
      if (token.toLowerCase() === 'or') {
        innerArray.push(tempString.trim());
        tempString = '';
      } else if (!isclosedBracket) {
        tempString += ` ${token}`;
      } else {
        innerArray.push(tempString.trim());
        outerArray.push(innerArray);
        innerArray = [];
        tempString = '';
      }
    } else {
      tempString += ` ${token}`;
    }
  });
  if (tempString) {
    innerArray.push(tempString.trim());
    outerArray.push(innerArray);
  }
  const sanitizedBooleanSubArrays = sanitizeBooleanSubArrays(outerArray);
  return _.uniqWith(sanitizedBooleanSubArrays, _.isEqual);
}

export function generateKeywordsFromBooleanString(booleanString) {
  if (!booleanString) return [];
  return _.flatten(generateArrayFromBooleanString(booleanString));
}

export function generateBooleanNiceToHaves(niceToHaves) {
  const sanitizedNiceToHaves = niceToHaves?.map(keyword => sanitizeKeyword(keyword))?.filter(keyword => keyword);
  return sanitizedNiceToHaves?.length > 0 ? `(${sanitizedNiceToHaves.join(') OR (')})` : '';
}

const mapping = {
  '(': ')',
  "'": "'",
  '"': '"',
};

function generateSimplifiedExpression(booleanString) {
  return booleanString
    .replace(doubleQuoteRegex, 'Δ')
    .replace(singleQuoteRegex, 'Δ') // replace quoted words with Δ
    .replace(andOrRegex, 'µ') // replace OR, AND with µ
    .replace(notRegex, 'Ψ') // replace NOT with Ψ
    .replace(wordRegex, 'W') // replace simple words with W
    .replace(/\s/g, '');
}

function getFlippedString(list) {
  return list
    .map(x => mapping[x])
    .reverse()
    .join('');
}

function tryCompleteQuote(booleanString) {
  const doubleQuoteOccurenceTimes = (booleanString.match(new RegExp('"', 'g')) || []).length;
  const singleQuoteOccurenceTimes = (booleanString.match(new RegExp("'", 'g')) || []).length;
  if (doubleQuoteOccurenceTimes % 2 === 1 && singleQuoteOccurenceTimes % 2 === 1) {
    const doubleQuoteLastIndex = booleanString.lastIndexOf('"');
    const singleQuoteLastIndex = booleanString.lastIndexOf("'");
    return doubleQuoteLastIndex > singleQuoteLastIndex ? `${booleanString}'` : `${booleanString}"`;
  }
  if (doubleQuoteOccurenceTimes % 2 === 1) {
    return `${booleanString}"`;
  }
  if (singleQuoteOccurenceTimes % 2 === 1) {
    return `${booleanString}'`;
  }
  return booleanString;
}

function autocompleteBoolean(booleanText) {
  let booleanString = booleanText;
  booleanString = tryCompleteQuote(booleanString);
  const simplifiedExpr = generateSimplifiedExpression(booleanString);
  const stack = [];
  for (let i = 0; i < simplifiedExpr.length; i += 1) {
    const char = simplifiedExpr.charAt(i);
    if (char === '(') {
      stack.push(char);
    }
    if (char === "'" || char === '"') {
      if (!stack.includes('"') && !stack.includes("'")) {
        stack.push(char);
      }
    }
    if (char === ')') {
      stack.pop(char);
    }
  }
  let autoCorrectedBooleanText = booleanString;
  const lastChar = simplifiedExpr[simplifiedExpr.length - 1];
  const operatorMatchChars = ['µ', 'Ψ'];
  if (operatorMatchChars.includes(lastChar)) {
    // Append a char/word for the sake of autocorrect
    autoCorrectedBooleanText += ' Ω';
  }
  const flippedString = getFlippedString(stack);
  autoCorrectedBooleanText += flippedString;
  return {
    autoCorrectedValue: autoCorrectedBooleanText,
    addedText: flippedString,
  };
}

function getLastRequiredCharacter(simplifiedExpression, addedText) {
  let length = 0;
  if (addedText) {
    length = addedText[0] === '"' ? addedText.length - 1 : addedText.length;
  }
  if (
    simplifiedExpression[simplifiedExpression.length - 1 - length] !== 'Δ' &&
    simplifiedExpression[simplifiedExpression.length - 1 - length] !== 'W'
  ) {
    return null;
  }
  const requiredCharacters = ['Δ', 'W'];
  const lastIndexes = requiredCharacters.map(char => {
    return simplifiedExpression.lastIndexOf(char);
  });
  const maxIndex = Math.max(...lastIndexes);
  if (maxIndex >= 0) {
    return simplifiedExpression.charAt(maxIndex);
  }
  return null;
}

function getMatchedWords(booleanString) {
  const matchedDoubleQuotedWords = booleanString.match(doubleQuoteRegex) ?? [];
  const matchedSingleQuotedWords = booleanString.replace(doubleQuoteRegex, 'Δ').match(singleQuoteRegex) ?? [];
  const quotedWords = [...matchedDoubleQuotedWords, ...matchedSingleQuotedWords];

  const matchedOperators =
    booleanString
      .replace(doubleQuoteRegex, 'Δ')
      .replace(singleQuoteRegex, 'Δ') // replace quoted words with Δ
      .match(andOrRegex, 'µ') ?? [];

  const matchedNots =
    booleanString
      .replace(doubleQuoteRegex, 'Δ')
      .replace(singleQuoteRegex, 'Δ') // replace quoted words with Δ
      .replace(andOrRegex, 'µ')
      .match(notRegex, 'Ψ') ?? [];

  const matchedWords =
    booleanString
      .replace(doubleQuoteRegex, 'Δ')
      .replace(singleQuoteRegex, 'Δ') // replace quoted words with Δ
      .replace(andOrRegex, 'µ') // replace OR, AND with µ
      .replace(notRegex, 'Ψ') // replace NOT with Ψ
      .match(wordRegex, 'W') ?? [];

  return {
    Δ: quotedWords,
    W: matchedWords,
    µ: matchedOperators,
    Ψ: matchedNots,
  };
}

const removeExtraWhiteSpaces = booleanString => {
  return booleanString.replace(whiteSpaceRegex, ' ').trim();
};

export const removeWhiteSpaceFromDoubeQuotedStrings = booleanString => {
  let updatedBooleanString = booleanString;
  const matchedStrings = _.get(getMatchedWords(booleanString), 'Δ');
  matchedStrings.forEach(match => {
    const currentMatch = match.split('"').join('');
    updatedBooleanString = updatedBooleanString.replace(currentMatch, currentMatch.trim());
  });
  return removeExtraWhiteSpaces(updatedBooleanString);
};

export function getBooleanAutoSuggestParameters(booleanString) {
  let matchedWord = null;
  let multiMatchedWords = null;
  let matchedOperator = null;
  let allowInvalidBoolean = false;
  const { autoCorrectedValue, addedText } = autocompleteBoolean(booleanString);
  const isValidBooleanString = isBooleanString(autoCorrectedValue);
  const simplifiedExpression = generateSimplifiedExpression(autoCorrectedValue);
  const requiredCharacter = getLastRequiredCharacter(simplifiedExpression, addedText);

  if (!requiredCharacter) {
    if (isValidBooleanString) {
      const sanitizedBooleanString = _.trimEnd(booleanString);
      matchedWord = booleanString.slice(sanitizedBooleanString.length);
    }
    return {
      matchedWord,
      matchedOperator,
      multiMatchedWords,
    };
  }

  const matchedWords = getMatchedWords(autoCorrectedValue);
  const characterMatchedWords = matchedWords[requiredCharacter];
  const characterMatchedOperators = matchedWords['µ'];
  const characterMatchedNots = matchedWords['Ψ'];
  if (characterMatchedWords.length === 0) {
    return {
      matchedWord,
      matchedOperator,
      multiMatchedWords,
    };
  }
  matchedWord = characterMatchedWords[characterMatchedWords.length - 1];

  if (matchedWord === 'Ω') {
    matchedWord = null;
    matchedOperator =
      characterMatchedOperators[characterMatchedOperators.length - 1] ??
      characterMatchedNots[characterMatchedNots.length - 1];

    const lastIndexOfWord = simplifiedExpression.lastIndexOf('W');
    if (simplifiedExpression[lastIndexOfWord - 2] === 'W') {
      matchedWord = characterMatchedWords[characterMatchedWords.length - 2];
    } else if (simplifiedExpression[lastIndexOfWord - 2] === 'Δ') {
      const matchedQuotedWords = matchedWords['Δ'];
      matchedWord = matchedQuotedWords[matchedQuotedWords.length - 1];
    }
  } else if (matchedWord) {
    if (requiredCharacter === 'W') {
      const lastIndex = simplifiedExpression.lastIndexOf(requiredCharacter);
      let i = 1;
      while (simplifiedExpression[lastIndex - i] === requiredCharacter) {
        i += 1;
      }
      if (i > 1) {
        matchedWord = null;
        multiMatchedWords = characterMatchedWords.slice(-i);
        allowInvalidBoolean = true;
      }
    }
  }
  if (!allowInvalidBoolean && !isValidBooleanString) {
    matchedWord = null;
    matchedOperator = null;
    multiMatchedWords = null;
  }
  return {
    matchedWord,
    matchedOperator,
    multiMatchedWords,
  };
}

export function generateMustsBooleanString(values) {
  const booleanSubArrays = sanitizeBooleanSubArrays(values);
  if (!booleanSubArrays?.length) return '';
  return `(${booleanSubArrays.map(booleanSubArray => booleanSubArray.join(' OR ')).join(') AND (')})`;
}
