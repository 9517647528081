import { signOut } from 'aws-amplify/auth';
import { closeBroadcastChannel } from '../Events/BroadcastNotificationHandler';
import { unloadSubscribers } from '../PubSub/Subscribers';
import { revokeCognitoRefreshToken } from '../Repository/CognitoRepository';
import config from '../Config/Config';

async function handleLogout() {
  await signOut();
  let linkUrl = '/';
  const client = localStorage.getItem('Client');
  const { hostname } = window.location;
  const isRedirectUrl = client && hostname.includes('goarya.com');
  if (isRedirectUrl) {
    linkUrl = `/${client}`;
  }
  closeBroadcastChannel();
  sessionStorage.removeItem('aryaidp');
  unloadSubscribers();
  await revokeCognitoRefreshToken();
  clearImpersonateState();
  localStorage.setItem('ContinueTo', linkUrl);
  const userType = hostname === config.urls.pulseHostName ? 'pulse' : 'non-pulse';
  window.location.href = `${config.auth.arya.loginUrl}/logout?service=arya&redirect=${
    userType === 'pulse' ? config.auth.pulse.logoutRedirectUrl : config.auth.arya.logoutRedirectUrl
  }`;
}

function clearImpersonateState() {
  localStorage.removeItem('Impersonate');
  localStorage.removeItem('ImpersonatedUser');
  localStorage.removeItem('ManagementUser');
  localStorage.removeItem('ManagementUserId');
}

export default handleLogout;
