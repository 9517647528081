import classNames from 'classnames';
import _ from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as CandidateActionCreators from '../../Actions/ActionCreators/CandidateActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as CandidateAdvanceFilterActions from '../../Actions/CandidateAdvanceFiltersActions';
import * as ConfigActions from '../../Actions/ConfigActions';
import * as DilatedActions from '../../Actions/DilatedIntelActions';
import * as JobActions from '../../Actions/JobActions';
import * as JobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as ManualSearchActions from '../../Actions/ManualSearchActions';
import * as ManualSearchCandidateActions from '../../Actions/ManualSearchCandidateActions';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import {
  defaultFilter,
  getActiveSourceName,
  getActiveTab,
  getAllFilterQueries,
  getCandidateFilter,
  getCandidatesRecommendationStatus,
  getCurrentPage,
  getManualSearchActiveSource,
  getPageSize,
  initialFilter,
} from '../../Reducers/JobCandidatesTabReducer';
import { getJobUtilities } from '../../Reducers/JobReducer';
import { getManualSearchPayload, getManualSearchPreFetchedFlag } from '../../Reducers/ManualSearchReducer';
import { getAccountTypes } from '../../Reducers/OrganizationsReducer';
import { getSelectedSubsegmentId } from '../../Reducers/SubsegmentReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { generateDefaultSegmentFilter } from '../../Utils/AdvanceFiltersJsonGenerationUtils';
import { getCurrentPageDefaultSize } from '../../Utils/CandidatePaginationUtils';
import { getCandidateStatusFilter } from '../../Utils/CandidatePipelineUtil';
import { getActualSourcingStats } from '../../Utils/JobSourcingStatsUtil';
import { getAdvancedSearchPageName, getManualSearchModifiedSource } from '../../Utils/ManualSearchUtils';
import { getAccountType } from '../../Utils/OrganizationUtils';
import { getSourceName, removeKeysFromFilter } from '../../Utils/SourceUtils';
import CandidatePipeline from '../CandidatePipeline/CandidatePipeline';
import JobCandidateListTabs from '../JobCandidateListTabs/JobCandidateListTabs';
import JobNotesContainer from '../JobNotes/JobNotesContainer';
import ManualSearchContainerV1 from '../ManualSearch/ManualSearchContainer/ManualSearchContainer';
import ManualSearchContainerV2 from '../ManualSearchV2/ManualSearchContainer/ManualSearchContainer';
import styles from './JobCandidatesTab.module.scss';
import CandidateStatusTab from './SegmentCandidateStatusTab/SegmentCandidateStatusTab';

export function getIdealCandidateIntelPopupVisibility(jobId) {
  const idealCandidateIntelPopupVisibility =
    JSON.parse(localStorage.getItem('IdealCandidateIntelPopupVisibility')) || {};
  return idealCandidateIntelPopupVisibility?.[jobId] ?? true;
}

const mapStateToProps = (state, props) => {
  return {
    config: getConfig(state),
    currentUser: getCurrentUser(state),
    featureToggleList: getFeatureToggleList(state),
    accountTypes: getAccountTypes(state),
    currPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    filter: getCandidateFilter(state),
    activeTab: getActiveTab(state),
    activeSourceName: getActiveSourceName(state),
    allFilterQueries: getAllFilterQueries(state),
    manualSearchPayload: getManualSearchPayload(state, { jobId: props.jobId }),
    isPreFetchRequied: getManualSearchPreFetchedFlag(state, { jobId: props.jobId }),
    utilities: getJobUtilities(state),
    allTabCandidatesFetchApi: getApiStatus(state, 'allTabCandidatesFetchApi'),
    manualSearchActiveSource: getManualSearchActiveSource(state),
    candidatesRecommendationStatus: getCandidatesRecommendationStatus(state, { jobId: props.jobId }),
    subSegmentIds: getSelectedSubsegmentId(state),
  };
};

const mapDispatchToProps = {
  fetchCandidateList: CandidateActions.fetchCandidateList,
  fetchManualSearchCandidateList: CandidateActions.fetchManualSearchCandidateList,
  clearCandidatesExtraInfo: CandidateActions.clearCandidatesExtraInfo,
  fetchJobSourcingStats: JobActions.fetchJobSourcingStats,
  setCurrentJobId: JobActions.setCurrentJobId,
  fetchIndustries: JobActions.fetchIndustries,
  getUserGivenIntel: JobActions.getUserGivenIntel,
  applyRefineCriteria: DilatedActions.applyRefineCriteria,
  fetchPortalCredentials: ConfigActions.fetchAllPortalCredentials,
  fetchDilatedIntel: DilatedActions.fetchJobDilatedIntel,
  setActivationStateChanged: JobActions.setActivationStateChanged,
  setJobCandidateTabPagination: JobCandidatesTabActions.setJobCandidateTabPagination,
  setJobCandidateTabFrom: JobCandidatesTabActions.setJobCandidateTabFrom,
  setJobCandidateTabActiveTab: JobCandidatesTabActions.setJobCandidateTabActiveTab,
  resetJobCandidatesTabState: JobCandidatesTabActions.resetJobCandidatesTabState,
  resetJobCandidatesTabBulkSelection: JobCandidatesTabActions.resetJobCandidatesTabBulkSelection,
  setJobCandidateTabActiveSourceName: JobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setJobCandidateTabFilter: JobCandidatesTabActions.setJobCandidateTabFilter,
  clearManualSearchCriteria: ManualSearchActions.clearManualSearchCriteria,
  clearManualSearchCandidates: ManualSearchCandidateActions.clearManualSearchCandidates,
  setCandidateListType: CandidateActionCreators.setCandidateListType,
  setManualSearchActiveSource: JobCandidatesTabActions.setManualSearchActiveSource,
  fetchAggregations: CandidateActions.fetchAggregations,
  fetchCandidateSourceCount: CandidateActions.fetchCandidateSourceCount,
  fetchCandidateAdvanceFilterFields: CandidateAdvanceFilterActions.fetchCandidateAdvanceFilterFields,
  fetchCandidateAdvanceFilterPortals: CandidateAdvanceFilterActions.fetchCandidateAdvanceFilterPortals,
  resetAllTabCandidatesFetchApiStatus: ManualSearchCandidateActions.resetAllTabCandidatesFetchApiStatus,
  fetchAppliedBucketScoringStatus: CandidateActions.fetchAppliedBucketScoringStatus,
};

class JobCandidatesTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isIdealCandidateListVisible: false,
      showIdealCandidateIntelPopup: true,
      isSearchPerformed: false,
    };
    this.advancedRefineIntelRef = React.createRef();
  }

  updateIntialState = async () => {
    const {
      setJobCandidateTabPagination,
      setJobCandidateTabActiveTab,
      status,
      connectionStatus,
      publishedStatus,
      candidateContext,
      location,
      config,
      candidateListType,
      currentUser,
      version,
      isAryaRankedTab,
      pageName,
      isScoutingAgentTab,
      setJobCandidateTabActiveSourceName,
    } = this.props;

    let activeTab = status ? status.toLowerCase() : 'sourced';
    const statusFromUrl = version !== 'ats' ? location?.search?.replace('?status=', '') : 'sourced';
    if (candidateContext === 'job') {
      activeTab = status ? status.toLowerCase() : 'sourced';
    }
    if (candidateContext === 'segment') {
      activeTab = status ? status.toLowerCase() : statusFromUrl;
    }
    if (connectionStatus) {
      activeTab = connectionStatus.toLowerCase();
    }
    if (publishedStatus && publishedStatus !== 'Sourced') {
      activeTab = publishedStatus.toLowerCase();
    }
    const currentTabDefaultPageSize = getCurrentPageDefaultSize(
      config,
      candidateContext === 'segment' && activeTab === 'sourced' ? 'ManualSearchCandidateList' : candidateListType,
      candidateContext,
      currentUser.email
    );
    if (isAryaRankedTab) activeTab = 'ranked';
    await setJobCandidateTabActiveTab({ activeTab });
    if (isScoutingAgentTab) {
      await setJobCandidateTabActiveTab({ activeTab: 'sourced' });
      setJobCandidateTabActiveSourceName({ activeSourceName: 'scoutingAgent' });
    }

    await setJobCandidateTabPagination({
      currPage: this.getDefaultPageNumber(currentTabDefaultPageSize),
      pageSize: currentTabDefaultPageSize,
    });
  };

  setSearchPerformed = value => {
    this.setState({
      isSearchPerformed: value,
    });
  };

  async componentDidMount() {
    const {
      fetchPortalCredentials,
      jobId,
      setCurrentJobId,
      fetchCandidateAdvanceFilterFields,
      fetchCandidateAdvanceFilterPortals,
      fetchIndustries,
      utilities,
    } = this.props;
    if (jobId) await setCurrentJobId(jobId);
    await this.updateIntialState();
    fetchPortalCredentials();
    this.fetchData();
    fetchCandidateAdvanceFilterFields();
    fetchCandidateAdvanceFilterPortals();
    const industries = _.get(utilities, ['industries'], []);
    if (!industries?.length)
      fetchIndustries({
        SearchTerm: '',
        Size: 200,
      });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { location, candidateContext, setJobCandidateTabActiveTab, activeTab } = this.props;
    if (candidateContext === 'segment' && prevProps.location?.search !== location?.search) {
      if (location?.search) await setJobCandidateTabActiveTab({ activeTab: location?.search?.replace('?status=', '') });
      else await setJobCandidateTabActiveTab({ activeTab: 'notes' });
    }
    const prevUrlParams = new URLSearchParams(prevProps.location?.search);
    const prevStatus = prevUrlParams.get('status');
    const urlParams = new URLSearchParams(location?.search);
    const status = urlParams.get('status');
    if (prevStatus !== status && activeTab !== status && status) {
      this.onStatusChange(status);
    }
    this.handleFetchData(prevProps, prevState);
  }

  componentWillUnmount() {
    const {
      resetJobCandidatesTabState,
      setCurrentJobId,
      jobId,
      clearManualSearchCriteria,
      clearManualSearchCandidates,
      clearCandidatesExtraInfo,
      featureToggleList,
      resetAllTabCandidatesFetchApiStatus,
    } = this.props;
    resetJobCandidatesTabState();
    if (jobId) setCurrentJobId(null);
    clearCandidatesExtraInfo({ jobId });
    if (featureToggleList.CandidateManualSearch.IsEnabled) {
      resetAllTabCandidatesFetchApiStatus();
      clearManualSearchCriteria({ jobId });
      clearManualSearchCandidates({ jobId });
    }
  }

  handleFetchData = (prevProps, prevState) => {
    const { currentJobDetails, setActivationStateChanged, jobId, activeTab } = this.props;
    const { isIdealCandidateListVisible } = this.state;
    if (isIdealCandidateListVisible) {
      return;
    }
    const isJobConfigUpdated = currentJobDetails.updateJobConfigApiStatus === 'COMPLETED';
    const isSourceLimitUpdated =
      currentJobDetails.SourcingConfiguration?.SourceLimit !==
      prevProps?.currentJobDetails?.SourcingConfiguration?.SourceLimit;
    const isActivationStateChanged =
      currentJobDetails?.activationStateChanged !== prevProps.currentJobDetails?.activationStateChanged &&
      currentJobDetails.activationStateChanged;
    const isIdealCandidateListClosed = prevState.isIdealCandidateListVisible && !isIdealCandidateListVisible;
    const isActiveTabChanged = prevProps.activeTab !== activeTab;
    if (
      (isJobConfigUpdated && isSourceLimitUpdated) ||
      isActivationStateChanged ||
      (isIdealCandidateListClosed && !isActiveTabChanged)
    ) {
      this.fetchData(isActivationStateChanged);
      setActivationStateChanged(jobId, false);
    }
  };

  fetchData = async isActivationStateChanged => {
    const { jobId, status, fetchJobSourcingStats, getUserGivenIntel, fetchDilatedIntel, activeSourceName } = this.props;
    const defaultFilter = { ...this.getDefaultFilter() };
    if (status?.toLowerCase() === 'applied') {
      defaultFilter.Applied = true;
      defaultFilter.Statuses = [];
    } else defaultFilter.Statuses = status ? [status] : [];
    if (activeSourceName === 'AryaRecommended' && status !== 'notes')
      this.fetchAryaRecommendedCandidates({ filter: defaultFilter, invokationContext: 'defaultLoad' });
    getUserGivenIntel(jobId);
    if (!isActivationStateChanged) fetchJobSourcingStats([jobId]);
    await fetchDilatedIntel(jobId, ['AryaSuggested'], true);
  };

  getDefaultPageNumber = defaultPageSize => {
    const { location, atsCandidateRankIndex, version } = this.props;
    const queryParams = queryString.parse(location.search);
    const candidateIndex = version === 'ats' ? atsCandidateRankIndex?.toString() : queryParams?.index ?? 0;
    if (candidateIndex === '-1') {
      return 1;
    }
    return Math.floor(candidateIndex / defaultPageSize) + 1;
  };

  getCurrentPipelineFilter = () => {
    const { status, connectionStatus, publishedStatus, filter } = this.props;
    return filter[status || connectionStatus || publishedStatus];
  };

  getDefaultFilter = () => {
    const {
      jobId,
      currentJobDetails,
      status,
      featureToggleList,
      pageSize,
      activeTab,
      currPage,
      smartRecruiterVersion,
    } = this.props;

    const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
    const candidateStatusFilter = getCandidateStatusFilter(
      activeTab,
      isPaidJobServiceEnabled,
      featureToggleList,
      smartRecruiterVersion
    );
    const pipelineFilter = this.getCurrentPipelineFilter();
    const jobGuid = currentJobDetails.JobGuid;
    const candidatesFilter = {
      jobId,
      jobGuid,
      ...candidateStatusFilter,
      ...pipelineFilter,
      Size: pageSize,
      From: (currPage - 1) * pageSize,
    };
    if (status?.toLowerCase() === 'applied') {
      candidatesFilter.Applied = true;
      candidatesFilter.Statuses = [];
    } else if (status && status?.toLowerCase() !== 'rejected') {
      candidatesFilter.Statuses = status ? [status] : [];
    }
    if (activeTab === 'favourite') {
      candidatesFilter.Statuses = ['Favourite'];
    }
    if (activeTab === 'failed') {
      candidatesFilter.Statuses = null;
    }
    return candidatesFilter;
  };

  onStatusChange = async (pipelineName, isModificationNeeded, targetJobId, isSubsegmentEmailRedirection) => {
    const {
      jobId,
      setUrl,
      currentJobDetails,
      featureToggleList,
      changeStatus,
      setJobCandidateTabPagination,
      setJobCandidateTabFrom,
      setJobCandidateTabActiveTab,
      setJobCandidateTabFilter,
      pageSize,
      resetJobCandidatesTabBulkSelection,
      setJobCandidateTabActiveSourceName,
      candidateContext = 'job',
      allFilterQueries,
      setManualSearchActiveSource,
      setCandidateListType,
      manualSearchPayload,
      currentUser,
      config,
      changeTabStatus,
      smartRecruiterVersion,
      isPreFetchRequied = true,
      allTabCandidatesFetchApi,
      manualSearchActiveSource,
      subSegmentIds,
      location,
    } = this.props;

    const { isIdealCandidateListVisible, isSearchPerformed, connectedActiveTab } = this.state;
    const {
      PaidJobService: { IsEnabled: isPaidJobServiceEnabled },
      AdvanceSearchV2: { IsEnabled: isAdvanceSearchV2Enabled },
      PrefetchAdvanceSearchResults: { IsEnabled: isPrefetchAdvanceSearchResultsEnabled },
    } = featureToggleList;
    const candidateStatusFilter = getCandidateStatusFilter(
      pipelineName,
      isPaidJobServiceEnabled,
      featureToggleList,
      smartRecruiterVersion
    );
    const { Status, ConnectionStatus, PublishedStatus, Applied, isAryaRankedTab } = candidateStatusFilter;
    let status = Status || ConnectionStatus || PublishedStatus || (Applied && 'applied');
    const isNonInternalPortalForSegmentEnabled = featureToggleList.AllowNonInternalPortalsForSegment.IsEnabled;
    setJobCandidateTabActiveSourceName({ activeSourceName: 'AryaRecommended' });
    setJobCandidateTabFrom(0);
    if (status) {
      status = status[0].toUpperCase() + status.slice(1);
      const jobGuid = currentJobDetails.JobGuid;
      setJobCandidateTabPagination({ currPage: 1 });
      await setJobCandidateTabActiveTab({
        activeTab: isAryaRankedTab ? 'ranked' : status.toLowerCase(),
      });
      await setJobCandidateTabFilter({ filter: defaultFilter });
      const selectedSubSegmentId = subSegmentIds;
      let fetchAryaRecommendatedCandidate = true;
      if (candidateContext === 'segment' && status?.toLowerCase() === 'sourced')
        fetchAryaRecommendatedCandidate = false;
      if (!selectedSubSegmentId && status?.toLowerCase() === 'subsegment') fetchAryaRecommendatedCandidate = false;
      const { filter } = this.props;
      const pipelineFilter = filter[status];
      const newFilter = _.cloneDeep(candidateStatusFilter);
      const currentConnectionStatus =
        newFilter.status === 'connected' || newFilter.status === 'contacted' || newFilter.status === 'failed'
          ? connectedActiveTab
          : newFilter.ConnectionStatus;
      newFilter.Statuses = candidateStatusFilter.Status ? [candidateStatusFilter.Status] : null;
      if (changeStatus) changeStatus(Applied ? status : newFilter.Status, currentConnectionStatus, isAryaRankedTab);
      delete newFilter.Status;
      newFilter.jobId = jobId;
      newFilter.jobGuid = jobGuid;
      newFilter.Size = pageSize;
      if (status?.toLowerCase() === 'subsegment') {
        newFilter.Statuses = ['shortlisted'];
      }
      if (candidateContext === 'job')
        setUrl(`/jobs/${jobId}/candidates?status=${isAryaRankedTab ? 'ranked' : status.toLowerCase()}`);
      else if (candidateContext === 'segment' && !changeTabStatus) {
        const queryParams = queryString.parse(location.search);
        const { subsegmentId } = queryParams;
        if (!subsegmentId || !isSubsegmentEmailRedirection)
          setUrl(`/segments/${jobId}/candidates?status=${status.toLowerCase()}`);
      }
      // else if (candidateContext === 'segment' && version === "ats" && !isSubsegmentEmailRedirection) {
      //   setUrl(`/segments/${jobId}/candidates?status=${status.toLowerCase()}`);
      // }

      if (fetchAryaRecommendatedCandidate) {
        const candidatesFetchByPayload = { ...newFilter, ...pipelineFilter, TargetJobId: targetJobId };
        if (status?.toLowerCase() === 'subsegment') {
          const subSegmentId = [];
          subSegmentId.push(parseInt(selectedSubSegmentId, 10));
          candidatesFetchByPayload.SubsegmentIds = subSegmentId;
        }
        this.fetchAryaRecommendedCandidates({ filter: candidatesFetchByPayload, invokationContext: 'defaultLoad' });
      }
      resetJobCandidatesTabBulkSelection();
    }
    if (
      (candidateContext === 'segment' && status?.toLowerCase() === 'sourced') ||
      ((isAdvanceSearchV2Enabled || smartRecruiterVersion === 'v2') &&
        status.toLowerCase() === 'sourced' &&
        !isAryaRankedTab)
    ) {
      const activeKey = allTabCandidatesFetchApi === 'COMPLETED' ? 'All' : getSourceName(manualSearchActiveSource);
      const sourcesSearched = manualSearchPayload?.Sources ?? [];
      const activeSource =
        candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled
          ? getManualSearchModifiedSource(candidateContext, isNonInternalPortalForSegmentEnabled)
          : sourcesSearched.find(source => getSourceName(source) === activeKey);
      const currentPortalQueries = manualSearchPayload?.PortalQueries?.[activeKey];
      const filterQueries = allFilterQueries[activeKey];
      const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };

      const candidateListType = 'ManualSearchCandidateList';
      const currentTabDefaultPageSize = getCurrentPageDefaultSize(
        config,
        candidateListType,
        candidateContext,
        currentUser.email
      );

      await setJobCandidateTabPagination({ pageSize: currentTabDefaultPageSize });
      const newManualSearchPayload = {
        ...manualSearchPayload,
        Sources: [activeSource],
        PortalQueries: { [activeKey]: updatedPortalQueries },
        From: 0,
        Size: currentTabDefaultPageSize,
      };
      setJobCandidateTabActiveSourceName({ activeSourceName: activeSource?.Portal });
      setManualSearchActiveSource({ manualSearchActiveSource: activeSource });
      this.fetchManualSearchCandidates({ manualSearchPayload: newManualSearchPayload });
      setCandidateListType(candidateListType);
      if (
        isSearchPerformed ||
        (candidateContext !== 'segment' && (!isPreFetchRequied || isPrefetchAdvanceSearchResultsEnabled))
      ) {
        const updatedSourceName =
          candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled ? 'Internal' : 'All';
        const activeSourceName =
          allTabCandidatesFetchApi === 'COMPLETED' ? updatedSourceName : getSourceName(manualSearchActiveSource);
        setJobCandidateTabActiveSourceName({ activeSourceName });
      }
    }
    if (candidateContext === 'segment' && status) {
      if (status.toLowerCase() !== 'sourced') {
        const currentTabDefaultPageSize = getCurrentPageDefaultSize(
          config,
          undefined,
          candidateContext,
          currentUser.email
        );
        setJobCandidateTabPagination({ pageSize: currentTabDefaultPageSize });
      }
    }
    if (isIdealCandidateListVisible) {
      this.setIsRefineCriteriaPanelExpanded(false);
      this.setIdealCandidateListVisiblity(false);
    }
  };

  fetchAryaRecommendedCandidates = async options => {
    const {
      filter,
      showLoader = true,
      isFilterModified,
      isCurrentCandidateViewedFlag,
      currentCandidateViewedFormValue,
      invokationContext,
      isCandidateShortlistedReject,
    } = options;
    const {
      fetchCandidateList,
      currentJobDetails,
      version,
      atsFetchBulkNotes,
      candidateContext,
      fetchAggregations,
      featureToggleList,
      activeSourceName,
      activeTab,
      fetchAppliedBucketScoringStatus,
      subSegmentIds,
    } = this.props;

    const IsScoutingAgentCandidate = activeSourceName === 'scoutingAgent';
    const atsJobId = currentJobDetails.JobCode;
    const isBulkNotesFetch = version !== 'ats';
    const newFilter = _.cloneDeep(filter);
    if (!filter?.Sources?.length) {
      newFilter.Sources = filter.Portals;
    }
    if (!filter?.Statuses?.length) {
      newFilter.Statuses = filter.Status ? [filter.Status] : null;
      delete newFilter.Status;
    }
    if (activeTab === 'sourced' && activeSourceName === 'scoutingAgent') {
      newFilter.Statuses = [];
      newFilter.SortBy = 'Latest';
    }
    if (activeTab === 'subsegment') {
      newFilter.Statuses = ['Shortlisted'];
      newFilter.Status = 'Shortlisted';
    }
    const isBulkTagFetch = true;
    newFilter.Portals = undefined;
    if (newFilter?.AdvanceFilterQuery === undefined || invokationContext === 'defaultLoad') {
      newFilter.AdvanceFilterQuery = generateDefaultSegmentFilter(activeTab);
    }
    if (activeTab === 'sourced' || activeTab === 'ranked')
      newFilter.IsScoutingAgentCandidate = IsScoutingAgentCandidate;
    if (activeTab === 'subsegment') {
      const selectedSubSegmentId = subSegmentIds;

      const subSegmentId = [];
      subSegmentId.push(parseInt(selectedSubSegmentId, 10));
      newFilter.SubsegmentIds = subSegmentId;
    }
    await fetchCandidateList(
      newFilter,
      false,
      isBulkNotesFetch,
      showLoader,
      null,
      isFilterModified,
      atsFetchBulkNotes,
      atsJobId,
      isBulkTagFetch,
      candidateContext,
      isCurrentCandidateViewedFlag,
      currentCandidateViewedFormValue
    );
    const { jobId } = newFilter;
    const {
      BulkContactFetch: { IsEnabled: isBulkContactFetchEnabled },
    } = featureToggleList;

    const currentPipelineFilter = this.getCurrentPipelineFilter();
    const isFilterApplied = !_.isEqual(
      removeKeysFromFilter(initialFilter, ['Portals', 'AdvanceFilterQuery', 'SortBy'], candidateContext),
      removeKeysFromFilter(currentPipelineFilter, ['Portals', 'AdvanceFilterQuery', 'SortBy'], candidateContext)
    );
    const isIncludedCandidates =
      candidateContext === 'segment' && (activeTab === 'shortlisted' || activeTab === 'rejected');
    const shouldAppendCandidateCount = isCandidateShortlistedReject;
    if (['defaultLoad', 'sourceFilter', 'aggregationFilter'].includes(invokationContext) && !isIncludedCandidates) {
      await fetchAggregations(
        newFilter,
        invokationContext !== 'sourceFilter',
        isBulkContactFetchEnabled,
        jobId,
        showLoader,
        shouldAppendCandidateCount,
        isFilterApplied,
        invokationContext
      );
    }
    if (newFilter.Applied) {
      fetchAppliedBucketScoringStatus({ jobId });
    }
  };

  fetchManualSearchCandidates = async ({
    manualSearchPayload,
    isAllTabCandidateFetchRequestAllowed,
    fetchRemoteDataOnly,
    setPrevCandidateCallback,
  }) => {
    const {
      fetchManualSearchCandidateList,
      currentJobDetails,
      version,
      atsFetchBulkNotes,
      candidateContext,
      setCandidateListType,
      manualSearchActiveSource,
    } = this.props;
    const { JobCode: atsJobId, JobId: jobId, JobGuid: jobGuid } = currentJobDetails;
    const isBulkNotesFetch = version !== 'ats';
    const isBulkTagFetch = true;
    await setCandidateListType('ManualSearchCandidateList');
    fetchManualSearchCandidateList({
      manualCriteria: manualSearchPayload,
      jobId,
      jobGuid,
      isBulkNotesFetch,
      atsFetchBulkNotes,
      atsJobId,
      isBulkTagFetch,
      candidateContext,
      isAllTabCandidateFetchRequestAllowed,
      fetchRemoteDataOnly,
      setPrevCandidateCallback,
      smartCandidateAgent: getSourceName(manualSearchActiveSource) === 'smartAgentCandidates',
    });
  };

  setIdealCandidateListVisiblity = isIdealCandidateListVisible => {
    this.setState({ isIdealCandidateListVisible });
  };

  setIsRefineCriteriaPanelExpanded = isRefineCriteriaPanelExpanded => {
    if (isRefineCriteriaPanelExpanded) {
      this.onCloseIdealCandidateIntelPopup();
    }
  };

  scrollToRefineIntel = () => {
    window.scrollTo({
      top: this.advancedRefineIntelRef.current.offsetTop,
      behavior: 'smooth',
    });
    this.setIsRefineCriteriaPanelExpanded(true);
  };

  onCloseIdealCandidateIntelPopup = () => {
    const { jobId } = this.props;
    const idealCandidateIntelPopupVisibility =
      JSON.parse(localStorage.getItem('IdealCandidateIntelPopupVisibility')) || {};
    if (idealCandidateIntelPopupVisibility[jobId] !== false) {
      idealCandidateIntelPopupVisibility[jobId] = false;
      localStorage.setItem('IdealCandidateIntelPopupVisibility', JSON.stringify(idealCandidateIntelPopupVisibility));
      this.setState({ showIdealCandidateIntelPopup: false });
    }
  };

  getIsPulseUser = () => {
    const { config, accountTypes } = this.props;
    const accountType = getAccountType(config?.AccountTypeId, accountTypes);
    return ['aryapulse'].includes(accountType?.toLowerCase());
  };

  onSearchCandidatesCallback = () => {
    const { setJobCandidateTabActiveTab } = this.props;
    setJobCandidateTabActiveTab({ activeTab: 'sourced' });
  };

  render() {
    const {
      jobId,
      activeTab,
      featureToggleList,
      currentJobDetails,
      version,
      status,
      currentUser,
      connectionStatus,
      openAryaCandidateView,
      notesContainer,
      callNotesContainer,
      openAtsView,
      postAtsCallNotes,
      atsCandidateRankIndex,
      history,
      publishedStatus,
      jobGuid,
      candidateContext = 'job',
      setPublishedStatus,
      setConnectionStatus,
      setJobCandidateTabActiveTab,
      setStatus,
      setUrl,
      isSearchPerformed,
      openSipCallWindowsApp,
      changeTabStatus,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
      isAryaRankedTab,
      smartRecruiterVersion,
      candidateId,
      isAtsCandidateFetchModalVisible,
      candidatesRecommendationStatus,
      usersById,
      atsActivePageName,
      location,
    } = this.props;

    const atsJobCandidateListProps = {
      version,
      atsCandidateRankIndex,
      openAryaCandidateView,
      notesContainer,
      callNotesContainer,
      openAtsView,
      postAtsCallNotes,
      candidateId,
    };
    const isNotesTabActive = !(
      status?.toLowerCase() === 'sourced' ||
      status?.toLowerCase() === 'shortlisted' ||
      status?.toLowerCase() === 'rejected' ||
      status?.toLowerCase() === 'subsegment'
    );
    const currentPipelineFilter = this.getCurrentPipelineFilter();
    const { isIdealCandidateListVisible, showIdealCandidateIntelPopup, isScoutingAgentTab } = this.state;

    const defaultFilter = this.getDefaultFilter();
    const {
      CandidateManualSearch: { IsEnabled: isCandidateManualSearchEnabled },
      PrefetchAdvanceSearchResults: { IsEnabled: isPrefetchAdvanceSearchResultsEnabled },
      PreferredKeywordAutoSelect: { IsEnabled: isPreferredKeywordAutoSelectEnabled },
    } = featureToggleList;
    const planName = currentJobDetails?.OrderInfo?.ProductVariantName;
    const isPulseUser = this.getIsPulseUser();
    const showIntelBanners = !isPulseUser && status?.toLowerCase() === 'sourced';
    const { sourcedCount, scoutingAgentCandidateCount } = getActualSourcingStats(currentJobDetails?.sourcingStats);
    const idealCandidateIntelPopupVisibility =
      sourcedCount > 0 && showIdealCandidateIntelPopup && getIdealCandidateIntelPopupVisibility(jobId);
    const isCandidateCRMEnabled = featureToggleList.CandidateCRM.IsEnabled;
    const isNonInternalPortalForSegmentEnabled = featureToggleList.AllowNonInternalPortalsForSegment.IsEnabled;

    const isAdvancedSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;

    let ManualSearchContainer = featureToggleList.AdvanceSearchV2.IsEnabled
      ? ManualSearchContainerV2
      : ManualSearchContainerV1;
    if (smartRecruiterVersion) {
      ManualSearchContainer = smartRecruiterVersion === 'v2' ? ManualSearchContainerV2 : ManualSearchContainerV1;
    }

    const advancedSearchPageName = getAdvancedSearchPageName(
      candidateContext,
      activeTab,
      featureToggleList,
      smartRecruiterVersion
    );

    return (
      <div className="candidate-list">
        {candidateContext === 'segment' ? (
          <CandidateStatusTab
            onStatusChange={this.onStatusChange}
            status={status}
            jobId={jobId}
            activeTab={activeTab}
            setPublishedStatus={setPublishedStatus}
            setConnectionStatus={setConnectionStatus}
            setUrl={setUrl}
            setStatus={setStatus}
            setJobCandidateTabActiveTab={setJobCandidateTabActiveTab}
            isCandidateCRMEnabled={isCandidateCRMEnabled}
            changeTabStatus={changeTabStatus}
            location={location}
          />
        ) : null}
        {candidateContext === 'segment' &&
        (activeTab?.toLowerCase() === 'notes' || status?.toLowerCase() === 'notes') ? (
          <JobNotesContainer jobId={jobId} candidateContext={candidateContext} />
        ) : null}
        {candidateContext === 'job' ? (
          <div className={classNames('candidate-status', { 'candidate-status-v2': isAdvancedSearchV2Enabled })}>
            <CandidatePipeline
              onPipelineItemClick={this.onStatusChange}
              jobId={jobId}
              activeTab={activeTab}
              showRejected
              smartRecruiterVersion={smartRecruiterVersion}
              candidateContext={candidateContext}
            />
          </div>
        ) : null}
        {(candidateContext === 'job' && isCandidateManualSearchEnabled && !isPulseUser) ||
        (candidateContext === 'segment' && isCandidateCRMEnabled && !isPulseUser) ? (
          <div
            className={classNames(styles.manualSearchContainer, {
              [styles.hide]: activeTab?.toLowerCase() !== 'sourced',
            })}
          >
            <ManualSearchContainer
              jobId={jobId}
              candidateContext={candidateContext}
              isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
              history={history}
              setSearchPerformed={this.setSearchPerformed}
              pageName={advancedSearchPageName}
              version={version}
              onSearchCandidatesCallback={this.onSearchCandidatesCallback}
              smartRecruiterVersion={smartRecruiterVersion}
              isAtsCandidateFetchModalVisible={isAtsCandidateFetchModalVisible}
              isPrefetchEnabled={isPrefetchAdvanceSearchResultsEnabled}
              isPreferredKeywordAutoSelectEnabled={isPreferredKeywordAutoSelectEnabled}
              atsActivePageName={atsActivePageName}
            />
          </div>
        ) : null}
        {(!isIdealCandidateListVisible && candidateContext === 'job') ||
        (candidateContext === 'segment' && !isNotesTabActive) ? (
          <JobCandidateListTabs
            jobId={jobId}
            sourcedCount={sourcedCount}
            scoutingAgentCandidateCount={scoutingAgentCandidateCount}
            atsJobCandidateListProps={atsJobCandidateListProps}
            connectionStatus={connectionStatus}
            currentJobDetails={currentJobDetails}
            currentPipelineFilter={currentPipelineFilter}
            currentUser={currentUser}
            defaultFilter={defaultFilter}
            fetchAryaRecommendedCandidates={this.fetchAryaRecommendedCandidates}
            fetchManualSearchCandidates={this.fetchManualSearchCandidates}
            idealCandidateIntelPopupVisibility={idealCandidateIntelPopupVisibility}
            onStatusChange={this.onStatusChange}
            onCloseIdealCandidateIntelPopup={this.onCloseIdealCandidateIntelPopup}
            status={status}
            showIntelBanners={showIntelBanners}
            scrollToRefineIntel={this.scrollToRefineIntel}
            version={version}
            history={history}
            publishedStatus={publishedStatus}
            jobGuid={jobGuid}
            planName={planName}
            candidateContext={candidateContext}
            isSearchPerformed={isSearchPerformed}
            isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
            openSipCallWindowsApp={openSipCallWindowsApp}
            openSegmentAtsView={openSegmentAtsView}
            openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
            onClickAddJobCallBack={onClickAddJobCallBack}
            isAryaRankedTab={isAryaRankedTab}
            smartRecruiterVersion={smartRecruiterVersion}
            candidatesRecommendationStatus={candidatesRecommendationStatus}
            usersById={usersById}
            isPulseUser={isPulseUser}
          />
        ) : null}
      </div>
    );
  }
}

export { JobCandidatesTab as JobCandidatesTabWithoutStore };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobCandidatesTab));
