import React from 'react';

export default function LogoutRedirect() {

  React.useEffect(() => {
    const redirectUrl = localStorage.getItem('ContinueTo');
    if (redirectUrl) {
      localStorage.removeItem('ContinueTo');
      window.location.href = redirectUrl;
    } else {
      window.location.href = '/';
    }
  }, []);

  return null;
}
