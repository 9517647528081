import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Skeleton, Icon, Select, Button, Collapse, Form, Popover, Timeline, Tooltip, Avatar, Alert } from 'antd';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import ChatGptRestartConfirmationModal from '../../ChatGptIntegration/ChatGptRestartConfirmationModal';
import InfoIconWithTooltip from '../../Common/InfoIconWithTooltip/InfoIconWithTooltip';
import { MergeTagIcon, PreHeaderIcon } from '../../../Icons/AryaIcons';
import InfoCircleIcon from '../../../Icons/SuggestedIcons/InfoCircleIcon';
import HtmlViewer from '../../HtmlViewer/HtmlViewer';
import RichTextEditor from '../../Editor/RichTextEditor';
import { getRelativeTime } from '../../../Utils/RelativeTimeCalculator';
import DripsDisplayContainer from '../../../Containers/DripsDisplayContainer/DripsDisplayContainer';
import createRequestBody from '../../../Containers/DripsDisplayContainer/DripRequestBodyCreator';
import './ConnectMailWindow.scss';
import '../../EmailComposer/EmailComposer.scss';
import UserAlerts from '../../UserAlerts/UserAlerts';
import DropdownWithLabel from '../../DropdownWithLabel/DropdownWithLabel';
import { validateStatus, getProviderLinkedEmails } from '../../../Utils/ContactUtils';
import { chatGptDefaultEmailConfiguration } from '../../../Utils/ChatGptDefaultRequestUtils';
import { getVerifiedUserEmails } from '../../../Utils/UserInfo';
import { validateTemplate, emailValidator } from '../../../Utils/Validators';
import {
  getSortedEmailsByEmailTypes,
  getEmailAddressWithEmailTypeIcon,
  getPreHeaderValidationStatus,
} from '../../../Utils/EmailUtils/EmailUtils';
import SmtpNotConfiguredWarning from '../../SmtpNotConfiguredWarning/SmtpNotConfiguredWarning';
import DripConfigSwitch from '../DripConfigSwitch/DripConfigSwitch';
import { getEmailTemplateIdentifier } from '../../Common/EmailTemplateIdentifier/EmailTemplateIdentifier';
import { isPulseUser } from '../../../Utils/ConfigUtils';
import Candidate360MailAndMessageComposeWindow from '../Candidate360MailAndMessageComposeWindow/Candidate360MailAndMessageComposeWindow';
import { validateEmail } from '../../../Utils/FormValidators';
import { getConfiguration } from '../../Collaborators/Collaborators';
import DynamicTagInput from '../../ChatGptIntegration/DynamicTagInput';
import OpenAiIcon from '../../../Icons/OpenAiIcon';
import { EllipsedText, removeEmptyParagraphWithBreak } from '../../../Utils/TextUtils';
import OpenAiContrastIcon from '../../../Icons/OpenAiContrastIcon';
import { scrollToCandidate360Top } from '../../../Utils/CandidateDrawerUtils';
import { parseTextFromHtml, checkHtmlForImages } from '../../../Utils/DomParserUtils';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';
import WorkflowWindow from '../CandidateWorkflowWindow/WorkflowWindow';
import CandidateWorkflow from '../CandidateWorkflowWindow/CandidateWorkflow';
import WorkflowTab from '../CandidateWorkflowWindow/WorkflowTab';
import message from '../../../Containers/CandidateDrawer/messages';
import jobMessage from '../../JobForm/JobMessages';
import placeholder from '../../Placeholders/PlaceholdersMessages';

const { Option } = Select;
const { Panel } = Collapse;
const { Item } = Form;
const htmlTagRemoveRegex = /(<([^>]+)>)/gi;
const excludedMergeTags = ['Candidate.JobApplyURL', 'Candidate.JobViewURL'];

export const getEmailStatusWithIcon = email => {
  return (
    <div className="email-status-360">
      <span className={email.ViewedTime ? 'active' : ''}>
        {email.ViewedTime ? <Icon type="check-circle" /> : null}
        <FormattedMessage {...message.openedLabel} />
      </span>{' '}
      |{' '}
      <span className={email.ClickedTime ? 'active' : ''}>
        {email.ClickedTime ? <Icon type="check-circle" /> : null}
        <FormattedMessage {...message.clickedLabel} />
      </span>
    </div>
  );
};

export function getEmailStatus(email) {
  if (email.RequestStatus === 'Success') {
    return getEmailStatusWithIcon(email);
  }
  let state = '';
  if (email.RequestStatus === 'Scheduled') {
    state = 'clock';
  } else if (['Skipped', 'Cancelled'].includes(email.RequestStatus)) {
    state = 'danger';
  }
  return (
    <div className="email-status">
      <span className={state}>{email.RequestStatus}</span>
    </div>
  );
}

export const getChatGptInputTooltip = () => {
  return <FormattedMessage {...message.chatGptInputInfoMessage} />;
};

export const getDisabledSendButtonTooltipText = () => {
  return <FormattedMessage {...message.chatGptIncompletePromptInfoMessage} />;
};

export const getSubjectRef = (enableGenerationThroughChatGpt, chatGptSubjectRef, actualRef) => {
  if (enableGenerationThroughChatGpt) return actualRef;
  return chatGptSubjectRef;
};

export const getComposeEmailTitle = enableGenerationThroughChatGpt => {
  if (enableGenerationThroughChatGpt)
    return (
      <div className="candidate360-compose-email">
        <FormattedMessage {...message.composeEmailWithLabel} />
        <span className="candidate360-compose-email-manually">
          <FormattedMessage {...message.existingTemplatesLabel} />
        </span>{' '}
      </div>
    );
  return (
    <div className="compose-email-chatGPT">
      <OpenAiContrastIcon />{' '}
      <div className="candidate360-compose-email">
        <FormattedMessage {...message.composeEmailWithLabel} />
        <span className="candidate360-compose-email-chatGPT">
          <FormattedMessage {...message.chatGptLabel} />
        </span>
      </div>
    </div>
  );
};

class ConnectMailWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSmtpConfiguredForSelectedEmail: true,
      currentPanel: [],
      emailOptionValue: undefined,
      restartModalVisibility: false,
      isChatGPTInputChanged: false,
    };
    this.myRef = React.createRef();
    this.preHeaderRef = React.createRef();
    this.mailsRef = React.createRef();
    this.connectMailRef = React.createRef();
    this.addTags = this.addTags.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeFromEmail = this.onChangeFromEmail.bind(this);
    this.getEmailStack = this.getEmailStack.bind(this);
    this.getEmailComposer = this.getEmailComposer.bind(this);
    this.getWorkflowEmailComposer = this.getWorkflowEmailComposer.bind(this);
    this.addTags = this.addTags.bind(this);
    this.getIsEmailComposerDisabled = this.getIsEmailComposerDisabled.bind(this);
    this.getSubscriptionStatus = this.getSubscriptionStatus.bind(this);
    this.getEmailsDropdown = this.getEmailsDropdown.bind(this);
    this.getFromEmailsDropDown = this.getFromEmailsDropDown.bind(this);
    this.checkSelectedEmailHasLinkedSMTP = this.checkSelectedEmailHasLinkedSMTP.bind(this);
    this.scrollMailRef = React.createRef();
  }

  getSubscriptionStatus() {
    const {
      contact,
      currentValues: { selectedEmail },
    } = this.props;
    if (!contact) return undefined;
    return selectedEmail ? selectedEmail.EmailSubscriptionStatus : undefined;
  }

  getIsEmailComposerDisabled() {
    const {
      contact,
      currentValues: { selectedEmail },
    } = this.props;
    const { ConsentStatus: consentStatus } = contact;
    const subscriptionStatus = this.getSubscriptionStatus();
    return (
      subscriptionStatus === 'Unsubscribed' ||
      (!selectedEmail?.IsAddedByUser &&
        consentStatus &&
        (consentStatus !== 'Approved' || subscriptionStatus !== 'Subscribed'))
    );
  }

  onChangeEmail(emailValue) {
    const {
      eventCallbacks,
      contact: { Emails: emails },
    } = this.props;
    if (!Array.isArray(emailValue)) {
      const isEmailExist = !!emails.find(entry => entry.EmailAddress === emailValue);
      if (isEmailExist) eventCallbacks.onChangeMail(emailValue);
    } else {
      eventCallbacks.onChangeMultipleMail(emailValue);
    }
    this.setState({
      replyToId: null,
      emailOptionValue: null,
    });
  }

  onChangeFromEmail(emailId) {
    const { eventCallbacks } = this.props;
    eventCallbacks.onChangeFromEmail(emailId);
    const isSmtpConfiguredForSelectedEmail = this.checkSelectedEmailHasLinkedSMTP(emailId);
    this.setState({
      isSmtpConfiguredForSelectedEmail,
    });
  }

  handleRestartbuttonConfirmationClick = () => {
    const { setRestartButtonOnClick } = this.props;
    setRestartButtonOnClick();
    this.setState({
      restartModalVisibility: false,
    });
  };

  handleRestartbuttonCancelClick = () => {
    this.setState({
      restartModalVisibility: false,
    });
  };

  handleRestartButtonChanges = () => {
    this.setState({
      restartModalVisibility: true,
    });
  };

  handleSpecificationOnClick = () => {
    const { getResponseFromChatGpt, updateCurrentInputValue, setChatGptButtonOnChange } = this.props;
    setChatGptButtonOnChange();
    getResponseFromChatGpt(chatGptDefaultEmailConfiguration);
    updateCurrentInputValue();
  };

  handleRegenerateOnClick = value => {
    const { getResponseFromChatGpt, updateCurrentInputValue, counter, generativeEmailsList, restartButtonFlag } =
      this.props;
    const additionalInputTags = generativeEmailsList[counter - 1]?.GenerativeAIContext?.UserPrompts;
    const chatGptPreviousContext = generativeEmailsList[counter - 1]?.GenerativeAIContext;
    if (restartButtonFlag) {
      const newChatGptPayload = {
        Intent: '',
        MergeTags: [],
        UserPrompts: value ? [value] : [],
        IsDefaultPromptRequired: false,
        Stream: true,
      };
      getResponseFromChatGpt(newChatGptPayload, true);
    } else {
      // eslint-disable-next-line no-lonely-if
      let newChatGptPayload;
      if (additionalInputTags) {
        newChatGptPayload = {
          ...chatGptPreviousContext,
          UserPrompts: value ? [...additionalInputTags, value] : additionalInputTags,
        };
      } else {
        newChatGptPayload = {
          ...chatGptDefaultEmailConfiguration,
          UserPrompts: value ? [value] : [],
        };
      }

      getResponseFromChatGpt(newChatGptPayload, true);
    }
    updateCurrentInputValue();
  };

  handleRegenerateWithOutValue = () => {
    this.handleRegenerateOnClick('');
  };

  findRepliesRecurively(id, emails) {
    const repliedMails = emails.filter(email => email.ReplyToMessageId === id);
    const repliesToReplies = [];
    if (repliedMails.length !== 0) {
      repliedMails.forEach(repliedMail =>
        repliesToReplies.push(...this.findRepliesRecurively(repliedMail.OriginalMessageId, emails))
      );
      return [...repliesToReplies, ...repliedMails];
    }
    return [];
  }

  groupEmailsByParticipantsAndSort(emails) {
    const emailGroups = {};
    for (const email of emails) {
      const participants = [email.From, email.To].sort();
      const key = `${participants[0]}-${participants[1]}`;
      if (!emailGroups[key]) {
        emailGroups[key] = [];
      }
      emailGroups[key].push(email);
    }
    Object.keys(emailGroups).forEach(key => {
      emailGroups[key].sort((a, b) => new Date(b.SentTime || b.ReceivedTime) - new Date(a.SentTime || a.ReceivedTime));
    });
    return emailGroups;
  }

  getMergedEmails() {
    const { allEmails } = this.props;
    if (!allEmails?.length) return [];
    const repliedEmails = allEmails.filter(email => !!email.ReplyToMessageId);
    const initiatorEmails = allEmails.filter(email => !email.ReplyToMessageId);
    const listOfEmailGroups = [];
    const ungroupedEmails = [];
    initiatorEmails.forEach(initiatorEmail => {
      const repliedEmailChain = this.findRepliesRecurively(initiatorEmail.OriginalMessageId, repliedEmails);
      const emailGroup = [...repliedEmailChain, initiatorEmail];

      // If the group only contains the initiator email, add it to ungroupedEmails
      if (repliedEmailChain.length === 0) {
        ungroupedEmails.push(initiatorEmail);
      } else {
        listOfEmailGroups.push(emailGroup);
      }
    });

    // ! Hack to fill reply emails which have mismatched message IDs (This happens in case of Gmail)
    const groupedEmails = listOfEmailGroups.flat();
    const ungroupedFiltered = allEmails.filter(
      email => !groupedEmails.includes(email) && !ungroupedEmails.includes(email)
    );
    const participantBasedGroups = this.groupEmailsByParticipantsAndSort([...ungroupedEmails, ...ungroupedFiltered]);
    const allGroups = [...listOfEmailGroups, ...Object.values(participantBasedGroups)];

    // Sort all email groups based on the latest SentTime or ReceivedTime within each group
    allGroups.sort((groupA, groupB) => {
      const latestTimeForGroupA = Math.max(
        ...groupA.map(email => new Date(email.SentTime || email.ReceivedTime).getTime())
      );
      const latestTimeForGroupB = Math.max(
        ...groupB.map(email => new Date(email.SentTime || email.ReceivedTime).getTime())
      );
      return latestTimeForGroupB - latestTimeForGroupA;
    });

    return allGroups;
  }

  onReplyClickCandidate360 = async (e, email, key) => {
    const { eventCallbacks, contact, setCurrentReplyingToEmail } = this.props;
    const verifiedUserEmails = this.getFromEmailsDropDown();
    const { Subject: emailSubject, OriginalMessageId } = email;
    const { Emails: emails } = contact;
    const mail = emails.find(
      m => m.EmailAddress?.toLowerCase() === email.From || m.EmailAddress?.toLowerCase() === email.To
    );
    if (mail) eventCallbacks.onChangeMail(mail.EmailAddress);
    if (verifiedUserEmails.includes(email.From)) eventCallbacks.onChangeFromEmail(email.From);
    eventCallbacks.onTemplateSelect(undefined);
    const cleanSubject = removeEmptyParagraphWithBreak(emailSubject);
    const replyEmailSubject = cleanSubject ?? '';
    e.stopPropagation();
    eventCallbacks.onUpdateEmailSubject({ htmlContent: replyEmailSubject });
    await eventCallbacks.setSubjectDisability(false);
    this.setState({ replyToId: OriginalMessageId });
    setCurrentReplyingToEmail([key]);
  };

  getCandidate360ReplyButton = ({ isEmailReplyEnabled, currentPanel, currentEmailId, email }) => {
    return isEmailReplyEnabled && currentPanel.includes(currentEmailId) ? (
      <div className="email-reply-icon-360">
        <Tooltip title={<FormattedMessage {...message.replyLabel} />}>
          <Button type="primary" shape="round" onClick={e => this.onReplyClickCandidate360(e, email, currentEmailId)}>
            <FormattedMessage {...message.replyLabel} />
          </Button>
        </Tooltip>
      </div>
    ) : null;
  };

  handleCollapseIconClick = ({ currentKey }) => {
    const { currentPanel } = this.state;
    const { currentReplyingToEmail, setCurrentReplyingToEmail } = this.props;
    if (currentPanel.includes(currentKey)) {
      const index = currentPanel.indexOf(currentKey);
      this.setState({ currentPanel: [...currentPanel.splice(0, index), ...currentPanel.splice(index + 1)] });
    }
    if (currentReplyingToEmail.includes(currentKey)) {
      const index = currentReplyingToEmail.indexOf(currentKey);
      setCurrentReplyingToEmail([
        ...currentReplyingToEmail.splice(0, index),
        ...currentReplyingToEmail.splice(index + 1),
      ]);
    }
  };

  getCollapseIcon = ({ currentKey }) => {
    const { currentPanel } = this.state;
    const collapseIconType = currentPanel.includes(currentKey) ? 'up' : 'down';
    return <Icon type={collapseIconType} onClick={() => this.handleCollapseIconClick({ currentKey })} />;
  };

  handlePanelChange = ({ currentKey }) => {
    const { currentPanel } = this.state;
    const { currentReplyingToEmail } = this.props;
    if (!currentPanel.includes(currentKey)) {
      this.setState({ currentPanel: [...currentPanel, currentKey] });
    }
    if (!currentReplyingToEmail.includes(currentKey) && currentPanel.includes(currentKey)) {
      const index = currentPanel.indexOf(currentKey);
      this.setState({ currentPanel: [...currentPanel.splice(0, index), ...currentPanel.splice(index + 1)] });
    }
  };

  showEmailStatus = ({ email, isFailed, isPending }) => {
    const emailActivityStatus = !email.ReceivedTime ? getEmailStatus(email) : null;
    const emailFailedStatus = isFailed ? (
      <div className="email-time failed">
        <FormattedMessage {...message.failedLabel} />
      </div>
    ) : null;
    const emailPendingStatus = isPending ? (
      <div className="email-time">
        <FormattedMessage {...message.sendingLabel} />
      </div>
    ) : null;
    return { emailActivityStatus, emailFailedStatus, emailPendingStatus };
  };

  showEmailRecievedAndSentTime = ({ isSuccess, email, intl }) => {
    const emailReceivedTime = email.ReceivedTime ? (
      <span>
        <FormattedMessage {...message.receivedLabel} />, {getRelativeTime(email.ReceivedTime, intl)}
      </span>
    ) : null;
    const emailSentTime = email.SentTime ? (
      <span>
        <FormattedMessage {...message.sentLabel} />, {getRelativeTime(email.SentTime, intl)}
      </span>
    ) : null;
    return isSuccess ? (
      <div className="email-time">
        {emailReceivedTime}
        {emailSentTime}
      </div>
    ) : null;
  };

  showCurrentEmailInfo = ({ isReplyingToCurrentEmail, email }) => {
    const subject = removeEmptyParagraphWithBreak(email.Subject) ? (
      email.Subject
    ) : (
      <span>
        (<FormattedMessage {...message.noSubjectLabel} />)
      </span>
    );
    return isReplyingToCurrentEmail ? (
      <div className="email-panel-email-info">
        <div>
          <FormattedMessage {...message.sentLabel} />:{' '}
          {moment.utc(email.SentTime).local().format('MMM DD YYYY , hh:mm A  ')}
        </div>
        <div>
          <FormattedMessage {...message.fromLabel} />: {email.From}
        </div>
        <div>
          <FormattedMessage {...message.subjectLabel} />: {subject}
        </div>
        <div>
          <FormattedMessage {...message.toLabel} />: {email.To}
        </div>
      </div>
    ) : null;
  };

  showRichTextEditorInEmailPanel = ({ isReplyingToCurrentEmail }) => {
    const { eventCallbacks, currentValues, contactsTabRef, intl } = this.props;
    const mergeTags = this.getMergeTags();
    const { emailBodyMergeTags } = mergeTags;
    return isReplyingToCurrentEmail ? (
      <RichTextEditor
        className="mail-editor"
        onChange={eventCallbacks.onUpdateMailContent}
        editorContent={{ htmlContent: currentValues.emailInputValue }}
        mergeTags={emailBodyMergeTags}
        showToolbar
        placeholder={intl.formatMessage({ ...placeholder.startFromHereLabel })}
        isEmailPanel
        mailRef={contactsTabRef}
      />
    ) : null;
  };

  getEmailStack(isSendButtonDisabled) {
    const { featureToggleList, currentReplyingToEmail, intl, personId, candidate, openCandidateView } = this.props;
    const { currentPanel } = this.state;
    const isEmailReplyEnabled = featureToggleList.EmailReply.IsEnabled;
    const listOfEmailGroups = this.getMergedEmails();

    let candidate360CollapseHeaderClassname = '';

    const isEmailComposerDisabled = this.getIsEmailComposerDisabled();
    const isSendDisabled = isSendButtonDisabled || isEmailComposerDisabled;
    return listOfEmailGroups.map(mergedEmails => {
      const mergedEmailStack = mergedEmails.map(email => {
        const { JobId, JobTitle } = email;
        const isPending = email.Status === 'Pending';
        const isFailed = email.Status === 'Failed';
        const isSuccess = email.Status === 'Success' || !email.Status;
        const subject = removeEmptyParagraphWithBreak(email.Subject) ? (
          email.Subject
        ) : (
          <span>
            (<FormattedMessage {...message.noSubjectLabel} />)
          </span>
        );
        const preHeader = email?.PreHeader
          ? `(${email.PreHeader.replace(htmlTagRemoveRegex, '').split('&nbsp;').join(' ')})
              `
          : null;
        const preHeader360 = preHeader ? (
          <div className="email-preHeader-360">
            <FormattedMessage {...message.preheaderLabel} />: {preHeader}
          </div>
        ) : null;
        const { emailActivityStatus, emailFailedStatus, emailPendingStatus } = this.showEmailStatus({
          email,
          isFailed,
          isPending,
        });
        const candidate360ViewPanelHeader =
          currentReplyingToEmail.includes(email.Id) && currentPanel.includes(email.Id) ? (
            <div className="candidate-reply-email-header">
              {this.getEmailComposer(true)}
              <div
                className="collapse-and-send-button-wrapper"
                onClick={() => this.handleCollapseIconClick({ currentKey: email.Id })}
                role="presentation"
              >
                <div>{this.getCollapseIcon({ currentKey: email.Id })}</div>
                <Button type="primary" shape="round" onClick={this.handleSubmit} disabled={isSendDisabled}>
                  <FormattedMessage {...message.sendButton} />
                </Button>
              </div>
            </div>
          ) : (
            <div className="candidate-360-view-panel-header">
              <div className="all-context-email-job-info">
                {personId && JobId && JobTitle && (
                  <div className="job-info-container">
                    <span>
                      Job Title: {JobTitle}, ID:{' '}
                      <u
                        onClick={e => {
                          e.stopPropagation();
                          openCandidateView(candidate, JobId);
                        }}
                        role="presentation"
                      >
                        {JobId}
                      </u>
                    </span>
                  </div>
                )}
              </div>
              <div className="email-subject-and-email-to-container">
                <div className="email-subject-360">
                  <FormattedMessage {...message.subjectLabel} />: {subject}
                </div>
                {preHeader360}
                <div className="email-to">
                  <FormattedMessage {...message.toLabel} />: {email.To}
                </div>
                <div className="subject-and-collapse-icon">
                  <div className="email-from">
                    <FormattedMessage {...message.fromLabel} />: {email?.From}
                  </div>
                  <div className="candidate-360-mail-collapse-icon">
                    {this.getCollapseIcon({ currentKey: email.Id })}
                  </div>
                </div>
              </div>
              <div className="email-meta-reply-icon">
                {this.getCandidate360ReplyButton({
                  isEmailReplyEnabled,
                  currentPanel,
                  currentEmailId: email.Id,
                  email,
                })}
                <div className="email-meta">
                  {emailActivityStatus}
                  {emailFailedStatus}
                  {emailPendingStatus}
                  {this.showEmailRecievedAndSentTime({ isSuccess, email, intl })}
                </div>
              </div>
            </div>
          );
        const isReplyingToCurrentEmail = currentReplyingToEmail.includes(email.Id) && currentPanel.includes(email.Id);
        candidate360CollapseHeaderClassname = classnames('candidate-360-email-collapse', {
          'candidate-360-collapse-header': isReplyingToCurrentEmail,
        });
        return (
          <Panel header={candidate360ViewPanelHeader} key={email.Id} showArrow={false}>
            <div className="email-panel-container">
              {this.showRichTextEditorInEmailPanel({ isReplyingToCurrentEmail })}
              {this.showCurrentEmailInfo({ isReplyingToCurrentEmail, email })}
              <HtmlViewer
                forbidTags={['style']}
                htmlContent={email.Body}
                className="email-view"
                htmlPurificationOptions={[{ FORBID_ATTR: ['href'] }]}
              />
            </div>
          </Panel>
        );
      });
      return mergedEmailStack.map(entry => (
        <div className="email-stack-group">
          <Collapse
            key={mergedEmails[0]?.Id}
            className={candidate360CollapseHeaderClassname}
            expandIconPosition="left"
            onChange={() => this.handlePanelChange({ currentKey: entry.key })}
            activeKey={currentPanel}
          >
            {entry}
          </Collapse>
        </div>
      ));
    });
  }

  componentDidMount() {
    const {
      emailTemplates,
      candidateContext,
      selectedFromEmail,
      setDefaultEmailTemplateValues,
      searchWorkflowTemplates,
      workflowWindowFlag,
      eventCallbacks,
      currentWorkflowDripTemplateIds,
      workflowDripTemplatesById,
    } = this.props;
    if (emailTemplates.length > 0 && candidateContext === 'job') setDefaultEmailTemplateValues();
    if (selectedFromEmail) {
      const isSmtpConfiguredForSelectedEmail = this.checkSelectedEmailHasLinkedSMTP(selectedFromEmail);
      this.setState({
        isSmtpConfiguredForSelectedEmail,
      });
    }

    if (workflowWindowFlag && currentWorkflowDripTemplateIds) {
      const initialWorkflowTemplateId = currentWorkflowDripTemplateIds.find(
        templateId => workflowDripTemplatesById[templateId]?.IsRecommended
      );
      eventCallbacks.onTemplateSelect(initialWorkflowTemplateId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      emailTemplates,
      candidateContext,
      selectedFromEmail,
      setDefaultEmailTemplateValues,
      searchWorkflowTemplates,
      workflowWindowFlag,
      candidate: { Id },
    } = this.props;
    if (emailTemplates.length > prevProps.emailTemplates.length && candidateContext === 'job')
      if (!prevProps.selectedFromEmail && selectedFromEmail) {
        setDefaultEmailTemplateValues();
        const isSmtpConfiguredForSelectedEmail = this.checkSelectedEmailHasLinkedSMTP(selectedFromEmail);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          isSmtpConfiguredForSelectedEmail,
        });
      }
    if (!prevProps.currentWorkflowDripTemplateIds && workflowWindowFlag)
      searchWorkflowTemplates({ page: 0, pageSize: 50, searchTerm: '', isDraft: false });
  }

  getShowMoreDripsButton = () => {
    const { dripTemplate, showAllDrips } = this.props;
    if (_.get(dripTemplate, ['Drops', 'length'], 0) > 1)
      return (
        <div
          className="connect-mail-window-drips-info"
          role="button"
          tabIndex={0}
          onClick={showAllDrips}
          onKeyPress={showAllDrips}
        >
          +{dripTemplate.Drops.length - 1} <FormattedMessage {...jobMessage.moreDripEmailsInfo} />
        </div>
      );
    return null;
  };

  getDripDropComponent = () => {
    const { dripTemplate, mergeTags, form, timeLineClassName, version, jobDetails } = this.props;
    if (dripTemplate && dripTemplate.Drops && dripTemplate.Drops.length > 0)
      return (
        <DripsDisplayContainer
          initialValues={{ Drops: (dripTemplate.Drops || []).slice(1) }}
          mergeTags={mergeTags}
          form={form}
          timeLineClassName={timeLineClassName}
          initialTimelineIndex={1}
          version={version}
          jobDetails={jobDetails}
          scrollMailRef={this.scrollMailRef}
          isConnectMailWindow
        />
      );
    return null;
  };

  getEmailsDropdown(dropDownClasses) {
    const {
      contact,
      currentValues: { selectedEmail },
      form: { getFieldDecorator },

      currentSelectedEmail,
      isConnectContentPane,
      workflowWindowFlag,
      intl,
    } = this.props;
    const { emailOptionValue } = this.state;
    const { Emails: emails, ConsentStatus: consentStatus, ConsentConfiguration: consentConfiguration } = contact;
    if (!emails || !emails.length) {
      return null;
    }
    const addOthersOption =
      consentStatus &&
      consentStatus !== 'Approved' &&
      consentConfiguration &&
      !consentConfiguration.IsContactViewAllowed &&
      emails.find(e => e.IsAddedByUser);
    const sortedEmails = getSortedEmailsByEmailTypes({ emails });
    const allowEmailAddOption = emailOptionValue && !isConnectContentPane;
    const maxEmailLength = 40;
    const selectedCurrentMail = currentSelectedEmail || selectedEmail?.EmailAddress;

    return (
      <div className="emails-dropdown-select-wrapper">
        <div className={dropDownClasses || 'connect-mail-window-flex-items mail-dropdown'}>
          <Item rules={[{ required: true }]}>
            {getFieldDecorator('email', {
              rules: [{ validator: validateEmail }],
              initialValue: selectedCurrentMail,
            })(
              <Select
                dropdownClassName="connect-drawer"
                className="select-to-dropdown-360"
                placeholder={intl.formatMessage({ ...placeholder.selectEmailAddressLabel })}
                placement="topLeft"
                mode={workflowWindowFlag ? 'multiple' : 'default'}
                onChange={value => this.onChangeEmail(value)}
                dropdownStyle={{ zIndex: 2400, minWidth: 'fit-content' }}
                showSearch
                onSearch={this.setEmailOptionValue}
                onSelect={this.onEmailAddClick}
                notFoundContent=""
              >
                {sortedEmails.map(email => {
                  return _.get(email, ['IsRefunded'], false) ? null : (
                    <Option value={email.EmailAddress} key={email.EmailAddress}>
                      <span className={validateStatus(email.ValidityStatus) ? 'invalid-spam-email' : null}>
                        {getEmailAddressWithEmailTypeIcon({
                          email,
                          maxEmailLength,
                          isEllipsedTextForManualEmails: true,
                        })}
                      </span>
                    </Option>
                  );
                })}
                {addOthersOption ? (
                  <Option value="others" key="others">
                    <FormattedMessage {...message.othersLabel} />
                  </Option>
                ) : null}
                {allowEmailAddOption ? (
                  <Option value={emailOptionValue} key={emailOptionValue} className="email-option-container">
                    <span>{emailOptionValue}</span>
                    <span className="add-email">
                      + <FormattedMessage {...message.addEmailLabel} />
                    </span>
                  </Option>
                ) : null}
              </Select>
            )}
          </Item>
        </div>
      </div>
    );
  }

  getFromEmailsDropDown() {
    const { userEmails } = this.props;
    return getVerifiedUserEmails(userEmails);
  }

  checkSelectedEmailHasLinkedSMTP(emailId) {
    const { connectSettings } = this.props;
    const { UserEmailProviders, AdminEmailProviders, OrgDefaultEmail = {} } = connectSettings;
    const linkedEmails = getProviderLinkedEmails(UserEmailProviders);
    const lowercaseLinkedEmails = linkedEmails.map(email => email?.toLowerCase());
    const updatedEmailId = emailId?.toLowerCase();
    return (
      (emailId && OrgDefaultEmail.EmailId?.toLowerCase() === updatedEmailId) ||
      (lowercaseLinkedEmails || []).includes(updatedEmailId) ||
      (AdminEmailProviders || []).length !== 0
    );
  }

  getDropdownWithLabel = () => {
    const { selectedFromEmail } = this.props;
    const verifiedUserEmails = this.getFromEmailsDropDown();
    return (
      <DropdownWithLabel
        cssClassname="connect-email-dropdown-360"
        optionValues={verifiedUserEmails}
        initialValue={selectedFromEmail}
        placeholderValue="Select an email address (required)"
        onChange={this.onChangeFromEmail}
        disabled={(verifiedUserEmails?.length ?? 0) < 2}
      />
    );
  };

  getSelectTemplateDropdown = workflowDropdown => {
    const {
      emailTemplates,
      emailTemplateIds,
      emailTemplatesById,
      eventCallbacks,
      currentValues,
      currentWorkflowDripTemplateIds,
      workflowDripTemplatesById,
      enableGenerationThroughChatGpt,
      candidateContext,
      intl,
    } = this.props;
    const isPulse = isPulseUser();
    const modifiedTemplateIds =
      candidateContext === 'segment'
        ? emailTemplateIds.filter(emailTemplateId => !emailTemplatesById[emailTemplateId].IsDrip)
        : emailTemplateIds;
    const templateSelectClassname = !currentValues.emailTemplateName ? 'empty-select-template' : '';
    const templateIds = workflowDropdown ? currentWorkflowDripTemplateIds : modifiedTemplateIds;
    const templateDetails = workflowDropdown ? workflowDripTemplatesById : emailTemplatesById;
    if ((emailTemplates?.length || !isPulse) && enableGenerationThroughChatGpt)
      return (
        <Select
          dropdownClassName={`connect-drawer email-templates-dropdown ${
            workflowDropdown && 'workflow-master-template'
          }`}
          placeholder={
            workflowDropdown ? (
              <FormattedMessage {...message.selectWorkflowLabel} />
            ) : (
              <FormattedMessage {...message.selectTemplateLabel} />
            )
          }
          value={currentValues.emailTemplateName}
          allowClear={!workflowDropdown}
          placement="topLeft"
          onChange={eventCallbacks.onTemplateSelect}
          dropdownStyle={{ zIndex: 2400 }}
          className={`select-tempalete-candidate-360 ${templateSelectClassname}`}
        >
          {templateIds.map(templateId => {
            const emailTemplateDetails = templateDetails[templateId];
            return (
              <Option key={templateId} value={templateId} title={templateDetails[templateId].Name}>
                <span className="email-template-name">{templateDetails[templateId].Name}</span>
                {!workflowDropdown
                  ? getEmailTemplateIdentifier(
                      emailTemplateDetails.IsDrip,
                      emailTemplateDetails.IsSystemTemplate,
                      emailTemplateDetails.IsDefault,
                      emailTemplateDetails.IsPublished
                    )
                  : getEmailTemplateIdentifier(
                      null,
                      emailTemplateDetails.IsRecommended,
                      emailTemplateDetails.IsRecommended,
                      null
                    )}
              </Option>
            );
          })}
        </Select>
      );
    return null;
  };

  showSMTPNotConfiguredWarning = ({ selectedFromEmail, isSmtpConfiguredForSelectedEmail }) => {
    const { version } = this.props;
    return selectedFromEmail && !isSmtpConfiguredForSelectedEmail ? (
      <div className="connect-mail-window-alert">
        <SmtpNotConfiguredWarning selectedEmailFrom={selectedFromEmail} version={version} />
      </div>
    ) : null;
  };

  showNonCompatibleTemplateWarning = () => {
    const { candidateContext } = this.props;
    return candidateContext === 'segment' ? (
      <div className="template-label">
        <FormattedMessage {...message.segmentTemplateSelectionPrompt} />
      </div>
    ) : null;
  };

  showDripComponent = () => {
    const { isAllDripsVisible } = this.props;
    return !isAllDripsVisible ? this.getShowMoreDripsButton() : this.getDripDropComponent();
  };

  isSendButtonDisabled = () => {
    const {
      currentValues,
      selectedFromEmail,
      currentValues: { selectedEmail },
      bannerVisibility,
    } = this.props;
    const regex = /\n$/gi;
    const trimmedEmailBody = currentValues.emailInputValue?.trim(); // to remove HTML tag

    return (
      this.myRef.current.quillRef.getText().trim().replace(regex, '').length !== 0 &&
      !getPreHeaderValidationStatus(this.preHeaderRef) &&
      currentValues.emailInputValue &&
      parseTextFromHtml(trimmedEmailBody).length !== 0 &&
      selectedFromEmail &&
      selectedEmail &&
      !bannerVisibility
    );
  };

  addEmail = value => {
    const { jobGuid, candidate, addContact } = this.props;
    const contactObject = {
      Emails: [
        {
          EmailAddress: value,
          IsAddedByUser: true,
          ValidityStatus: 'Valid',
        },
      ],
    };
    addContact(jobGuid, candidate.PersonId, contactObject);
  };

  onEmailAddClick = async value => {
    const {
      eventCallbacks,
      form,
      contact: { Emails: emails },
      setCurrentSelectedEmail,
      workflowWindowFlag,
    } = this.props;
    const { emailOptionValue } = this.state;
    const { validateFields } = form;
    const isEmailExist = !!emails.find(entry => entry.EmailAddress === value);
    if (setCurrentSelectedEmail) setCurrentSelectedEmail(value);
    if (!isEmailExist) {
      validateFields(err => {
        if (!err) {
          this.addEmail(emailOptionValue);
          eventCallbacks.onChangeMail(value);
        }
      });
    }

    if (workflowWindowFlag) {
      const isEmailValid = emailValidator(emailOptionValue);
      if (isEmailValid) this.addEmail(emailOptionValue);
    }
  };

  setEmailOptionValue = value => {
    this.setState({
      emailOptionValue: value,
    });
  };

  getEmailPanelSubject = ({ isEmailPanel, addEmailSubjectMergeTags, mailSubjectEditorClass }) => {
    const { eventCallbacks, currentValues, isSubjectDisabled, intl } = this.props;
    const mergeTags = this.getMergeTags();
    const { emailSubjectMergeTags } = mergeTags;
    return isEmailPanel ? (
      <div className="connect-email-subject">
        {this.getEmailSubjectLabelWithMergeTags({ subjectmergeTags: addEmailSubjectMergeTags })}
        {addEmailSubjectMergeTags}
        <RichTextEditor
          className={mailSubjectEditorClass}
          editorContent={{ htmlContent: currentValues.mailSubject }}
          onChange={eventCallbacks.onUpdateEmailSubject}
          mergeTags={emailSubjectMergeTags}
          showToolbar={false}
          ref={this.myRef}
          disabled={isSubjectDisabled}
          placeholder={intl.formatMessage({ ...placeholder.enterSubjectLabel })}
        />
      </div>
    ) : null;
  };

  getEmailPreHeaderEditorFor360 = ({ addEmailPreHeaderMergeTags, mailSubjectEditorClass }) => {
    const { eventCallbacks, currentValues, preHeaderVisibility, removePreHeaderFromEmail, dripTemplate, intl } =
      this.props;
    const mergeTags = this.getMergeTags();
    const { emailPreHeaderMergeTags } = mergeTags;
    const emailPreHeaderValidationMessage = getPreHeaderValidationStatus(this.preHeaderRef)
      ? 'Preheader cannot exceed 100 characters'
      : '';
    return preHeaderVisibility && !dripTemplate ? (
      <div className="connect-email-preHeader">
        {this.getEmailPreHeaderLabelWithTooltip()}
        {addEmailPreHeaderMergeTags}
        <Form.Item
          help={emailPreHeaderValidationMessage}
          validateStatus={getPreHeaderValidationStatus(this.preHeaderRef)}
          colon={false}
        >
          <RichTextEditor
            className={mailSubjectEditorClass}
            editorContent={{ htmlContent: currentValues.mailPreHeader }}
            onChange={eventCallbacks.onUpdateEmailPreHeader}
            mergeTags={emailPreHeaderMergeTags}
            showToolbar={false}
            ref={this.preHeaderRef}
            placeholder={intl.formatMessage({ ...placeholder.addPreheaderLabel })}
          />
        </Form.Item>
        &nbsp;
        <span id="candidateView360PreHeaderRemoveSection">
          <Icon id="candidateViewPreHeaderRemoveIcon" onClick={removePreHeaderFromEmail} type="delete" />
        </span>
      </div>
    ) : null;
  };

  getEmailPanelPreHeader = ({ isEmailPanel, addEmailPreHeaderMergeTags, mailSubjectEditorClass }) => {
    return isEmailPanel
      ? this.getEmailPreHeaderEditorFor360({ addEmailPreHeaderMergeTags, mailSubjectEditorClass })
      : null;
  };

  getCurrentUsedEditor = () => {
    const {
      eventCallbacks,
      currentValues,

      textEditorContentVisibility,
      isChatGptContentGenerated,
      isSubmitButtonEnabled,
      enableGenerationThroughChatGpt,
      chatGptBodyRef,
      intl,
    } = this.props;
    const mergeTags = this.getMergeTags();
    const { emailBodyMergeTags } = mergeTags;
    return currentValues.editorUsed?.toLowerCase() === 'reactemaileditor' ? (
      <HtmlViewer htmlContent={currentValues.emailInputValue} className="connect-email-preview" />
    ) : (
      <RichTextEditor
        className={!enableGenerationThroughChatGpt ? 'chatGpt-mail-editor' : 'mail-editor-360'}
        editorContent={{
          htmlContent: currentValues.emailInputValue,
        }}
        type="body"
        ref={chatGptBodyRef}
        isSubmitButtonEnabled={isSubmitButtonEnabled}
        onChange={eventCallbacks.onUpdateMailContent}
        mergeTags={emailBodyMergeTags}
        showToolbar
        disabled={currentValues.isReadonly}
        placeholder={intl.formatMessage({ ...placeholder.enterYourTextHereLabel })}
        isChatGptContentGenerated={isChatGptContentGenerated}
        textEditorContentVisibility={textEditorContentVisibility}
        footerButtons={this.footerButtons()}
        enableGenerationThroughChatGpt={enableGenerationThroughChatGpt}
        isCandidate360MailWindow
        mailRef={this.scrollMailRef}
      />
    );
  };

  showAlertMessage = () => {
    const { bannerVisibility, enableGenerationThroughChatGpt } = this.props;
    return bannerVisibility && !enableGenerationThroughChatGpt ? (
      <Alert
        className="alert-banner"
        description={
          <div className="alert-banner-descrition">
            <span className="banner-description-tag1">
              <FormattedMessage {...message.emailGenerationInputLabel} />
            </span>
            <span className="banner-description-tag2">
              <FormattedMessage {...message.chatGPTAssistanceLabel} />{' '}
            </span>
            <span className="banner-description-tag3">
              <FormattedMessage {...message.useExistingTemplatesLabel} />
            </span>
          </div>
        }
      />
    ) : null;
  };

  getEmailSubjectLabelWithMergeTags = ({ subjectMergeTags }) => {
    return (
      <div className="candidate-view-email-subject-label">
        <span className="candidate-view-label-font">
          <FormattedMessage {...message.subjectLabel} />
        </span>
        <span id="labelMergeTag">{subjectMergeTags}</span>
      </div>
    );
  };

  getEmailSubjectLabelWithMergeTagsFor360 = ({ subjectMergeTags }) => {
    const { addPreHeaderToEmail, preHeaderVisibility, isChatGptContentGenerated, dripTemplate } = this.props;
    const buttonId = !isChatGptContentGenerated
      ? 'candidateView360AddPreHeaderButton'
      : 'candidateView360AddPreHeaderButtonDisabled';
    const preHeaderButton = (
      <Tooltip zIndex={2223} title={<FormattedMessage {...message.preheaderLabel} />}>
        <Button id={buttonId} type="link" onClick={addPreHeaderToEmail}>
          <PreHeaderIcon />
        </Button>
      </Tooltip>
    );

    return (
      <div className="preheader-with-subject">
        <div className="candidate-view-email-subject-label">
          <span id="labelMergeTag">{subjectMergeTags}</span>
        </div>
        {!preHeaderVisibility && !dripTemplate ? preHeaderButton : null}
      </div>
    );
  };

  footerButtons = () => {
    const {
      displayRegenerateButton,
      enableGenerationThroughChatGpt,
      handleStopGenerationOnClick,
      textEditorContentVisibility,
      candidate,
    } = this.props;
    let button;
    if (!enableGenerationThroughChatGpt) {
      if (displayRegenerateButton) {
        button = (
          <Button
            className="generate-AI-buttons"
            onClick={this.handleRegenerateWithOutValue}
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.regenerateEmailUsingChatGpt,
              candidate
            )}
          >
            <Icon type="sync" /> <FormattedMessage {...message.regenerateChatGPTResponseLabel} />
          </Button>
        );
      }
      if (textEditorContentVisibility && !displayRegenerateButton) {
        button = (
          <Button
            className="generate-AI-buttons"
            onClick={handleStopGenerationOnClick}
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.stopChatGptPromptForEmailGenerate,
              candidate
            )}
          >
            <Icon type="border" />
            <FormattedMessage {...message.stopGeneratingLabel} />
          </Button>
        );
      }
    }
    return <div className={!enableGenerationThroughChatGpt ? 'footer-buttons' : ''}>{button}</div>;
  };

  getEmailPreHeaderLabelWithTooltip = () => {
    return (
      <div className="candidate-view-email-label-section">
        <span className="candidate-view-label-font">
          <FormattedMessage {...message.preheaderLabel} />
        </span>
        <Tooltip zIndex={2222} title={<FormattedMessage {...message.preheaderInfoMessage} />}>
          <Icon type="question-circle-o" />
        </Tooltip>
      </div>
    );
  };

  regenerativeVarients = () => {
    const {
      featureToggleList,
      regenerativeVarients,
      generativeEmailListSize,
      generativeVarientsLeftClick,
      isSubmitButtonEnabled,
      counter,
      generativeVarientsRightClick,
      bannerVisibility,
    } = this.props;
    const emailGenerationWithChatGptEnabled = featureToggleList.GenerativeAIEmailGeneration.IsEnabled;
    return emailGenerationWithChatGptEnabled && regenerativeVarients ? (
      <div>
        {generativeEmailListSize > 0 ? (
          <span style={{ marginTop: '5px' }}>
            <Tooltip
              overlayClassName="regenerative-varients-tooltip"
              title="Regenerative Varients"
              trigger="hover"
              zIndex={2223}
            >
              <div className={!isSubmitButtonEnabled || bannerVisibility ? 'disabled-icon' : ''}>
                {!isSubmitButtonEnabled || bannerVisibility ? (
                  <Icon type="left" className="disabled-icon" />
                ) : (
                  <Icon type="left" onClick={generativeVarientsLeftClick} />
                )}

                {`${counter}/${generativeEmailListSize}`}
                {!isSubmitButtonEnabled || bannerVisibility ? (
                  <Icon type="right" className="disabled-icon" />
                ) : (
                  <Icon type="right" onClick={generativeVarientsRightClick} />
                )}
              </div>
            </Tooltip>
          </span>
        ) : null}
      </div>
    ) : null;
  };

  chatGptButtonAndVarients = () => {
    const { featureToggleList, enableGenerationThroughChatGpt, candidateContext, candidate } = this.props;
    const emailGenerationWithChatGptEnabled = featureToggleList.GenerativeAIEmailGeneration.IsEnabled;
    return (
      <div className="regenerateButton-varients">
        {emailGenerationWithChatGptEnabled && enableGenerationThroughChatGpt && candidateContext !== 'segment' ? (
          <>
            <Button
              className="regenerate-button"
              shape="round"
              onClick={this.handleSpecificationOnClick}
              style={{ color: '#51575E', display: 'flex', paddingTop: '5px' }}
              sk-event-name={getEventNameByCandidateStatus(
                eventTypes.candidate.candidateConnect.useChatGptPromptForEmail,
                candidate
              )}
            >
              <span className="regenerate-text">
                <FormattedMessage {...message.generateEmailWithLabel} />
              </span>
              <span style={{ marginLeft: '4px' }}>
                <OpenAiIcon />
              </span>
              <span style={{ marginLeft: '0px', paddingTop: '1px', fontWeight: '600', color: '#1F2730' }}>
                <FormattedMessage {...message.chatGptLabel} />
              </span>
            </Button>
            {this.regenerativeVarients()}
          </>
        ) : null}
      </div>
    );
  };

  getRestartButton = displayRestartButton => {
    return displayRestartButton ? (
      <div className="restart-button-and-info-icon" data-testid="restart-with-info-icon">
        <Button className="restart-button" onClick={this.handleRestartButtonChanges} type="link">
          <span className="restart-button-content">
            {' '}
            <FormattedMessage {...message.restartLabel} />
          </span>{' '}
        </Button>

        <span id="dynamic-input-header-tooltip">
          <InfoIconWithTooltip
            placement="topLeft"
            tooltipTitle={
              <span>
                <FormattedMessage {...message.restartGenerationInfoMessage} />
              </span>
            }
            style={{ zIndex: 3000, maxWidth: '200px' }}
            overlayClassName="restart-tooltip"
          />
        </span>
      </div>
    ) : null;
  };

  getChatGptRestartButtonWithInputContent = payload => {
    const {
      enableGenerationThroughChatGpt,
      restartModalVisibility,
      displayRestartButton,
      dynamicInputLabelText,
      resolvedMergeTagValues,
      displayRegenerateButton,
    } = payload;

    const mergetags = resolvedMergeTagValues?.ResolvedMergeTagValues;
    const filteredMergeTags = mergetags?.filter(tag => !excludedMergeTags.includes(tag.Key));

    const { isChatGPTInputChanged } = this.state;

    const restartButtonClassname = displayRestartButton
      ? 'dynamic-input-header-content-modal'
      : 'input-header-content-modal';

    return !enableGenerationThroughChatGpt ? (
      <div className="consent-for-chatgpt">
        <ChatGptRestartConfirmationModal
          visible={restartModalVisibility}
          onCancel={this.handleRestartbuttonCancelClick}
          onConfirm={this.handleRestartbuttonConfirmationClick}
        />
        <div className={restartButtonClassname}>
          <div>
            <span className="dynamic-input-header-content">{dynamicInputLabelText}</span>
            <span id="dynamic-input-header-tooltip">
              <InfoIconWithTooltip
                tooltipTitle={<span>{getChatGptInputTooltip()}</span>}
                style={{ zIndex: 3000, maxWidth: '300px' }}
              />
            </span>
          </div>
          {this.getRestartButton(displayRestartButton)}
        </div>
        <DynamicTagInput
          visible={!enableGenerationThroughChatGpt}
          handleRegenerateOnClick={this.handleRegenerateOnClick}
          mergeTags={filteredMergeTags}
          displayRegenerateButton={displayRegenerateButton}
          inputChanged={isChatGPTInputChanged}
          setIsInputChanged={this.setIsInputChanged}
        />
        <div className="disclaimer-modal">
          <span className="disclaimer-modal-label">
            <FormattedMessage {...message.disclaimerLabel} />:
          </span>
          <span className="disclaimer-modal-content">
            {' '}
            <FormattedMessage {...message.contentGeneratedDisclaimerLabel} />
          </span>
        </div>
      </div>
    ) : null;
  };

  getConnectCandidate360MailTimelineItems = candidate360MailTimelinePayload => {
    const {
      isEmailPanel,
      addEmailSubjectMergeTags,
      addEmailPreHeaderMergeTags,
      connectMailClassname,
      mailSubjectEditorClass,
      connectMailTimelineNlassname,
    } = candidate360MailTimelinePayload;

    const {
      eventCallbacks,
      currentValues,
      isSubjectDisabled,
      featureToggleList,
      enableGenerationThroughChatGpt,
      counter,
      generativeEmailsList,
      setChatGptButtonOnChange,
      resolvedMergeTagValues,
      displayRestartButton,
      currentUser,
      displayRegenerateButton,
      userPromptAfterRestart,
      bannerVisibility,
      textEditorContentVisibility,
      chatGptSubjectRef,
      isSubmitButtonEnabled,
      intl,
    } = this.props;
    let firstName;
    let lastName;
    if (currentUser) {
      firstName = currentUser.given_name;
      lastName = currentUser.family_name;
    }
    const config = getConfiguration([firstName, lastName].join(' '));
    const mergeTags = this.getMergeTags();
    const { emailSubjectMergeTags } = mergeTags;
    const emailGenerationWithChatGptEnabled = featureToggleList.GenerativeAIEmailGeneration.IsEnabled;
    const { restartModalVisibility } = this.state;
    const userPrompts = generativeEmailsList[counter - 1]?.GenerativeAIContext?.UserPrompts;
    const userPromptsString = userPrompts ? userPrompts.join(', ') : '';
    const regex = /(<([^>]+)>)/gi;
    const updatedUserPromptsString = userPromptAfterRestart || userPromptsString.replace(regex, '');
    const dynamicInputLabelText = bannerVisibility ? (
      <FormattedMessage {...message.resetConversationLabel} />
    ) : (
      <FormattedMessage {...message.addInputsWithGPTLabel} />
    );
    const mailInputBoxWrapId = !enableGenerationThroughChatGpt ? 'mail360-input-box-wrap' : 'mail-input-box-wrap';

    return !isEmailPanel ? (
      <div className={`connect-email-input-wrapper ${connectMailClassname}`}>
        <Timeline.Item dot={1} className={`connect-mail-timeline-wrapper ${connectMailTimelineNlassname}`}>
          <div>
            {this.getEmailPreHeaderEditorFor360({ addEmailPreHeaderMergeTags, mailSubjectEditorClass })}
            {this.chatGptButtonAndVarients()}
          </div>

          {!enableGenerationThroughChatGpt ? (
            <div className="generative-ai-context-modal">
              {' '}
              <Button
                type="link"
                className="existing-template-button"
                disabled={!displayRegenerateButton}
                onClick={setChatGptButtonOnChange}
              >
                <span className="existing-template-button-content">
                  <FormattedMessage {...message.useExistingTemplateLabel} />
                </span>
              </Button>
              <div className="generative-ai-context">
                <div className="generative-ai-content">
                  <Avatar size={19} style={{ color: '#ffffff', backgroundColor: '#B4B7BA', marginRight: 5 }}>
                    {config.text}
                  </Avatar>
                  <EllipsedText
                    text={userPromptsString !== '' ? updatedUserPromptsString : 'Your inputs will appear here'}
                    maxTextLength={50}
                    tooltipPlacement="bottom"
                    toolTipOverlayStyle={{
                      maxWidth: 474,
                      maxHeight: 200,
                      overflowY: 'auto',
                      backgroundColor: '#1F2730',
                    }}
                  />
                </div>
                {this.regenerativeVarients()}
              </div>
            </div>
          ) : null}
          {this.showAlertMessage()}
          <div className="connect-email-subject-360">
            {this.getEmailSubjectLabelWithMergeTagsFor360({
              subjectMergeTags: addEmailSubjectMergeTags,
            })}
            <RichTextEditor
              className="custom-mail-subject-editor-360"
              editorContent={{ htmlContent: currentValues.mailSubject }}
              onChange={eventCallbacks.onUpdateEmailSubject}
              mergeTags={emailSubjectMergeTags}
              showToolbar={false}
              ref={getSubjectRef(enableGenerationThroughChatGpt, chatGptSubjectRef, this.myRef)}
              disabled={isSubjectDisabled || !textEditorContentVisibility}
              placeholder={intl.formatMessage({ ...placeholder.enterSubjectlabel })}
              emailGenerationWithChatGptEnabled={emailGenerationWithChatGptEnabled}
              showSubjectLabel
              isSubmitButtonEnabled={isSubmitButtonEnabled}
            />
          </div>
          <div
            id={mailInputBoxWrapId}
            className={classnames('connect-mail-window-flex-items', 'connect-mail-window-flex-items-candidate-view')}
          >
            <div className="email-body-360">{this.getCurrentUsedEditor()} </div>

            {this.getChatGptRestartButtonWithInputContent({
              enableGenerationThroughChatGpt,
              restartModalVisibility,
              displayRestartButton,
              dynamicInputLabelText,
              resolvedMergeTagValues,
              displayRegenerateButton,
            })}
          </div>
        </Timeline.Item>
      </div>
    ) : null;
  };

  getEmailComposer(isEmailPanel) {
    const {
      eventCallbacks,
      currentValues,
      featureToggleList,
      isAllDripsVisible,
      selectedFromEmail,
      candidate,
      conversationId,
      getConsentStatusInfoBanner,
      isSubmitButtonEnabled,
      isChatGptContentGenerated,
      dripTemplate,
    } = this.props;
    const { isSmtpConfiguredForSelectedEmail } = this.state;
    const showEmailWrapperClassname = isEmailPanel ? 'emailPanel' : '';
    let isEnabled = false;
    const { mailSubject, emailInputValue } = currentValues;
    const mailRegex = /(<([^>]+)>)/gi;
    const mailSubjectLength = mailSubject?.replace(mailRegex, '')?.length;
    const emailInputValueLength = emailInputValue?.replace(mailRegex, '').length;
    const isEmailingEnabledForUser = featureToggleList.EmailRead.IsAllowed;
    const mergeTags = this.getMergeTags();
    const { emailSubjectMergeTags, emailPreHeaderMergeTags } = mergeTags;
    const mergeTagClass = 'mail-window-merge-tag-360';
    const preHeaderMergeTagClass = 'mail-window-merge-tag-preHeader';

    const popoverTrigger = !isChatGptContentGenerated ? 'click' : '';
    const popoverClassName = isChatGptContentGenerated ? 'mergeTagDisabled' : '';
    const mailSubjectEditorClass = 'mail-subject-editor-360';
    const emailSubjectPopoverContent = (
      <div className="tags-popover-content">
        {emailSubjectMergeTags.map(mergeTag => (
          <div className="merge-tag" onClick={() => this.addTags(mergeTag)} role="presentation" key={mergeTag.Key}>
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );
    const emailPreHeaderPopoverContent = (
      <div className="tags-popover-content">
        {emailPreHeaderMergeTags.map(mergeTag => (
          <div
            className="merge-tag"
            onClick={() => this.addPreHeaderTags(mergeTag)}
            role="presentation"
            key={mergeTag.Key}
          >
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const mergeTagsContent = (
      <Tooltip zIndex={2223} title={<FormattedMessage {...message.mergeTagsLabel} />}>
        <MergeTagIcon />
      </Tooltip>
    );

    const addEmailSubjectMergeTags = (
      <div className={`mail-window-merge-tag ${mergeTagClass}`}>
        <Popover
          overlayStyle={{ zIndex: 2223 }}
          content={emailSubjectPopoverContent}
          placement="left"
          trigger={popoverTrigger}
          className={popoverClassName}
        >
          {mergeTagsContent}
        </Popover>
      </div>
    );

    const addEmailPreHeaderMergeTags = (
      <div className={`${preHeaderMergeTagClass}`}>
        <Popover
          overlayStyle={{ zIndex: 2223 }}
          content={emailPreHeaderPopoverContent}
          placement="left"
          trigger="click"
        >
          <Tooltip zIndex={2223} title="Merge Tags">
            <MergeTagIcon />
          </Tooltip>
        </Popover>
      </div>
    );

    if (this.myRef.current) {
      isEnabled = this.isSendButtonDisabled();
    }
    if (!this.myRef.current && mailSubjectLength && emailInputValueLength) {
      isEnabled = true;
    }
    let isSendButtonDisabled = !isEnabled || !isEmailingEnabledForUser || !isSmtpConfiguredForSelectedEmail;
    if (!isSubmitButtonEnabled) {
      isSendButtonDisabled = true;
    }
    const verifiedUserEmails = this.getFromEmailsDropDown();
    if (!selectedFromEmail && verifiedUserEmails?.length) {
      eventCallbacks.onChangeFromEmail(verifiedUserEmails[0]);
    }
    const connectMailClassname = isAllDripsVisible ? 'connect-mail-with-timeline' : 'connect-mail-without-timeline';
    const connectMailTimelineNlassname = currentValues.isReadonly ? 'disabled' : null;
    const candidate360MailTimelinePayload = {
      isEmailPanel,
      addEmailSubjectMergeTags,
      addEmailPreHeaderMergeTags,
      connectMailClassname,
      mailSubjectEditorClass,
      connectMailTimelineNlassname,
    };

    const modalContent360Style =
      isAllDripsVisible && !isEmailPanel
        ? {
            paddingBottom: '150px',
          }
        : {};
    return (
      <div
        className={`modal-content-360 ${showEmailWrapperClassname}`}
        ref={this.scrollMailRef}
        style={modalContent360Style}
      >
        <div>
          <div className="email-window-header">
            <div className="candidate-360-to-email-wrapper">
              <div className="candidate-email-to">
                {' '}
                <FormattedMessage {...message.toLabel} />:
              </div>{' '}
              {this.getEmailsDropdown()}
            </div>
          </div>
          {this.showSMTPNotConfiguredWarning({ selectedFromEmail, isSmtpConfiguredForSelectedEmail })}
          {getConsentStatusInfoBanner()}
          <div className="candidate-360-select-from">
            <span className="candidate-email-from">
              <FormattedMessage {...jobMessage.fromLabel} />
            </span>{' '}
            :{this.getDropdownWithLabel()}
          </div>
        </div>
        {this.showNonCompatibleTemplateWarning()}
        <div id="templates-select">{this.getSelectTemplateDropdown()}</div>
        {this.getEmailPanelSubject({ isEmailPanel, addEmailSubjectMergeTags, mailSubjectEditorClass })}
        {this.getEmailPanelPreHeader({ isEmailPanel, addEmailPreHeaderMergeTags, mailSubjectEditorClass })}
        {this.getConnectCandidate360MailTimelineItems(candidate360MailTimelinePayload)}
        {dripTemplate ? (
          <div className="connect-mail-window-drip-disclaimer-message">
            <div>
              <InfoCircleIcon />
            </div>
            <b>
              <u>
                <FormattedMessage {...jobMessage.noteLabel} />:
              </u>
            </b>
            <FormattedMessage {...message.dripWillStopOnceCandidateResponds} />
          </div>
        ) : null}
        {this.showDripComponent()}
      </div>
    );
  }

  getWorkflowEmailComposer() {
    const { workflowReportFilterData, workflowId, candidateContext } = this.props;
    const dropdownClasses = 'workflow-mail-window-flex-items mail-dropdown';
    const dropDownStyle = {
      zIndex: 2400,
      width: 400,
    };
    return (
      <div className="workflow-modal-content-360" ref={this.scrollMailRef}>
        {candidateContext === 'segment' && (
          <div className="workflow-segment-compatible-note">
            <Icon type="exclamation-circle" theme="filled" style={{ margin: '0 10px' }} />
            Please select a compatible template for this segment, which does not have any job merge tags
          </div>
        )}
        <div className={`email-window-header ${candidateContext === 'segment' ? 'segmentHeader' : ''}`}>
          <div className="email-address-container">
            <div className="candidate-360-select-from">
              <span className="candidate-email-from">From</span> :{this.getDropdownWithLabel()}
            </div>
            <div className="candidate-360-to-email-wrapper">
              <div className="candidate-email-to">To:</div> {this.getEmailsDropdown(dropdownClasses)}
            </div>
          </div>

          <div id="templates-select">{this.getSelectTemplateDropdown(dropDownStyle)}</div>
        </div>
        <CandidateWorkflow workflowId={workflowId} workflowReportFilterData={workflowReportFilterData} />
      </div>
    );
  }

  addTags(tag) {
    const { chatGptSubjectRef, enableGenerationThroughChatGpt } = this.props;
    if (enableGenerationThroughChatGpt) {
      this.myRef.current.addTags(tag, false);
    } else {
      chatGptSubjectRef.current?.addTags(tag, false);
    }
  }

  addPreHeaderTags = tag => {
    this.preHeaderRef.current.addTags(tag);
  };

  getMergeTags = () => {
    const { mergeTags, candidateContext } = this.props;
    let emailSubjectMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject'));
    let emailBodyMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailBody'));
    let emailPreHeaderMergeTags = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailPreHeader'));

    if (candidateContext === 'segment') {
      emailSubjectMergeTags = emailSubjectMergeTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
      emailBodyMergeTags = emailBodyMergeTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
      emailPreHeaderMergeTags = emailPreHeaderMergeTags.filter(
        mergeTag => !mergeTag.Key?.toLowerCase().includes('job') && mergeTag.Key.slice(0, 3)?.toLowerCase() !== 'bot'
      );
    }
    return { emailSubjectMergeTags, emailBodyMergeTags, emailPreHeaderMergeTags };
  };

  validateEmailTemplate = ({ subject, requestBody }) => {
    const { currentValues = {} } = this.props;
    const { isReadonly, editorUsed, mailPreHeader } = currentValues;
    const {
      emailSubjectMergeTags: subjectMergeTags,
      emailBodyMergeTags: bodyMergeTags,
      emailPreHeaderMergeTags: preHeaderMergeTags,
    } = this.getMergeTags();
    const _subject = requestBody
      ? `${subject} ${requestBody?.Drops?.map(drop => drop.Subject)
          .filter(x => x)
          .join(',')}`
      : subject;
    const _body = requestBody
      ? `${currentValues.emailInputValue} ${requestBody?.Drops?.map(drop => drop.Body)
          .filter(x => x)
          .join(',')}`
      : currentValues.emailInputValue;
    const emailTemplateValidationPayload = {
      subject: _subject,
      preHeader: mailPreHeader,
      preHeaderMergeTags,
      subjectMergeTags,
      bodyMergeTags,
    };
    if (!isReadonly && editorUsed?.toLowerCase() !== 'reactemaileditor') {
      emailTemplateValidationPayload.body = _body;
    }
    return validateTemplate(emailTemplateValidationPayload);
  };

  handleSubmit = event => {
    const {
      eventCallbacks,
      form,
      setInvalidTemplateNotification,
      currentValues,
      dripTemplate,
      candidateContext,
      contactsTabRef,
    } = this.props;
    const { replyToId } = this.state;
    event.preventDefault();
    const regex = /\n$/gi;
    const parsedSubject = parseTextFromHtml(currentValues?.mailSubject);
    form.validateFields((err, values) => {
      if (!err) {
        const subject =
          this.myRef.current?.quillRef.getText().trim().replace(regex, '') || parsedSubject.trim().replace(regex, '');
        const requestBody = dripTemplate ? createRequestBody(values, (dripTemplate.Drops || []).slice(1), 1) : null;
        if (!this.validateEmailTemplate({ subject, requestBody })) {
          setInvalidTemplateNotification(candidateContext);
          return;
        }
        eventCallbacks.onSend(requestBody, subject, values.email, replyToId);

        scrollToCandidate360Top(contactsTabRef);
      }
    });
  };

  showCandidate360MailComposeWindow = ({ isSendButtonDisabled }) => {
    const {
      isComposeEmailModalVisible,
      setEmailComposeModalVisibility,
      isComposeWorkflowModalVisible,
      setWorkflowComposeModalVisibility,
      currentValues: { isCommunicationAllowed, selectedMultipleEmail },
      preHeaderVisibility,
      onCloseEmailComposer,
      enableGenerationThroughChatGpt,
      dripTemplate,
      candidate,
      workflowWindowFlag,
      workflowDripTemplatesById,
      workflowId,
      jobGuid,
      selectedFromEmail,
      startCandidateWorkflow,
      startWorkflowWindowApiStatus,
      jobId,
      workflowHistory,
      setCandidateWorkflowHistoryAction,
      jobDetails = {},
    } = this.props;
    const { isChatGPTInputChanged, workflowWidowChangeContent } = this.state;
    const isEmailComposerDisabled = this.getIsEmailComposerDisabled();
    const isSendDisabled = isSendButtonDisabled || isEmailComposerDisabled;
    const shouldShowTooltip = isSendDisabled && !enableGenerationThroughChatGpt && isChatGPTInputChanged;
    const sendButtonTooltipText = getDisabledSendButtonTooltipText();
    if (workflowWindowFlag && isComposeWorkflowModalVisible && isCommunicationAllowed)
      return (
        <WorkflowWindow
          candidateId={candidate.Id}
          candidateName={candidate.Name}
          jobGuid={jobGuid}
          selectedMultipleEmail={selectedMultipleEmail}
          selectedFromEmail={selectedFromEmail}
          setModalVisibility={setWorkflowComposeModalVisibility}
          isModalVisible={isComposeWorkflowModalVisible}
          getCandidate360MailWindow={this.getWorkflowEmailComposer}
          id="connect-mail-send-button"
          htmlType="submit"
          workflowData={workflowDripTemplatesById[workflowId]}
          isSendDisabled={isSendDisabled}
          handleSubmit={this.handleSubmit}
          composeModalTitle={getComposeEmailTitle(enableGenerationThroughChatGpt)}
          context="workflow"
          workflowId={workflowId}
          preHeaderVisibility={preHeaderVisibility}
          onCloseEmailComposer={onCloseEmailComposer}
          shouldShowTooltip={shouldShowTooltip}
          sendButtonTooltipText={sendButtonTooltipText}
          startCandidateWorkflow={startCandidateWorkflow}
          startWorkflowWindowApiStatus={startWorkflowWindowApiStatus}
          jobId={jobId}
          jobTitle={jobDetails.JobTitle}
          workflowWidowChangeContent={workflowWidowChangeContent}
          workflowHistory={workflowHistory}
          setCandidateWorkflowHistoryAction={setCandidateWorkflowHistoryAction}
        />
      );
    return isComposeEmailModalVisible && isCommunicationAllowed && !workflowWindowFlag ? (
      <Candidate360MailAndMessageComposeWindow
        getCandidate360MailWindow={this.getEmailComposer}
        setModalVisibility={setEmailComposeModalVisibility}
        isModalVisible={isComposeEmailModalVisible}
        id="connect-mail-send-button"
        htmlType="submit"
        isSendDisabled={isSendDisabled}
        handleSubmit={this.handleSubmit}
        composeModalTitle={getComposeEmailTitle(enableGenerationThroughChatGpt)}
        context="email"
        preHeaderVisibility={preHeaderVisibility}
        onCloseEmailComposer={onCloseEmailComposer}
        shouldShowTooltip={shouldShowTooltip}
        sendButtonTooltipText={sendButtonTooltipText}
        dripTemplate={dripTemplate}
      />
    ) : null;
  };

  showEmails = isSendButtonDisabled => {
    const {
      allEmails,
      contact: { Emails: emails },
    } = this.props;
    return emails && !allEmails ? (
      <Skeleton active paragraph={{ rows: 6 }} />
    ) : (
      this.getEmailStack(isSendButtonDisabled)
    );
  };

  showEmailFeatureNotEnabledAlert = ({ isEmailingEnabledForUser }) => {
    return isEmailingEnabledForUser === false ? (
      <div className="user-alert-wrapper">
        <UserAlerts
          header="Pro feature alert!"
          content={
            <span>
              <FormattedMessage {...jobMessage.featureNotEnabledInfoLabel} />{' '}
              <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
              <FormattedMessage {...jobMessage.upgradeYourPlanLabel} />
            </span>
          }
        />
      </div>
    ) : null;
  };

  showCandidate360EmailComposeContent = ({ isSendButtonDisabled }) => {
    const {
      currentValues: { isCommunicationAllowed },
      featureToggleList,
      getConsentStatusInfoBanner,
      workflowWindowFlag,
      workflowHistory,
      setWorkflowComposeModalVisibility,
      stopCandidateWorkflow,
      stopWorkflowApiStatus,
      jobGuid,
      jobId,
      candidate,
      setCandidateWorkflowHistoryAction,
      jobDetails,
    } = this.props;
    const isEmailWriteEnabled = featureToggleList.EmailWrite.IsEnabled;
    const isEmailingEnabledForUser = featureToggleList.EmailRead.IsAllowed;
    const showEmailComposeContent = isCommunicationAllowed;

    return (
      <>
        {showEmailComposeContent ? null : getConsentStatusInfoBanner()}
        {showEmailComposeContent
          ? (() => {
              if (!isEmailWriteEnabled) {
                return null;
              }
              return <>{this.showCandidate360MailComposeWindow({ isSendButtonDisabled })}</>;
            })()
          : null}
        {!workflowWindowFlag ? (
          <div className="connect-mail-window-flex-items mails-wrapper" ref={this.mailsRef}>
            <div className="inner-mails-wrapper inner-mails-wrapper-360">{this.showEmails(isSendButtonDisabled)}</div>
          </div>
        ) : (
          <WorkflowTab
            setWorkflowComposeModalVisibility={setWorkflowComposeModalVisibility}
            workflowHistory={workflowHistory}
            stopCandidateWorkflow={stopCandidateWorkflow}
            stopWorkflowApiStatus={stopWorkflowApiStatus}
            candidateId={candidate.Id}
            candidateName={candidate.Name}
            jobGuid={jobGuid}
            jobId={jobId}
            jobTitle={jobDetails.JobTitle}
            setCandidateWorkflowHistoryAction={setCandidateWorkflowHistoryAction}
          />
        )}
        {showEmailComposeContent ? this.showEmailFeatureNotEnabledAlert({ isEmailingEnabledForUser }) : null}
      </>
    );
  };

  setIsInputChanged = value => {
    this.setState({ isChatGPTInputChanged: value });
  };

  render() {
    const {
      featureToggleList,
      isCandidateViewHeaderVisible,
      currentValues,
      isSubmitButtonEnabled,
      enableGenerationThroughChatGpt,
      bannerVisibility,
    } = this.props;
    const { isChatGPTInputChanged } = this.state;
    const { mailSubject, emailInputValue } = currentValues;
    const mailSubjectLength = parseTextFromHtml(mailSubject)?.length;
    const emailInputValueLength = parseTextFromHtml(emailInputValue)?.length;
    const { isSmtpConfiguredForSelectedEmail } = this.state;
    const isEmailingEnabledForUser = featureToggleList.EmailRead.IsAllowed;
    let isEnabled = false;

    if (this.myRef.current) {
      isEnabled = this.isSendButtonDisabled() || checkHtmlForImages(emailInputValue);
    }
    if (!this.myRef.current && mailSubjectLength && (emailInputValueLength || checkHtmlForImages(emailInputValue))) {
      isEnabled = true;
    }
    const isSendDisabled = !enableGenerationThroughChatGpt && isChatGPTInputChanged;

    let isSendButtonDisabled =
      !isEnabled || !isEmailingEnabledForUser || !isSmtpConfiguredForSelectedEmail || isSendDisabled;
    if (!isSubmitButtonEnabled) {
      isSendButtonDisabled = true;
    }
    if (bannerVisibility) {
      isSendButtonDisabled = true;
    }
    const connectMailWindow360Classname = isCandidateViewHeaderVisible ? '' : 'extended-connect-mail-window-360';
    return (
      <Form className="connect-mail-form">
        <div id="connect-mail-window-360" ref={this.connectMailRef} className={connectMailWindow360Classname}>
          {this.showCandidate360EmailComposeContent({
            isSendButtonDisabled,
          })}
        </div>
      </Form>
    );
  }
}

export default injectIntl(Form.create()(ConnectMailWindow));
export { ConnectMailWindow as ConnectMailWindowWithoutForm };
