import React from 'react';
import { Icon, Badge, Button } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import styles from './JobUsageBudgetNotification.module.scss';
import JobUsageBudgetApprovedIcon from '../../../Icons/JobUsageBudgetApprovedIcon';
import JobUsageBudgetDismissedIcon from '../../../Icons/JobUsageBudgetDismissedIcon';
import messages from '../../../Containers/ManualSearchV2/ManualSearchMessages';

export default function JobUsageBudgetNotification(props) {
  const { notification, onClick, featureToggleList } = props;
  const isJobUsageBudgetFeatureEnabled = featureToggleList.JobUsageBudget.IsEnabled;
  const { Payload: payload, UpdatedTime: updatedTime, UnreadCount: unreadCount, Id: userId } = notification;
  const { ActionPerformedBy: actionPerformedBy, BasicJobInfo: basicJobInfo, RequestStatus: requestStatus } = payload;
  const { JobId, JobTitle } = basicJobInfo?.[0] || {};
  const status = (
    <b>
      <FormattedMessage {...messages[requestStatus]} />
    </b>
  );
  const notificationTitle =
    actionPerformedBy === userId ? (
      <FormattedMessage
        {...messages.youHaveRequestStatusACreditRequestLabel}
        values={{ Status: status, jobTitle: <b>{JobTitle}</b>, jobId: <b>{JobId}</b> }}
      />
    ) : (
      <FormattedMessage
        {...messages.creditRequestHasBeenApproved}
        values={{ Status: status, jobTitle: <b>{JobTitle}</b>, jobId: <b>{JobId}</b> }}
      />
    );
  return isJobUsageBudgetFeatureEnabled && JobId ? (
    <div className={styles.notification} role="presentation" onClick={() => onClick(notification)}>
      <div className={styles.AcceptedIcon}>
        {requestStatus === 'Approved' ? <JobUsageBudgetApprovedIcon /> : <JobUsageBudgetDismissedIcon />}
      </div>
      <div className={styles.content}>
        <div className={styles.notificationTitle}>{notificationTitle}</div>
        <Button size="small" className={styles.viewJobButton}>
          <FormattedMessage {...messages.viewJobLabel} />
        </Button>
        <div className={styles.time}>{moment.utc(updatedTime).local().fromNow()}</div>
      </div>
      <div className={styles.viewSymbol}>
        <Badge dot={unreadCount > 0} />
      </div>
    </div>
  ) : null;
}
