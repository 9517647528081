import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'antd';
import classNames from 'classnames';
import styles from './Candidate360Info.module.scss';
import ComposeEmailAndMessageButton from '../ComposeEmailAndMessageButton/ComposeEmailAndMessageButton';
import { isPulseUser } from '../../../Utils/ConfigUtils';
import candidateDrawermessage from '../../../Containers/CandidateDrawer/messages';

function Candidate360EmailAndMessageEmptyScreen(props) {
  const {
    setComposeModalVisibility,
    activeTab,
    infoMessage,
    getFetchContactButton,
    isCandidateViewHeaderVisible,
    isDisabled,
    iconStyle,
    workflowheaderMessage,
    infoMessageStyle,
    contactInfoStyle,
    composeEmailType,
    hideButton,
    isAllEmails,
  } = props;

  const isPulse = isPulseUser();
  const defaultMessage =
    activeTab === 'message' ? (
      <FormattedMessage {...candidateDrawermessage.defaultTextInfo} />
    ) : (
      <FormattedMessage {...candidateDrawermessage.defaultMailInfoMessage} />
    );
  const message = infoMessage || defaultMessage;
  const workflowheaderText = workflowheaderMessage || (
    <FormattedMessage {...candidateDrawermessage.youAreStartingANewWorkflowLabel} />
  );
  const button = getFetchContactButton ? (
    getFetchContactButton()
  ) : (
    <ComposeEmailAndMessageButton
      setComposeModalVisibility={setComposeModalVisibility}
      activeTab={activeTab}
      isDisabled={isDisabled}
      composeEmailType={composeEmailType}
    />
  );
  const shouldHideInfoMessage = isPulse && activeTab === 'message';
  return (
    <div
      className={classNames(styles.contactInfoContainer, {
        [styles.extendedContactInfoContainer]: !isCandidateViewHeaderVisible,
      })}
      style={contactInfoStyle}
    >
      <div className={styles.chatIconAndMessage}>
        <Icon type="wechat" className={styles.weChatIcon} style={iconStyle} />
        {infoMessage || shouldHideInfoMessage ? null : (
          <div className={styles.contactInfoMessage} style={{ infoMessageStyle }}>
            <FormattedMessage {...candidateDrawermessage.newConversationLabel} />
          </div>
        )}
        {activeTab === 'workflow' && <div className={styles.contactInfoMessage}>{workflowheaderText}</div>}
      </div>
      {shouldHideInfoMessage ? null : <div className={styles.contactInfoText}>{message}</div>}
      {!(hideButton || isAllEmails) && button}
    </div>
  );
}

export default Candidate360EmailAndMessageEmptyScreen;
