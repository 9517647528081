import { Empty } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import message from '../../../Components/Placeholders/PlaceholdersMessages';
import { EmptyCandidatesIcon } from '../../../Icons/AryaIcons';
import styles from './SmartAgentCandidateEmptyScreen.module.scss';

const SmartAgentCandidateEmptyScreen = props => {
  const { activeSmartAgent = {} } = props;

  const agentId = activeSmartAgent.AgentId;
  const status = activeSmartAgent.Status;

  const getActiveMessage = () => {
    switch (status) {
      case 'Inprogress':
        return <FormattedMessage {...message.smartAgentCandidateProgressMessage} />;
      case 'Idle':
        return <FormattedMessage {...message.smartAgentCandidateIdleMessage} />;
      case 'Failed':
        return <FormattedMessage {...message.smartAgentCandidateFailedMessage} />;
      case 'Completed':
        return <FormattedMessage {...message.noCandidatesFoundLabel} />;
      default:
        return <FormattedMessage {...message.smartAgentCandidateProgressMessage} />;
    }
  };
  const activeMessage = getActiveMessage();
  const descriptionMessage = agentId ? activeMessage : <FormattedMessage {...message.noActiveAgentMessage} />;

  return (
    <div className={styles.smartAgentCandidateEmptyScreenWrapper}>
      <Empty
        image={<EmptyCandidatesIcon className={styles.smartAgentCandidateEmptyScreen} />}
        description={<div className={styles.messageInfo}>{descriptionMessage}</div>}
      />
    </div>
  );
};

export default SmartAgentCandidateEmptyScreen;
