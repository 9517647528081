import React from 'react';
import { Icon } from 'antd';

const ChatbotWhiteSvg = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49072 12.5V14.6666L8.49047 12.5H13.3241C14.1513 12.5 14.8241 11.8272 14.8241 11V2.83331C14.8241 2.00606 14.1513 1.33331 13.3241 1.33331H2.99072C2.16347 1.33331 1.49072 2.00606 1.49072 2.83331V11C1.49072 11.8272 2.16347 12.5 2.99072 12.5H4.49072ZM8.15731 9.91665C6.61981 9.91665 5.30281 8.98965 4.72381 7.66665H11.5908C11.0118 8.98965 9.69481 9.91665 8.15731 9.91665ZM10.4074 5.79165C9.88989 5.79165 9.46989 5.37165 9.46989 4.85415C9.46989 4.33665 9.88989 3.91665 10.4074 3.91665C10.9249 3.91665 11.3449 4.33665 11.3449 4.85415C11.3449 5.37165 10.9249 5.79165 10.4074 5.79165ZM4.96989 4.85415C4.96989 5.37165 5.38989 5.79165 5.90739 5.79165C6.42489 5.79165 6.84489 5.37165 6.84489 4.85415C6.84489 4.33665 6.42489 3.91665 5.90739 3.91665C5.38989 3.91665 4.96989 4.33665 4.96989 4.85415Z"
        fill="#B4B7BA"
      />
      <mask
        id="mask0_34_98"
        style={{ 'mask-type': 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.49072 12.5V14.6666L8.49047 12.5H13.3241C14.1513 12.5 14.8241 11.8272 14.8241 11V2.83331C14.8241 2.00606 14.1513 1.33331 13.3241 1.33331H2.99072C2.16347 1.33331 1.49072 2.00606 1.49072 2.83331V11C1.49072 11.8272 2.16347 12.5 2.99072 12.5H4.49072ZM8.15731 9.91665C6.61981 9.91665 5.30281 8.98965 4.72381 7.66665H11.5908C11.0118 8.98965 9.69481 9.91665 8.15731 9.91665ZM10.4074 5.79165C9.88989 5.79165 9.46989 5.37165 9.46989 4.85415C9.46989 4.33665 9.88989 3.91665 10.4074 3.91665C10.9249 3.91665 11.3449 4.33665 11.3449 4.85415C11.3449 5.37165 10.9249 5.79165 10.4074 5.79165ZM4.96989 4.85415C4.96989 5.37165 5.38989 5.79165 5.90739 5.79165C6.42489 5.79165 6.84489 5.37165 6.84489 4.85415C6.84489 4.33665 6.42489 3.91665 5.90739 3.91665C5.38989 3.91665 4.96989 4.33665 4.96989 4.85415Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_34_98)">
        <rect x="0.157227" width="16" height="16" fill="white" />
      </g>
    </svg>
  );
};

const ChatbotWhiteIcon = props => <Icon component={ChatbotWhiteSvg} {...props} />;

export default ChatbotWhiteIcon;
