/* eslint-disable sonarjs/no-all-duplicated-branches */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import './Candidate360MailAndMessageComposeWindow.scss';
import CloseMailAndMessageComposeWindowModal from './CloseMailAndMessageComposeWindowModal';
import message from '../../../Containers/CandidateDrawer/messages';

export const getSendButtonWithTooltip = ({ shouldShowTooltip, sendButtonTooltipText, sendButton }) => {
  return shouldShowTooltip ? (
    <Tooltip title={sendButtonTooltipText} overlayStyle={{ zIndex: '2229' }} placement="topLeft">
      {sendButton}
    </Tooltip>
  ) : (
    sendButton
  );
};

function Candidate360MailAndMessageComposeWindow(props) {
  const {
    isModalVisible,
    setModalVisibility,
    id,
    htmlType,
    isSendDisabled,
    handleSubmit,
    composeModalTitle,
    context,
    candidate360MessageComposeSectionPayload,
    getCandidate360MailWindow,
    getCandidate360MessageWindow,
    preHeaderVisibility,
    onCloseEmailComposer,
    shouldShowTooltip,
    sendButtonTooltipText,
    jobId,
    jobTitle,
    candidateContext,
    version,
    jobCode,
    readChat,
    conversationId,
    currentValues = {},
    setPhoneNumber,
    getReportConversationDetails,
  } = props;
  const [isEmailModalMinimized, minimizeEmailModal] = React.useState(context === 'message');
  const [isCloseModalVisible, setCloseModalVisibility] = React.useState(false);
  const [sizeTooltipVisiblity, setSizeTooltipVisiblity] = React.useState(false);

  React.useEffect(() => {
    if (context === 'message') {
      const { selectedPhone } = currentValues;
      if (conversationId && selectedPhone) {
        setPhoneNumber(selectedPhone.Number);
      }
      readChat();
      getReportConversationDetails(conversationId);
    }
  }, []);

  React.useEffect(() => {
    return () => {
      if (context === 'message') {
        readChat();
      }
    };
  }, []);

  const modalContent =
    context === 'email'
      ? getCandidate360MailWindow()
      : getCandidate360MessageWindow(candidate360MessageComposeSectionPayload);

  const getModalBodyStyle = () => {
    const minimizedModalStyle = {
      height: '84%',
      backgroundColor: '#fafafb',
      padding: '0px',
    };
    const maximizedModalStyle = {
      height: context === 'email' ? 'calc(100vh - 91px)' : 'calc(100vh - 49px)',
      padding: '0px',
      backgroundColor: '#FAFAFA',
    };
    if (context === 'email') {
      minimizedModalStyle.padding = '4px 0 24px 24px';
      maximizedModalStyle.padding = '0 0 0 24px';
    }
    return isEmailModalMinimized ? minimizedModalStyle : maximizedModalStyle;
  };

  const handleOnClose = () => {
    setModalVisibility(false);
    if (onCloseEmailComposer) onCloseEmailComposer();
    if (isCloseModalVisible) setCloseModalVisibility(false);
  };

  const handleOnClickCloseIcon = () => {
    setCloseModalVisibility(true);
  };

  const handleOnSend = event => {
    setModalVisibility(false);
    handleSubmit(event);
    if (onCloseEmailComposer) onCloseEmailComposer();
  };

  const changeModalSize = () => {
    minimizeEmailModal(!isEmailModalMinimized);
    setSizeTooltipVisiblity(false);
  };
  const toggleTooltipVisibilty = value => {
    setSizeTooltipVisiblity(value);
  };
  const getModalContentClassname = () => {
    if (!preHeaderVisibility && isEmailModalMinimized) return 'candidate-360-view-modal-content';
    if (!preHeaderVisibility && !isEmailModalMinimized) return 'candidate-360-view-maximized-modal-content';
    return '';
  };

  const changeSizeIcon = isEmailModalMinimized ? 'arrows-alt' : 'shrink';
  const changeSizeButtonTooltipText = isEmailModalMinimized ? (
    <FormattedMessage {...message.expandLabel} />
  ) : (
    <FormattedMessage {...message.shrinkLabel} />
  );
  const tooltipPlacement = 'bottom';

  const modalContentClassname = getModalContentClassname();

  const sendButton = (
    <Button
      shape="round"
      key="submit"
      type="primary"
      id={id}
      htmlType={htmlType}
      disabled={isSendDisabled}
      onClick={handleOnSend}
    >
      <FormattedMessage {...message.sendButton} />
    </Button>
  );

  const displayedSendButton = getSendButtonWithTooltip({ shouldShowTooltip, sendButtonTooltipText, sendButton });

  const footerButtons = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <div className="action-buttons"></div>
      <div className="action-buttons">
        <Button shape="round" key="back" onClick={handleOnClose}>
          <FormattedMessage {...message.cancelButton} />
        </Button>
        {displayedSendButton}
      </div>
    </div>
  );

  const title = (
    <div className="candidate-360-compose-modal-title" style={jobTitle ? { fontWeight: '400' } : {}}>
      <span className="compose-modal-title">{composeModalTitle}</span>
      {jobTitle && (
        <Tooltip title={jobTitle} placement="bottomLeft" overlayStyle={{ zIndex: 3000 }}>
          <span style={jobTitle ? { flex: '1', textTransform: 'capitalize' } : {}}>
            &nbsp;-&nbsp;
            {candidateContext === 'job' ? (
              <FormattedMessage {...message.jobTitleLabel} />
            ) : (
              <FormattedMessage {...message.segmentTitleLabel} />
            )}
            : {_.truncate(jobTitle, { length: 43 })}, ID: {version === 'ats' ? jobCode : jobId}
          </span>
        </Tooltip>
      )}

      <div className="expand-shrink-icons">
        <div onMouseMove={() => toggleTooltipVisibilty(true)} onMouseLeave={() => toggleTooltipVisibilty(false)}>
          <Tooltip
            title={changeSizeButtonTooltipText}
            placement={tooltipPlacement}
            overlayStyle={{ zIndex: '2229' }}
            visible={sizeTooltipVisiblity}
          >
            <Icon type={changeSizeIcon} onClick={changeModalSize} />
          </Tooltip>
        </div>
        <Icon type="close" onClick={handleOnClickCloseIcon} />
      </div>
    </div>
  );
  return (
    <div>
      <Modal
        className={classNames('candidate-360-compose-modal', {
          'maximized-modal': !isEmailModalMinimized,
        })}
        title={title}
        visible={isModalVisible}
        zIndex={2222}
        footer={context === 'email' ? [footerButtons] : false}
        wrapClassName="modal-container"
        onCancel={handleOnClose}
        bodyStyle={getModalBodyStyle()}
        closable={false}
        maskClosable={false}
        style={{ maxHeight: '100vh', overflow: 'hidden' }}
      >
        <div
          className={classNames(modalContentClassname, {
            'maximized-modal-content': !isEmailModalMinimized,
          })}
        >
          {modalContent}
        </div>
      </Modal>
      {isCloseModalVisible ? (
        <CloseMailAndMessageComposeWindowModal
          isCloseModalVisible={isCloseModalVisible}
          setCloseModalVisibility={setCloseModalVisibility}
          onCloseComposeWindow={handleOnClose}
          context={context}
        />
      ) : null}
    </div>
  );
}

export default Candidate360MailAndMessageComposeWindow;
