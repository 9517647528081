import _ from 'lodash';

const setConversationMails = (state, mailKey, idValue, action) => {
  const newState = _.cloneDeep(state);
  const mails = _.get(state, [mailKey, idValue, 'Mails'], []);
  const mailIds = mails.map(mail => mail.Id);
  let pendingMails = _.get(state, ['Pending', mailKey, idValue], []);
  pendingMails = pendingMails.filter(mail => !mailIds.includes(mail.Id));
  _.set(newState, ['Pending', mailKey, idValue], pendingMails);
  newState[mailKey][idValue] = {
    Mails: [...(newState[mailKey][idValue]?.Mails || []), ...(action.payload.Mails || [])],
    MailsCount: action.payload.MailsCount,
  };
  return newState;
};

const setPendingMail = (state, key, id, email) => {
  const newState = _.cloneDeep(state);
  const mails = _.get(state, ['Pending', key, id], []);
  mails.unshift(email);
  _.set(newState, ['Pending', key, id], mails);
  return newState;
};

const addNewMail = (state, receivedMail, idKey, idValue) => {
  const newState = _.cloneDeep(state);
  let pendingMails = _.get(state, ['Pending', idKey, idValue], []);
  pendingMails = pendingMails.filter(mail => mail.Id !== receivedMail.Id);
  _.set(newState, ['Pending', idKey, idValue], pendingMails);
  const mails = _.get(state, [idKey, idValue, 'Mails'], []);
  const receivedMailGuid = receivedMail.Id;
  const index = mails.findIndex(mail => mail.Id === receivedMailGuid);
  if (index !== -1) {
    mails[index] = receivedMail;
  } else {
    mails.unshift(receivedMail);
    newState[idKey][idValue] = {
      ...newState[idKey][idValue],
      MailsCount: (newState[idKey][idValue]?.MailsCount || 0) + 1,
    };
  }
  newState[idKey][idValue].Mails = mails;
  return newState;
};

function MailConversationsReducer(state = {}, action) {
  let newState;
  const { isAllConversationsEnabled } = action.payload || {};
  switch (action.type) {
    case 'SET_CONVERSATION_MAILS':
      newState = _.cloneDeep(state);
      if (!newState.ByConversationId) {
        newState.ByConversationId = {};
      }
      if (!newState.ByPersonId) {
        newState.ByPersonId = {};
      }

      if (action.payload.conversationId) {
        newState = setConversationMails(newState, 'ByConversationId', action.payload.conversationId, action);
      } else if (action.payload.personId && isAllConversationsEnabled) {
        newState = setConversationMails(newState, 'ByPersonId', action.payload.personId, action);
      }
      return newState;
    case 'RESET_CONVERSATION_MAILS': {
      if (action.payload.conversationId)
        return {
          ...state,
          ByConversationId: {
            ...state.ByConversationId,
            [action.payload.conversationId]: {
              Mails: [],
              MailsCount: 0,
            },
          },
        };
      if (action.payload.personId && isAllConversationsEnabled)
        return {
          ...state,
          ByPersonId: {
            ...state.ByPersonId,
            [action.payload.personId]: {
              Mails: [],
              MailsCount: 0,
            },
          },
        };
      return state;
    }
    case 'SET_CONVERSATION_PENDING_MAILS':
      newState = _.cloneDeep(state);
      if (action.payload.conversationId) {
        newState = setPendingMail(state, 'ByConversationId', action.payload.conversationId, action.payload.email);
      } else if (action.payload.personId && isAllConversationsEnabled) {
        newState = setPendingMail(state, 'ByPersonId', action.payload.personId, action.payload.email);
      }
      return newState;

    case 'ADD_NEW_MAIL': {
      if (action.payload.Mail.RequestStatus === 'Pending') {
        return state;
      }
      newState = _.cloneDeep(state);
      if (!newState.ByConversationId) {
        newState.ByConversationId = {};
      }
      if (!newState.ByPersonId) {
        newState.ByPersonId = {};
      }
      const receivedMail = action.payload.Mail;
      if (action.payload.conversationId) {
        newState = addNewMail(newState, receivedMail, 'ByConversationId', action.payload.conversationId);
      }
      if (action.payload.personId && isAllConversationsEnabled) {
        newState = addNewMail(newState, receivedMail, 'ByPersonId', action.payload.personId);
      }
      return newState;
    }
    default:
      return state;
  }
}

function getMailConversations(state, conversationId) {
  const mails = _.get(state.MailConversationsReducer, ['ByConversationId', conversationId, 'Mails'], []);
  let pendingMails = _.get(state.MailConversationsReducer, ['Pending', 'ByConversationId', conversationId], []).map(
    email => ({
      ...email,
      Status: 'Pending',
    })
  );
  pendingMails = _.differenceBy(pendingMails, mails, 'Id');
  return [...pendingMails, ...mails];
}

function getAllMailConversations(state, personId) {
  const mails = _.get(state.MailConversationsReducer, ['ByPersonId', personId, 'Mails'], []);
  let pendingMails = _.get(state.MailConversationsReducer, ['Pending', 'ByPersonId', personId], []).map(email => ({
    ...email,
    Status: 'Pending',
  }));
  pendingMails = _.differenceBy(pendingMails, mails, 'Id');
  return [...pendingMails, ...mails];
}

function getAllMailCount(state, personId) {
  return _.get(state.MailConversationsReducer, ['ByPersonId', personId, 'MailsCount'], 0);
}

function getMailCount(state, conversationId) {
  return _.get(state.MailConversationsReducer, ['ByConversationId', conversationId, 'MailsCount'], 0);
}

export { MailConversationsReducer, getMailConversations, getAllMailConversations, getAllMailCount, getMailCount };
