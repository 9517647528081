import React from 'react';
import classNames from 'classnames';
import CandidateCardWrapper from '../CandidateCardWrapper';
import CandidateCardHeader from '../CandidateCardHeader';
import CandidateCardPersonalInfo from '../CandidateCardPersonalInfo';
import CandidateQualificationSummary from '../CandidateCardQualificationsSummary/CandidateCardQualificationSummary';
import eventTypes, { modules } from '../../../Analytics/EventTypes';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import { logSmartKarrotEvent } from '../../../Analytics/EventUtils';
import styles from './DuplicateCandidateCardContainer.module.scss';
import { getDefaultCandidateCardView } from '../../../Utils/CandidateCardUtils';

function DuplicateCandidateCardContainer(props) {
  const {
    candidateId,
    getCandidateDetails,
    userConfig,
    whiteLabelInfo,
    featureToggleList,
    candidateContext,
    candidateType,
    updateCandidateBookmarkStatus,
    jobId,
    candidateBookmarkApiStatus,
    version,
    indexKey,
    isCandidateInfoPanelAllowed,
    showCandidateDetail,
    isLastDuplicateCandidate,
    currentUserDetails,
    manualSearchFiltersStatus,
    candidateCollapsedFilters,
    setshowExpandedCandidateCard,
    areDuplicateCandidatesVisible,
    setShowDuplicateExpandedCandidateCard,
    showExpandedCandidateCard,
    setCandidateViewIconVisibility,
    isCandidateViewIconVisible,
    adminFeatures,
    activeSourceName,
  } = props;
  const candidate = getCandidateDetails(candidateId);

  const setCandidateCardViewType = () => {
    const candidateCardType = getDefaultCandidateCardView(
      candidateCollapsedFilters,
      candidate,
      currentUserDetails,
      featureToggleList,
      true,
      activeSourceName
    );
    setShowDuplicateExpandedCandidateCard(candidateCardType);
    if (!candidateCardType) setshowExpandedCandidateCard(candidateCardType);
  };

  React.useEffect(() => {
    setCandidateCardViewType();
  }, [candidate.LastViewedBy, manualSearchFiltersStatus, candidate.Status]);

  const onCandidateCardClick = tabName => {
    if (isCandidateInfoPanelAllowed(candidateId)) {
      if (showCandidateDetail) {
        showCandidateDetail(candidate, 'profile', indexKey, tabName);
      } else
        logSmartKarrotEvent(getEventNameByCandidateStatus(eventTypes.candidate.candidateView.view, candidate), {
          Module: modules.candidate.candidateActions,
        });
    }
  };

  const candidateCardClickDivProps = {
    tabIndex: 0,
    onClick: () => onCandidateCardClick('profile'),
    role: 'button',
    onKeyPress: () => onCandidateCardClick('profile'),
  };

  return areDuplicateCandidatesVisible ? (
    <div
      className={classNames(
        styles.duplicateCandidateCardContainer,
        {
          [styles.blurCandidateCardWrapper]: !showExpandedCandidateCard && isCandidateViewIconVisible,
        },
        {
          [styles.initialBlurCandidateCardWrapper]: !showExpandedCandidateCard && !isCandidateViewIconVisible,
        }
      )}
      {...candidateCardClickDivProps}
      data-testid="duplicate-candidate-card-wrapper"
      onMouseMove={() => {
        if (!showExpandedCandidateCard) setCandidateViewIconVisibility(true);
      }}
      onMouseLeave={() => {
        if (showExpandedCandidateCard) setCandidateViewIconVisibility(false);
      }}
    >
      <CandidateCardWrapper {...props} candidate={candidate} candidateCardContext="duplicate">
        <CandidateCardHeader showExpandedCandidateCard={showExpandedCandidateCard} />
        <div
          className={classNames(styles.duplicateCandidateInfo, {
            [styles.lastDuplicateCandidate]: isLastDuplicateCandidate,
          })}
        >
          <CandidateCardPersonalInfo
            candidate={candidate}
            userConfig={userConfig}
            whiteLabelInfo={whiteLabelInfo}
            featureToggleList={featureToggleList}
            candidateContext={candidateContext}
            candidateType={candidateType}
            updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
            jobId={jobId}
            candidateBookmarkApiStatus={candidateBookmarkApiStatus}
            version={version}
            adminFeatures={adminFeatures}
          />
          {showExpandedCandidateCard ? <CandidateQualificationSummary /> : null}
        </div>
      </CandidateCardWrapper>
    </div>
  ) : null;
}

export default DuplicateCandidateCardContainer;
