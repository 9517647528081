import React from 'react';
import { connect } from 'react-redux';
import ReportPortalComponent from '../../Components/ReportPortal/ReportPortalComponent';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import * as ReportPortalActions from '../../Actions/ReportPortalActions';
import styles from './ReportPortalContainer.module.scss';
import * as reportPortalReducer from '../../Reducers/ReportPortalReducer';

const mapStateToProps = state => {
  return {
    featureToggleList: getFeatureToggleList(state),
    currentNotification: reportPortalReducer.getCurrentNotification(state),
  };
};
const mapDispatchToProps = {
  getAvailableReportsForOrganization: ReportPortalActions.getAvailableReportsForOrganization,
};

export function ReportPortalContainer(props) {
  const { featureToggleList, getAvailableReportsForOrganization, currentNotification } = props;
  React.useEffect(() => {
    getAvailableReportsForOrganization();
  }, []);
  return (
    <div className={styles.reportingPortalContainer}>
      <ReportPortalComponent featureToggleList={featureToggleList} currentNotification={currentNotification} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPortalContainer);
