import { Button, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import * as ManualSearchActions from '../../../Actions/ManualSearchActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getCandidatesAsList } from '../../../Reducers/CandidateReducer';
import { getJobActiveSmartAgent } from '../../../Reducers/ManualSearchReducer';

const mapStateToProps = (state, props) => ({
  replenishSmartAgentCandidatesApiStatus: getApiStatus(state, 'replenishSmartAgentCandidatesApiStatus'),
  activeSmartAgent: getJobActiveSmartAgent(state, { jobId: props.jobId }),
  candidates: getCandidatesAsList(state),
});

const mapDispatchToProps = {
  replenishSmartAgentCandidates: ManualSearchActions.replenishSmartAgentCandidates,
};

function RefreshSmartAgentCandidates(props) {
  const {
    replenishSmartAgentCandidates,
    replenishSmartAgentCandidatesApiStatus,
    activeSmartAgent = {},
    jobId,
    candidates = [],
    sourcesPayload
  } = props;
  const agentId = activeSmartAgent.AgentId;
  const status = activeSmartAgent.Status;

  const isReplenishAgentButtonLoading = replenishSmartAgentCandidatesApiStatus === 'INPROGRESS';

  const onClick = () => {
    replenishSmartAgentCandidates({ jobId, agentId, sourcesPayload });
  };

  const isRefreshButtonDisabled = !agentId || status !== 'Completed' || !candidates.length;

  const tooltipContent = isRefreshButtonDisabled
    ? ''
    : 'Refreshes the list by replacing the job associated candidates with new ones.';

  return (
    <div>
      <Tooltip title={tooltipContent}>
        <Button onClick={onClick} loading={isReplenishAgentButtonLoading} disabled={isRefreshButtonDisabled}>
          Refresh Candidates
        </Button>
      </Tooltip>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RefreshSmartAgentCandidates);
